<template>
    <div class="panel_izda filtros">
        <v-text-field
            class="searcher"
            :type="'text'"
            v-model="filtro"
            :placeholder="$t(localeRoute + 'buscartrl')"
            :append-icon="'mdi-magnify'"
            clearable
            @click:clear="filtro = ''"
        ></v-text-field>
        <div class="consejeras-list">
            <p class="nav_link">{{usuario_loggeado.tipo === 'Admin' ? $t(localeRoute + 'consejeras') : $t(localeRoute + 'trls')}}</p>
            <v-container fluid class="tipo checkbox_container" v-if="usuarios_todos.length">
                <v-checkbox v-model="todos" :label="$t(localeRoute + 'todas')" class="checkbox" @click.native="todosClick"></v-checkbox>
                <v-checkbox v-for="(persona, index) in usuarios_todos" :key="index" 
                    v-model="persona.filtro" :label="persona.nombre" class="checkbox"
                    :disabled="check_disabled"
                >
                </v-checkbox>
            </v-container>
            <v-container v-else>
                <p class="parrafo">{{ $t(localeRoute + 'notienes') }}</p>
            </v-container>
        </div>
        <v-expansion-panels class="consejeras-expansion-panel" v-model="panelTareas">
            <v-expansion-panel>
                <v-expansion-panel-header>Filtrar usuarios</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field
                        :type="'text'"
                        v-model="filtro"
                        :placeholder="$t(localeRoute + 'buscartrl')"
                        :append-icon="'mdi-magnify'"
                        clearable
                        @click:clear="filtro = ''"
                    ></v-text-field>
                    <v-checkbox v-model="todos" :label="$t(localeRoute + 'todas')" class="checkbox" @click.native="todosClick"></v-checkbox>
                    <v-checkbox v-for="(persona, index) in usuarios_todos" :key="index" 
                        v-model="persona.filtro" :label="persona.nombre" class="checkbox"
                        :disabled="check_disabled"
                    >
                    </v-checkbox>
                    <button class="boton cta mb-4 w-100" @click="panelTareas = !panelTareas">Filtrar</button>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'PanelFiltrosTareas',
	props: [],
    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            // ruta: null,
            filtro: '',
            todos: true,
            usuarios_todos: [],
            usuarios_filtrados: [],
            check_disabled: false,
            // trls: [],
            // consejeras: [],
            panelTareas: 1
        }
    },
	computed: {
        ...mapState([
            'usuarios', 'usuario_loggeado'
        ]),
    },
    watch: {
        usuarios_todos: {
            handler(newComponents, oldComponents) {
                this.filtrarUsuarios();
                if (this.usuarios_todos.find(us => us.filtro == false)) {
                    this.todos = false;
                } else {
                    this.todos = true;
                }
            },
            deep: true
        },
        filtro() {
            this.filtro.length ? this.check_disabled = true : this.check_disabled = false;
            this.usuarios_todos.forEach(usuario => {
                if (usuario.nombre.toLowerCase().includes(this.filtro.toLowerCase()) || this.filtro === '') {
                    usuario.filtro = true;
                } else {
                    usuario.filtro = false;
                }
            });
        },
        usuarios() {
            this.setUsuarios();
        },
        usuario_loggeado() {
            this.setUsuarios();
        }
    },
    created: function() {
        this.setUsuarios();
    },
    mounted: function() {},

    methods: {
        filtrarUsuarios() {
            let filtrados = [];
            this.usuarios_todos.forEach(usuario => {
                if (usuario.filtro) { 
                    filtrados.push(usuario.id);
                }
            });
            // console.log(filtrados);
            // this.$emit('filtrarUsuarios', filtrados);
            this.$emit('filtrarUsuarios', {filtrados: filtrados, filtro: this.filtro});
        },
        setUsuarios() {
            this.usuarios_todos = [];
            this.usuarios.forEach(usuario => {
                const nombre = usuario.personales.nombre + ' ' + usuario.personales.apellidos;
                const el = {
                    nombre: nombre,
                    id: usuario.id,
                    filtro: true
                }
                if ((this.usuario_loggeado.tipo === 'Admin') && (usuario.tipo === "Consejera" || usuario.tipo === 'Admin')) {
                    this.usuarios_todos.push(el);
                } else if ((this.usuario_loggeado.tipo === 'Consejera') && (usuario.tipo === "TRL") && (usuario.consejera.id == this.usuario_loggeado.id)) {
                    this.usuarios_todos.push(el);
                }
            });
            this.usuarios_todos.sort((a, b) => a.nombre.localeCompare(b.nombre));
            this.usuarios_filtrados = [...this.usuarios_todos];
        },
        todosClick() {
            if (this.todos) {
                this.usuarios_todos.forEach(usu => usu.filtro = true);
            } else {
                this.usuarios_todos.forEach(usu => usu.filtro = false);
            }
        }
	}
}
</script>
<style scoped lang="scss">
.v-application #app .view .filtros {
    .consejeras-expansion-panel {
        display: none;
    }
    .nav_link {
        width: 100%;
        padding-left: 0;
        padding-bottom: .5em;
        border-bottom: 1px solid var(--purple03);
    }
    .tipo {
        padding-left: 0;
        ::v-deep .v-input--selection-controls__input {
            margin-right: 1em;
        }
    }
    .estado {
        padding-left: 0;
        ::v-deep .v-radio {
            width: calc(50% - 1em);
            .v-input--selection-controls__input {
                margin-right: .5em;
            }
        }
    }
}
 @media (max-width: 900px) {
     .v-application #app .view .filtros {
        .searcher {
            display: none;
        }
        .consejeras-expansion-panel {
            display: block;
        }
        .consejeras-list {
            display: none;
        }
     }
 }
</style>
