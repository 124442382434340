<template>
    <div></div>
</template>

<script>
import { mapState } from 'vuex'
import {webServices} from '@/api/api.js'

export default {
	name: 'Service',
    props: ['ws', 'data', 'need_token', 'spinner'],
    data() {
        return {
            intentos: 0
        }
    },

    watch: {
        ws() {
            if (this.ws) {
                this.ws === 'multiple' ? this.multipleServiceCall()  : this.serviceCall();
            }
        }
    },

	computed: {
        ...mapState([
            'token'
        ]),
    },

    methods: {
        serviceCall() {
            if (this.spinner) this.$store.dispatch('setLoading', true);
            let ws;
            let that = this;
            if (this.data) { 
                ws = webServices[this.ws](this.token, this.data);
            } else {
                ws = webServices[this.ws](this.token)
            }
            fetch(ws.url, ws.requestOptions)
                .then(response => {return response.json()})
                .then(result => {
                    this.intentos = 0;
                    if (result.code == 401 && result.data != "User not active.") {
                        if (result.data === "Expired JWT Token" || result.message === "Expired JWT Token") {
                            this.apiLogin();
                        } else {
                            console.log('autologout');
                            localStorage.removeItem('isLogged');
                            localStorage.removeItem('usuario_logged');
                            localStorage.removeItem('toquen');
                            if (this.$router.currentRoute.path != '/Login' && this.$router.currentRoute.path != '/') {
                                this.$router.push('/');
                            }
                            if (this.spinner) this.$store.dispatch('setLoading', false);
                        }
                    } else {
                        if (this.spinner) this.$store.dispatch('setLoading', false);
                        this.$emit('success', {'ws': that.ws, 'data': result.data, 'error': result.error});
                    }
                })
                .catch(error => {
                    this.$emit('error', error);
                    this.$store.dispatch('setLoading', true);
                    if (this.intentos < 2) {
                        this.intentos++;
                        this.serviceCall()
                    } else {
                        this.$store.dispatch('setLoading', false);
                        if (that.ws === 'getUser' || that.ws === 'getUserEnc') {
                            this.$emit('errorImportante', error);
                        }
                    }
                });
        },
        multipleServiceCall() {
            let that = this;
            let wss = [];
            this.data.calls.forEach(call => {
                let ws = webServices[call.ws](this.token, call.data);
                wss.push(fetch(ws.url, ws.requestOptions).then(response => response.json()));
            });

            Promise.all(wss)
                .then(responses => {
                    let error = false;
                    responses.forEach(result => {
                        result.error ? error = true : '' ;
                    })
                    if (error) {
                        debugger;
                        this.apiLogin()
                    } else {
                        this.$emit('success', {'ws': that.ws, 'data': responses});
                    }
                })
                .catch(error => this.$emit('error', error));
        },
        apiLogin() {
            console.log('api login');
            let ws = webServices.getToken();
            fetch(ws.url, ws.requestOptions)
                .then(response => {return response.text();})
                .then(result => {
                    localStorage.setItem('toquen', result.token);
                    this.$store.dispatch('setToken', result.token);
                    this.serviceCall();
                })
                .catch(error => console.log('error', error));
        },
	},
    created: function() {
    },
    mounted: function() {}
}
</script>
