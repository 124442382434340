<template>
	<div id="servicios_crud">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'
            @error='console.log($event)'
            @errorImportante="modal_simple_error = true"
        />
        <panel-filtros-servicios 
            :disableChecks="disableChecks"
            :lista_categorias="lista_categorias" 
            @filtrarServicios="filtrarServicios"
        />
        <modal-crear-servicio 
            v-if="modal_crear_servicio" 
            :tipo_servicio="tipo_servicio" 
            :servicio="servicio_selected"
            :categorias="lista_categorias"
            :servicios_ordenados="servicios_ordenados"
            :servicio_tipos="servicio_categorias"
            @closeModalServicio="closeModalServicio" 
        />
        <div class="view-container">
            <div class="subheader">
                <h3 class="titulo">Servicios y Paquetes</h3>
                <div class="toggle">
                    <button @click.stop="toggle_servicio = !toggle_servicio;" class="boton">Nuevo</button>
                    <ul v-if="toggle_servicio">
                        <li class="crear_servicio" @click="openModalServicio('servicio')">
                            Servicio
                        </li>
                        <li class="crear_servicio" @click="openModalServicio('paquete')">
                            Paquete
                        </li>
                    </ul>
                </div>
            </div>
            <v-tabs class="tabs" v-model="tabs" show-arrows>
                <v-tab href="#servicios" class="tab" @click="enableCategories">
                    <p class="nav_link">Servicios <span v-if="filtro_nombre">({{servicios_filtrados.length}})</span></p>
                </v-tab>
                <v-tab href="#paquetes" class="tab" @click="disableCategories">
                    <p class="nav_link">Paquetes <span v-if="filtro_nombre">({{paquetes_filtrados.length}})</span></p>
                </v-tab>
                <v-tab-item value="servicios">
                    <v-row v-for="(tipo, index) in servicio_categorias" :key="index" class="todos_servicios">
                        <v-col cols="12">
                            <h3 class="area_titulo">{{tipo}}</h3>
                        </v-col>
                        <v-col cols="12">
                            <div class="lista_servicios">
                                <div class="servicio" v-for="(servicio, subindex) in servicios_ordenados[tipo]" :key="subindex">
                                    <div class="card" 
                                        @click="openModalServicio('servicio', servicio)" 
                                        @mouseover="servicio.sub.length ? servicio.show_sub = true : ''"
                                        @mouseleave="servicio.sub.length ? servicio.show_sub = false : ''"
                                    >
                                        <p class="parrafo">{{ servicio.nombre }}</p>
                                        <div class="mostrar_sub" v-if="servicio.sub.length">
                                            <svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="2" cy="2" r="2" fill="#A291D6"/><circle cx="10" cy="2" r="2" fill="#A291D6"/><circle cx="18" cy="2" r="2" fill="#A291D6"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="card subcards" v-if="servicio.sub && servicio.show_sub" @click="servicio.show_sub = false">
                                        <p class="parrafo" v-for="(sub, iindex) in servicio.sub" :key="iindex">
                                            {{sub}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item value="paquetes">
                    <v-row>
                        <v-col cols="12">
                            <div class="lista_paquetes">
                                <div class="card" v-for="(paquete, subindex) in paquetes_filtrados" :key="subindex"
                                    @click="openModalServicio('paquete', paquete)"
                                >
                                    <!-- <div class="icono">
                                        <img :src="paquete.icono"/>
                                    </div> -->
                                    <!-- <v-icon class="icon">mdi-information-outline</v-icon> -->
                                    <p class="parrafo">{{paquete.nombre}}</p>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
import PanelFiltrosServicios from '@/components/PanelFiltrosServicios.vue'
import ModalCrearServicio from '@/components/ModalCrearServicio.vue'
export default {
	name: 'Servicios',
	components: {
        Service,
        PanelFiltrosServicios,
        ModalCrearServicio
	},
	computed: {
        ...mapState([
            'servicios',
            'paquetes'
        ]),
    },

    data() {
        return {
            localeRoute: `message.pag_${[this.$options.name.toLowerCase()]}.`,
            servicios_ordenados: {},
            servicio_categorias: [],
            servicios_filtrados: [],
            filtro_nombre: false,
            tabs: 'servicios',
            toggle_servicio: false,
            modal_crear_servicio: false,
            tipo_servicio: null,
            servicio: null,
            lista_categorias: [],
            ws: null,
            data: null,
            ws_spinner: false,
            disableChecks: false
        }
    },

    watch: {
        servicios() {
            this.servicios_filtrados = [...this.servicios.filter(serv => !serv.deleted)];
            this.listarServicios();
            this.listarCategorias();
        },
        paquetes() {
            this.paquetes_filtrados = [...this.paquetes.filter(paq => !paq.deleted)];
        }
    },
    created: function() {
    },

    mounted: function() {
        this.ws_spinner = true;
        this.ws = 'serviceList';
    },
    methods: {
        openModalServicio(tipo, servicio) {
			this.tipo_servicio = tipo;
            this.toggle_servicio = false;
            if (tipo === 'servicio') {
                this.servicio_selected = servicio ? servicio.original : null;
            } else if (tipo === 'paquete') {
                this.servicio_selected = servicio || null;
            }
            this.modal_crear_servicio = true;
        },
        closeModalServicio(tipo) {
            this.modal_crear_servicio = false;
			this.tipo_servicio = null;
            this.servicios = null;
            if (tipo === 'servicio') this.tabs = 'servicios'
            else if (tipo === 'paquete') this.tabs = 'paquetes';
        },
        listarCategorias() {
            this.lista_categorias = [];
            this.servicios_filtrados.forEach(servicio => {
                let categoria = {
                    nombre: servicio.tipo,
                    active: true
                }
                let categoria_existe = this.lista_categorias.find(cat => cat.nombre === servicio.tipo);
                if (!categoria_existe) {
                    this.lista_categorias.push(categoria);
                }
            });
            this.lista_categorias.sort((a, b) => a.nombre.localeCompare(b.nombre));

        },
        listarServicios() {
            this.servicio_categorias = [];
            this.servicios_ordenados = {};
            this.servicios_filtrados.forEach(servicio => {
                let tipo = this.servicios_ordenados[servicio.tipo];
                if (!tipo) {
                    this.servicios_ordenados[servicio.tipo] = [servicio];
                    this.servicio_categorias.push(servicio.tipo);
                } else {
                    tipo.push(servicio);
                }
            });
        },
        filtrarServicios(filtros) {
            this.filtro_nombre = filtros.filtro.length ? true : false;
            //filtrar servicios por nombre o categoria
            this.servicios_filtrados = this.servicios.filter(servicio => {
                let categoria = filtros.categorias.includes(servicio.tipo);
                let nombre = servicio.nombre.toLowerCase().includes(filtros.filtro.toLowerCase()) || filtros.filtro === '';
                return categoria && nombre && !servicio.deleted;
            });
            //volver a listar los servicios
            this.listarServicios();

            //filtrar paquetes por nombre
            this.paquetes_filtrados = this.paquetes.filter(paq => {
                let nombre = paq.nombre.toLowerCase().includes(filtros.filtro.toLowerCase()) || filtros.filtro === '';
                return nombre && !paq.deleted;
            });
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
			if (response.ws === 'serviceList') {
                this.ws_spinner = false;
                this.ws = 'packList';
                this.$store.dispatch('setServicios', response.data);
            } else if (response.ws === 'packList') {
                this.ws = 'servicePackList';
                this.$store.dispatch('setPaquetes', response.data);
            }
            this.ws_spinner = false;
        },
        disableCategories() {
            this.disableChecks = true;
        },
        enableCategories() {
            this.disableChecks = false;
        }
	}
}
</script>
<style scoped lang="scss">
#servicios_crud {
    #servicios, #paquetes {
        padding-bottom: 10em;
        .todos_servicios .area_titulo { 
            color: var(--purple03);
            font-weight: bold;
            font-size: 1.2em;
        }
        .lista_servicios, .lista_paquetes {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 25px 20px;
            margin-bottom: 10px;
            padding: 0 3px; 
            .card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all .2s ease-in;
                height: 8.5em;
                cursor: pointer;
                &:hover>.parrafo {color: var(--purple03);}
                .icon {
                    position: absolute;
                    right: .2em;
                    top: .2em;
                }
                .parrafo {
                    text-align: center;
                    padding: 1.1em 0;
                    width: 100%;
                }
                .mostrar_sub {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    text-align: center;
                    padding: .5em 0 2em;
                }
            }
            .servicio {
                position: relative;
                .subcards {
                    // display: flex;
                    position: absolute;
                    height: auto;
                    top: 8.6em;
                    z-index: 100;
                    box-shadow: var(--sombra_ext_dark);
                    width: 100%;
                    &:hover>.parrafo {color: inherit;}
                    .parrafo {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        margin-top: 0;
                        padding-top: 0;
                        &:not(:last-child) {
                            margin-bottom: .5em;
                            padding-bottom: .5em;
                            border-bottom: 1px solid var(--gray03);
                        }
                        &:not(:first-child) {
                            margin-top: .5em;
                            padding-top: .5em;
                        }
                    }
                }
            }
        }
        .lista_paquetes {padding-top: 2em;}
    }
    ::v-deep .v-tabs-bar {
        background: none;
        border-bottom: 1px solid var(--gray03);
        .v-tab {
            text-transform: unset;
            text-align: center;
            &.v-tab--active {
                .nav_link {
                    color: var(--purple03);
                    font-weight: 600;
                }
            }
        }
        .v-tabs-slider-wrapper {color: var(--purple03); height: 3px;}
    }
    ::v-deep .v-tabs-items {
        background-color: transparent!important;
        overflow-y: auto;
        max-height: calc(100vh - 14em);
        overflow-x: hidden;
    }
    .trl::v-deep .v-tabs-items {max-height: unset;}
}
@media (max-width: 900px) {
    #servicios_crud {
        #servicios .lista_servicios, #paquetes .lista_paquetes {
            margin-bottom: 0;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1em;
        }
    }
}
@media (max-width: 760px) {
    #servicios_crud {
        #servicios .lista_servicios, #paquetes .lista_paquetes {
            margin-bottom: 0;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1em;
        }
    }
}
</style>

