<template>
    <div></div>
</template>

<script>
//generate doc from template
import Docxtemplater from 'docxtemplater'
import PizZip from "pizzip"
import PizZipUtils from "pizzip/utils/index.js"
import { saveAs } from 'file-saver';

export default {
	name: 'StDocxTemplater',
    components: {},
    props: [
        "usuario", 'trigger'
    ],
    data() {
        return {
        }
    },

    watch: {
        trigger() {
            this.trigger ? this.crearWord() : '';
        }
    },

	computed: {
    },

    methods: {
        crearWord() {
            let that = this;
            function loadFile (url,callback) {
                PizZipUtils.getBinaryContent(url,callback);
            };
            let usuario = this.usuario;
            loadFile('https://www.stepsrelocation-panel.com/docs/template_contrato.docx', function (error,content) {
                if (error) { throw error };
                var zip = new PizZip(content);
                var doc = new Docxtemplater(zip);
                doc.setData({
                    trl_name: 'don/doña ' + usuario.personales.nombre + ' ' + usuario.personales.apellidos || '',
                    trl_dni: usuario.personales.pasaporte || '',
                    trl_email: usuario.contacto.email_profesional || '',
                    propietario_name: usuario.servicio_vivienda.datos_vivienda.propietario_nombre || '',
                    propietario_dni: usuario.servicio_vivienda.datos_vivienda.propietario_dni || '',
                    propietario_direccion: usuario.servicio_vivienda.datos_vivienda.propietario_direccion || '',
                    propietario_iban: usuario.servicio_vivienda.datos_vivienda.propietario_iban || '',
                    propietario_email: usuario.servicio_vivienda.datos_vivienda.propietario_email || '',
                    vivienda_direccion: usuario.servicio_vivienda.datos_vivienda.direccion || '',
                    vivienda_ref_catastral: usuario.servicio_vivienda.datos_vivienda.referencia_catastral || '',
                    trastero: '' || '',
                    garaje: '' || '',
                    otros: '' || '',
                    fecha_inicio: usuario.servicio_vivienda.contrato_alquiler.fecha_inicio || '',
                    duracion: usuario.servicio_vivienda.contrato_alquiler.duracion_obligatoria || '',
                    alquiler: usuario.servicio_vivienda.contrato_alquiler.renta || '',
                    fianza: usuario.servicio_vivienda.contrato_alquiler.fianza || '',
                    deposito: usuario.servicio_vivienda.contrato_alquiler.deposito || ''
                });
                try {
                    doc.render();
                } catch (error) {
                    function replaceErrors(key, value) {
                        if (value instanceof Error) {
                            return Object.getOwnPropertyNames(value).reduce(function(error,key) {
                                error[key] = value[key];
                                return error;
                            }, {});
                        }
                        return value;
                    }
                    console.log(JSON.stringify({ error: error }, replaceErrors));
                    if (error.properties && error.properties.errors instanceof Array) {
                        const errorMessages = error.properties.errors.map(function(error) {return error.properties.explanation;}).join("\n");
                        console.log("errorMessages", errorMessages);
                    }
                    throw error;
                }

                var out = doc.getZip().generate({
                    type: "blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                });
                saveAs(out, "contrato_" + usuario.personales.apellidos + ".docx");
                that.$emit('fin', true);
            });
        },
	},
    created: function() {
    },
    mounted: function() {
    }
}
</script>

