<template>
    <div class="modal">
        <service 
            :ws='ws'
            :data="data" 
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <div class="submodal">
            <svg class="cerrar_modal" @click="cerrarModal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <p class="titulo bold"><span>Historial de:</span> <br v-if="$vuetify.breakpoint.xs">{{usuario.personales.nombre}}</p>
            <!-- <div class="tabla">
                <ul class="fila titulos">
                    <li class="parrafo bold fecha">Fecha</li>
                    <li class="parrafo bold quien">Quién</li>
                    <li class="parrafo bold campo">Campo</li>
                    <li class="parrafo bold anterior">Dato anterior</li>
                    <li class="parrafo bold actual">Dato actual</li>
                </ul>
                <ul class="card lista">
                    <li class="fila" v-for="(log, index) in full_log" :key="index">
                        <p class="parrafo fecha">{{log.fecha}}</p>
                        <p class="parrafo quien">{{log.quien}}</p>
                        <p class="parrafo campo">{{log.campo}}</p>
                        <p class="parrafo anterior">{{log.anterior}}</p>
                        <p class="parrafo actual">{{log.actual}}</p>
                    </li>
                </ul>
            </div> -->
            <v-data-table
                :headers="headers"
                :items="full_log"
                :items-per-page="5"
                class="elevation-1 tabla-historial"
            ></v-data-table>
            <vue-excel-xlsx
                class="boton cta"
                :data="excelData"
                :columns="excelColumns"
                :filename="'Log_' + usuario.personales.apellidos"
                :sheetname="'Hoja1'">
                <span>Descargar</span>
            </vue-excel-xlsx>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'

export default {
	name: 'ModalTrlLog',
    components: {Service},
    props: {
        usuario: Object
    },
    data() {
        return {
            full_log: [],
            ws: null,
            data: null,
            excelData: [],
            excelColumns : [
                {
                    label: 'Fecha',
                    field: 'fecha'
                },
                {
                    label: 'Quién',
                    field: 'quien'
                },
                {
                    label: 'Campo',
                    field: 'campo'
                },
                {
                    label: 'Dato anterior',
                    field: 'anterior'
                },
                {
                    label: 'Dato actual',
                    field: 'actual'
                }
            ],
            campos: {
                'sruser.status': 'Estado',
                'sruser.trlstatus': 'TRL finalizado',
                'sruser.email': 'Email',
                'sruser.name': 'Nombre',
                'sruser.surnames': 'Apellidos',
                'sruser.phone_number': 'Teléfono',
                'sruser.languages': 'Idioma',
                'sruser.photo': 'Foto',
                'sruser.secondary_email': 'Email personal',
                'sruser.relocation_city': 'Ciudad de realojo',
                'sruser.nationality': 'Nacionalidad',
                'sruser.identity_id_photo': 'Imagen del pasaporte',
                'sruser.identity_id': 'Pasaporte / DNI',
                'sruser.current_address': 'Direccion actual',
                'sruser.privacy_policy': 'Política de privacidad',
                'sruser.spouse_privacy_policy': 'Política de privacidad de cónyuge',
                'sruser.user_status': 'Estado del usuario',
                'sruser.trl_status': 'TRL finalizado',
                'sruser.rrhh': 'RRHH',
                'trlUserHomeContract.date_advise': 'Datos empresa: fecha aviso',
                'trlUserHomeContract.date_first_contact': 'Datos empresa: fecha primer contacto',
                'trlUserHomeContract.date_arrival': 'Datos empresa: fecha llegada',
                'trlUserHomeContract.date_home_visit': 'Datos empresa: fecha visita a casas',
                'trlUserHomeContract.date_search_school': 'Datos empresa: fecha búsqueda colegios',
                'trlUserHomeContract.date_signature_contract': 'Datos empresa: Fecha firma contrato',
                'trlUserHomeContract.date_entry': 'Datos empresa: Fecha entrada',
                'trlUserHomeContract.company_notes': 'Datos empresa: Notas',
                'trlUserHomeContract.home_cadastral_reference': 'Datos vivienda: referencia catastral',
                'trlUserHomeContract.home_owner_name': 'Datos vivienda: nombre del propietario',
                'trlUserHomeContract.home_owner_identity_id': 'Datos vivienda: DNI del propietario',
                'trlUserHomeContract.home_owner_phone_number': 'Datos vivienda: teléfono del propietario',
                'trlUserHomeContract.home_owner_email': 'Datos vivienda: email del propietario',
                'trlUserHomeContract.home_owner_iban': 'Datos vivienda: IBAN del propietario',
                'trlUserHomeContract.home_keeper_name': 'Datos vivienda: nombre del encargado',
                'trlUserHomeContract.home_keeper_phone_number': 'Datos vivienda: teléfono del encargado',
                'trlUserHomeContract.home_keeper_email': 'Datos vivienda: email del encargado',
                'trlUserHomeContract.home_real_estate_agency_name': 'Datos vivienda: nombre de la agencia',
                'trlUserHomeContract.home_real_estate_agency_phone_number': 'Datos vivienda: teléfono de la agencia',
                'trlUserHomeContract.home_real_estate_agency_email': 'Datos vivienda: email de la agencia',
                'trlUserHomeContract.home_notes': 'Datos vivienda: notas',
                'trlUserHomeContract.home_rental_mandatory_duration': 'Contrato: duración obligatoria',
                'trlUserHomeContract.home_rental_notice': 'Contrato: preaviso',
                'trlUserHomeContract.home_rental_rent': 'Contrato: renta',
                'trlUserHomeContract.home_rental_holder': 'Contrato: a nombre de',
                'trlUserHomeContract.home_rental_signature': 'Contrato: firmante',
                'trlUserHomeContract.home_rental_deposit': 'Contrato: depósito / aval',
                'trlUserHomeContract.home_rental_deposit_holder': 'Contrato: depósito / aval pagador',
                'trlUserHomeContract.home_rental_guarantee': 'Contrato: fianza',
                'trlUserHomeContract.home_rental_guarantee_holder': 'Contrato: fianza pagador',
                'trlUserHomeContract.home_rental_agency_commission': 'Contrato: comisión agencia',
                'trlUserHomeContract.home_rental_agency_commission_holder': 'Contrato: comisión agencia pagador',
                'trlUserHomeContract.home_rental_photographic_inventory': 'Contrato: inventario fotográfico',
                'trlUserHomeContract.home_rental_extendable': 'Contrato: prorrogable',
                'trlUserHomeContract.home_checkin_presence_tenant': 'Contrato: presencia del inquilino',
                'trlUserHomeContract.home_checkin_presence_cons': 'Contrato: presencia de la consejera',
                'trlUserHomeContract.home_checkin_presence_agent': 'Contrato: presencia del agente',
                'trlUserHomeContract.home_checkin_presence_owner': 'Contrato: presencia del dueño',
                'trlUserHomeContract.home_rental_date_init': 'Contrato: fecha inicio',
                'trlUserHomeContract.home_rental_contract_notes': 'Contrato: otros',
                'trlUserHomeContract.home_address': 'Vivienda: dirección',
                'trlUserHomeContract.home_owner_address': 'Contrato: dirección propietario',
                'trlUserHomeContract.home_insurance_owner_name': 'Seguro hogar (propietario): nombre',
                'trlUserHomeContract.home_insurance_owner_phone': 'Seguro hogar (propietario): teléfono',
                'trlUserHomeContract.home_insurance_owner_policy': 'Seguro hogar (propietario): num. póliza',
                'trlUserHomeContract.home_insurance_owner_email': 'Seguro hogar (propietario): email',
                'trlUserHomeContract.home_owner_insurance_notes': 'Seguro hogar (propietario): notas',
                'trlUserHomeContract.home_insurance_tenant_name': 'Seguro hogar (inquilino): nombre',
                'trlUserHomeContract.home_insurance_tenant_phone': 'Seguro hogar (inquilino): teléfono',
                'trlUserHomeContract.home_insurance_tenant_policy': 'Seguro hogar (inquilino): num. póliza',
                'trlUserHomeContract.home_insurance_tenant_email': 'Seguro hogar (inquilino): email',
                'trlUserHomeContract.home_tenant_insurance_notes': 'Seguro hogar (inquilino): notas',
                'trlUserHomeContract.home_preselected_homes': 'Viviendas enviadas',
                'trlUserRelocationPreferences.relocation_date': 'Preferencias traslado: fecha traslado',
                'trlUserRelocationPreferences.desired_relocation_date': 'Preferencias traslado: fecha entrada deseada',
                'trlUserRelocationPreferences.relocation_visit_date': 'Preferencias traslado: fecha disponible visitas',
                'trlUserRelocationPreferences.relocation_budget': 'Preferencias traslado: presupuesto',
                'trlUserRelocationPreferences.relocation_rental_duration': 'Preferencias traslado: duración alquiler',
                'trlUserRelocationPreferences.house_type_piso': 'Preferencias traslado: casa',
                'trlUserRelocationPreferences.house_type_casa': 'Preferencias traslado: piso',
                'trlUserRelocationPreferences.house_meters': 'Preferencias traslado: metros cuadrados',
                'trlUserRelocationPreferences.house_rooms_number': 'Preferencias traslado: num. habitaciones',
                'trlUserRelocationPreferences.house_state_amueblada': 'Preferencias traslado: amueblada',
                'trlUserRelocationPreferences.house_state_noamueblada': 'Preferencias traslado: sin amueblar',
                'trlUserRelocationPreferences.house_style_clasico': 'Preferencias traslado: estilo clásico',
                'trlUserRelocationPreferences.house_style_moderno': 'Preferencias traslado: estilo moderno',
                'trlUserRelocationPreferences.house_elevator': 'Preferencias traslado: ascensor',
                'trlUserRelocationPreferences.house_pool': 'Preferencias traslado: piscina',
                'trlUserRelocationPreferences.house_garden': 'Preferencias traslado: jardín',
                'trlUserRelocationPreferences.house_terrace': 'Preferencias traslado: terraza',
                'trlUserRelocationPreferences.house_room_service': 'Preferencias traslado: habitación de servicio',
                'trlUserRelocationPreferences.house_city_center': 'Preferencias traslado: en el centro',
                'trlUserRelocationPreferences.house_near_office': 'Preferencias traslado: cerca de la oficina',
                'trlUserRelocationPreferences.house_near_school': 'Preferencias traslado: cerca del colegio',
                'trlUserRelocationPreferences.house_near_subway': 'Preferencias traslado: cerca del metro / FCG',
                'trlUserRelocationPreferences.house_sunny': 'Preferencias traslado: soleado',
                'trlUserRelocationPreferences.house_quiet_zone': 'Preferencias traslado: zona tranquila',
                'trlUserRelocationPreferences.house_residential_areas': 'Preferencias traslado: áreas residenciales',
                'trlUserRelocationPreferences.house_garage': 'Preferencias traslado: garage',
                'trlUserRelocationPreferences.house_parking_spaces': 'Preferencias traslado: plazas parking',
                'trlUserRelocationPreferences.house_parking_spaces_in_budget': 'Preferencias traslado: parking en presupuesto',
                'trlUserRelocationPreferences.house_pet_allowed': 'Preferencias traslado: mascota',
                'trlUserRelocationPreferences.house_pet_type': 'Preferencias traslado: tipo de mascota',
                'trlUserRelocationPreferences.house_others': 'Preferencias traslado: otros',
                'trlUserRelocationPreferences.house_city_outskirts': 'Preferencias traslado: en las afueras',
                'trlUserRelocationPreferences.house_children_area': 'Preferencias traslado: zonas infantiles',
                'trlUserRelocationPreferences.concertado': 'Preferencias colegios: concertado',
                'trlUserRelocationPreferences.privado' : 'Preferencias colegios: privado',
                'trlUserRelocationPreferences.publico' : 'Preferencias colegios: público',
                'trlUserRelocationPreferences.education_german' : 'Preferencias colegios: alemana',
                'trlUserRelocationPreferences.education_american' : 'Preferencias colegios: americana',
                'trlUserRelocationPreferences.education_britsh' : 'Preferencias colegios: británica',
                'trlUserRelocationPreferences.education_spanish' : 'Preferencias colegios: española',
                'trlUserRelocationPreferences.education_french' : 'Preferencias colegios: francesa',
                'trlUserRelocationPreferences.education_other' : 'Preferencias colegios: otras',
                'trlspouse.name': 'Datos cónyuge: nombre',
                'trlspouse.surnames': 'Datos cónyuge: apellidos',
                'trlspouse.nationality': 'Datos cónyuge: nacionalidad',
                'trlspouse.languages': 'Datos cónyuge: idiomas',
                'trlspouse.email': 'Datos cónyuge: email',
                'trlspouse.phone_number': 'Datos cónyuge: teléfono',
                'trlspouse.identity_id': 'Datos cónyuge: pasaporte / DNI',
                'trlspouse.identity_id_photo': 'Datos cónyuge: Imagen del pasaporte',
                'sruser.available_legal_user_access': 'Mostrar a Legal'
            },
            headers: [
                {
                    text: 'Fecha',
                    align: 'start',
                    sortable: false,
                    value: 'fecha',
                },
                { text: 'Quién', value: 'quien' , sortable: false,},
                { text: 'Campo', value: 'campo' , sortable: false,},
                { text: 'Dato anterior', value: 'anterior' , sortable: false,},
                { text: 'Dato actual', value: 'actual', sortable: false, },
            ],
        }
    },

    watch: {
    },

    computed:  {
        ...mapState([
            'usuario_loggeado', 'usuarios'
        ]),
    },

    methods: {
        cerrarModal() {
            this.$emit("closeModalTrlLog", true);
        },
        getUser(id) {
            let usuario = this.usuarios.find( usuario => usuario.id == id );
            return usuario;
        },
        getDateTime(timestamp) {
            let n = this.$moment(parseInt(timestamp)).year() > 1980 ? 1 : 1000;
            return this.$moment(parseInt(timestamp * n)).format('DD-MM-YYYY HH:mm');
        },
        getDate(timestamp) {
            let n = this.$moment(parseInt(timestamp)).year() > 1980 ? 1 : 1000;
            return this.$moment(parseInt(timestamp * n)).format('DD-MM-YYYY');
        },
        parseoCampos(dato, campo) {
            let dato_parseado = dato;
            if (campo === 'sruser.rrhh') {
                if (!dato.includes(",")) {
                    dato_parseado = this.getUser(dato) ? this.getUser(dato).personales.nombre + ' ' + this.getUser(dato).personales.apellidos : dato;
                } else {
                    dato = dato.split(',');
                    dato_parseado = '';
                    dato.forEach(dat => dato_parseado += this.getUser(dat) ? this.getUser(dat).personales.nombre + ' ' + this.getUser(dat).personales.apellidos + ', ' : dat);
                }
            } else if (campo === 'trlUserRelocationPreferences.relocation_visit_date' && dato.length) {
                dato = dato.split(',');
                dato_parseado = '';
                dato.forEach(dat => dato_parseado += this.getDate(dat) + ', ');
            } else if (campo === 'sruser.status') {
                if (dato == 0 || dato === 'false') { dato_parseado = 'Inactivo'}
                else if (dato == 1 || dato === 'true') {dato_parseado = 'Activo'} 
                else if (dato == 2) {dato_parseado = 'Eliminado'} 
                else if (dato === 'null') {dato_parseado = ''}
            } else {
                if (dato == 1 || dato === 'true') { dato_parseado = 'Sí'}
                else if (dato == 0 || dato === 'false') {dato_parseado = 'No'} 
                else if (dato === 'null') {dato_parseado = ''}
            }
            return dato_parseado;
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            if (response.ws === 'logList') {
                this.full_log = [];
                response.data.forEach(log => {
                    this.full_log.push({
                        fecha: this.getDateTime(log.datetime),
                        quien: log.creator_name + ' ' + log.creator_surnames,
                        campo: this.campos[log.field] || 'Campo desconocido',
                        anterior: log.old_data && !log.field.includes('phone') && !log.field.includes('identity_id') && this.$moment(Number(log.old_data)).isValid() && log.old_data.length > 9 ? this.getDate(log.old_data) : this.parseoCampos(log.old_data, log.field),
                        actual: log.new_data && !log.field.includes('phone') && !log.field.includes('identity_id') && this.$moment(Number(log.new_data)).isValid() && log.new_data.length > 9 ? this.getDate(log.new_data) : this.parseoCampos(log.new_data, log.field),
                    });
                });
                this.excelData = this.full_log;
            }
        }
	},
    created: function() {
        this.console = window.console;
        
    },
    mounted: function() {
        this.console = window.console;
        this.data = this.usuario.id;
        this.ws = 'logList';
    }
}
</script>
<style scoped lang="scss">
.v-application #app .modal .submodal {
    .titulo {
        text-align: left;
    }
}
.tabla-historial {
    ::v-deep tr {
        &:nth-child(odd) {
            td {
                background-color: rgb(244, 244, 255);
            }
        }
    }
}
@media (max-width: 900px) {
    .v-application #app .modal .submodal {
        .titulo {
            text-align: left;
            span {
                font-size: .6em;
            }
        }
        
    }
}
</style>
