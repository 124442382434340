<template>
	<div id="documentacion">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <modal-simple v-if="modal_simple" :tipo="'borrar_archivo'" :archivo="archivo_borrar" @closeModalSimple="closeModalSimple"/>
        <modal-nuevotrl v-if="modal_nuevotrl && usuario_loggeado.tipo === 'TRL'"
            @closeModalNuevotrl="closeModalNuevotrl" 
            :preferencias="trl_preferencias" 
            :trl_servicios="trl_servicios" 
        />
        <v-file-input 
            ref="fileInput"
            id="fileInput"
            v-model="new_file"
            accept="*"
            style="display: none"
        />
        <panel-consejera v-if="usuario_loggeado.tipo === 'TRL'" @openPreferencias="openPreferencias" @openServicios="openServicios" />
        <div :class="['view-container', usuario_loggeado.tipo]">
            <div class="subheader">
                <h3 class="titulo">{{ $t(localeRoute + 'documentacion') }}</h3>
                <div class="boton_upload_file">
                    <button class="boton cta" @click="fileInputClick">{{ $t(localeRoute + 'subirarchivo') }}</button>
                    <small v-if="!file_error" v-html="$t(localeRoute + 'recuerdaquelosnombres')"></small>
                    <small v-else class="error">{{file_error}}</small>
                </div>
                
            </div>
            <div class="titulo_area">
                <p class="parrafo bold">{{ $t(localeRoute + 'tusdocumentos') }}</p>
            </div>
            <div class="lista_documentos">
                <p class="parrafo" v-if="!archivos_usuario.length">{{ $t(localeRoute + 'todaviano') }}</p>
                <div class="archivo" v-for="(archivo, index) in archivos_usuario" :key="index">
                    <img v-if="esImagen(archivo.formato)" :src="archivo.url" class="image" />
                    <img v-else :src="'images/doc_' + archivo.formato + '.svg'" class="image" />
                    <p class="parrafo">{{archivo.nombre}}</p>
                    <p class="small">{{archivo.fecha}}</p>
                    <div class="actions">
                        <span @click.prevent="descargarArchivo(archivo)">
                            <svg class="exportar" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 10.2916L7.23618 17.631L14.4724 10.2916H10.0905C9.92965 9.14352 9.56784 4.46926 15.1558 1.10707L15.8794 0.656048L15.0352 0.492039C15.0352 0.492039 14.5528 0.410034 13.7487 0.410034C12.0201 0.410034 8.72362 0.902061 6.15075 4.05924C6.07035 4.14124 4.30151 6.10935 4.34171 10.2916H0Z" fill="#4F4F4F"/>
                            </svg>
                        </span>
                        <!-- <a href="#" @click="printDoc(archivo.url)"><svg class="imprimir" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5539 4.56465H15.5811V0.882143C15.5811 0.531428 15.305 0.244141 14.9598 0.244141H4.43843C4.09692 0.244141 3.81717 0.527697 3.81717 0.882143V4.56838H1.8444C1.04513 4.56838 0.398438 5.23623 0.398438 6.05332V12.4595C0.398438 13.2803 1.04876 13.9444 1.8444 13.9444H3.81717V17.362C3.81717 17.7127 4.09328 18 4.43843 18H14.9598C15.3013 18 15.5811 17.7165 15.5811 17.362V13.9444H17.5539C18.3531 13.9444 18.9998 13.2766 18.9998 12.4595V6.05332C18.9998 5.2325 18.3495 4.56465 17.5539 4.56465ZM5.05968 1.51641H14.3422V4.56465H5.05968V1.51641ZM14.3386 16.724H5.05968C5.05968 16.6009 5.05968 11.6685 5.05968 11.5155H14.3422C14.3386 11.6722 14.3386 16.6046 14.3386 16.724ZM17.7609 12.4595C17.7609 12.5751 17.6665 12.6721 17.5539 12.6721H15.5811V10.8812C15.5811 10.5305 15.305 10.2432 14.9598 10.2432H4.43843C4.09692 10.2432 3.81717 10.5268 3.81717 10.8812V12.6721H1.8444C1.73178 12.6721 1.63732 12.5751 1.63732 12.4595V6.05332C1.63732 5.93766 1.73178 5.84065 1.8444 5.84065C2.32034 5.84065 17.0888 5.84065 17.5539 5.84065C17.6665 5.84065 17.7609 5.93766 17.7609 6.05332V12.4595Z" fill="#4F4F4F"/>
                        </svg></a> -->
                        <span>
                            <svg @click="borrarArchivo(archivo)" class="borrar" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.21484 6.55718C5.21484 6.33821 5.3965 6.15906 5.61853 6.15906C5.84056 6.15906 6.02223 6.33821 6.02223 6.55718V14.0818C6.02223 14.3008 5.84056 14.4799 5.61853 14.4799C5.3965 14.4799 5.21484 14.3008 5.21484 14.0818V6.55718Z" fill="#4F4F4F"/>
                                <path d="M9.97852 6.55718C9.97852 6.33821 10.1602 6.15906 10.3822 6.15906C10.6042 6.15906 10.7859 6.33821 10.7859 6.55718V14.0818C10.7859 14.3008 10.6042 14.4799 10.3822 14.4799C10.1602 14.4799 9.97852 14.3008 9.97852 14.0818V6.55718Z" fill="#4F4F4F"/>
                                <path d="M7.5957 6.55718C7.5957 6.33821 7.77736 6.15906 7.99939 6.15906C8.22142 6.15906 8.40308 6.33821 8.40308 6.55718V14.0818C8.40308 14.3008 8.22142 14.4799 7.99939 14.4799C7.77736 14.4799 7.5957 14.3008 7.5957 14.0818V6.55718Z" fill="#4F4F4F"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.14559 5.06001L2.87224 14.8699C2.87224 15.4511 3.08619 15.9926 3.46566 16.3867C3.8411 16.7769 4.36186 16.9998 4.90684 16.9998H11.0914C11.6364 16.9998 12.1571 16.7769 12.5326 16.3867C12.908 15.9965 13.126 15.4511 13.126 14.8699L13.8526 5.06001C14.5995 4.86493 15.0879 4.15229 14.987 3.39186C14.8861 2.63542 14.2321 2.07008 13.457 2.07008H11.3901V1.57243C11.3942 1.15439 11.2246 0.752286 10.9259 0.457672C10.6231 0.159078 10.2154 -0.00415426 9.7915 -0.000172993H6.20673C5.78285 -0.00415426 5.37512 0.159078 5.07235 0.457672C4.77362 0.752286 4.60407 1.15439 4.60811 1.57243V2.07008H2.54121C1.76613 2.07008 1.11215 2.63542 1.01122 3.39585C0.910301 4.15229 1.39877 4.86493 2.14559 5.06001ZM11.0914 16.2036H4.90684C4.21653 16.2036 3.67962 15.6183 3.67962 14.8699L2.95298 5.09585H13.0452L12.3186 14.8699C12.3186 15.6183 11.7817 16.2036 11.0914 16.2036ZM5.41549 1.57243C5.41145 1.3654 5.49623 1.16634 5.6456 1.01903C5.79496 0.871724 5.99681 0.792099 6.20673 0.79608H9.7915C10.0014 0.792099 10.2033 0.871724 10.3526 1.01903C10.502 1.16634 10.5827 1.3654 10.5827 1.57243V2.07008H5.41549V1.57243ZM2.54121 2.86634H13.457C13.8567 2.86634 14.1837 3.18882 14.1837 3.58296C14.1837 3.97711 13.8567 4.29959 13.457 4.29959H2.54121C2.14156 4.29959 1.81457 3.97711 1.81457 3.58296C1.81457 3.18882 2.14156 2.86634 2.54121 2.86634Z" fill="#4F4F4F"/>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <div class="titulo_area" v-if="archivos_steps.length">
                <p class="parrafo bold">{{ $t(localeRoute + 'documentossubidos') }}</p>
            </div>
            <div class="lista_documentos">
                <div class="archivo" v-for="(archivo, index) in archivos_steps" :key="index">
                    <img v-if="esImagen(archivo.formato)" :src="archivo.url" class="image" />
                    <img v-else :src="'images/doc_' + archivo.formato + '.svg'" class="image" />
                    <p class="parrafo">{{archivo.nombre}}</p>
                    <p class="small">{{archivo.fecha}}</p>
                    <div class="actions">
                        <span @click.prevent="descargarArchivo(archivo)">
                            <svg class="exportar" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 10.2916L7.23618 17.631L14.4724 10.2916H10.0905C9.92965 9.14352 9.56784 4.46926 15.1558 1.10707L15.8794 0.656048L15.0352 0.492039C15.0352 0.492039 14.5528 0.410034 13.7487 0.410034C12.0201 0.410034 8.72362 0.902061 6.15075 4.05924C6.07035 4.14124 4.30151 6.10935 4.34171 10.2916H0Z" fill="#4F4F4F"/>
                            </svg>
                        </span>
                        <!-- <a href="#"><svg class="imprimir" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5539 4.56465H15.5811V0.882143C15.5811 0.531428 15.305 0.244141 14.9598 0.244141H4.43843C4.09692 0.244141 3.81717 0.527697 3.81717 0.882143V4.56838H1.8444C1.04513 4.56838 0.398438 5.23623 0.398438 6.05332V12.4595C0.398438 13.2803 1.04876 13.9444 1.8444 13.9444H3.81717V17.362C3.81717 17.7127 4.09328 18 4.43843 18H14.9598C15.3013 18 15.5811 17.7165 15.5811 17.362V13.9444H17.5539C18.3531 13.9444 18.9998 13.2766 18.9998 12.4595V6.05332C18.9998 5.2325 18.3495 4.56465 17.5539 4.56465ZM5.05968 1.51641H14.3422V4.56465H5.05968V1.51641ZM14.3386 16.724H5.05968C5.05968 16.6009 5.05968 11.6685 5.05968 11.5155H14.3422C14.3386 11.6722 14.3386 16.6046 14.3386 16.724ZM17.7609 12.4595C17.7609 12.5751 17.6665 12.6721 17.5539 12.6721H15.5811V10.8812C15.5811 10.5305 15.305 10.2432 14.9598 10.2432H4.43843C4.09692 10.2432 3.81717 10.5268 3.81717 10.8812V12.6721H1.8444C1.73178 12.6721 1.63732 12.5751 1.63732 12.4595V6.05332C1.63732 5.93766 1.73178 5.84065 1.8444 5.84065C2.32034 5.84065 17.0888 5.84065 17.5539 5.84065C17.6665 5.84065 17.7609 5.93766 17.7609 6.05332V12.4595Z" fill="#4F4F4F"/>
                        </svg></a> -->
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
import ModalNuevotrl from '@/components/ModalNuevotrl.vue'
import PanelConsejera from '@/components/PanelConsejera.vue'
import ModalSimple from '@/components/ModalSimple.vue'
import { saveAs } from 'file-saver'

export default {
	name: 'Documentacion',
	components: {
        ModalNuevotrl,
        PanelConsejera,
        ModalSimple,
        Service
	},
    computed: {
        ...mapState([
            'usuario_loggeado', 'archivos'
        ]),
        archivos_steps() {
            return this.archivos.filter(fil => fil.subido_por != this.usuario_loggeado.id);
        },
        archivos_usuario() {
            return this.archivos.filter(fil => fil.subido_por == this.usuario_loggeado.id);
        }
     },

    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            modal_nuevotrl: false,
            trl_preferencias: false,
            trl_servicios: false,
            new_file: null,
            file_error: '',
            archivo_borrar: null,
            modal_simple: false,
            ws: null,
            data: null,
            ws_spinner: false
        }
    },
    watch: {
        new_file() {
            if (this.new_file) {
                this.file_error = '';
                const caracteres_especiales = /[!@#$%*()\=\[\]{};':"\\|<>\/?]+/;
                if ((this.new_file.size/1048576).toFixed(1) >= 16) {
                    this.file_error = this.$t(this.localeRoute + 'archivolimite');
                    this.new_file = null;
                } else if (caracteres_especiales.test(this.new_file.name) ) {
                    this.file_error = this.$t(this.localeRoute + 'archivocaracteres');
                    this.new_file = null;
                } else {
                    this.ws_spinner = true;
                    this.data = {
                        trl_id: this.usuario_loggeado.id,
                        file: this.new_file,
                        tipo: JSON.stringify(['documento', this.usuario_loggeado.id])
                    };
                    this.ws = 'fileUpload';
                }
            }
        },
        archivos() {
            this.getPasaportes();
        },
        'usuario_loggeado.personales.pasaporte_imagen'() {
            let archivos = this.archivos;
            let formato;
            if (this.usuario_loggeado.personales.pasaporte_imagen && this.usuario_loggeado.personales.pasaporte_imagen.name) {
                formato = this.usuario_loggeado.personales.pasaporte_imagen.name.split('.').pop();
                archivos.push({
                    fecha:"",
                    formato: formato,
                    id: "0",
                    nombre:"foto del pasaporte",
                    subido_por: this.usuario_loggeado.id,
                    tipo: "documento",
                    url: this.usuario_loggeado.personales.pasaporte_imagen_url
                });
            }
        },
        'usuario_loggeado.conyuge.pasaporte_imagen'() {
            let archivos = this.archivos;
            let formato;
            if (this.usuario_loggeado.conyuge.pasaporte_imagen && this.usuario_loggeado.conyuge.pasaporte_imagen.name) {
                formato = this.usuario_loggeado.conyuge.pasaporte_imagen.name.split('.').pop();
                archivos.push({
                    fecha:"",
                    formato: formato,
                    id: "0",
                    nombre:"foto del pasaporte de cónyuge",
                    subido_por: this.usuario_loggeado.id,
                    tipo: "documento",
                    url: this.usuario_loggeado.conyuge.pasaporte_imagen_url
                });
            }
        },
    },
    methods: {
        openPreferencias() {
            this.trl_preferencias = true;
            this.modal_nuevotrl = true;
        },
        openServicios() {
            this.trl_servicios = true;
            this.modal_nuevotrl = true;
        },
        closeModalNuevotrl() {
            this.modal_nuevotrl = false;
            this.trl_preferencias = false;
        },
        fileInputClick() {
            document.getElementById("fileInput").click();
        },
        borrarArchivo(archivo) {
            this.archivo_borrar = archivo;
            this.modal_simple = true;
        },
        closeModalSimple(action) {
            this.modal_simple = false;
            if (action === 'delete_archivo') {
                this.ws_spinner = true;
                if (this.archivo_borrar.nombre === 'foto del pasaporte') {
                    this.data = {id: this.usuario_loggeado.id, updater: this.usuario_loggeado.id};
                    this.ws = 'deleteTrlIdentityPhoto';
                } else if (this.archivo_borrar.nombre === 'foto del pasaporte de cónyuge') {
                    this.data = {id: this.usuario_loggeado.conyuge.id};
                    this.ws = 'deleteTrlSpouseIdentityPhoto';
                } else {
                    this.data = this.archivo_borrar.id;
                    this.ws = 'fileRemove';
                }
            }
        },
        printDoc(url) {
            var iframe = this._printIframe;
            if (!this._printIframe) {
                iframe = this._printIframe = document.createElement('iframe');
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.onload = function() {
                setTimeout(function() {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
                };
            }
            iframe.src = url;
        },
        getFiles(url, from) {
            let that = this;
            async function createFile(){
                let response = await fetch(url, {mode: 'no-cors'});
                let data = await response.blob();
                var index = url.lastIndexOf("/") + 1;
                var filename = url.substr(index);
                if (from === 'trl') {
                    that.usuario_loggeado.personales.pasaporte_imagen = new File([data], filename);
                } else {
                    that.usuario_loggeado.conyuge.pasaporte_imagen = new File([data], filename);
                }
            }
            createFile();
        },
        esImagen(formato) {
            return formato === 'jpg' || formato === 'jpeg' || formato === 'png' || formato === 'apng' ? true : false
        },
        descargarArchivo(archivo) {
            saveAs(archivo.url, archivo.nombre);
        },
        getPasaportes() {
            if (this.usuario_loggeado.personales.pasaporte_imagen && !this.usuario_loggeado.personales.pasaporte_imagen.name) {
                this.usuario_loggeado.personales.pasaporte_imagen_url = this.usuario_loggeado.personales.pasaporte_imagen || null;
                this.usuario_loggeado.personales.pasaporte_imagen ? this.getFiles(this.usuario_loggeado.personales.pasaporte_imagen, 'trl') : '';
            } 
            if (this.usuario_loggeado.conyuge.pasaporte_imagen && !this.usuario_loggeado.conyuge.pasaporte_imagen.name) {
                this.usuario_loggeado.conyuge.pasaporte_imagen_url = this.usuario_loggeado.conyuge.pasaporte_imagen || null;
                this.usuario_loggeado.conyuge.pasaporte_imagen ? this.getFiles(this.usuario_loggeado.conyuge.pasaporte_imagen, 'conyuge') : '';
            } 
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'fileList') {
                let archivos = [];
                response.data.forEach(ar => {
                    let tipo = JSON.parse(ar.type);
                    let formato = ar.filename.split('.').pop().toLowerCase();
                    if (formato === 'pdf') { formato = 'pdf'}
                    else if (formato === 'doc' || formato === 'txt' || formato === 'docx') { formato = 'doc'}
                    else { this.esImagen(formato) ? '' : formato = 'img'}

                    archivos.push({
                        id: ar.id,
                        nombre: ar.filename,
                        fecha: this.$moment(parseInt(ar.datetime) * 1000).format('YYYY-MM-DD HH:mm'),
                        subido_por: tipo[1],
                        tipo: tipo[0],
                        formato: formato,
                        url: ar.url
                    })
                });
                this.$store.dispatch('setArchivos', archivos);
                this.$store.dispatch('setLoading', false);
            } else if (response.ws === 'fileUpload' || response.ws === 'fileRemove' || response.ws === 'deleteTrlIdentityPhoto' || response.ws === 'deleteTrlSpouseIdentityPhoto') {
                // this.$store.dispatch('setLoading', true);
                this.new_file = null;
                this.archivo_borrar = null;
                this.ws_spinner = true;
                this.data = this.usuario_loggeado.id;
                this.ws = 'fileList';
                if (response.ws === 'deleteTrlIdentityPhoto' || response.ws === 'deleteTrlSpouseIdentityPhoto') {
                    this.data = this.usuario_loggeado.id;
                    this.ws = 'getUser';
                }
            } else if (response.ws === 'getUser') {
                this.$store.dispatch('setUsuarioLoggeado',response.data);
            }
        },
	},
    created: function() {
        this.console = window.console;
        if (!localStorage.getItem('isLogged')) {
            this.$router.push('/');
        }
        
    },
    mounted: function() {
        if (this.usuario_loggeado.id) {
            this.data = this.usuario_loggeado.id;
            this.ws = 'fileList';
        }
    }
}
</script>
<style scoped lang="scss">
#documentacion .view-container {
    .subheader {
        justify-content: flex-start!important;
        flex-wrap: wrap;
        .boton_upload_file {
            display: flex;
            align-items: center;
            flex: 1 1 300px;
            small {
                margin-left: 1em;
                display: inline-block;
                // max-width: 60%;
                font-size: .8rem;
                &:not(.error) {
                    font-size: .65rem;
                    line-height: 1.1em;
                    opacity: .8;
                }
                &.error {
                    background-color: #EB5757!important;
                    color: white;
                    padding: .5em;
                }
            }
        }
    }
    .titulo_area {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
        .bold {font-size: 1.2em; margin-right: 1em;}
        
    }
    .lista_documentos {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .5em;
        padding: 2em 0;
        width: 100%;
        .archivo {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: space-between;
            margin: 0 2em 3em 0;
            text-align: center;
            width: 10em;
            .image {
                width: 56px;
                height: 67px;
                object-fit: cover;
                border-radius: 5px;
                filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.15));
            }
            .parrafo {
                margin: 1em 0 .2em; 
                font-size: .9em;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .small {color: var(--gray03)}
            .actions {
                margin-top: .7em;
                & > span {
                    margin: 1em;
                    cursor: pointer;
                    &:hover path {fill: var(--purple03);}
                }
            } 
        }
    }
}
@media (max-width: 900px) {
    .v-application #app .view .view-container.TRL {
        padding-top: 17em;
        // padding-top: 2em;
    }
}

// @media (max-width: 760px) {
//     .v-application #app .view .view-container.TRL {
//         padding-top: 17em;
//     }
// }
</style>

