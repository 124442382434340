<template>
	<div id="ficha_trl">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'
            @error='console.log($event)'
            @errorImportante="modal_simple_error = true"
        />
        <st-docx-templater :usuario="usuario" :trigger="crear_word" @fin="crear_word = false"/>
        <!-- MODALES DE PANEL FICHA -->
        <modal-asignar-consejera v-if="modal_consejera" :usuario="usuario" @closeModalConsejera="closeModalConsejera"/>
        <modal-crear-usuario v-if="modal_crear_usuario" :tipo_usuario="'TRL'" :usuario="usuario" @closeModalUsuario="closeModalUsuario"/>
        <modal-trl-log v-if="modal_trl_log" :usuario="usuario" @closeModalTrlLog="closeModalTrlLog"/>
        <modal-nuevotrl v-if="modal_nuevotrl" @closeModalNuevotrl="modal_nuevotrl = false" :trl_servicios="true" :usuario="usuario"/>
        <modal-simple v-if="modal_simple" :tipo="'borrar_usuario'" :usuario="usuario" @closeModalSimple="closeModalSimple"/>
        <!--  -->
        <panel-ficha v-if="usuario.id" :usuario="usuario" @refreshUser="refreshUser" @openModal="openModal" @updateProfile="updateProfile('datos_personales')"/>
        <modal-crear-tarea :usuario="usuario" :servicio="servicio_selected" :subservicio="subservicio_selected" :tarea_sel="tarea_selected" v-if="modal_tarea" @closeModalTarea="closeModalTarea"/>
        <modal-simple v-if="modal_simple_borrar" :tipo="'borrar_archivo'" :archivo="archivo_borrar" @closeModalSimple="closeModalSimple"/>
        <modal-simple v-if="modal_simple_respuesta" :tipo="'respuesta_cons'" :usuario="usuario.personales.nombre + ' ' + usuario.personales.apellidos" :respuesta='respuesta' @closeModalSimple="closeModalSimple"/>
        <modal-simple v-if="modal_simple_finalizado" :tipo="'trl_finalizado'" :usuario="usuario.personales.nombre + ' ' + usuario.personales.apellidos" @closeModalSimple="closeModalSimple"/>
        <modal-simple v-if="modal_simple_cancelar_servicio" :tipo="'cancelar_servicio'" :usuario="usuario" :servicio="modal_simple_cancelar_servicio" @closeModalSimple="closeModalSimple"/>
        <modal-simple v-if="modal_simple_error" :tipo="'error_servidor'" @closeModalSimple="closeModalSimple"/>
        <modal-simple v-if="modal_cerrar_trl" :tipo="'cerrar_trl'" :usuario="usuario" @closeModalSimple="closeModalSimple"/>
        <div class="view-container" v-if="usuario.id">
            <div class="subheader">
                <h3 class="titulo">Usuarios > Ficha de usuario</h3>
            </div>
            <v-tabs class="tabs" show-arrows>
                <v-tab class="tab" href="#tab_info">
                    <p class="nav_link">Info personal</p>
                </v-tab>
                <v-tab class="tab" href="#tab_traslado">
                    <p class="nav_link">Preferencias de traslado</p>
                </v-tab>
                <v-tab class="tab" href="#tab_colegios" v-if="!rrhh_block && (usuario.servicios.find(serv => serv.id == 9) || (usuario.preferencias.colegios && usuario.preferencias.colegios.numero_hijos > -1 && usuario.preferencias.colegios.hijos[0].nombre))">
                    <p class="nav_link">Colegios</p>
                </v-tab>
                <v-tab class="tab" href="#tab_vivienda">
                    <p class="nav_link">Vivienda y contrato</p>
                </v-tab>
                <v-tab class="tab" href="#tab_suministros" v-if="servicio_suministros && !abogado_block">
                    <p class="nav_link">Suministros</p>
                </v-tab>
                <v-tab class="tab" href="#tab_mas" v-if="!abogado_block">
                    <p class="nav_link">Más servicios</p>
                </v-tab>
                <v-tab class="tab" href="#tab_documentos">
                    <p class="nav_link">Documentos</p>
                </v-tab>

                <v-tab-item value="tab_info">
                    <v-form class="form">
                        <v-container class="card">
                            <v-row>
                                <div class="editar disabled" v-if="!edit_datos_personales && !rrhh_block && !abogado_block" @click="edit_datos_personales = !edit_datos_personales">
                                    <div class="boton fab">
                                        <v-icon class="icono">mdi-pencil-outline</v-icon>
                                    </div>
                                </div>
                                <div class="editar enabled" v-else-if="!rrhh_block && !abogado_block">
                                    <div class="boton fab check">
                                        <v-icon class="icono " @click="updateProfile('datos_personales')">mdi-check</v-icon>
                                    </div>
                                    <div class="boton fab close">
                                        <v-icon class="icono" @click="cancelEdit('datos_personales')">mdi-close</v-icon>
                                    </div>
                                </div>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Datos personales</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Nombre"
                                        v-model="usuario.personales.nombre"
                                        :disabled="!edit_datos_personales"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Apellidos"
                                        v-model="usuario.personales.apellidos"
                                        :disabled="!edit_datos_personales"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Nacionalidad"
                                        v-model="usuario.personales.nacionalidad"
                                        :disabled="!edit_datos_personales"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Idioma preferido"
                                        :value="usuario.personales.idioma_preferido === 'es' ? 'Español' : usuario.personales.idioma_preferido === 'en' ? 'Inglés' : ''"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Dirección actual"
                                        v-model="usuario.personales.direccion"
                                        :disabled="!edit_datos_personales"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" lg="3">
                                    <p class="parrafo radio_label">Sexo</p>
                                    <v-radio-group v-model="usuario.personales.sexo" class="checkbox_container mitad" row :disabled="!edit_datos_personales">
                                        <v-radio class="checkbox" label="Hombre" :value="'hombre'"></v-radio>
                                        <v-radio class="checkbox" label="Mujer" :value="'mujer'"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" lg="3">
                                    <v-text-field
                                        label="Pasaporte / DNI"
                                        v-model="usuario.personales.pasaporte"
                                        :disabled="!edit_datos_personales"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-file-input 
                                        id="fileImageInput"
                                        label="Imagen del pasaporte / DNI"
                                        v-model="usuario.personales.pasaporte_imagen"
                                        accept="image/*" 
                                        append-icon="mdi-paperclip"
                                        prepend-icon=""
                                        @click:append="openFile('fileImageInput')"
                                        v-on:change="edit_imagen_pasaporte = true"
                                        :disabled="!edit_datos_personales"
                                    ></v-file-input>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <a class="boton pasaporte" :class="{disabled: !usuario.personales.pasaporte_imagen}" @click.prevent="descargarPasaporte('trl')">
                                        {{$vuetify.breakpoint.xs ? 'Descargar imagen' : 'Descargar'}}</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card">
                            <div class="editar disabled" v-if="!edit_datos_contacto && !rrhh_block && !abogado_block" @click="edit_datos_contacto = !edit_datos_contacto">
                                <div class="boton fab">
                                    <v-icon class="icono">mdi-pencil-outline</v-icon>
                                </div>
                            </div>
                            <div class="editar enabled" v-else-if="!rrhh_block && !abogado_block">
                                <div class="boton fab check">
                                    <v-icon class="icono " @click="updateProfile('datos_contacto')">mdi-check</v-icon>
                                </div>
                                <div class="boton fab close">
                                    <v-icon class="icono" @click="cancelEdit('datos_contacto')">mdi-close</v-icon>
                                </div>
                            </div>
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Datos de contacto</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Email profesional"
                                        v-model="usuario.contacto.email_profesional"
                                        :disabled="!edit_datos_contacto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Email personal"
                                        v-model="usuario.contacto.email_personal"
                                        :disabled="!edit_datos_contacto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Móvil"
                                        v-model="usuario.contacto.telefono"
                                        :disabled="!edit_datos_contacto"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card" v-if="!abogado_block">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Datos de la empresa</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-autocomplete
                                        v-model="usuario.personales.empresa"
                                        :items="lista_empresas"
                                        placeholder="Empresa"
                                        clearable
                                        v-on:change="cambios = 'personales'"
                                        :disabled="rrhh_block || abogado_block"
                                        no-data-text="No hay resultados"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-autocomplete
                                        v-model="usuario.personales.rrhh"
                                        :items="lista_rrhh"
                                        placeholder="RRHH"
                                        v-on:change="cambios = 'personales'"
                                        :disabled="rrhh_block || abogado_block"
                                        no-data-text="No hay resultados"
                                        small-chips
                                        deletable-chips
                                        multiple
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card" v-if="!rrhh_block">
                            <div class="editar disabled" v-if="!edit_datos_conyuge && !abogado_block" @click="edit_datos_conyuge = !edit_datos_conyuge">
                                <div class="boton fab">
                                    <v-icon class="icono">mdi-pencil-outline</v-icon>
                                </div>
                            </div>
                            <div class="editar enabled" v-else-if="!abogado_block">
                                <div class="boton fab check">
                                    <v-icon class="icono " @click="updateProfile('datos_conyuge')">mdi-check</v-icon>
                                </div>
                                <div class="boton fab close">
                                    <v-icon class="icono" @click="cancelEdit('datos_conyuge')">mdi-close</v-icon>
                                </div>
                            </div>
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Datos del cónyuge</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    ¿Se realoja tu cónyuge contigo?
                                    <v-radio-group v-model="conyuge" row :disabled="!edit_datos_conyuge" class="checkbox_container">
                                        <v-radio class="checkbox" label="Sí" :value="true"></v-radio>
                                        <v-radio class="checkbox" label="No" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="conyuge == true">
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Nombre"
                                        v-model="usuario.conyuge.nombre"
                                        :disabled="!edit_datos_conyuge"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Apellidos"
                                        v-model="usuario.conyuge.apellidos"
                                        :disabled="!edit_datos_conyuge"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Nacionalidad"
                                        v-model="usuario.conyuge.nacionalidad"
                                        :disabled="!edit_datos_conyuge"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Idiomas"
                                        v-model="usuario.conyuge.idiomas"
                                        :disabled="!edit_datos_conyuge"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Email"
                                        v-model="usuario.conyuge.email"
                                        :disabled="!edit_datos_conyuge"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Móvil"
                                        v-model="usuario.conyuge.telefono"
                                        :disabled="!edit_datos_conyuge"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Pasaporte"
                                        v-model="usuario.conyuge.pasaporte"
                                        :disabled="!edit_datos_conyuge"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-file-input 
                                        label="Imagen del pasaporte"
                                        v-model="usuario.conyuge.pasaporte_imagen"
                                        accept="image/*" 
                                        append-icon="mdi-paperclip"
                                        prepend-icon=""
                                        v-on:change="edit_imagen_pasaporte_conyuge = true"
                                        :disabled="!edit_datos_conyuge"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card" v-if="!rrhh_block && !abogado_block">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Datos bancarios (solo visible para Steps Relocation)</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Banco"
                                        v-model="usuario.datos_bancarios.banco"
                                        v-on:change="cambios = 'personales'"
                                        :disabled="abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="IBAN"
                                        v-model="usuario.datos_bancarios.cuenta"
                                        v-on:change="cambios = 'personales'"
                                        :disabled="abogado_block"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-tab-item>
                <v-tab-item value="tab_traslado">
                    <v-form class="form">
                        <v-container class="card">
                            <div class="editar disabled" v-if="!edit_traslado && !rrhh_block && !abogado_block" @click="edit_traslado = !edit_traslado">
                                <div class="boton fab">
                                    <v-icon class="icono">mdi-pencil-outline</v-icon>
                                </div>
                            </div>
                            <div class="editar enabled" v-else-if="!rrhh_block && !abogado_block">
                                <div class="boton fab check">
                                    <v-icon class="icono " @click="updateProfile('datos_traslado')">mdi-check</v-icon>
                                </div>
                                <div class="boton fab close">
                                    <v-icon class="icono" @click="cancelEdit('datos_traslado')">mdi-close</v-icon>
                                </div>
                            </div>
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Traslado</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha traslado" 
                                        :disabled="!edit_traslado" 
                                        @nueva_fecha="usuario.preferencias.traslado.fecha_traslado = $event;"
                                        :old_fecha="usuario.preferencias.traslado.fecha_traslado"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha entrada deseada" 
                                        :disabled="!edit_traslado" 
                                        @nueva_fecha="usuario.preferencias.traslado.fecha_entrada = $event;"
                                        :old_fecha="usuario.preferencias.traslado.fecha_entrada"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <!-- <v-text-field
                                        label="Fecha disponible para visitas (de lunes a viernes)"
                                        v-model="usuario.preferencias.traslado.fecha_disponible"
                                        :disabled="!edit_traslado"
                                    ></v-text-field> -->
                                    <st-date-picker 
                                        :multiple="true" 
                                        label="Fecha disponible para visitas (de lunes a viernes)"
                                        :old_fecha="usuario.preferencias.traslado.fecha_disponible" 
                                        @nueva_fecha="usuario.preferencias.traslado.fecha_disponible = $event;" 
                                        :futuro="true"
                                        :disabled="!edit_traslado"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Presupuesto"
                                        v-model="usuario.preferencias.traslado.presupuesto"
                                        :disabled="!edit_traslado"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Duración alquiler"
                                        v-model="usuario.preferencias.traslado.tiempo_alquiler"
                                        :disabled="!edit_traslado"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card" v-if="!rrhh_block">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Vivienda</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Metros cuadrados"
                                        v-model="usuario.preferencias.vivienda.metros"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Número de habitaciones"
                                        v-model="usuario.preferencias.vivienda.habitaciones"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Tipo"
                                        :value="toList(usuario.preferencias.vivienda.tipo)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Estilo"
                                        :value="toList(usuario.preferencias.vivienda.estilo)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Estado"
                                        :value="toList(usuario.preferencias.vivienda.estado)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Características"
                                        :value="toList(usuario.preferencias.vivienda.caracteristicas)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Otras preferencias"
                                        :value="toList(usuario.preferencias.vivienda.otras_preferencias)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Áreas residenciales"
                                        :value="usuario.preferencias.vivienda.areas_residenciales"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Garaje"
                                        :value="garaje"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Mascota"
                                        :value="mascota"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Notas"
                                        :value="usuario.preferencias.vivienda.otros"
                                        disabled
                                        auto-grow
                                        rows="1"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-tab-item>
                <!-- <v-tab-item value="tab_colegios" v-if="usuario.preferencias.colegios.numero_hijos > 0"> -->
				<v-tab-item value="tab_colegios" v-if="usuario.preferencias.colegios && usuario.preferencias.colegios.numero_hijos > -1">
                    <v-form class="form">
                        <v-container class="card" v-for="(hijo, subindex) in usuario.preferencias.colegios.hijos" :key="subindex">
                            <v-row>
                                <div class="editar disabled" v-if="!edit_datos_hijos && !abogado_block" @click="edit_datos_hijos = !edit_datos_hijos">
                                    <div class="boton fab">
                                        <v-icon class="icono">mdi-pencil-outline</v-icon>
                                    </div>
                                </div>
                                <div class="editar enabled" v-else-if="!abogado_block">
                                    <div class="boton fab check">
                                        <v-icon class="icono " @click="updateProfile('datos_hijos')">mdi-check</v-icon>
                                    </div>
                                    <div class="boton fab close">
                                        <v-icon class="icono" @click="cancelEdit('datos_hijos')">mdi-close</v-icon>
                                    </div>
                                </div>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Hijo/a {{subindex + 1}}</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Nombre*"
                                        v-model="hijo.nombre"
                                        :disabled="!edit_datos_hijos"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Apellidos*"
                                        v-model="hijo.apellidos"
                                        :disabled="!edit_datos_hijos"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Sexo"
                                        v-model="hijo.sexo"
                                        :disabled="!edit_datos_hijos"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Fecha de nacimiento"
                                        v-model="hijo.nacimiento"
                                        :disabled="!edit_datos_hijos"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Curso"
                                        v-model="hijo.curso"
                                        :disabled="!edit_datos_hijos"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Idiomas"
                                        v-model="hijo.idiomas"
                                        :disabled="!edit_datos_hijos"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Preferencias colegio</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Tipo"
                                        :value="toList(usuario.preferencias.colegios.preferencias.tipo_colegio)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Tipo de educación"
                                        :value="toList(usuario.preferencias.colegios.preferencias.tipo_educacion)"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                 </v-tab-item>
                <v-tab-item value="tab_vivienda">
                    <v-form class="form">
                        <v-container class="card">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Datos para la empresa</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha aviso" 
                                        @nueva_fecha="usuario.servicio_vivienda.gestion.fecha_aviso = $event;" 
                                        :old_fecha="usuario.servicio_vivienda.gestion.fecha_aviso" 
                                        @change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha 1er contacto con TRL" 
                                        @nueva_fecha="usuario.servicio_vivienda.gestion.fecha_primer_contacto = $event;" 
                                        :old_fecha="usuario.servicio_vivienda.gestion.fecha_primer_contacto"
                                        @change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha llegada" 
                                        @nueva_fecha="usuario.servicio_vivienda.gestion.fecha_llegada = $event;" 
                                        :old_fecha="usuario.servicio_vivienda.gestion.fecha_llegada"
                                        @change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha visita a casas" 
                                        @nueva_fecha="usuario.servicio_vivienda.gestion.fecha_visita = $event;" 
                                        :old_fecha="usuario.servicio_vivienda.gestion.fecha_visita"
                                        @change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha búsqueda colegios" 
                                        @nueva_fecha="usuario.servicio_vivienda.gestion.fecha_busqueda_colegios = $event;" 
                                        :old_fecha="usuario.servicio_vivienda.gestion.fecha_busqueda_colegios"
                                        @change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha firma contrato" 
                                        @nueva_fecha="usuario.servicio_vivienda.gestion.fecha_firma_contrato = $event;" 
                                        :old_fecha="usuario.servicio_vivienda.gestion.fecha_firma_contrato"
                                        @change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha entrada" 
                                        @nueva_fecha="usuario.servicio_vivienda.gestion.fecha_entrada = $event;" 
                                        :old_fecha="usuario.servicio_vivienda.gestion.fecha_entrada"
                                        @change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Notas"
                                        v-model="usuario.servicio_vivienda.gestion.otros"
                                        @change="cambios = 'vivienda'"
                                        auto-grow
                                        rows="1"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Datos de la vivienda</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Dirección"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.direccion"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Referencia catastral"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.referencia_catastral"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Nombre del propietario"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.propietario_nombre"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="DNI"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.propietario_dni"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Dirección propietario"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.propietario_direccion"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Teléfono propietario"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.propietario_telefono"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Email propietario"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.propietario_email"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Cuenta bancaria (IBAN)"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.iban"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Nombre encargado"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.encargado_nombre"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Teléfono"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.encargado_telefono"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Email"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.encargado_email"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Agencia inmobiliaria"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.inmobiliaria_nombre"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Teléfono"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.inmobiliaria_telefono"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Email"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.inmobiliaria_email"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Notas"
                                        v-model="usuario.servicio_vivienda.datos_vivienda.otros"
                                        @change="cambios = 'vivienda'"
                                        auto-grow
                                        rows="1"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card">
                            <v-row>
                                <v-col cols="9">
                                    <h3 class="area_titulo">Contrato de alquiler</h3>
                                </v-col>
                                <v-col cols="12" sm="3" style="text-align: right" v-if="!rrhh_block">
                                    <!-- PENDIENTE DE LA FUNCION DE crearWord -->
                                    <button class="boton crear_tarea" @click.prevent="crearWord">Descargar contrato</button>
                                    <!-- <button class="boton crear_tarea">Descargar contrato</button> -->
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <st-date-picker 
                                        label="Fecha inicio contrato" 
                                        @nueva_fecha="usuario.servicio_vivienda.contrato_alquiler.fecha_inicio = $event;" 
                                        :old_fecha="usuario.servicio_vivienda.contrato_alquiler.fecha_inicio"
                                        @change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Duración obligatoria"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.duracion_obligatoria"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Preaviso"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.preaviso"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Renta"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.renta"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Contrato a nombre de"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.contrato_nombre"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        label="Firmado por"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.contrato_firmado"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Fianza"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.fianza"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Fianza pagada por"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.fianza_pagador"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Depósito/aval"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.deposito"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Depósito/aval pagado por"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.deposito_pagador"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Comisión agencia"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.comision"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Comisión agencia pagada por"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.comision_pagador"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    Inventario fotográfico
                                    <v-radio-group v-model="usuario.servicio_vivienda.contrato_alquiler.inventario" class="checkbox_container" row v-on:change="cambios = 'vivienda'" :disabled="rrhh_block || abogado_block">
                                        <v-radio class="checkbox" label="Sí" :value="true"></v-radio>
                                        <v-radio class="checkbox" label="No" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    Contrato prorrogable
                                    <v-radio-group v-model="usuario.servicio_vivienda.contrato_alquiler.contrato_prorrogable" class="checkbox_container" row v-on:change="cambios = 'vivienda'" :disabled="rrhh_block || abogado_block">
                                        <v-radio class="checkbox" label="Sí" :value="true"></v-radio>
                                        <v-radio class="checkbox" label="No" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12">
                                    <p class="area_titulo">Presencia check-in</p>
                                    <v-container class="checkbox_container">
                                        <v-checkbox class="checkbox" v-model="usuario.servicio_vivienda.contrato_alquiler.presencia_checkin.inquilino" label="Inquilino" v-on:change="cambios = 'vivienda'" :disabled="rrhh_block || abogado_block"></v-checkbox>
                                        <v-checkbox class="checkbox" v-model="usuario.servicio_vivienda.contrato_alquiler.presencia_checkin.consejera" label="Consejera" v-on:change="cambios = 'vivienda'" :disabled="rrhh_block || abogado_block"></v-checkbox>
                                        <v-checkbox class="checkbox" v-model="usuario.servicio_vivienda.contrato_alquiler.presencia_checkin.agente" label="Agente" v-on:change="cambios = 'vivienda'" :disabled="rrhh_block || abogado_block"></v-checkbox>
                                        <v-checkbox class="checkbox" v-model="usuario.servicio_vivienda.contrato_alquiler.presencia_checkin.propietario" label="Propietario" v-on:change="cambios = 'vivienda'" :disabled="rrhh_block || abogado_block"></v-checkbox>
                                    </v-container>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Notas"
                                        v-model="usuario.servicio_vivienda.contrato_alquiler.otros"
                                        @change="cambios = 'vivienda'"
                                        auto-grow
                                        rows="1"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Seguro hogar (inquilino)</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Nombre"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.inquilino_nombre"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Teléfono"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.inquilino_telefono"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Nº póliza"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.inquilino_poliza"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Email"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.inquilino_email"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Notas"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.inquilino_otros"
                                        @change="cambios = 'vivienda'"
                                        auto-grow
                                        rows="1"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="card">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">Seguro hogar (propietario)</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Nombre"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.propietario_nombre"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Teléfono"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.propietario_telefono"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Nº póliza"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.propietario_poliza"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        label="Email"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.propietario_email"
                                        v-on:change="cambios = 'vivienda'"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Notas"
                                        v-model="usuario.servicio_vivienda.seguro_hogar.propietario_otros"
                                        @change="cambios = 'vivienda'"
                                        auto-grow
                                        rows="1"
                                        :disabled="rrhh_block || abogado_block"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                 </v-tab-item>
                <v-tab-item value="tab_suministros" class="tab_suministros" v-if="servicio_suministros">
                    <div v-if="usuario && !rrhh_block" class="card ficha_usuario">
                        <p class="parrafo bold">TRL</p>
                        <v-container class="caja">
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <p class="parrafo"><b>Nombre</b> {{`${usuario.personales.nombre} ${usuario.personales.apellidos}`}}</p>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <p class="parrafo"><b>NIE:</b> {{usuario.personales.pasaporte}}</p>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <p class="parrafo"><b>Cta.bancaria:</b> {{usuario.datos_bancarios.cuenta}}</p>
                                </v-col>
                            </v-row>
                        </v-container>
                        <p class="parrafo bold">Vivienda</p>
                        <v-container class="caja">
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <p class="parrafo"><b>Propietario</b> {{usuario.servicio_vivienda.datos_vivienda.propietario_nombre}}</p>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <p class="parrafo"><b>DNI:</b> {{usuario.servicio_vivienda.datos_vivienda.propietario_dni}}</p>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <p class="parrafo"><b>Dirección:</b> {{usuario.servicio_vivienda.datos_vivienda.direccion}}</p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <v-form class="form">
                        <v-container class="card" v-for="(suministro, iindex) in ['internet', 'agua', 'luz', 'gas']" :key="iindex" :class="{cancelado: servicio_suministros.gestion[suministro].gestion.estado === 'cancelado'}">
                            <v-row>
                                <v-col cols="9">
                                    <h3 class="area_titulo">{{suministro}}</h3>
                                </v-col>
                                <v-col cols="12" sm="3" v-if="servicio_suministros.gestion[suministro].gestion.estado">
                                    <span class="estado" :class="servicio_suministros.gestion[suministro].gestion.estado">
                                        {{servicio_suministros.gestion[suministro].gestion.estado}}
                                    </span>
                                </v-col>
                                <!-- <div class="editar enabled" v-else-if="">
                                    <div class="boton fab check">
                                        <v-icon class="icono " @click="updateProfile('datos_personales')">mdi-check</v-icon>
                                    </div>
                                    <div class="boton fab close">
                                        <v-icon class="icono" @click="edit_datos_personales = !edit_datos_personales">mdi-close</v-icon>
                                    </div>
                                </div> -->
                            </v-row>
                            <button class="boton crear_tarea w-100 mb-4" @click.prevent="openModalTarea(43, null, suministro)" v-if="!rrhh_block && (servicio_suministros.gestion[suministro].gestion.estado != 'cancelado' && $vuetify.breakpoint.xs)">Añadir tarea al calendario</button>
                            <v-tabs class="tabs" show-arrows>
                                <v-tab class="tab" v-if="!rrhh_block" :href="`#${suministro}_servicio`">
                                    <p class="nav_link"><v-icon class="icon">mdi-information-outline</v-icon> SERVICIO</p>
                                </v-tab>
                                <v-tab class="tab" :href="`#${suministro}_gestion`">
                                    <p class="nav_link"><v-icon class="icon">mdi-clipboard-list-outline</v-icon> INICIO/FIN</p>
                                </v-tab>
                                <v-tab class="tab" :href="`#${suministro}_agenda`">
                                    <p class="nav_link"><v-icon class="icon">mdi-calendar-month-outline</v-icon> AGENDA</p>
                                </v-tab>
                                <button class="boton crear_tarea" @click.prevent="openModalTarea(43, null, suministro)" v-if="!rrhh_block && (servicio_suministros.gestion[suministro].gestion.estado != 'cancelado' && $vuetify.breakpoint.smAndUp)">Añadir tarea al calendario</button>
                                <v-tab-item v-if="!rrhh_block" :value="`${suministro}_servicio`">
                                    <v-form class="form">
                                        <v-row v-if="suministro === 'internet'">
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Compañía"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.compañia"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Teléfono asociado"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.telefono_asociado"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Nº ref"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.num_ref"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = {ser: 'suministros', sub: suministro}" :label="'Fecha alta'" :old_fecha="servicio_suministros.gestion[suministro].servicio.fecha_alta" @nueva_fecha="servicio_suministros.gestion[suministro].servicio.fecha_alta = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Comprobación"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.comprobacion"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="9">
                                                <v-textarea
                                                    label="Otros"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.otros"
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    auto-grow
                                                    rows="1"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Compañía"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.compañia"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Teléfono"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.telefono"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Nº contrato"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.num_contrato"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker 
                                                    label="Fecha alta/domiciliación" 
                                                    @change="cambios = {ser: 'suministros', sub: suministro}"
                                                    @nueva_fecha="servicio_suministros.gestion[suministro].servicio.fecha_alta = $event;"
                                                    :old_fecha="servicio_suministros.gestion[suministro].servicio.fecha_alta"
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Titular"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.titular"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="DNI"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.dni"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Lectura"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.lectura"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Comprobación"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.comprobacion"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-textarea
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    label="Otros"
                                                    v-model="servicio_suministros.gestion[suministro].servicio.otros"
                                                    auto-grow
                                                    rows="1"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-tab-item>
                                <v-tab-item :value="`${suministro}_gestion`">
                                    <v-form class="form">
                                        <v-row>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = {ser: 'suministros', sub: suministro}" :disabled="rrhh_block || servicio_suministros.gestion[suministro].gestion.estado === 'cancelado'" :label="'Fecha inicio'" :old_fecha="servicio_suministros.gestion[suministro].gestion.inicio" @nueva_fecha="servicio_suministros.gestion[suministro].gestion.inicio = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = {ser: 'suministros', sub: suministro}" :disabled="rrhh_block || servicio_suministros.gestion[suministro].gestion.estado === 'cancelado'" :label="'Fecha fin'" :old_fecha="servicio_suministros.gestion[suministro].gestion.fin" @nueva_fecha="servicio_suministros.gestion[suministro].gestion.fin = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="4" v-if="!rrhh_block">
                                                <v-textarea
                                                    label="Notas"
                                                    v-model="servicio_suministros.gestion[suministro].gestion.notas"
                                                    :disabled="servicio_suministros.gestion[suministro].gestion.estado === 'cancelado'"
                                                    v-on:change="cambios = {ser: 'suministros', sub: suministro}"
                                                    auto-grow
                                                    rows="1"
                                                ></v-textarea>
                                            </v-col>
                                            <v-col cols="12" sm="2" v-if="!rrhh_block">
                                                <p class="parrafo semibold cancelar" @click="modal_simple_cancelar_servicio = suministro">
                                                    {{servicio_suministros.gestion[suministro].gestion.estado === 'cancelado' ? 'Reanudar servicio' : 'Cancelar servicio'}}
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-tab-item>
                                <v-tab-item :value="`${suministro}_agenda`">
                                    <ul class="lista_tareas">
                                        <li class="tarea" v-for="(tarea, index) in tareas_trl" :key="index">
                                            <v-row v-if="usuario_loggeado.tipo != 'RRHH' && tarea.servicio === 43 && tarea.name.includes(suministro)">
                                                <v-col cols="12" sm="2">
                                                    <p class="parrafo">{{tarea.name}}</p>
                                                </v-col>
                                                <v-col cols="9" sm="3">
                                                    <p class="parrafo"><v-icon class="icono">mdi-calendar</v-icon>{{formatDate(tarea.start)}}</p>
                                                </v-col>
                                                <v-col cols="3" sm="1">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                           <p class="parrafo custom_checkbox" v-on="on" :class="{active: tarea.mostrar_rrhh}">
                                                                <v-icon class="icono">mdi-eye-outline</v-icon>
                                                            </p>
                                                        </template>
                                                        <span v-if="tarea.mostrar_rrhh">Visible para TRL y RRHH</span>
                                                        <span v-else>No visible para TRL y RRHH</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="8" sm="3">
                                                    <p class="parrafo custom_checkbox" :class="{active: tarea.notificar_email}">
                                                        <v-icon class="icono">mdi-email-outline</v-icon>
                                                        Notificar por email
                                                    </p>
                                                </v-col>
                                                <v-col cols="4" sm="2">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn class="nota" v-on="on"><v-icon class="icono">mdi-clipboard-text-outline</v-icon> Notas</v-btn>
                                                        </template>
                                                        <span>{{tarea.notas}}</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="12" sm="1">
                                                    <div class="boton fab editar editar_tarea" @click.prevent="openModalTarea(43, tarea, suministro)">
                                                        <v-icon class="icono">mdi-pencil-outline</v-icon>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="usuario_loggeado.tipo === 'RRHH' && tarea.mostrar_rrhh && tarea.servicio === 43 && tarea.name.includes(suministro)">
                                                <v-col cols="12" sm="9">
                                                    <p class="parrafo">{{tarea.name}}</p>
                                                </v-col>
                                                <v-col cols="9" sm="3">
                                                    <p class="parrafo">{{formatDate(tarea.start)}}</p>
                                                </v-col>
                                            </v-row>
                                        </li>
                                        <li class="tarea empty_state" v-if="!tareas_trl.find(tarea => tarea.servicio == 43 && tarea.name.includes(suministro))">
                                            <p class="parrafo">Aún no hay tareas para este servicio</p>
                                        </li>
                                    </ul>
                                </v-tab-item>
                            </v-tabs>
                        </v-container>
                        <v-container class="card">
                            <v-row>
                                <v-col cols="12">
                                    <p class="area_titulo">Otros</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="servicio_suministros.gestion.otros"
                                        rows="1"
                                        auto-grow
                                        v-on:change="cambios = 'suministros'"
                                        :disabled="rrhh_block"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                 </v-tab-item>
                <v-tab-item value="tab_mas">
                    <v-form class="form">
                        <v-container class="card" v-for="(servicio, index) in mas_servicios" :key="index" :class="{cancelado: servicio.estado === 'cancelado'}">
                            <v-row>
                                <v-col cols="12">
                                    <h3 class="area_titulo">{{servicio.nombre + (servicio.nombre_sub ? (' – ' + servicio.nombre_sub) : '')}}</h3>
                                </v-col>
                                <span v-if="servicio.estado" class="estado" :class="servicio.estado">
                                    {{servicio.estado}}
                                </span>
                            </v-row>
                            <button v-if="!rrhh_block && (servicio.estado != 'cancelado' && $vuetify.breakpoint.xs)" class="boton crear_tarea w-100 mb-4" @click.prevent="openModalTarea(servicio.id)">Añadir tarea al calendario</button>
                            <v-tabs class="tabs" show-arrows>
                                <v-tab v-if="!rrhh_block && servicio.id == 42" :href="`#${servicio.id}_servicio`">
                                    <p class="nav_link"><v-icon class="icon">mdi-information-outline</v-icon> SERVICIO</p>
                                </v-tab>
                                <v-tab class="tab" :href="`#${servicio.id}_gestion`">
                                    <p class="nav_link"><v-icon class="icon">mdi-clipboard-list-outline</v-icon> INICIO/FIN</p>
                                </v-tab>
                                <v-tab class="tab" :href="`#${servicio.id}_agenda`" :disabled="servicio.estado === 'cancelado'">
                                    <p class="nav_link"><v-icon class="icon">mdi-calendar-month-outline</v-icon> AGENDA</p>
                                </v-tab>
                                <button v-if="!rrhh_block && (servicio.estado != 'cancelado' && $vuetify.breakpoint.smAndUp)" class="boton crear_tarea" @click.prevent="openModalTarea(servicio.id)">Añadir tarea al calendario</button>
                                <v-tab-item :value="`${servicio.id}_servicio`" v-if="!rrhh_block && servicio.id == 42">
                                    <v-form class="form">
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <st-date-picker @change="cambios = servicio.id" :disabled="servicio.estado === 'cancelado'" :label="'Fecha envío carta notificación'" :old_fecha="getServicio(servicio.id).servicio.fecha_envio_notificacion" @nueva_fecha="getServicio(servicio.id).servicio.fecha_envio_notificacion = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <st-date-picker @change="cambios = servicio.id" :disabled="servicio.estado === 'cancelado'" :label="'Fecha check-out'" :old_fecha="getServicio(servicio.id).servicio.fecha_check_out" @nueva_fecha="getServicio(servicio.id).servicio.fecha_check_out = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    label="Devolución fianza"
                                                    v-model="getServicio(servicio.id).servicio.devolucion_fianza"
                                                    :disabled="servicio.estado === 'cancelado'"
                                                    v-on:change="cambios = servicio.id"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = servicio.id" :disabled="servicio.estado === 'cancelado'" :label="'Baja luz'" :old_fecha="getServicio(servicio.id).servicio.baja_luz" @nueva_fecha="getServicio(servicio.id).servicio.baja_luz = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = servicio.id" :disabled="servicio.estado === 'cancelado'" :label="'Baja agua'" :old_fecha="getServicio(servicio.id).servicio.baja_agua" @nueva_fecha="getServicio(servicio.id).servicio.baja_agua = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = servicio.id" :disabled="servicio.estado === 'cancelado'" :label="'Baja gas'" :old_fecha="getServicio(servicio.id).servicio.baja_gas" @nueva_fecha="getServicio(servicio.id).servicio.baja_gas = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = servicio.id" :disabled="servicio.estado === 'cancelado'" :label="'Baja internet'" :old_fecha="getServicio(servicio.id).servicio.baja_internet" @nueva_fecha="getServicio(servicio.id).servicio.baja_internet = $event;"/>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-tab-item>
                                <v-tab-item :value="`${servicio.id}_gestion`">
                                    <v-form class="form">
                                        <v-row>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = servicio.id" :disabled="rrhh_block || servicio.estado === 'cancelado'" :label="'Fecha inicio'" :old_fecha="getServicio(servicio.id).gestion.inicio" @nueva_fecha="getServicio(servicio.id).gestion.inicio = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <st-date-picker @change="cambios = servicio.id" :disabled="rrhh_block || servicio.estado === 'cancelado'" :label="'Fecha fin'" :old_fecha="getServicio(servicio.id).gestion.fin" @nueva_fecha="getServicio(servicio.id).gestion.fin = $event;"/>
                                            </v-col>
                                            <v-col cols="12" sm="4" v-if="!rrhh_block && getServicio(servicio.id).gestion">
                                                <v-textarea
                                                    label="Notas"
                                                    v-model="getServicio(servicio.id).gestion.notas"
                                                    :disabled="servicio.estado === 'cancelado'"
                                                    v-on:change="cambios = servicio.id"
                                                    auto-grow
                                                    rows="1"
                                                ></v-textarea>
                                            </v-col>
                                            <v-col v-if="!rrhh_block" cols="12" sm="2">
                                                <p class="parrafo semibold cancelar" @click="modal_simple_cancelar_servicio = servicio">
                                                    {{servicio.estado === 'cancelado' ? 'Reanudar servicio' : 'Cancelar servicio'}}
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-tab-item>
                                <v-tab-item :value="`${servicio.id}_agenda`">
                                    <ul class="lista_tareas">
                                        <li class="tarea" v-for="(tarea, index) in tareas_trl" :key="index">
                                            <v-row v-if="usuario_loggeado.tipo != 'RRHH' && tarea.servicio === servicio.id">
                                                <v-col cols="12" sm="2">
                                                    <p class="parrafo">{{tarea.name}}</p>
                                                </v-col>
                                                <v-col cols="9" sm="3">
                                                    <p class="parrafo"><v-icon class="icono">mdi-calendar</v-icon>{{formatDate(tarea.start)}}</p>
                                                </v-col>
                                                <v-col cols="3" sm="1">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <p class="parrafo custom_checkbox" v-on="on" :class="{active: tarea.mostrar_rrhh}">
                                                                <v-icon class="icono">mdi-eye-outline</v-icon>
                                                            </p>
                                                        </template>
                                                        <span v-if="tarea.mostrar_rrhh">Visible para TRL y RRHH</span>
                                                        <span v-else>No visible para TRL y RRHH</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="8" sm="3">
                                                    <p class="parrafo custom_checkbox" :class="{active: tarea.notificar_email}">
                                                        <v-icon class="icono">mdi-email-outline</v-icon>
                                                        Notificado al TRL
                                                    </p>
                                                </v-col>
                                                <v-col cols="4" sm="2">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn class="nota" v-on="on"><v-icon class="icono">mdi-clipboard-text-outline</v-icon> Notas</v-btn>
                                                        </template>
                                                        <span v-if="tarea">{{tarea.notas}}</span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="12" sm="1">
                                                    <div class="boton fab editar editar_tarea" @click.prevent="openModalTarea(servicio.id, tarea)">
                                                        <v-icon class="icono">mdi-pencil-outline</v-icon>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="usuario_loggeado.tipo === 'RRHH' && tarea.mostrar_rrhh && tarea.servicio === servicio.id">
                                                <v-col cols="12" sm="9">
                                                    <p class="parrafo">{{tarea.name}}</p>
                                                </v-col>
                                                <v-col cols="9" sm="3">
                                                    <p class="parrafo">{{formatDate(tarea.start)}}</p>
                                                </v-col>
                                            </v-row>
                                        </li>
                                        <li class="tarea empty_state" v-if="!tareas_trl.find(tarea => tarea.servicio == servicio.id)">
                                            <p class="parrafo">Aún no hay tareas asignadas</p>
                                        </li>
                                    </ul>
                                </v-tab-item>
                            </v-tabs>
                        </v-container>
                    </v-form>
                 </v-tab-item>
                <v-tab-item  value="tab_documentos" class="tab_documentos">
                    <div class="titulo_area">
                        <p class="parrafo bold">Subido por Steps Relocation</p>
                        <div class="boton_upload_file" v-if="usuario_loggeado.tipo != 'RRHH'">
                            <button class="boton cta" @click="fileInputClick">Subir un archivo</button>
                            <small v-if="!file_error">
                                Recuerda que los nombres de los archivos no deben contener tildes ni caracteres de puntuación o símbolos.<br>
                                Tamaño de archivo máximo 16Mb.
                            </small>
                            <small v-else class="error">{{file_error}}</small>
                        </div>
                    </div>
                    <div class="lista_documentos">
                        <div class="archivo" v-for="(archivo, index) in archivos_steps" :key="index">
                            <img v-if="esImagen(archivo.formato)" :src="archivo.url" class="image" />
                            <img v-else :src="'images/doc_' + archivo.formato + '.svg'" class="image" />
                            <p class="parrafo">{{archivo.nombre}}</p>
                            <p class="small">{{archivo.fecha}}</p>
                            <div class="actions">
                                <span @click.prevent="descargarArchivo(archivo)">
                                    <svg class="exportar" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 10.2916L7.23618 17.631L14.4724 10.2916H10.0905C9.92965 9.14352 9.56784 4.46926 15.1558 1.10707L15.8794 0.656048L15.0352 0.492039C15.0352 0.492039 14.5528 0.410034 13.7487 0.410034C12.0201 0.410034 8.72362 0.902061 6.15075 4.05924C6.07035 4.14124 4.30151 6.10935 4.34171 10.2916H0Z" fill="#4F4F4F"/>
                                    </svg>
                                </span>
                                <!-- <a href="#"><svg class="imprimir" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5539 4.56465H15.5811V0.882143C15.5811 0.531428 15.305 0.244141 14.9598 0.244141H4.43843C4.09692 0.244141 3.81717 0.527697 3.81717 0.882143V4.56838H1.8444C1.04513 4.56838 0.398438 5.23623 0.398438 6.05332V12.4595C0.398438 13.2803 1.04876 13.9444 1.8444 13.9444H3.81717V17.362C3.81717 17.7127 4.09328 18 4.43843 18H14.9598C15.3013 18 15.5811 17.7165 15.5811 17.362V13.9444H17.5539C18.3531 13.9444 18.9998 13.2766 18.9998 12.4595V6.05332C18.9998 5.2325 18.3495 4.56465 17.5539 4.56465ZM5.05968 1.51641H14.3422V4.56465H5.05968V1.51641ZM14.3386 16.724H5.05968C5.05968 16.6009 5.05968 11.6685 5.05968 11.5155H14.3422C14.3386 11.6722 14.3386 16.6046 14.3386 16.724ZM17.7609 12.4595C17.7609 12.5751 17.6665 12.6721 17.5539 12.6721H15.5811V10.8812C15.5811 10.5305 15.305 10.2432 14.9598 10.2432H4.43843C4.09692 10.2432 3.81717 10.5268 3.81717 10.8812V12.6721H1.8444C1.73178 12.6721 1.63732 12.5751 1.63732 12.4595V6.05332C1.63732 5.93766 1.73178 5.84065 1.8444 5.84065C2.32034 5.84065 17.0888 5.84065 17.5539 5.84065C17.6665 5.84065 17.7609 5.93766 17.7609 6.05332V12.4595Z" fill="#4F4F4F"/>
                                </svg></a> -->
                                <span v-if="usuario_loggeado.tipo != 'Legal' && usuario_loggeado.tipo != 'RRHH'">
                                    <svg @click="borrarArchivo(archivo)" class="borrar" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.21484 6.55718C5.21484 6.33821 5.3965 6.15906 5.61853 6.15906C5.84056 6.15906 6.02223 6.33821 6.02223 6.55718V14.0818C6.02223 14.3008 5.84056 14.4799 5.61853 14.4799C5.3965 14.4799 5.21484 14.3008 5.21484 14.0818V6.55718Z" fill="#4F4F4F"/>
                                        <path d="M9.97852 6.55718C9.97852 6.33821 10.1602 6.15906 10.3822 6.15906C10.6042 6.15906 10.7859 6.33821 10.7859 6.55718V14.0818C10.7859 14.3008 10.6042 14.4799 10.3822 14.4799C10.1602 14.4799 9.97852 14.3008 9.97852 14.0818V6.55718Z" fill="#4F4F4F"/>
                                        <path d="M7.5957 6.55718C7.5957 6.33821 7.77736 6.15906 7.99939 6.15906C8.22142 6.15906 8.40308 6.33821 8.40308 6.55718V14.0818C8.40308 14.3008 8.22142 14.4799 7.99939 14.4799C7.77736 14.4799 7.5957 14.3008 7.5957 14.0818V6.55718Z" fill="#4F4F4F"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.14559 5.06001L2.87224 14.8699C2.87224 15.4511 3.08619 15.9926 3.46566 16.3867C3.8411 16.7769 4.36186 16.9998 4.90684 16.9998H11.0914C11.6364 16.9998 12.1571 16.7769 12.5326 16.3867C12.908 15.9965 13.126 15.4511 13.126 14.8699L13.8526 5.06001C14.5995 4.86493 15.0879 4.15229 14.987 3.39186C14.8861 2.63542 14.2321 2.07008 13.457 2.07008H11.3901V1.57243C11.3942 1.15439 11.2246 0.752286 10.9259 0.457672C10.6231 0.159078 10.2154 -0.00415426 9.7915 -0.000172993H6.20673C5.78285 -0.00415426 5.37512 0.159078 5.07235 0.457672C4.77362 0.752286 4.60407 1.15439 4.60811 1.57243V2.07008H2.54121C1.76613 2.07008 1.11215 2.63542 1.01122 3.39585C0.910301 4.15229 1.39877 4.86493 2.14559 5.06001ZM11.0914 16.2036H4.90684C4.21653 16.2036 3.67962 15.6183 3.67962 14.8699L2.95298 5.09585H13.0452L12.3186 14.8699C12.3186 15.6183 11.7817 16.2036 11.0914 16.2036ZM5.41549 1.57243C5.41145 1.3654 5.49623 1.16634 5.6456 1.01903C5.79496 0.871724 5.99681 0.792099 6.20673 0.79608H9.7915C10.0014 0.792099 10.2033 0.871724 10.3526 1.01903C10.502 1.16634 10.5827 1.3654 10.5827 1.57243V2.07008H5.41549V1.57243ZM2.54121 2.86634H13.457C13.8567 2.86634 14.1837 3.18882 14.1837 3.58296C14.1837 3.97711 13.8567 4.29959 13.457 4.29959H2.54121C2.14156 4.29959 1.81457 3.97711 1.81457 3.58296C1.81457 3.18882 2.14156 2.86634 2.54121 2.86634Z" fill="#4F4F4F"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="titulo_area" v-if="archivos_usuario.length">
                        <p class="parrafo bold">Subido por el usuario</p>
                    </div>
                    <div class="lista_documentos">
                        <div class="archivo" v-for="(archivo, index) in archivos_usuario" :key="index">
                            <img v-if="esImagen(archivo.formato)" :src="archivo.url" class="image" />
                            <img v-else :src="'images/doc_' + archivo.formato + '.svg'" class="image" />
                            <p class="parrafo">{{archivo.nombre}}</p>
                            <p class="small">{{archivo.fecha}}</p>
                            <div class="actions">
                                <span @click.prevent="descargarArchivo(archivo)">
                                    <svg class="exportar" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 10.2916L7.23618 17.631L14.4724 10.2916H10.0905C9.92965 9.14352 9.56784 4.46926 15.1558 1.10707L15.8794 0.656048L15.0352 0.492039C15.0352 0.492039 14.5528 0.410034 13.7487 0.410034C12.0201 0.410034 8.72362 0.902061 6.15075 4.05924C6.07035 4.14124 4.30151 6.10935 4.34171 10.2916H0Z" fill="#4F4F4F"/>
                                    </svg>
                                </span>
                                <!-- <a href="#"><svg class="imprimir" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5539 4.56465H15.5811V0.882143C15.5811 0.531428 15.305 0.244141 14.9598 0.244141H4.43843C4.09692 0.244141 3.81717 0.527697 3.81717 0.882143V4.56838H1.8444C1.04513 4.56838 0.398438 5.23623 0.398438 6.05332V12.4595C0.398438 13.2803 1.04876 13.9444 1.8444 13.9444H3.81717V17.362C3.81717 17.7127 4.09328 18 4.43843 18H14.9598C15.3013 18 15.5811 17.7165 15.5811 17.362V13.9444H17.5539C18.3531 13.9444 18.9998 13.2766 18.9998 12.4595V6.05332C18.9998 5.2325 18.3495 4.56465 17.5539 4.56465ZM5.05968 1.51641H14.3422V4.56465H5.05968V1.51641ZM14.3386 16.724H5.05968C5.05968 16.6009 5.05968 11.6685 5.05968 11.5155H14.3422C14.3386 11.6722 14.3386 16.6046 14.3386 16.724ZM17.7609 12.4595C17.7609 12.5751 17.6665 12.6721 17.5539 12.6721H15.5811V10.8812C15.5811 10.5305 15.305 10.2432 14.9598 10.2432H4.43843C4.09692 10.2432 3.81717 10.5268 3.81717 10.8812V12.6721H1.8444C1.73178 12.6721 1.63732 12.5751 1.63732 12.4595V6.05332C1.63732 5.93766 1.73178 5.84065 1.8444 5.84065C2.32034 5.84065 17.0888 5.84065 17.5539 5.84065C17.6665 5.84065 17.7609 5.93766 17.7609 6.05332V12.4595Z" fill="#4F4F4F"/>
                                </svg></a> -->
                                <span v-if="usuario_loggeado.tipo != 'Legal' && usuario_loggeado.tipo != 'RRHH'">
                                    <svg @click="borrarArchivo(archivo)" class="borrar" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.21484 6.55718C5.21484 6.33821 5.3965 6.15906 5.61853 6.15906C5.84056 6.15906 6.02223 6.33821 6.02223 6.55718V14.0818C6.02223 14.3008 5.84056 14.4799 5.61853 14.4799C5.3965 14.4799 5.21484 14.3008 5.21484 14.0818V6.55718Z" fill="#4F4F4F"/>
                                        <path d="M9.97852 6.55718C9.97852 6.33821 10.1602 6.15906 10.3822 6.15906C10.6042 6.15906 10.7859 6.33821 10.7859 6.55718V14.0818C10.7859 14.3008 10.6042 14.4799 10.3822 14.4799C10.1602 14.4799 9.97852 14.3008 9.97852 14.0818V6.55718Z" fill="#4F4F4F"/>
                                        <path d="M7.5957 6.55718C7.5957 6.33821 7.77736 6.15906 7.99939 6.15906C8.22142 6.15906 8.40308 6.33821 8.40308 6.55718V14.0818C8.40308 14.3008 8.22142 14.4799 7.99939 14.4799C7.77736 14.4799 7.5957 14.3008 7.5957 14.0818V6.55718Z" fill="#4F4F4F"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.14559 5.06001L2.87224 14.8699C2.87224 15.4511 3.08619 15.9926 3.46566 16.3867C3.8411 16.7769 4.36186 16.9998 4.90684 16.9998H11.0914C11.6364 16.9998 12.1571 16.7769 12.5326 16.3867C12.908 15.9965 13.126 15.4511 13.126 14.8699L13.8526 5.06001C14.5995 4.86493 15.0879 4.15229 14.987 3.39186C14.8861 2.63542 14.2321 2.07008 13.457 2.07008H11.3901V1.57243C11.3942 1.15439 11.2246 0.752286 10.9259 0.457672C10.6231 0.159078 10.2154 -0.00415426 9.7915 -0.000172993H6.20673C5.78285 -0.00415426 5.37512 0.159078 5.07235 0.457672C4.77362 0.752286 4.60407 1.15439 4.60811 1.57243V2.07008H2.54121C1.76613 2.07008 1.11215 2.63542 1.01122 3.39585C0.910301 4.15229 1.39877 4.86493 2.14559 5.06001ZM11.0914 16.2036H4.90684C4.21653 16.2036 3.67962 15.6183 3.67962 14.8699L2.95298 5.09585H13.0452L12.3186 14.8699C12.3186 15.6183 11.7817 16.2036 11.0914 16.2036ZM5.41549 1.57243C5.41145 1.3654 5.49623 1.16634 5.6456 1.01903C5.79496 0.871724 5.99681 0.792099 6.20673 0.79608H9.7915C10.0014 0.792099 10.2033 0.871724 10.3526 1.01903C10.502 1.16634 10.5827 1.3654 10.5827 1.57243V2.07008H5.41549V1.57243ZM2.54121 2.86634H13.457C13.8567 2.86634 14.1837 3.18882 14.1837 3.58296C14.1837 3.97711 13.8567 4.29959 13.457 4.29959H2.54121C2.14156 4.29959 1.81457 3.97711 1.81457 3.58296C1.81457 3.18882 2.14156 2.86634 2.54121 2.86634Z" fill="#4F4F4F"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <v-file-input 
                        ref="fileInput"
                        id="fileInput"
                        v-model="new_file"
                        accept="*"
                        style="display: none"
                    />
                </v-tab-item>
            </v-tabs>
        </div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ snackbar_text }}
            <v-btn snackbar_text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
import PanelFicha from '@/components/PanelFicha.vue'
import ModalCrearTarea from '@/components/ModalCrearTarea.vue'
import ModalSimple from '@/components/ModalSimple.vue'
import StDatePicker from '@/components/StDatePicker.vue'
import StDocxTemplater from '@/components/StDocxTemplater.vue'
import ModalAsignarConsejera from '@/components/ModalAsignarConsejera.vue'
import ModalCrearUsuario from '@/components/ModalCrearUsuario.vue'
import ModalNuevotrl from '@/components/ModalNuevotrl.vue'
import ModalTrlLog from '@/components/ModalTrlLog.vue'
import { saveAs } from 'file-saver'


export default {
	name: 'FichaTRL',
    components: {
        PanelFicha,
        ModalCrearTarea,
        ModalSimple,
        StDatePicker,
        StDocxTemplater,
        Service,
        ModalAsignarConsejera, ModalSimple, ModalCrearUsuario, ModalNuevotrl, ModalTrlLog
	},
	// props: ['userId'],

    data() {
        return {
            user_Id: null,
            consAnswer: null,
            respuesta: null,
            usuario: {},
            usuario_inicial: {},
            conyuge: null,
            edit_datos_personales: false,
            edit_datos_contacto: false,
            edit_datos_trabajo: false,
            edit_datos_conyuge: false,
            edit_datos_hijos: false,
            edit_imagen_pasaporte: false,
            edit_imagen_pasaporte_conyuge: false,
            edit_traslado: false,
            edit_vivienda: false,
            edit_colegios: false,
            edit_suministros: false,
            edit_otros: false,
            fecha_traslado_input: false,
            fecha_entrada_input: false,
            tareas_trl: null,
            modal_tarea: false,
            servicio_selected: null,
            subservicio_selected: null,
            tarea_selected: null,
            mas_servicios: [],
            lista_empresas: [],
            lista_rrhh: [],
            archivos: [],
            new_file: null,
            file_error: '',
            snackbar: false,
            snackbar_text : null,
            cambios: null,
            post_render: false,
            archivo_borrar: null,
            modal_simple_borrar: false,
            modal_simple_respuesta: false,
            modal_simple_finalizado: false,
            modal_simple_cancelar_servicio: false,
            modal_simple_error: false,
            modal_cerrar_trl: false,
            crear_word: false,
            servicio_a_actualizar: null,
            archivos_usuario: [],
            ws: null,
            data: null,
            ws_spinner: false,
            modal_nuevotrl: false,
            modal_crear_usuario: false,
            modal_simple: false,
            modal_consejera: false,
            modal_trl_log: false,
        }
    },

    watch: {
        usuario_loggeado() {
            if (this.user_id && this.usuario_loggeado.id) {
                this.data = this.user_id;
                this.ws = 'getUserEnc';
            } 
        },
        eventos: {
            handler(newComponents, oldComponents) {
                this.tareas_trl = this.eventos.filter(tarea => tarea.trl === this.usuario.id);
            },
            deep: true
        },
        'usuario.personales.pasaporte_imagen': {
            handler(newComponents, oldComponents) {
                if (!!oldComponents && !newComponents && !this.edit_datos_personales) {
                    this.data = {id: this.usuario.id, updater: this.usuario_loggeado.id};
                    this.ws_spinner = true;
                    this.ws = 'deleteTrlIdentityPhoto';
                }
            },
        },
        'usuario.conyuge.pasaporte_imagen': {
            handler(newComponents, oldComponents) {
                if (!!oldComponents && !newComponents && !this.edit_datos_conyuge) {
                    this.data = {id: this.usuario.conyuge.id};
                    this.ws_spinner = true;
                    this.ws = 'deleteTrlSpouseIdentityPhoto';
                }
            },
        },
        empresas() {
            this.empresas.length ? this.empresas.forEach(empresa => {
                let empresa_lista = {
                    value: parseInt(empresa.id),
                    text: empresa.nombre
                }
                this.lista_empresas.push(empresa_lista);
            }) : '';
        },
        empresa_sel() {
            this.lista_rrhh = [];
            let rrhhs = this.usuarios.filter(usuario => usuario.tipo === 'RRHH' && usuario.personales.empresa == this.empresa_sel );
            rrhhs.forEach(rrhh => {
                let nombre_completo = rrhh.personales.nombre + ' ' + rrhh.personales.apellidos;
                this.lista_rrhh.push({value: rrhh.id, text: nombre_completo});
            })
        },
        usuarios() {
            this.lista_rrhh = [];
            let rrhhs = this.usuarios.filter(usuario => usuario.tipo === 'RRHH' && usuario.personales.empresa == this.empresa_sel );
            rrhhs.forEach(rrhh => {
                let nombre_completo = rrhh.personales.nombre + ' ' + rrhh.personales.apellidos;
                this.lista_rrhh.push({value: rrhh.id, text: nombre_completo});
            })
        },
        cambios() {
            if (this.cambios && this.cambios.ser && this.cambios.ser === 'suministros') {
                clearTimeout(update);
                let cambios = this.cambios;
                let update = setTimeout(() => {
                    this.updateProfile('suministros', cambios.sub || null);
                    this.cambios = null;
                    cambios = null;
                }, 1000);
            } else if (this.servicios.find(sr => sr.id == this.cambios)) {
                clearTimeout(update_other);
                let cambios = this.cambios;
                let update_other = setTimeout(() => {
                    this.updateProfile('servicios', cambios);
                    this.cambios = null;
                    cambios = null;
                }, 1000);
            } else if (this.cambios === 'vivienda') {
                if (this.post_render) {
                    clearTimeout(update);
                    let update = setTimeout(() => {
                        this.updateProfile('vivienda');
                        this.cambios = null;
                    }, 1000);
                }
            } else if (this.cambios === 'personales') {
                clearTimeout(update);
                let update = setTimeout(() => {
                    this.updateProfile('datos_personales');
                    this.cambios = null;
                }, 1000);
            }
        },
        new_file() {
            if (this.new_file) {
                this.file_error = '';
                const caracteres_especiales = /[!@#$%*()\=\[\]{};':"\\|<>\/?]+/;
                if ((this.new_file.size/1048576).toFixed(1) >= 16) {
                    this.file_error = 'No se puede subir el archivo porque supera el límite de tamaño de 16megas';
                    this.new_file = null;
                } else if (caracteres_especiales.test(this.new_file.name) ) {
                    this.file_error = 'No se puede subir el archivo porque contiene caracteres especiales que no están admitidos';
                    this.new_file = null;
                } else {
                    this.ws_spinner = true;
                    this.data = {
                        trl_id: this.usuario.id,
                        file: this.new_file,
                        tipo: JSON.stringify(['documento', this.usuario_loggeado.id])
                    };
                    this.ws = 'fileUpload';
                }
            }
        },
        archivos() {
            let archivos = this.archivos.filter(fil => fil.subido_por == this.usuario.id);
            let formato;
            if (this.usuario.personales.pasaporte_imagen && this.usuario.personales.pasaporte_imagen.name) {
                formato = this.usuario.personales.pasaporte_imagen.name.split('.').pop();
                archivos.push({
                    fecha:"",
                    formato: formato,
                    id: "0",
                    nombre:"foto del pasaporte",
                    subido_por: this.usuario.id,
                    tipo: "documento",
                    url: this.usuario.personales.pasaporte_imagen_url
                });
            }
            if (this.usuario.conyuge.pasaporte_imagen && this.usuario.conyuge.pasaporte_imagen.name) {
                formato = this.usuario.conyuge.pasaporte_imagen.name.split('.').pop();
                archivos.push({
                    fecha:"",
                    formato: formato,
                    id: "0",
                    nombre:"foto del pasaporte de cónyuge",
                    subido_por: this.usuario.id,
                    tipo: "documento",
                    url: this.usuario.conyuge.pasaporte_imagen_url
                });
            }
            this.archivos_usuario = archivos;
        }
    },
	computed:  {
        ...mapState([
            'eventos', 'usuario_loggeado', 'servicios', 'empresas', 'usuarios'
        ]),
        garaje() {
            if (this.usuario.preferencias.vivienda.garaje) {
                return  `Sí | ${this.usuario.preferencias.vivienda.coches} coche | ${this.usuario.preferencias.vivienda.coches_en_presupuesto ? `inluido en el presupuesto ` : ``}`;
            } else {return 'No';}
        },
        mascota() {
            if (this.usuario.preferencias.vivienda.mascota) {
                return  `Sí | ${this.usuario.preferencias.vivienda.mascota_tipo}`;
            } else {return 'No';}
        },
        servicio_suministros() {
            return this.usuario.servicios.find(serv => serv.nombre === 'Suministros');
        },
        empresa_sel() {
            return this.usuario.id ? this.usuario.personales.empresa : ''
        },
        servicio_suministros_gestion() {return this.usuario.servicios.find(serv => serv.nombre === 'Suministros').gestion;},
        archivos_steps() {
            return this.archivos.filter(fil => fil.subido_por != this.usuario.id);
        },
        rrhh_block() {
            return this.usuario_loggeado.tipo === 'RRHH';
        },
        abogado_block() {
            return this.usuario_loggeado.tipo === 'Legal';
        }
    },

    methods: {
        openFile(ref) {
            document.getElementById(ref).click()
        },
        toList(obj) {
            let list = "";
            for (let prop in obj) {
                if (obj[prop]) {list += prop + ', '}
            }
            return list.replace(/_/g, ' ');
        },
        crearTarea(servicio_id) {
            let evento = {
                name: this.nueva_tarea.name,
                trl: this.usuario.id,
                consejera: this.usuario.consejera.id,
                creada_por: this.usuario_loggeado.id,
                start: this.nueva_tarea.start,
                servicio: servicio_id,
                notas: this.nueva_tarea.notas,
                mostrar_rrhh: this.nueva_tarea.mostrar_rrhh,
                notificar_email: this.nueva_tarea.notificar_email
            }
            let tareas = [...this.eventos];
            tareas.push(evento);
            this.$store.dispatch('setEventos', tareas);
        },
        openModal(modal) {
            this[modal] = true;
        },
        openModalTarea(servicio, tarea, subservicio) {
            tarea ? this.tarea_selected = tarea : this.tarea_selected = null;
            this.servicio_selected = servicio;
            subservicio ? this.subservicio_selected = subservicio :  this.subservicio_selected = null;
			this.modal_tarea = true;
        },

        // MODALES DE PANEL
        closeModalUsuario(nuevo_usuario) {
			this.modal_crear_usuario = false;
            if (nuevo_usuario.id) this.refreshUser();
		},
        closeModalTrlLog() {
            this.modal_trl_log = false;
        },
        closeModalConsejera() {
            this.refreshUser()
            this.modal_consejera = false;
        },
        // -------------------------
        closeModalSimple(action, servicio) {
            this.modal_simple_borrar = false;
            this.modal_simple_respuesta = false;
            this.modal_simple_cancelar_servicio = false;
            this.modal_simple_finalizado = false;
            this.modal_simple_error = false;
            this.modal_cerrar_trl = false;
            this.modal_simple = false;
            if (action === 'delete_usuario') {
                this.data = {trl: this.usuario.id, updater: this.usuario_loggeado.id};
                this.ws_spinner = true;
                this.ws = 'deleteUser';
            } else if (action === 'delete_archivo') {
                this.ws_spinner = true;
                if (this.archivo_borrar.nombre === 'foto del pasaporte') {
                    this.usuario.personales.pasaporte_imagen = null;
                } else if (this.archivo_borrar.nombre === 'foto del pasaporte de cónyuge') {
                    this.usuario.conyuge.pasaporte_imagen = null;
                } else {
                    this.data = this.archivo_borrar.id;
                    this.ws = 'fileRemove';
                }
                // this.$store.dispatch('setLoading', true);
            } else if (action === 'cerrar_TRL_con_encuesta') {
                this.data = {id: this.usuario.id, status: 1, send_survey: 'yes', updater: this.usuario_loggeado.id};
                this.ws = 'trlStatusUpdate';
            } else if (action === 'cerrar_TRL_sin_encuesta') {
                this.data = {id: this.usuario.id, status: 1, send_survey: 'no', updater: this.usuario_loggeado.id};
                this.ws = 'trlStatusUpdate';
            } else if (action === 'reactivar_TRL') {
                this.data = {id: this.usuario.id, status: 0, updater: this.usuario_loggeado.id};
                this.ws = 'trlStatusUpdate';
            } else if (action === 'cancelar_servicio') {
                this.cancelarServicio(servicio);
            } else if (action === 'cons_rechazo') {
                this.$router.push({ name: 'Usuarios'});
            }
        },
		closeModalTarea() {
			this.modal_tarea = false;
            this.tarea_selected = null;
            this.subservicio_selected = null;
		},
        formatDate(date) {
            return this.$moment(date).format('DD/MM/YYYY HH:mm');
        },
        getServicio(id) {
            let servicio = this.usuario.servicios.find(servicio => servicio.id == id );
            return servicio;
        },
        updateProfile(seccion, servicio) {
            this.data = {};
            this.data.updater = this.usuario_loggeado.id;
            if (seccion === "datos_personales" || seccion === 'datos_contacto' || seccion === 'datos_conyuge') {
                this.usuario.conyuge.id && !this.conyuge ? this.usuario.conyuge = {
                        id: null,
                        nombre: ' ',
                        apellidos: null,
                        nacionalidad: null,
                        telefono: null,
                        email: null,
                        pasaporte: null,
                        pasaporte_imagen: null
                } : '';
                this.data = {...this.usuario};
                this.data.updater = this.usuario_loggeado.id;
                this.ws = 'trlPersonalDataReg';
            } else if (seccion === 'datos_traslado') {
                this.data = {...this.usuario};
                this.data.updater = this.usuario_loggeado.id;
                this.ws = 'trlRelocation_data';
            } else if (seccion === 'datos_hijos') {
                this.data = {...this.usuario};
                this.data.updater = this.usuario_loggeado.id;
                this.ws = 'trlSchoolPreferences';
            } else if (seccion === 'suministros') {
                this.data.service_id = 43;
                this.data.id = this.usuario.id;
                this.data.servicio = this.servicio_suministros;
                servicio && !this.servicio_suministros.gestion[servicio].gestion.inicio ? this.servicio_suministros.gestion[servicio].gestion.inicio = this.$moment().format('YYYY-MM-DD HH:mm') : ''
                // ['internet', 'agua', 'luz', 'gas'].forEach(sumi => {
                //     !this.servicio_suministros.gestion[sumi].gestion.inicio ? this.servicio_suministros.gestion[sumi].gestion.inicio = this.$moment().format('YYYY-MM-DD HH:mm') : ''
                // });
                this.ws = 'trlServiceUpdate';
            } else if (seccion === 'servicios') {
                this.data.service_id = servicio;
                this.data.id = this.usuario.id;
                this.data.servicio = {...this.usuario.servicios.find(serv => serv.id == servicio)};
                if (servicio != 43) {
                    !this.data.servicio.gestion.inicio ? this.data.servicio.gestion.inicio = this.$moment().format('YYYY-MM-DD HH:mm') : '';
                } 
                this.ws = 'trlServiceUpdate';
            } else if (seccion === 'vivienda') {
                this.data = {id: this.usuario.id, servicio_vivienda: this.usuario.servicio_vivienda, updater: this.usuario_loggeado.id}
                this.ws = 'trlHomeContract';
            }
        },
        cancelEdit(seccion) {
            if (seccion === 'datos_personales') {
                this.usuario.personales = {...this.usuario_inicial.personales};
                this.edit_datos_personales = !this.edit_datos_personales;
            } else if (seccion === 'datos_contacto') {
                this.usuario.contacto = {...this.usuario_inicial.contacto};
                this.edit_datos_contacto = !this.edit_datos_contacto;
            } else if (seccion === 'datos_conyuge') {
                this.usuario.conyuge = {...this.usuario_inicial.conyuge};
                this.edit_datos_conyuge = !this.edit_datos_conyuge;
            } else if (seccion === 'datos_traslado') {
                this.usuario.preferencias.traslado = {...this.usuario_inicial.preferencias.traslado};
                this.edit_traslado = !this.edit_traslado;
            }
        },
        setEstadoServicio() {
            // this.$store.dispatch('setLoading', true);
            let todos_finalizados = true;
            this.usuario.servicios.forEach(servi => {
                if (servi.id == 43) {
                    ['internet', 'agua', 'luz', 'gas'].forEach(sumi => {
                        if (servi.gestion[sumi].gestion.estado != 'cancelado' && servi.gestion[sumi].gestion.inicio) {
                            if (servi.gestion[sumi].gestion.fin) {
								servi.gestion[sumi].gestion.estado = 'finalizado';
								servi.gestion[sumi].gestion.finalizada_por = this.usuario_loggeado.id;
                            } else if (servi.gestion[sumi].gestion.inicio) {
								servi.gestion[sumi].gestion.estado = 'en proceso'
							}
                        }
                        if (!servi.gestion[sumi].gestion.estado || servi.gestion[sumi].gestion.estado === 'en proceso') {todos_finalizados = false;}
                    });
                } else if (servi.estado != 'cancelado' &&  servi.otros_servicios && servi.gestion.inicio) {
                    if (servi.gestion.fin) { 
						servi.estado = 'finalizado';
						servi.finalizada_por = this.usuario_loggeado.id;
                    } else if (servi.gestion.inicio) {
						servi.estado = 'en proceso'; todos_finalizados = false;
					}
                } 
                if (servi.otros_servicios && (!servi.estado || servi.estado === 'en proceso')) {todos_finalizados = false;}
            });
            if (todos_finalizados && this.usuario.estado == 0 && this.usuario.servicios.length && !!this.usuario.servicios.find(ser => ser.otros_servicios) && !this.usuario.finalizado) {
                this.modal_simple_finalizado = true;
            } else if (!todos_finalizados && this.usuario.estado == 1) {
                // this.data = {id: this.usuario.id, status: 0};
                // this.ws = 'trlStatusUpdate';
            }
        },
        cancelarServicio(servi) {
            let servicio;
            if (servi === 'internet' || servi === 'agua' || servi === 'luz' || servi === 'gas') {
                servicio = this.servicio_suministros.gestion[servi];
                if (servicio.gestion.estado != 'cancelado') {
                    servicio.gestion.estado = 'cancelado';
                    this.eventos.forEach(ev => {
                    //eliminar tareas de ese usuario y ese subservicio
                        if (ev.trl == this.usuario.id && ev.servicio == 43 && ev.name.includes(servi)) { 
                            this.data = ev.id;
                            this.ws = 'taskRemove';
                            this.servicio_a_actualizar = 43;
                        }
                    })
                    this.updateProfile('servicios', 43);
                } else {
                    servicio.gestion.estado = 'en proceso';
                    this.updateProfile('servicios', 43);
                }
            } else {
                servicio = servi;
                if (servicio.estado != 'cancelado') {
                    servicio.estado = 'cancelado';
                    this.eventos.forEach(ev => {
                        //eliminar tareas de ese usuario y ese servicio
                        if (ev.trl == this.usuario.id && ev.servicio == servicio.id) {
                            this.data = ev.id;
                            this.ws = 'taskRemove';
                            this.servicio_a_actualizar = servicio.id;
                        }
                    })
                    this.updateProfile('servicios', servicio.id);
                } else {
                    servicio.estado = 'en proceso';
                    this.updateProfile('servicios', servicio.id);
                }
            }
            // let serv_id = servi === 'internet' || servi === 'agua' || servi === 'luz' || servi === 'gas' ? 43 : servicio.id;
            // this.updateProfile('servicios', serv_id);
        },
        crearWord() {
            this.crear_word = true;
            // setTimeout(() => this.crear_word = false, 500);
        },
        fileInputClick() {
            document.getElementById("fileInput").click();
        },
        borrarArchivo(archivo) {
            this.archivo_borrar = archivo;
            this.modal_simple_borrar = true;
        },
        getFiles(url, from) {
            let that = this;
            async function createFile(){
                let response = await fetch(url, {mode: 'no-cors'});
                let data = await response.blob();
                var index = url.lastIndexOf("/") + 1;
                var filename = url.substr(index);
                if (from === 'trl') {
                    that.usuario.personales.pasaporte_imagen = new File([data], filename);
                } else {
                    that.usuario.conyuge.pasaporte_imagen = new File([data], filename);
                }
            }
            createFile();
        },
        descargarPasaporte(emisor) {
            if (emisor === 'trl') {
                const formato = this.usuario.personales.pasaporte_imagen.name.split('.').pop();
                saveAs(this.usuario.personales.pasaporte_imagen_url, "pasaporte_" + this.usuario.personales.apellidos + "." + formato);
            } else {
                const formato = this.usuario.conyuge.pasaporte_imagen.name.split('.').pop();
                saveAs(this.usuario.conyuge.pasaporte_imagen_url, "pasaporte_" + this.usuario.personales.apellidos + "_conyuge." + formato);
            }
        },
        descargarArchivo(archivo) {
            saveAs(archivo.url, archivo.nombre);
        },
        esImagen(formato) {
            return formato === 'jpg' || formato === 'jpeg' || formato === 'png' || formato === 'apng' ? true : false
        },
        refreshUser() {
            this.data = this.usuario.enc_id;
            this.ws = 'getUserEnc';
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'deleteUser') {
                this.deletedUser = true;
                this.data = {queries: '?order_by=recents&type=trl,admin,cons,rrhh'};
                this.ws = 'userList';
            } else if (response.ws === 'getUser' || response.ws === 'getUserEnc') {
                let data = response.data;
                const iterate = (obj) => {
                    if (obj && typeof obj !== "undefined") {
                        Object.keys(obj).forEach(key => {
                            if (typeof obj[key] === 'object') {
                                iterate(obj[key])
                            } else {
                                obj[key] === 'null' ? obj[key] = null : ''
                            }
                        })
                    }
                }
                iterate(data);
                let servicios = [];
                data.t_rluser_services.forEach(ser => {
                    let servicio;
                    if (ser.data.id) {
                        if (ser.data.id === 43) {
                            if (Array.isArray(ser.data.gestion.internet.servicio)) ser.data.gestion.internet = JSON.parse(JSON.stringify(this.servicios.find(se => se.id == 43).gestion.internet));
                            if (Array.isArray(ser.data.gestion.gas.servicio)) ser.data.gestion.gas =  JSON.parse(JSON.stringify(this.servicios.find(se => se.id == 43).gestion.gas));
                            if (Array.isArray(ser.data.gestion.luz.servicio)) ser.data.gestion.luz =  JSON.parse(JSON.stringify(this.servicios.find(se => se.id == 43).gestion.luz));
                            if (Array.isArray(ser.data.gestion.agua.servicio)) ser.data.gestion.agua =  JSON.parse(JSON.stringify(this.servicios.find(se => se.id == 43).gestion.agua));
                        } 
                        servicio = ser.data;
                    } else {
                        servicio = JSON.parse(JSON.stringify(this.servicios.find(se => se.id == ser.service_id)));
                        servicio.nombre_sub = ser.data.nombre_sub || null;
                    }
                    if (!servicio.oculto) servicios.push(servicio);
                });
                if (servicios.length) servicios.sort((a, b) => a.order - b.order);

                let paquetes = [];
                data.t_rluser_packs.forEach(paq => paquetes.push(paq.pack_id));

                let hijos = [];
                let colegios = {
					numero_hijos: 1,
					hijos: [
						{
							nombre: '',
							apellidos: '',
							nacimiento: '',
							sexo: '',
							curso: '',
							idiomas: ''
						}
					],
					preferencias: {
						tipo_colegio: {
							concertado: false,
							privado: false,
							publico: false
						},
						tipo_educacion: {
							alemana: false,
							americana: false,
							britanica: false,
							española: false,
							francesa: false,
							otras: false
						}
					}
				};
                if (data.t_rluser_school_preferences) {
                    data.t_rluser_school_preferences.children.forEach(hi => {
                        let hijo = {
                            nombre: hi.name,
                            apellidos: hi.surnames,
                            nacimiento: hi.birthdate,
                            sexo: hi.gendre,
                            curso: hi.school_year,
                            idiomas: hi.languages
                        }
                        hijos.push(hijo);
                    });
                    colegios = {
                        numero_hijos: data.t_rluser_school_preferences.children.length,
                        hijos: hijos,
                        preferencias: {
                            tipo_colegio: {
                                concertado: data.t_rluser_school_preferences.type_concertado,
                                privado: data.t_rluser_school_preferences.type_privado,
                                publico: data.t_rluser_school_preferences.type_publico
                            },
                            tipo_educacion: {
                                alemana: data.t_rluser_school_preferences.education_german,
                                americana: data.t_rluser_school_preferences.education_american,
                                britanica: data.t_rluser_school_preferences.education_british,
                                española: data.t_rluser_school_preferences.education_spanish,
                                francesa: data.t_rluser_school_preferences.education_french,
                                otras: data.t_rluser_school_preferences.education_other
                            }
                        }
                    }
                } 

                let conyuge;
                if (data.t_rluser_spouse) {
                    this.conyuge = true;
                    conyuge = {
                        id: data.t_rluser_spouse.id,
                        nombre: data.t_rluser_spouse.name,
                        apellidos: data.t_rluser_spouse.surnames,
                        nacionalidad: data.t_rluser_spouse.nationality,
                        telefono: data.t_rluser_spouse.phone_number,
                        email: data.t_rluser_spouse.email,
                        pasaporte: data.t_rluser_spouse.identity_id,
                        pasaporte_imagen: !data.t_rluser_spouse.identity_id_photo || data.t_rluser_spouse.identity_id_photo === 'https://ws.stepsrelocation-panel.com/file/view/siip/' ? null : data.t_rluser_spouse.identity_id_photo
                    }
                } else {
                    this.conyuge = false;
                    conyuge = {
                        id: null,
                        nombre: null,
                        apellidos: null,
                        nacionalidad: null,
                        telefono: null,
                        email: null,
                        pasaporte: null,
                        pasaporte_imagen: null
                    }
                }

                let consejeras = [];
                if (data.t_rluser_cons[0]) {
                    data.t_rluser_cons.forEach(con => {
                        consejeras.push(con);
                    });
                }

                let trl_rrhhs = [];
                if (data.t_rluser_rrhhs.length) {
                    data.t_rluser_rrhhs.forEach(rh => {
                        trl_rrhhs.push(rh.id);
                    });
                }

                let toDate = (timestamp) => {
                    return timestamp ? this.$moment(parseInt(timestamp)).format('YYYY-MM-DD') || null : '';
                }
                
                let fechas_disponible = [];
                if (data.t_rluser_relocation_preferences) {
                    data.t_rluser_relocation_preferences.relocation_visit_date ? data.t_rluser_relocation_preferences.relocation_visit_date.forEach(fecha => fechas_disponible.push(toDate(fecha))) : '';
                } 
                this.usuario = {
                    id: data.id,
                    enc_id: data.encrypted_id,
                    tipo: 'TRL',
                    estado: data.trl_status,
                    user_status: data.user_status,
                    creado_por: null,
                    finalizado: data.finished_date || null,
                    politica_privacidad: data.privacy_policy && data.spouse_privacy_policy,
                    idioma_seleccionado: data.preferred_language,
                    personales: {
                        nombre: data.name,
                        apellidos: data.surnames,
                        empresa: data.company ? data.company.id : null,
                        foto: data.photo,
                        ciudad: data.relocation_city,
                        rrhh: data.t_rluser_rrhhs.length ? trl_rrhhs : null,
                        idiomas: null,
                        idioma_preferido: data.preferred_language,
                        nacionalidad: data.nationality,
                        direccion: data.current_address,
                        pasaporte: data.identity_id,
                        pasaporte_imagen: data.identity_id_photo || null,
                        sexo: data.gender,
                        visible_abogados: data.available_legal_user_access
                    },
                    datos_bancarios: {
                        cuenta: data.iban || '',
                        banco: data.bank || ''
                    },
                    contacto: {
                        telefono: data.phone_number,
                        email_profesional: data.email,
                        email: data.secondary_email,
                        email_personal: data.secondary_email
                    },
                    conyuge: conyuge,
                    consejera: {
                        id: consejeras.find(cons => cons.status == 3) ? consejeras.find(cons => cons.status == 3).id : 1,
                        asignadas: consejeras
                    },
                    preferencias: {
                        traslado: {
                            fecha_traslado: data.t_rluser_relocation_preferences ? toDate(data.t_rluser_relocation_preferences.relocation_date) : null,
                            fecha_entrada: data.t_rluser_relocation_preferences ? toDate(data.t_rluser_relocation_preferences.desired_relocation_date) : null,
                            fecha_disponible: fechas_disponible,
                            tiempo_alquiler: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.relocation_rental_duration : null,
                            presupuesto: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.relocation_budget : null
                        },
                        vivienda: {
                            tipo: {
                                piso: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_type_piso : '',
                                casa: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_type_casa : ''
                            },
                            metros: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_meters : '',
                            habitaciones: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_rooms_number : '',
                            estado: {
                                amueblada: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_state_amueblada : '',
                                sin_amueblar: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_state_noamueblada : ''
                            },
                            estilo: {
                                clasico: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_style_clasico : '',
                                moderno: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_type_moderno : ''
                            },
                            caracteristicas: {
                                piscina: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_pool : '',
                                jardin: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_garden : '',
                                terraza: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_terrace : '',
                                zonas_infantiles: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_children_area : '',
                                habitacion_servicio: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_room_service : ''
                            },
                            otras_preferencias: {
                                centro: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_city_center : '',
                                afueras: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_city_outskirts : '',
                                cerca_oficina: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_near_office : '',
                                cerca_colegio: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_near_school : '',
                                cerca_metro: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_near_subway : '',
                                soleado: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_sunny : '',
                                zona_tranquila: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_quiet_zone : ''
                            },
                            areas_residenciales: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_residential_areas : '',
                            garaje: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_parking_spaces ? true : false : null,
                            coches: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_parking_spaces : '',
                            coches_en_presupuesto: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_parking_spaces_in_budget : '',
                            mascota: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_pet_allowed : '',
                            mascota_tipo: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_pet_type : '',
                            otros: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_others : ''
                        },
                        colegios: colegios
                    },
                    servicio_vivienda: {
                        viviendas_preseleccionadas: [],
                        viviendas_preseleccionadas_links: [],
                        gestion: {
                            fecha_aviso: data.t_rluser_home_contract ? toDate(data.t_rluser_home_contract.date_advise) : null,
                            fecha_primer_contacto: data.t_rluser_home_contract ? toDate(data.t_rluser_home_contract.date_first_contact) : null,
                            fecha_llegada: data.t_rluser_home_contract ? toDate(data.t_rluser_home_contract.date_arrival) : null,
                            fecha_visita: data.t_rluser_home_contract ? toDate(data.t_rluser_home_contract.date_home_visit) : null,
                            fecha_busqueda_colegios: data.t_rluser_home_contract ? toDate(data.t_rluser_home_contract.date_search_school) : null,
                            fecha_firma_contrato: data.t_rluser_home_contract ? toDate(data.t_rluser_home_contract.date_signature_contract) : null,
                            fecha_entrada: data.t_rluser_home_contract ? toDate(data.t_rluser_home_contract.date_entry) : null,
                            otros: data.t_rluser_home_contract ? data.t_rluser_home_contract.company_notes : null
                        },
                        datos_vivienda: {
                            direccion: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_address : null,
                            referencia_catastral: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_cadastral_reference : null,
                            propietario_nombre: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_owner_name : null,
                            propietario_dni: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_owner_identity_id : null,
                            propietario_direccion: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_owner_address : null,
                            propietario_telefono: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_owner_phone_number : null,
                            propietario_email: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_owner_email : null,
                            iban: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_owner_iban : null,
                            encargado_nombre: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_keeper_name : null,
                            encargado_telefono: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_keeper_phone_number : null,
                            encargado_email: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_keeper_email : null,
                            inmobiliaria_nombre: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_real_estate_agency_name : null,
                            inmobiliaria_telefono: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_real_estate_agency_phone_number : null,
                            inmobiliaria_email: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_real_estate_agency_email : null,
                            otros: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_notes : null
                        },
                        contrato_alquiler: {
                            fecha_inicio: data.t_rluser_home_contract ? toDate(data.t_rluser_home_contract.home_rental_date_init) : null,
                            duracion_obligatoria: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_mandatory_duration : null,
                            preaviso: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_notice : null,
                            renta: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_rent : null,
                            contrato_nombre: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_holder : null,
                            contrato_firmado: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_signature : null,
                            fianza: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_deposit : null,
                            fianza_pagador: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_deposit_holder : null,
                            deposito: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_guarantee : null,
                            deposito_pagador: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_guarantee_holder : null,
                            comision: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_agency_commission : null,
                            comision_pagador: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_agency_commission_holder : null,
                            inventario: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_photographic_inventory : null,
                            contrato_prorrogable: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_extendable : null,
                            presencia_checkin: {
                                inquilino: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_checkin_presence_tenant : null,
                                consejera: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_checkin_presence_cons : null,
                                agente: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_checkin_presence_agent : null,
                                propietario: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_checkin_presence_owner : null
                            },
                            otros: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_rental_contract_notes : null
                        },
                        seguro_hogar: {
                            propietario_nombre: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_owner_name : null,
                            propietario_telefono: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_owner_phone : null,
                            propietario_poliza: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_owner_policy : null,
                            propietario_email: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_owner_email : null,
                            propietario_otros: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_owner_notes : null,
                            inquilino_nombre: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_tenant_name : null,
                            inquilino_telefono: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_tenant_phone : null,
                            inquilino_poliza: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_tenant_policy : null,
                            inquilino_email: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_tenant_email : null,
                            inquilino_otros: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_insurance_tenant_notes : null,
                        },
                        viviendas_preseleccionadas: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_preselected_homes : null
                    },
                    paquetes: paquetes,
                    servicios: servicios,
                }
                this.usuario_inicial = JSON.parse(JSON.stringify(this.usuario));

                this.lista_rrhh = [];
                let rrhhs = this.usuarios.filter(usu => usu.tipo === 'RRHH' && this.usuario.personales.empresa ? usu.personales.empresa == this.usuario.personales.empresa : true );
                rrhhs.forEach(rrhh => {
                    let nombre_completo = rrhh.personales.nombre + ' ' + rrhh.personales.apellidos;
                    this.lista_rrhh.push({value: parseInt(rrhh.id), text: nombre_completo});
                });
                
                this.tareas_trl = this.eventos.filter(tarea => tarea.trl === this.usuario.id);
                this.mas_servicios = this.usuario.servicios.filter(servicio => servicio.otros_servicios != false);
				this.mas_servicios.forEach(ser => {
					if (!ser.order) ser.order = this.servicios.find(servi => servi.id === ser.id).order;
				});
				this.mas_servicios.sort((a, b) => a.order - b.order);

                this.$store.dispatch('setLoading', false);
                if (this.user_id && this.consAnswer) {
                    const existe_cons_asignada = this.usuario.consejera.asignadas.find(co => co.status == 3) || null;
                    // const fecha_diferente = existe_cons_asignada ? (new Date() - (new Date(this.usuario.consejera.asignadas.find(co => co.status == 3).datetime_added  * 1000))) > 1000000 : false;

                    if (!existe_cons_asignada) {
                        this.data = {trl: this.usuario.id, cons: this.usuario_loggeado.id}
                        if (this.consAnswer === 'yes') {
                            this.ws = 'trlConsAccept';
                        } else if (this.consAnswer === 'no') {
                            this.ws = 'trlConsReject';
                        }
                    } else {
                        existe_cons_asignada.id != this.usuario_loggeado.id ? alert('El TRL ya ha sido asignado') : '';
                    }
                } else {
                    this.data = this.usuario.id;
                    this.ws = 'fileList';
                }
                this.usuario.personales.pasaporte_imagen_url = this.usuario.personales.pasaporte_imagen || null;
                this.usuario.personales.pasaporte_imagen ? this.getFiles(this.usuario.personales.pasaporte_imagen, 'trl') : '';

                this.usuario.conyuge.pasaporte_imagen_url = this.usuario.conyuge.pasaporte_imagen || null;
                this.usuario.conyuge.pasaporte_imagen ? this.getFiles(this.usuario.conyuge.pasaporte_imagen, 'conyuge') : '';
            } else if (response.ws === 'trlPersonalDataReg' || response.ws === 'trlRelocation_data' || response.ws === 'trlHomeContract' || response.ws === 'trlSchoolPreferences') {
                this.snackbar_text = 'Datos del TRL editados correctamente';
                this.snackbar = true;
                
                if (this.edit_datos_personales) {
                    this.usuario_inicial.personales = {...this.usuario.personales};
                    this.edit_datos_personales = false;
                } else if (this.edit_datos_contacto) {
                    this.usuario_inicial.contacto = {...this.usuario.contacto};
                    this.edit_datos_contacto = false;
                } else if (this.edit_datos_conyuge) {
                    this.usuario_inicial.conyuge = {...this.usuario.conyuge};
                    this.edit_datos_conyuge = false;
                } else if (this.edit_traslado) {
                    this.usuario_inicial.preferencias.traslado = {...this.usuario.preferencias.traslado};
                    this.edit_traslado = false;
                } else if (this.edit_datos_hijos) {
                    this.usuario_inicial.preferencias.colegios = {...this.usuario.preferencias.colegios};
                    this.edit_datos_hijos = false
                } 
                this.edit_datos_trabajo = false;

                const tipo = this.usuario_loggeado.tipo;
                this.data = {queries: '?order_by=recents&include_finished=no'}
                if (tipo === 'Admin') {this.data.queries += '&type=trl,admin,cons,rrhh';}
                else if (tipo === 'Consejera') {this.data.queries += '&type=trl,cons,rrhh&cons=' + this.usuario_loggeado.id;}
                this.ws = 'userList';

                if (this.edit_imagen_pasaporte_conyuge) {
                    this.data = {id: this.usuario.conyuge.id, img: this.usuario.conyuge.pasaporte_imagen, updater: this.usuario_loggeado.id};
                    this.ws = this.usuario.conyuge.pasaporte_imagen ? 'trlSpouseIdentityPhoto' : 'deleteTrlSpouseIdentityPhoto';
                }
            } else if (response.ws === 'trlServiceUpdate') {
                this.setEstadoServicio();
                // let usuarios = this.usuarios;
                // usuarios.forEach(usu => usu.id == this.usuario.id ? usu = this.usuario : '');
                // this.$store.dispatch('setUsuarios', usuarios);

                this.snackbar_text = 'Datos guardados correctamente';
                this.snackbar = true;
            } else if (response.ws === 'fileUpload' || response.ws === 'fileRemove') {
                this.new_file = null;
                this.data = this.usuario.id;
                this.ws = 'fileList';
            } else if (response.ws === 'fileList') {
                this.archivos = [];
                if (response.data.length) {
                    response.data.forEach(ar => {
                        let tipo = JSON.parse(ar.type);
                        let formato = ar.filename.split('.').pop().toLowerCase();
                        if (formato === 'pdf') { formato = 'pdf'}
                        else if (formato === 'doc' || formato === 'txt' || formato === 'docx') { formato = 'doc'}
                        else { this.esImagen(formato) ? '' : formato = 'img'}

                        this.archivos.push({
                            id: ar.id,
                            nombre: ar.filename,
                            fecha: this.$moment(parseInt(ar.datetime) * 1000).format('YYYY-MM-DD HH:mm'),
                            subido_por: tipo[1],
                            tipo: tipo[0],
                            formato: formato,
                            url: ar.url
                        })
                    });
                }
                this.setEstadoServicio();
                this.$store.dispatch('setLoading', false);
            } else if (response.ws === 'userList') {
                if (this.edit_imagen_pasaporte) {
                    this.data = {id: this.usuario.id, img: this.usuario.personales.pasaporte_imagen, updater: this.usuario_loggeado.id};
                    this.ws = this.usuario.personales.pasaporte_imagen ? 'trlIdentityPhoto' : 'deleteTrlIdentityPhoto';
                } else if (this.edit_imagen_pasaporte_conyuge) {
                    this.refreshUser();
                }
                // this.edit_imagen_pasaporte_conyuge = false;
                // this.edit_imagen_pasaporte = false;
                let usuarios = response.data;
                this.$store.dispatch('setUsuarios', usuarios);
                if (this.deletedUser) {
                    this.$store.dispatch('setLoading', false);
                    this.$router.push({ name: 'Usuarios'});
                }
            } else if (response.ws === 'trlStatusUpdate') {
                // alert('Todos los servicios se han finalizado. Marcamos el TRL como Finalizado.');
                this.modal_simple_finalizado = false;
                this.refreshUser();
            } else if (response.ws === 'trlConsAccept' || response.ws === 'trlConsReject') {
                this.consAnswer = null;
                this.$store.dispatch('setStartedPoint', null);
                this.modal_simple_respuesta = true;
                this.data = this.usuario.enc_id;
                this.ws = 'getUserEnc';
            } else if (response.ws === 'taskRemove') {
                // this.updateProfile('servicios', this.servicio_a_actualizar);
            } else if (response.ws === 'trlIdentityPhoto' || response.ws === 'trlSpouseIdentityPhoto' || response.ws === 'deleteTrlIdentityPhoto' || response.ws === 'deleteTrlSpouseIdentityPhoto') {
                response.ws === 'trlIdentityPhoto' || response.ws === 'deleteTrlIdentityPhoto' ? this.edit_imagen_pasaporte = false : '';
                response.ws === 'trlSpouseIdentityPhoto' || response.ws === 'deleteTrlSpouseIdentityPhoto' ? this.edit_imagen_pasaporte_conyuge = false : '';
                this.refreshUser();
            }
            setTimeout(() => this.$store.dispatch('setLoading', false), 5000);
        }
	},
    created: function() {},
    mounted: function() {
        this.console = window.console;
        this.user_id = this.$route.query['userId'];
        this.consAnswer = this.$route.query['accepted'];
        this.respuesta = this.$route.query['accepted'];

        if (!localStorage.getItem('isLogged')) {
            this.$router.push('/');
        }
        this.ws_spinner = true;
        if (this.$route.params.usuario) {
            this.data = this.$route.params.usuario.id;
            this.ws = 'getUser';
        } else if (this.user_id) {
            this.data = this.user_id;
            this.ws = 'getUserEnc';
        } else {
            this.$router.push({ name: 'Usuarios'});
        }
        this.empresas.forEach(empresa => {
            let empresa_lista = {
                value: parseInt(empresa.id),
                text: empresa.nombre
            }
            this.lista_empresas.push(empresa_lista);
        });
        setTimeout(() => {this.post_render = true}, 1000);
        // if (this.user_id) this.$router.replace( {name: 'FichaTRL'} );
    },
    destroyed: function() {}
}
</script>
<style scoped lang="scss">
#ficha_trl {
    .subheader {
        .acciones {
            display: flex;
            margin-left: auto;
            .boton {
                margin: 0 .5em;
                background-color: white;
                box-shadow: var(--sombra_ext);
            }
        }
    }
    .tabs {
        ::v-deep .v-tabs-bar {
            // margin-bottom: 2em;
            border-bottom: 1px solid var(--gray03);
            background: none;
            .v-tab {
                text-transform: unset;
                text-align: center;
                &.v-tab--active {
                    .nav_link {
                        color: var(--purple03);
                        font-weight: 600;
                    }
                }
            }
            .v-tabs-slider-wrapper {color: var(--purple03); height: 3px;}
        }
        ::v-deep .v-tabs-items {
            background-color: transparent;
            max-height: calc(100vh - 15em);
            overflow-x: scroll;
            padding: 0 0.5em;
            margin: 0 -0.5em;
            max-width: calc(100% + 1em);
        }
    }
    .tab_suministros .area_titulo {text-transform: capitalize;}
    .form {
        .cancelar {
            min-height: 30px;
            padding: .7em;
            border: 1px solid var(--gray02);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 4px;
            color: var(--gray04);
            cursor: pointer;
            &:hover {
                background-color: #EF9A9A;
            }
        }
        .container.card { background-color: white; }
        ::v-deep .theme--light.v-input--is-disabled input {color: inherit;}
        .card {
            position: relative;
            .estado {
                position: absolute;
                top: 1em;
                right: 1em;
                width: 10em;
                text-align: center;
                font-weight: bold;
                color: white;
                padding: 0.25em;
                border-radius: 50px;
                text-transform: uppercase;
                &.proceso {background-color: #61C6FF; border: 1px solid #2D9CDB;}
                &.finalizado {background-color: #6FCF97; border: 1px solid #219653;}
                &.cancelado {background-color: #EF9A9A; border: 1px solid #EB5757;}
            }
            .editar {
                position: absolute;
                top: 1em;
                right: 1em;
                width: auto;
                .boton {
                    float: right;
                    margin: 0 .5em;
                    box-shadow: var(--sombra_ext);
                    background-color: white;
                    cursor: pointer;
                    .icono {
                        color: var(--gray04);
                        font-size: 1.5em;
                    }
                    &.check .icono{color: #27AE60;}
                    &.close .icono{color: #EB5757;}
                    &:hover {
                        &.check {
                            background-color: #27AE60;
                            .icono{color: white;}
                        }
                        &.close {
                            background-color: #EB5757;
                            .icono{color: white;}
                        }
                    }
                }
                &.editar_tarea {
                    position: initial;
                    background-color: var(--purple03);
                    width: 3em;
                    height: 3em;
                    .icono {color: white; margin-right: 0!important;}
                }
            }
            // .area_titulo
            .lista_tareas {
                .tarea {
                    &.empty_state {padding-top: 2em;}
                    &>.row > div {
                        display: flex;
                        align-items: center;
                        border-bottom: .5px solid var(--gray03);
                        .icono {margin-right: .2em;}
                    }
                }
            }
            .boton.crear_tarea {
                    margin-left: auto;
                    margin-bottom: .4em;
                    margin-top: .4em;
                    &.w-100{
                        width: 100%;
                    }
            }
            .boton.pasaporte.disabled {
                min-width: 100%;
                opacity: .25;
                cursor: not-allowed;
            }
            .nota {
                box-shadow: none;
                background-color: transparent;
                color: var(--purple03);
                text-transform: capitalize;
            }
            .custom_checkbox {
                .icono {color: var(--gray03)}
                &.active {
                    .icono {color: var(--purple03);}
                }
            }
            &.cancelado {
                // opacity: .5;
                // .area_titulo {color: #EF9A9A}
                box-shadow: none;
                opacity: .8;
            }
        }
    }
    .ficha_usuario {
        margin-top: 2em;
        box-shadow: none!important;
        .caja {
            background-color: var(--gray01);
            padding: .2em 1em;
            margin: .2em 0 1em;
        }
    }
    .tab_documentos {
        padding: 2em 0;
        overflow-x: hidden;
        .titulo_area {
            margin-bottom: 1em;
            display: flex;
            flex-wrap: wrap;
            .bold {font-size: 1.2em; margin-right: 1em; margin-bottom: .8em; white-space: nowrap;}
            .boton_upload_file {
                display: flex;
                align-items: center;
                flex: 1 1 300px;
                small {
                    margin-left: 1em;
                    display: inline-block;
                    font-size: .65rem;
                    // max-width: 60%;
                    line-height: 1.1em;
                    opacity: .8;
                    &.error {
                        background-color: #EB5757!important;
                        color: white;
                        padding: .5em;
                    }
                }
            }
        }
        .lista_documentos {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            grid-gap: 3em 2em;
            margin: 2em 0 3em;
            .archivo {
                place-self: center;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: space-between;
                // margin: 0 2em 3em 0;
                text-align: center;
                width: 10em;
                .image {
                    width: 56px;
                    height: 67px;
                    object-fit: cover;
                    border-radius: 5px;
                    filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.15));
                }
                .parrafo {
                    margin: 1em 0 .2em; 
                    font-size: .9em;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .small {color: var(--gray03)}
                .actions {
                    margin-top: .7em;
                    & > span {
                        margin: 1em;
                        cursor: pointer;
                        &:hover path {fill: var(--purple03);}
                    }
                } 
            }
        }
    }
}
@media (max-width: 900px) {
    #ficha_trl {
        .form {
            .card {
                .lista_tareas {
                    .tarea {
                        &>.row > div {
                            border-bottom: .5px solid var(--gray02);
                            &:first-child p{
                                font-weight: 800;
                            }
                            &:last-child {
                                border-bottom: 1.5px solid var(--gray04);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
