<template>
    <div class="modal">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <modal-simple v-if="modal_simple" :tipo="'borrar_empresa'" :empresa="nueva_empresa" @closeModalSimple="closeModalSimple"/>
        <div class="submodal succes" v-if="success">
            <p class="titulo bold">{{empresa_selected ? "Editar empresa" : "Nueva empresa"}}</p>
            <div class="boton fab check">
                <v-icon class="icono">mdi-check</v-icon>
            </div>
            <p class="parrafo">{{empresa_selected ? "La empresa se ha editado correctamente." : "La empresa se ha creado correctamente."}}</p>
        </div>
        <div class="submodal" v-else>
            <svg class="cerrar_modal" @click="cerrar_modal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <p class="titulo">{{empresa_selected ? "Editar empresa" : "Nueva empresa"}}</p>
            <v-form class="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12" class="titulo_caja">
                            <h3 class="area_titulo">Datos empresa</h3>
                            <div v-if="usuario_loggeado.tipo === 'Admin' && empresa_selected && !this.usuarios.find(usu => usu.personales.empresa == nueva_empresa.id)" class="boton fab borrar" @click="modal_simple = true">
                                <v-icon class="icono">mdi-delete</v-icon>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre*"
                                v-model="nueva_empresa.nombre"
                                v-on:change=" cambio_nombre = true"
                                :rules="cambio_nombre ? [rules.required, rules.duplicated] : [rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="CIF*"
                                v-model="nueva_empresa.cif"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-autocomplete
                                placeholder="Sector*"
                                v-model="nueva_empresa.sector_id"
                                :items="lista_sectores"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-file-input 
                                id="fileLogo"
                                label="Logo*"
                                v-model="nueva_empresa.logo"
                                accept="image/*" 
                                append-icon="mdi-paperclip"
                                prepend-icon=""
                                @click:append="openFile('fileLogo')"
                                :rules="[rules.required]"
                                v-on:change="cambio_logo = true"
                            >
                                <!-- <template v-slot:append>
                                    <img class="preview" v-if="nueva_empresa.logo" :src="logo_preview" />
                                </template> -->
                            </v-file-input>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Dirección de las oficinas"
                                v-model="nueva_empresa.direccion"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="facturacion">
                            <div v-for="(direccion, index) in nueva_empresa.direccion_facturacion" :key="index">
                                <v-text-field
                                    :label="'Dirección de facturación ' + (index + 1)"
                                    v-model="nueva_empresa.direccion_facturacion[index]"
                                    @keyup.enter="addDireccion"
                                ></v-text-field>
                                <svg v-if="index + 1 == nueva_empresa.direccion_facturacion.length" class="anadir" @click="addDireccion" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.9706 9.48528H9.48528M9.48528 9.48528V1M9.48528 9.48528L1 9.48528M9.48528 9.48528L9.48528 17.9706" stroke="#B4B4B7" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <svg v-else class="anadir" @click="removeLink(index)" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 13L7 7M7 7L13 1M7 7L1 1M7 7L1 13" stroke="#B4B4B7" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="empresa_selected && lista_rrhh[0]">
                        <v-col cols="12">
                            <v-chip v-for="(usuario, index) in lista_rrhh" :key="index" 
                                class="chip_rrhh"
                                @click="linkUsuariosFiltrados(usuario.personales.nombre + ' ' + usuario.personales.apellidos)"
                            >
                                {{usuario.personales.nombre + ' ' + usuario.personales.apellidos}}
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-btn class="boton cta" :disabled="!empresa_selected && !valid" :loading="loading" @click="crearEmpresa">{{empresa_selected ? 'Guardar' : 'Crear'}}</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
import ModalSimple from '@/components/ModalSimple.vue'

export default {
	name: 'ModalCrearEmpresa',
    components: {Service, ModalSimple},
    props: {
        empresa_selected: Object
    },
    data() {
        return {
            modal_simple: false,
            nueva_empresa: {
                nombre: "",
                cif: "",
                direccion: "",
                logo: null,
                sector: '',
                direccion_facturacion: [''],
                id: null,
                sector_id: null
            },
            lista_rrhh:[],
            logo_preview: null,
            lista_sectores: [],
            cambio_logo: false,
            success: false,
            valid: false,
            cambio_nombre: false,
            rules: {
                required: value => !!value || '',
                duplicated: value => {
                    let existe = this.empresas.find(emp => emp.nombre.toLowerCase() === value.toLowerCase());
                    return !existe || 'Ya existe una empresa con este nombre.'
                },
            },
            loading: false,
            loader: null,
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 5000)
            this.loader = null
        },
    },

	computed:  {
        ...mapState([
            'empresas', 'usuarios', 'token', 'sectores', 'usuario_loggeado'
        ]),
        nueva_empresa_logo() {return this.nueva_empresa.logo}
	},

    methods: {
        openFile(ref) {
            document.getElementById(ref).click()
        },
        cerrar_modal() {
            this.$emit("closeModalEmpresa", true);
        },
        crearEmpresa() {
            this.loader = 'loading';
            // this.$store.dispatch('setLoading', true);
            if (this.empresa_selected) {
                this.data = this.nueva_empresa;
                this.ws = 'companyUpdate';
            } else {
                this.data = this.nueva_empresa;
                this.ws = 'companyRegister';
            }
        },
        addDireccion() {
            var container = this.$el.querySelector(".form");
            const prev_height = container.scrollHeight;
            this.nueva_empresa.direccion_facturacion.push('');
            setTimeout(()=> {
                container.scrollTo({
                    top: container.scrollTop + (container.scrollHeight - prev_height),
                    behavior: 'smooth'
                }); 
            }, 200);
        },
        removeLink(index) {
            this.nueva_empresa.direccion_facturacion.splice(index, 1);
        },
        linkUsuariosFiltrados(nombre) {
            this.$router.push({ name: 'Usuarios', params: {'pre_filtro': {'palabra': nombre, 'tipo': ['RRHH']} }});
        },
        closeModalSimple(action) {
            this.modal_simple = false;
            if (action === 'delete_empresa') {
                this.data = this.nueva_empresa.id;
                this.ws_spinner = true;
                this.ws = 'deleteCompany';
                // this.$store.dispatch('setLoading', true);
            }
        },
        getFiles(url) {
            let that = this;
            async function createFile(){
                let response = await fetch(url,{mode: 'no-cors'});
                let data = await response.blob();
                var index = that.nueva_empresa.logo.lastIndexOf("/") + 1;
                var filename = that.nueva_empresa.logo.substr(index);
                // let metadata = {
                //     type: 'image/jpeg'
                // };
                that.nueva_empresa.logo = new File([data], filename);
            }
            createFile();
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'companyUpdate' || response.ws === 'deleteCompany' || response.ws === 'companyRegister') {
                if (response.data != 'Company name already in use') {
                    if (this.nueva_empresa.logo && (this.cambio_logo || response.ws === 'companyRegister')) {
                        this.data = {empresa_id: this.nueva_empresa.id || response.data, img: this.nueva_empresa.logo, nombre: this.nueva_empresa.logo.name};
                        this.ws = 'companyLogo';
                    } else {
                        this.ws= 'companyList';
                    }
                } else {
                    alert('Ya existe una empresa con este nombre');
                }
            } else if (response.ws === 'companyLogo') {
                this.ws_spinner = true;
                this.ws= 'companyList';
            } else if (response.ws === 'companyList') {
                // this.$store.dispatch('setLoading', false);
                let empresas = response.data;
                empresas.forEach(emp => {
                    emp.sector = this.sectores.find(sec => sec.id == emp.sector_id).nombre;
                });
                this.$store.dispatch('setEmpresas', empresas);
                this.empresa_selected ? this.success = 'empresa_creada' : '';
                setTimeout(() => this.$emit("closeModalEmpresa", true), 2000);
            } 
        }
	},
    created: function() {
        this.console = window.console;
    },
    mounted: function() {
        if (this.empresa_selected) {
            this.nueva_empresa = {...this.empresa_selected};
            this.lista_rrhh = this.usuarios.filter(usuario => usuario.tipo === 'RRHH' && usuario.personales.empresa == this.nueva_empresa.id);
            this.logo_preview = this.nueva_empresa.logo;
            this.nueva_empresa.sector_id = parseInt(this.nueva_empresa.sector_id);
            
            let direcciones_facturacion = [];
            this.nueva_empresa.direccion_facturacion.forEach(dire => dire ? direcciones_facturacion.push(dire) : '');
            this.nueva_empresa.direccion_facturacion = direcciones_facturacion;
            this.nueva_empresa.direccion_facturacion.push('');
            
            this.getFiles(this.nueva_empresa.logo);
        }
        this.sectores.forEach(sec => {
            this.lista_sectores.push({value: sec.id, text: sec.nombre});
        })
    }
}
</script>
<style scoped lang="scss">
.v-application #app .submodal {
    // .facturacion {margin-bottom: -12px;}
    .chip_rrhh {
        margin-right: 1em;
        margin-bottom: .5em;
        background-color: var(--purple01);
    }
    .preview {
        width: 4em;
        height: 4em;
        object-fit: cover;
        border-radius: 5px;
    }
    .facturacion {
        margin-top: -1em;
        &>div {height: 4.5em;}
        .anadir {
            position: relative;
            left: 96%;
            bottom: 4em;
            cursor: pointer;
        }
    }
    .titulo_caja {
        .area_titulo {
            float: left;
        }
        .borrar {
            float: right;
            background-color: white;
            box-shadow: var(--sombra_ext);
        }
    }
    &.succes {
        .check {
            width: 6em;
            height: 6em;
            margin: 4em 0;
            margin-left: calc(50% - 3em);
            text-align: center;
            background-color: #27AE60;
            .icono{color: white;}
        }
        .titulo {margin: 1em 0;}
        .parrafo {text-align: center;}
    } 
}
</style>
