<template>
	<div id="empresas">
       <panel-filtros-empresas @filtrarEmpresas="filtrarEmpresas" :num_empresas_filtradas="empresas_filtradas.length"/>
        <div class="view-container empresas">
            <div class="subheader">
                <h3 class="titulo">Empresas</h3>
                <div class="orden_container">
                    <p class="parrafo">Ordenar</p>
                    <v-select
                        :items="['Recientes', 'Nombre a-z']"
                        class="selector"
                        v-model="orden_recientes"
                    ></v-select>
                </div>
                <p class="boton nueva-empresa" v-if="usuario_loggeado.tipo === 'Admin'" @click="openModalEmpresa({})">
                    Crear empresa
                </p>
            </div>
			
			<modal-crear-empresa v-if="modal_empresa" @closeModalEmpresa="closeModalEmpresa" :empresa_selected="empresa_selected"/>
			<ul class="lista-empresas">
				<li class="card empresa" v-for="(empresa, subindex) in empresas_filtradas" :key="subindex"
                    @click="openModalEmpresa(empresa)"
                >
                    <div class="foto">
                        <img v-if="empresa.logo" class="logo" :src="empresa.logo || 'images/empresa_placeholder.png'"/>
					</div>
					<p class="parrafo bold nombre">{{empresa.nombre}}</p>
					<p class="parrafo sector">{{empresa.sector}}</p>
                    <div class="usuarios">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <p v-on="on" class="parrafo" @click.stop="linkUsuariosFiltrados(empresa, 'TRL')">
                                    <img class="icono" src="images/icon_trl_activo.svg">
                                    {{getNumTrlsActivos(empresa.id)}}
                                </p>
                            </template>
                            <p>TRLs activos</p>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <p v-on="on" class="parrafo" @click.stop="linkUsuariosFiltrados(empresa, 'TRL', true)">
                                    <img class="icono" src="images/icon_trl_finalizado.svg">
                                    {{getNumTrlsInactivos(empresa.id)}}
                                </p>
                            </template>
                            <p>TRLs finalizados</p>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <p v-on="on" class="parrafo" @click.stop="linkUsuariosFiltrados(empresa, 'RRHH')">
                                    <img class="icono" src="images/icon_rrhh.svg">
                                    {{getNumRrhh(empresa.id)}}
                                </p>
                            </template>
                            <p>RRHH</p>
                        </v-tooltip>
                    </div>
				</li>
			</ul>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import PanelFiltrosEmpresas from '@/components/PanelFiltrosEmpresas.vue';
import ModalCrearEmpresa from '@/components/ModalCrearEmpresa.vue'

export default {
	name: 'Empresas',
	components: {
        PanelFiltrosEmpresas,
        ModalCrearEmpresa
	},
	props: [],
    computed:  {
        ...mapState([
            'empresas', 'usuarios', 'usuario_loggeado'
        ]),
	},

    data() {
        return {
			modal_empresa: false,
            empresa_selected: null,
            empresas_filtradas: [],
            orden_recientes: 'Recientes',
		}
    },

    watch: {
        empresas() {
            this.empresas_filtradas = [...this.empresas];
        },
        orden_recientes() {
            this.ordenarUsuarios();
        },
    },

    methods: {
        openModalEmpresa(empresa) {
            empresa.nombre ? this.empresa_selected = empresa : this.empresa_selected = null;
			this.modal_empresa = true;
		},
		closeModalEmpresa() {
			this.modal_empresa = false;
            this.empresa_selected = null;
		},
        filtrarEmpresas(filtros) {
            let filtrado = (empresa) => {
                let sector = filtros.sectores.includes(empresa.sector);
                let nombre = empresa.nombre.toLowerCase().includes(filtros.filtro.toLowerCase()) || filtros.filtro === '';
                return sector && nombre;
            }
            this.empresas_filtradas = this.empresas.filter(filtrado);
            this.ordenarUsuarios();
        },
        getNumTrlsActivos(id) {
            let num = this.usuarios.filter(usuario => usuario.tipo === 'TRL' && usuario.personales.empresa == id && !usuario.estado != 1);
            return num.length;
        },
        getNumTrlsInactivos(id) {
            let num = this.usuarios.filter(usuario => usuario.tipo === 'TRL' && usuario.personales.empresa == id && usuario.estado == 1);
            return num.length;
        },
        getNumRrhh(id) {
            let num = this.usuarios.filter(usuario => usuario.tipo === 'RRHH' && usuario.personales.empresa == id);
            return num.length;
        },
        linkUsuariosFiltrados(empresa, tipo, finalizado) {
            this.$router.push({ name: 'Usuarios', params: {'pre_filtro': {'palabra': finalizado ? empresa.nombre : '' ,'tipo': [tipo], 'finalizado': finalizado}, 'empresa': empresa.id }});
        },
        ordenarUsuarios() {
            if (this.orden_recientes === 'Recientes') {
                this.empresas_filtradas.sort((a, b) => b.id - a.id);
            } else if (this.orden_recientes === 'Nombre a-z') {
                this.empresas_filtradas.sort((a, b) => a.nombre.localeCompare(b.nombre));
            } 
        },
	},
    created: function() {
        if (!localStorage.getItem('isLogged')) {
            this.$router.push('/');
        }
        this.empresas_filtradas = [...this.empresas];
    },
    mounted: function() {
        // this.$store.dispatch('setLoading', false);
    }
}
</script>
<style scoped lang="scss">
#empresas {
    .subheader {
        display: flex;
        justify-content: space-between;
        .orden_container {
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: 2%;
            .selector {
                margin: 0 1em;
            }
        }
    }
	.lista-empresas {
		display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3%;
        padding: 1em;
        margin: -1em -1em 2em;
        max-height: calc(100vh - 13em);
        overflow-x: scroll;

		.card.empresa {
            position: relative;
            min-height: 20em;
            height: 95%;
            display: flex;
            flex-flow: column;
            // justify-content: center;
            text-align: center;
            cursor: pointer;
            .foto {
                text-align: center;
                width: 75%;
                max-width: 150px;
                height: 50%;
                margin: 1.8em auto 0;
                display: flex;
                .logo {
                    height: 5em;
                    width: 95%;
                    object-fit: contain;
                    margin: auto;
                }
            }
            .parrafo {
                margin: 5% auto;
                &.nombre {
                    height: 20%;
                }
                &.sector {
                    border: 0.5px solid var(--gray03_5);
                    color: var(--gray03_5);
                    border-radius: 2em;
                    min-width: 65%;
                    max-width: 80%;
                    padding: .5em;
                    font-size: .8em;
                    text-transform: uppercase;
                }
            }
            .usuarios {
                display: flex;
                .parrafo {
                    display: flex;
                    align-items: center;
                    .icono {margin-right: .35em; font-size: 25px}
                }
            }
		}
	}
}
@media (max-width: 900px) {
    #empresas {
        .subheader {
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
            .orden_container {
                width: 100%;
                margin: 0;
                .parrafo {
                    margin-left: 0;
                }
            }
            .nueva-empresa {
                display: none;
            }
        }
        .lista-empresas {
            grid-template-columns: repeat(2, 1fr);
        }
    }
 }

 @media (max-width: 760px) {
    #empresas {
        .subheader {
            .orden {
                width: 100%;
                margin: 0;
            }
        }
        .lista-empresas {
            grid-template-columns: repeat(1, 1fr);
        }
    }
 }
</style>

