<template>
	<div class="">
        <service 
            :ws='ws'
            :data="data" 
            @success='onSuccess'  
            @error='onError'
        />
        <panel-filtros-viviendas v-if="usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo ==='Consejera'  || usuario_loggeado.tipo ==='RRHH'"  
            @filtrarViviendas="filtrarViviendas" 
            :usuario="usuario" 
            :prefiltros="prefiltros"
            :num_viviendas_filtradas="viviendas_filtradas.length"
        />
        <panel-consejera v-else-if="usuario_loggeado.id" @openPreferencias="openPreferencias" @openServicios="openServicios"/>
        <modal-nuevotrl v-if="modal_nuevotrl && usuario_loggeado.tipo === 'TRL'" @closeModalNuevotrl="closeModalNuevotrl" :preferencias="trl_preferencias" :trl_servicios="trl_servicios"/>
        <modal-crear-vivienda v-if="modal_vivienda" @closeModalVivienda="closeModalVivienda" />
        <modal-enviar-viviendas v-if="modal_enviar_viviendas" @closeModalEnviarViviendas="closeModalEnviarViviendas" :viviendas_seleccionadas="viviendas_seleccionadas" :usuario="usuario"/>
        <div id="viviendas" :class="['view-container', usuario_loggeado.tipo]" :style="$vuetify.breakpoint.xs && usuario ? 'padding-top: 11em' : ''">
            <div v-if="usuario" class="card ficha_usuario">
                <div class="izda">
                    <div class="foto">
                        <img class="avatar" :src="usuario.personales.foto || 'images/avatar_placeholder.jpg'"/>
                    </div>
                    <p class="parrafo bold nombre">{{usuario.personales.nombre}}<br>{{usuario.personales.apellidos}}</p>
                    <img v-if="usuario.personales.empresa && getEmpresa().logo" class="logo" :src="getEmpresa().logo" />
                </div>
                <div class="dcha">
                    <p class="titulo_caja parrafo bold">PREFERENCIAS</p>
                    <v-row class="caja">
                        <v-col cols="6" sm="3">
                            <p class="parrafo"><b>Fecha trasl.:</b> {{usuario.preferencias.traslado.fecha_traslado}}</p>
                            <p class="parrafo"><b>Duración:</b> {{usuario.preferencias.traslado.tiempo_alquiler}}</p>
                            <p class="parrafo"><b>Tamaño:</b> {{usuario.preferencias.vivienda.metros}}m2</p>
                            <p class="parrafo"><b>Nº hab.:</b> {{usuario.preferencias.vivienda.habitaciones}}</p>
                            <p class="parrafo"><b>Presupuesto:</b> {{usuario.preferencias.traslado.presupuesto ? usuario.preferencias.traslado.presupuesto + '€' : ''}}</p>
                        </v-col>
                        <v-col cols="6" sm="3">
                            <p class="parrafo"><b>Tipo:</b> {{toList(usuario.preferencias.vivienda.tipo)}}</p>
                            <p class="parrafo"><b>Estilo:</b> {{toList(usuario.preferencias.vivienda.estilo)}}</p>
                            <p class="parrafo"><b>Estado:</b> {{toList(usuario.preferencias.vivienda.estado)}}</p>
                            <p class="parrafo"><b>Mascota:</b> {{usuario.preferencias.vivienda.mascota ? 'Sí' : 'No'}}</p>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <p class="parrafo"><b>Características:</b> {{toList(usuario.preferencias.vivienda.caracteristicas)}}</p>
                            <p class="parrafo"><b>Otras pref.:</b> {{toList(usuario.preferencias.vivienda.otras_preferencias)}}</p>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="subheader">
                <h3 class="titulo">{{ $t(localeRoute + 'viviendas')}} <span v-if="usuario_loggeado.tipo === 'TRL'">{{ $t(localeRoute + 'seleccionadassegun')}}</span></h3>
                <div class="orden">
                    <p class="parrafo">{{$t(localeRoute + 'ordenar')}}</p>
                    <v-select
                        :items="[$t(localeRoute + 'fechadeincorporacion'), $t(localeRoute + 'ultimamodificacion') ,$t(localeRoute + 'precio')]"
                        class="selector"
                        v-model="orden_viviendas"
                    ></v-select>
                </div>
                <p v-if="!usuario && usuario_loggeado.tipo != 'TRL'" class="boton nueva-vivienda" @click="openModalCrearVivienda">Nueva vivienda</p>
                <p v-else-if="usuario_loggeado.tipo != 'TRL'" class="boton" @click="openModalEnviarViviendas">Enviar al TRL</p>
            </div>
			<ul class="lista-viviendas" v-if="viviendas_filtradas.length">
				<li  
                    v-for="(vivienda, subindex) in viviendas_filtradas" :key="subindex" 
                    class="precard"
                    :class="{enviada: viviendas_preseleccionadas ? viviendas_preseleccionadas.find(viv => viv == vivienda.id) : false}"
                >
                    <v-checkbox @click.stop="vivienda.seleccionada = !vivienda.seleccionada" v-if="usuario" class="checkbox" v-model="vivienda.seleccionada" :off-icon="'mdi-checkbox-blank'"></v-checkbox>
                    <router-link :to="routerFicha(vivienda, usuario)" class="card vivienda">
                        <div class="foto" v-if="vivienda.foto_principal">
                            <img class="foto_principal" :src="vivienda.foto_principal"/>
                        </div>
                        <div class="dcha">
                            <span class="tipo" :class="vivienda.tipo">{{$t(localeRoute + (vivienda.tipo.toLowerCase()))}}</span>
                            <p class="small_tit">{{vivienda.barrio}}</p>
                            <p class="parrafo bold">{{vivienda.direccion}}</p>
                            <p class="precio parrafo bold"><span class="titulo bold">{{vivienda.precio}}</span> {{vivienda.tipo_contrato != 'Compra' ? $t(localeRoute + 'emes') : '€'}}</p>
                            <p class="small">{{caracteristicasVivienda(vivienda)}}</p>
                            <p class="small ultima" v-if="vivienda.modificado != '1970-01-01 01:00'">{{$t(localeRoute + 'ultmodificacion')}} {{vivienda.modificado}}</p>
                        </div>
                        <v-tooltip top v-if="vivienda.preseleccionada" >
                            <template v-slot:activator="{ on }">
                                <svg class="vivienda_enviada" 
                                    v-on="on"
                                    width="17" height="15" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M14.0473 6.58366L11.714 4.25033V6.00033H6.46396V7.16699H11.714V8.91699L14.0473 6.58366ZM10.5473 8.33366V11.2503H1.54505V6.00033H0.37838L5.97523 0.166992L10.5473 4.83366H8.7973L5.97523 1.91699L2.71171 5.41699V10.0837H9.38063V8.33366H10.5473Z" fill="#828282"/>
                                </svg>
                            </template>
                            <span>Ya ha sido enviada</span>
                        </v-tooltip>
                    </router-link>
				</li>
			</ul>
            <ul class="lista-viviendas" v-else>
                <p class="parrafo" v-if="usuario_loggeado.tipo != 'TRL'">{{$t(localeRoute + 'notenemosaunviviendas')}}</p>
                <p class="parrafo" v-else-if="usuario_loggeado.tipo === 'TRL'">{{$t(localeRoute + 'suconsejeraaun')}}</p>
            </ul>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import PanelFiltrosViviendas from '@/components/PanelFiltrosViviendas.vue'
import PanelConsejera from '@/components/PanelConsejera.vue'
import ModalCrearVivienda from '@/components/ModalCrearVivienda.vue'
import ModalEnviarViviendas from '@/components/ModalEnviarViviendas.vue'
import ModalNuevotrl from '@/components/ModalNuevotrl.vue'
import Service from '@/components/Service.vue'

export default {
	name: 'Viviendas',
	components: {
        PanelFiltrosViviendas,
        PanelConsejera,
        ModalCrearVivienda,
        ModalEnviarViviendas,
        ModalNuevotrl,
        Service
	},
	props: [],

    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            modal_vivienda: false,
            viviendas_filtradas: [],
            usuario: null,
            modal_enviar_viviendas: false,
            // viviendas_seleccionadas: [],
            orden_viviendas: '',
            trl_preferencias: false,
            trl_servicios: false,
            modal_nuevotrl: false,
            prefiltros: null,
            ws: null,
            data: null
        }
    },

    watch: {
        viviendas() {
            this.doInitialThings();
        },
        orden_viviendas() {
            this.ordenar_viviendas();
        },
        viviendas_preseleccionadas() {
            this.viviendas_filtradas.forEach(viv => viv.preseleccionada = false);
            this.viviendas_preseleccionadas.forEach(sel_id => {
                this.viviendas_filtradas.find(viv => viv.id == sel_id) ? this.viviendas_filtradas.find(viv => viv.id == sel_id).preseleccionada = true : '';
            })
        },
        usuario_loggeado() {
            this.doInitialThings();
        }
    },

	computed:  {
        ...mapState([
            'viviendas', 'usuario_loggeado', 'empresas'
        ]),
        viviendas_preseleccionadas() {
            return this.usuario && this.usuario.servicio_vivienda.viviendas_preseleccionadas ? this.usuario.servicio_vivienda.viviendas_preseleccionadas : [];
        },
        viviendas_seleccionadas() {
            return this.viviendas_filtradas.filter(vivienda => vivienda.seleccionada);
        }
	},

    methods: {
        openModalCrearVivienda() {
            this.modal_vivienda = true;
        },
        openModalEnviarViviendas() {
            // this.viviendas_seleccionadas = this.viviendas_filtradas.filter(vivienda => vivienda.seleccionada);
            this.modal_enviar_viviendas = true;
        },
        routerFicha(vivienda) {
            if (!this.usuario) {
                return { 
                    name: 'FichaVivienda', 
                    params: { vivienda_activa: vivienda, prefiltros: this.prefiltros }, 
                    query: { vivId: vivienda.id },
                };
            } else {
                return { 
                    name: 'FichaVivienda', 
                    params: {
                        vivienda_activa: vivienda, 
                        usuario: this.usuario,
                        prefiltros: this.prefiltros,
                        viviendas_seleccionadas: this.viviendas_seleccionadas
                    }, 
                    query: { vivId: vivienda.id } 
                };
            }
        },
        caracteristicasVivienda(vivienda) {
            let caracteristicas = vivienda.habitaciones ? `${vivienda.habitaciones} ${this.$t(this.localeRoute + 'hab')} ` : "";
            caracteristicas += vivienda.metros ? `${vivienda.metros}m2 ` : "";
            caracteristicas += vivienda.piso ? `${vivienda.piso} ` : "";
            // caracteristicas += vivienda.orientacion ? `${vivienda.orientacion} ` : "";
            caracteristicas += vivienda.ascensor ? this.$t(this.localeRoute + 'ascensor') : "";
            caracteristicas += vivienda.piscina ? this.$t(this.localeRoute + 'piscina') : "";
            caracteristicas += vivienda.jardin ? this.$t(this.localeRoute + 'jardin') : "";
            caracteristicas += vivienda.terraza ? this.$t(this.localeRoute + 'terraza') : "";

            return caracteristicas;
        },
        closeModalVivienda() {
            this.modal_vivienda = false;
        },
        closeModalEnviarViviendas(envio) {
            this.modal_enviar_viviendas = false;
            if (envio) {
                // this.viviendas_seleccionadas = [];
                this.viviendas_filtradas.forEach(viv => viv.seleccionada = false);
            }
        },
        filtrarViviendas(filtros, filtros_preform) {
            this.prefiltros = filtros_preform;
            let filtrado = (vivienda) => {
                let disponibilidad = filtros.disponibilidad == vivienda.disponibilidad;
                let estado = filtros.estado.includes(vivienda.estado) || filtros.estado === '';
                let equipamiento = filtros.equipamiento.includes(vivienda.equipamiento) || filtros.equipamiento === '';
                let barrio = filtros.barrio.includes(vivienda.barrio.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || filtros.barrio === '';
                let habitaciones = vivienda.habitaciones >= filtros.habitaciones;
                let baños = vivienda.baños >= filtros.baños;
                let precio = (filtros.precio_min <= vivienda.precio) && (vivienda.precio <= filtros.precio_max);
                let metros = (filtros.metros_min <= vivienda.metros) && (vivienda.metros <= filtros.metros_max);

				let estilo = false;
				if (filtros.estilo.length) {
					filtros.estilo.forEach(est => {
						if (est === vivienda.estilo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) estilo = true;
					});
				} else {estilo = true;}
                // let estilo = filtros.estilo.includes(vivienda.estilo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || filtros.estilo === '' || vivienda.estilo === 'undefined';
                // let tipo = filtros.tipo.includes(vivienda.tipo.toLowerCase()) || filtros.tipo === '';
                let tipo = false;
				if (filtros.tipo.length) {
					filtros.tipo.forEach(tip => {
						if (tip === vivienda.tipo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) tipo = true;
					});
				} else {tipo = true;}

                let checks = true;
                filtros.checks.forEach(fil => {
                    if (!vivienda[fil]) {checks = false;}
                });
                let filtro_norm = filtros.filtro.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                let calle = vivienda.direccion.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filtro_norm) || vivienda.barrio.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filtro_norm) || filtros.filtro === '';

                return disponibilidad && calle && estado && equipamiento && barrio && precio && metros && calle && habitaciones && baños && checks && tipo && estilo;
            }
            this.viviendas_filtradas = this.viviendas.filter(filtrado);
        },
        getEmpresa() {
            let id = this.usuario.personales.empresa;
            let empresa = this.empresas.find( empresa => empresa.id == id );
            return empresa;
        },
        toList(obj) {
            let list = "";
            for (let prop in obj) {
                if (obj[prop]) {list += prop + ', '}
            }
            return list;
        },
        ordenar_viviendas() {
            if (this.orden_viviendas === this.$t(this.localeRoute + 'precio') ) {
                this.viviendas_filtradas.sort((a, b) => parseInt(a.precio) - parseInt(b.precio));
            } else if (this.orden_viviendas === this.$t(this.localeRoute + 'ultimamodificacion')) {
                this.viviendas_filtradas.sort((a, b) => this.$moment(b.modificado).unix() - this.$moment(a.modificado).unix());
            } else {
                this.viviendas_filtradas.sort((a, b) => b.id - a.id);
            }
        },
        closeModalNuevotrl() {
            this.modal_nuevotrl = false;
            this.trl_preferencias = false;
        },
        openPreferencias() {
            this.trl_preferencias = true;
            this.modal_nuevotrl = true;
        },
        openServicios() {
            this.trl_servicios = true;
            this.modal_nuevotrl = true;
        },
        doInitialThings() {
            // this.viviendas_seleccionadas = [];
            this.viviendas_filtradas = [];
            if (this.usuario_loggeado.tipo === 'Admin' || this.usuario_loggeado.tipo === 'Consejera' || this.usuario_loggeado.tipo === 'RRHH') {
                this.viviendas_filtradas = [...this.viviendas].filter(viv => viv.disponibilidad);
                if (this.$route.params) {
                    this.usuario = this.$route.params.usuario;
                }
            } else if (this.usuario_loggeado.tipo === 'TRL') {
                if (this.usuario_loggeado.servicio_vivienda.viviendas_preseleccionadas) {
                    this.usuario_loggeado.servicio_vivienda.viviendas_preseleccionadas.forEach(id => {
                        let vivienda = this.viviendas.find(viv => viv.id == id);
                        if (vivienda) this.viviendas_filtradas.push(vivienda);
                    });
                }
            }
        }
	},
    created: function() {
        // this.$store.dispatch('setLoading', true);
        this.orden_viviendas = this.$t(this.localeRoute + 'fechadeincorporacion');
        if (!localStorage.getItem('isLogged')) {
            this.$router.push('/');
        }
        if (this.viviendas.length) this.doInitialThings();
        if (this.$route.params.prefiltros) this.prefiltros = this.$route.params.prefiltros;
            
    },
    mounted: function() {
        // this.$store.dispatch('setLoading', false);
    }
}
</script>
<style scoped lang="scss">
#viviendas {
    .subheader {
        display: flex;
        justify-content: space-between;
        .orden {
            display: flex;
            align-items: center;
            width: 35%;
            margin-left: auto;
            margin-right: 2em;
            .parrafo {margin: 0 1em;}
        }
    }
    .card.ficha_usuario {
        display: flex;
        padding-left: 0;
        .izda {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            width: 20%;
            text-align: center;
            border-right: .5px solid var(--gray03);
            margin-right: 2em;
            .nombre {line-height: 1.2em;}
            .foto {margin: 1em 0;}
            .logo {
                width: 5em;
                height: 5em;
                object-fit: contain;
                margin-top: auto;
            }
            .avatar {
                object-fit: cover;
                width: 8em;
                height: 8em;
            }
        }
        .dcha {
            width: 80%;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            .titulo_caja {margin-bottom: .5em;}
            .caja {
                width: 100%;
                border-radius: 3px;
                padding: 1em;
                margin-left: 0;
                background-color: var(--gray01);
                .col {
                    display: flex;
                    flex-flow: column;
                    justify-content: space-around;
                    padding: 0;
                }
                .parrafo {margin: .8em 0;}
            }
        }
    }
	.lista-viviendas {
		display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: min-content;
        grid-gap: 2em 3%;
        // padding-left: 0;
        // margin-bottom: 2em;
        padding: 1em;
        margin: -1em -1em 0;
        height: calc(100vh - 15em);
        overflow-x: scroll;
        .precard {
            position: relative;
            z-index: 1;
            .checkbox {
                position: absolute;
                top: 0;
                left: 0;
                padding: .5em 0 0 .5em;
                z-index: 100;
                ::v-deep .v-icon {
                    color: white;
                    filter: drop-shadow(0px 0px 2px rgba(0,0,0,.2)) drop-shadow(0px 0px 2px white);
                } 
            }
            .card.vivienda {
                position: relative;
                height: 12em;
                display: flex;
                padding: 0;
                overflow: hidden;
                margin: 0;
                .foto {
                    text-align: center;
                    width: 37%;
                    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);
                    .foto_principal {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .dcha {
                    width: 53%;
                    padding: 1em;
                    padding-left: 1.5em;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    .small_tit {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 1em;
                        margin-top: .1em;
                    }
                    .precio {
                        flex-grow: .1;
                        line-height: unset;
                    }
                    .ultima {color: var(--gray03)}
                    .tipo {
                        position: absolute;
                        width: 7em;
                        top: 1em;
                        right: 1em;
                        padding: .5em;
                        font-size: .8em;
                        font-weight: bold;
                        text-transform: uppercase;
                        color: white;
                        text-align: center;
                        border-radius: 50px;
                        &.Piso {
                            background-color: #61C6FF;
                            border: 0.5 solid #2D9CDB;
                        }
                        &.Casa {
                            background-color: #A3E07E;
                            border: 0.5 solid #27AE60;
                        }
                        &.Atico {
                            background-color: #F9D067;
                            border: 0.5 solid #F2994A;
                        }
                    }
                }
                .vivienda_enviada {
                    position: absolute;
                    bottom: 1em;
                    right: 1em;
                }
            }
        }
	}
}
 @media (max-width: 900px) {
    .v-application #app .view .view-container.TRL {
        padding-top: 2em;
    }
     #viviendas {
         .subheader {
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
            .orden {
                width: 100%;
                margin: 0;
                .parrafo {
                    margin-left: 0;
                }
            }
        }
        .card.ficha_usuario {
            flex-direction: column;
            padding: 2rem 0 0;
            .izda {
                width: 100%;
                margin-right: 0;
                border-right: none;
                .foto {display: none;}
                .nombre {
                    width: 100%;
                    margin-top: 1em;
                }
            }
            .dcha {
                width: 100%;
                .titulo_caja {
                    margin-left: 1em;
                }
            }
        }
        .lista-viviendas {
            .precard {
                .card.vivienda {
                    .dcha {
                        width: 70%;
                        padding-top: 35px;
                    }
                    .foto {
                        width: 30%;
                        height: 13em;
                    }
                }
            }
        }
     }
 }
 @media (max-width: 760px) {
    #viviendas {
        .lista-viviendas {
            grid-template-columns: repeat(1, 1fr);
        }
    }
 }
</style>

