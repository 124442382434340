import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import Home from './views/Home.vue'
import Usuarios from './views/Usuarios.vue'
import FichaTRL from './views/FichaTRL.vue'
import FichaVivienda from './views/FichaVivienda.vue'
import Viviendas from './views/Viviendas.vue'
import Empresas from './views/Empresas.vue'
import Servicios from './views/Servicios.vue'
import Documentacion from './views/Documentacion.vue'
import PaginaBasica from './views/PaginaBasica.vue'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: { requiresAuth: true }
        },
        {
            path: '/usuarios',
            name: 'Usuarios',
            component: Usuarios,
            meta: { requiresAuth: true }
        },
        {
            path: '/fichaTRL',
            name: 'FichaTRL',
            component: FichaTRL,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/fichaVivienda',
            name: 'FichaVivienda',
            props: true,
            component: FichaVivienda
        },
        {
            path: '/viviendas',
            name: 'Viviendas',
            component: Viviendas,
            meta: { requiresAuth: true }
        },
        {
            path: '/empresas',
            name: 'Empresas',
            component: Empresas,
            meta: { requiresAuth: true }
        },
        {
            path: '/servicios',
            name: 'Servicios',
            component: Servicios,
            meta: { requiresAuth: true }
        },
        {
            path: '/documentacion',
            name: 'Documentacion',
            component: Documentacion,
            meta: { requiresAuth: true }
        },
        {
            path: '/paginaBasica/:pagina',
            name: 'PaginaBasica',
            props: true,
            component: PaginaBasica,
            meta: { requiresAuth: true }
        },
        {
            path: '/politicaPrivacidad',
            name: 'PoliticaPrivacidad',
            props: true,
            component: PaginaBasica
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (to.name === 'FichaVivienda' || (localStorage.getItem('isLogged') && localStorage.getItem('toquen') && localStorage.getItem('usuario_logged'))) {
            next();
        } else {
            console.log('no loggeado');
            localStorage.removeItem('isLogged');
            localStorage.removeItem('usuario_logged');
            localStorage.removeItem('toquen');
            localStorage.removeItem('ult_con');
            localStorage.setItem('setStartedPoint', to.fullPath);
            next('/');
        }
    } else {
        next() // make sure to always call next()!
    }
});


export default router;
