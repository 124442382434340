<template>
    <div class="panel_izda filtros">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <v-text-field
            class="searcher"
            v-model="filtro"
            :type="'text'"
            :placeholder="$t(localeRoute + 'buscar')"
            :append-icon="'mdi-magnify'"
            clearable
            @click:clear="filtro = ''"
        ></v-text-field>
        <div class="usuarios-list">
            <div v-if="usuario_loggeado.tipo != 'Legal'">
                <p class="nav_link">{{ $t(localeRoute + 'tipo') }}</p>
                <v-container fluid class="tipo checkbox_container" >
                    <v-checkbox v-model="todos" :label="$t(localeRoute + 'todos')" class="checkbox" @click.native="todosClick"></v-checkbox>
                    <v-checkbox v-model="tipo.TRL" :label="$t(localeRoute + 'trls')" class="checkbox"></v-checkbox>
                    <v-checkbox v-model="tipo.RRHH" :label="$t(localeRoute + 'rrhh')" class="checkbox"></v-checkbox>
                    <v-checkbox v-if="usuario_loggeado.tipo === 'Admin'" v-model="tipo.Consejera" :label="$t(localeRoute + 'consejeras')" class="checkbox"></v-checkbox>
                    <v-checkbox v-if="usuario_loggeado.tipo === 'Admin'" v-model="tipo.Admin" :label="$t(localeRoute + 'admin')" class="checkbox"></v-checkbox>
                    <v-checkbox v-if="usuario_loggeado.tipo === 'Admin'" v-model="tipo.Legal" label="Legal" class="checkbox"></v-checkbox>
                </v-container>
            </div>
            <p class="nav_link">{{ $t(localeRoute + 'estado') }}</p>
            <v-container fluid class="estado">
                <v-radio-group v-model="estado" row>
                    <v-radio :label="$t(localeRoute + 'activos')" value="activos"></v-radio>
                    <v-radio :label="$t(localeRoute + 'finalizados')" value="finalizados"></v-radio>
                </v-radio-group>
            </v-container>
        </div>
        <v-tooltip bottom v-if="usingChromeIos" v-model="showTooltip">
            <template v-slot:activator="{ on }">
                <v-btn class="boton mt-2 mb-4" color="primary" block v-on="on" @click="showTooltip = !showTooltip">Descargar usuarios</v-btn>
            </template>
            <p>Función solo disponible en el navegador Safari</p>
        </v-tooltip>
        <v-btn v-else-if="usuario_loggeado.tipo === 'Admin'" class="boton mt-2 mb-4" color="primary" block @click="downloadUsers">Descargar usuarios</v-btn>
        <vue-excel-xlsx
            style="display: none;"
            ref="downloadUserBtn"
            class="boton"
            :data="excelData"
            :columns="excelColumns"
            :filename="'Usuarios'"
            :sheetname="'Hoja1'">
            <span>Descargar usuarios</span>
        </vue-excel-xlsx>
        <v-expansion-panels class="usuarios-expansion-panel" v-model="panelPersonas">
            <v-expansion-panel>
                <v-expansion-panel-header>Filtrar usuarios ({{num_usuarios_filtrados}})</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field
                        v-model="filtro"
                        :type="'text'"
                        :placeholder="$t(localeRoute + 'buscar')"
                        :append-icon="'mdi-magnify'"
                        clearable
                        @click:clear="filtro = ''"
                    ></v-text-field>
                    <p class="nav_link">{{ $t(localeRoute + 'tipo') }}</p>
                    <v-container fluid class="tipo checkbox_container" >
                        <v-checkbox v-model="todos" :label="$t(localeRoute + 'todos')" class="checkbox" @click.native="todosClick"></v-checkbox>
                        <v-checkbox v-model="tipo.TRL" :label="$t(localeRoute + 'trls')" class="checkbox"></v-checkbox>
                        <v-checkbox v-model="tipo.RRHH" :label="$t(localeRoute + 'rrhh')" class="checkbox"></v-checkbox>
                        <v-checkbox v-if="usuario_loggeado.tipo === 'Admin'" v-model="tipo.Consejera" :label="$t(localeRoute + 'consejeras')" class="checkbox"></v-checkbox>
                        <v-checkbox v-if="usuario_loggeado.tipo === 'Admin'" v-model="tipo.Admin" :label="$t(localeRoute + 'admin')" class="checkbox"></v-checkbox>
                    </v-container>
                    <p class="nav_link">{{ $t(localeRoute + 'estado') }}</p>
                    <v-container fluid class="estado">
                        <v-radio-group v-model="estado" row>
                            <v-radio :label="$t(localeRoute + 'activos')" value="activos"></v-radio>
                            <v-radio :label="$t(localeRoute + 'finalizados')" value="finalizados"></v-radio>
                        </v-radio-group>
                    </v-container>
                    <button class="boton cta mb-4 w-100" @click="panelPersonas = !panelPersonas">Filtrar</button>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'

export default {
    name: 'PanelFiltrosPersonas',
    components: {Service},
	props: ['pre_filtro', 'num_usuarios_filtrados'],
    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            ruta: null,
            filtro: '',
            todos: true,
            tipo: {
                TRL: true,
                RRHH: true,
                Consejera: true,
                Admin: true,
                Legal: true
            },
            personas: [],
            estado: 'activos',
            ws: null,
            ws_spinner: false,
            excelColumns : [
                {
                    label: "ID",
                    field: "trl_id"
                },
                {
                    label: "AÑO REGISTRO",
                    field: "trl_creation_date",
                    dataFormat: this.dateFormatYear
                },
                {
                    label: "ACCESO",
                    field: "trl_activation_date",
                    dataFormat: this.dateFormatMs
                },
                {
                    label: "FINALIZADO",
                    field: "trl_finished_date",
                    dataFormat: this.dateFormatMs
                },
                {
                    label: "NOMBRE",
                    field: "trl_name"
                },
                {
                    label: "APELLIDOS",
                    field: "trl_surnames"
                },
                {
                    label: "NACIONALIDAD",
                    field: "trl_nationality"
                },
                {
                    label: "EMAIL PROFESIONAL",
                    field: "trl_email"
                },
                {
                    label: "EMAIL PERSONAL",
                    field: "trl_secondary_email"
                },
                {
                    label: "SEXO",
                    field: "trl_gender"
                },
                {
                    label: "CONSEJERA",
                    field: "cons",
                    dataFormat: this.consFormat
                },
                {
                    label: "EMPRESA",
                    field: "company_name"
                },
                {
                    label: "RRHH",
                    field: "trl_rrhhusers",
                    dataFormat: this.formatTrlRrhh
                },
                {
                    label: "SERVICIOS CONTRATADOS",
                    field: "trl_services",
                    dataFormat: this.trlUserServiceFormat
                },
                {
                    label: "CÓNYUGE",
                    field: "trl_spouse",
                    dataFormat: this.trueFalseFormat
                },
                {
                    label: "FECHA AVISO EMPRESA",
                    field: "trluser_homecontract_dateadvise",
                    dataFormat: this.dateFormat
                },
                {
                    label: "FECHA CONTACTO TRL",
                    field: "trluser_homecontract_datefirstcontact",
                    dataFormat: this.dateFormat
                },
                {
                    label: "FECHA LLEGADA",
                    field: "trluser_homecontract_datearrival",
                    dataFormat: this.dateFormat
                },
                {
                    label: "FECHA VISITA CASAS",
                    field: "trluser_homecontract_datevisit",
                    dataFormat: this.dateFormat
                },
                {
                    label: "FECHA BÚSQUEDA COLEGIOS",
                    field: "trluser_homecontract_datesearchschool",
                    dataFormat: this.dateFormat
                },
                {
                    label: "FECHA CONTRATO",
                    field: "trluser_homecontract_datesignature",
                    dataFormat: this.dateFormat
                },
                {
                    label: "ENTRADA DOMICILIO",
                    field: "trluser_homecontract_dateentry",
                    dataFormat: this.dateFormat
                }
            ],
            excelData : [],
            panelPersonas: 1,
            showTooltip: false
        }
    },

    watch: {
        // todos() {
        //     if (this.todos) {
        //         for (let type in this.tipo) {
        //             this.tipo[type] = true;
        //         };
        //     }
        // },
        tipo: {
            handler(newComponents, oldComponents) {
                let alguno_falso = false;
                for (let type in this.tipo) {
                    if (!this.tipo[type]) {
                        this.todos = false; 
                        alguno_falso = true;
                    }
                };
                if (!alguno_falso) {this.todos = true;}
                this.filtrarUsuarios();
            },
            deep: true
        },
        personas: {
            handler(newComponents, oldComponents) {
                if (this.estado != 'finalizados' || (this.estado === 'finalizados' && this.usuarios.find(usu => usu.estado == 1))) {
                    this.filtrarUsuarios();
                }
            },
            deep: true
        },
        estado() {
            this.filtro = '';
			if (this.estado != 'finalizados') {
				this.todos = true;
				this.tipo = {
					TRL: true,
					RRHH: true,
					Consejera: true,
					Admin: true,
                    Legal: true
				}
			} else {
				this.todos = false;
				this.tipo = {
					TRL: true,
					RRHH: false,
					Consejera: false,
					Admin: false,
                    Legal: false
				}
			}
            this.filtrarUsuarios();
        },
        filtro() {this.filtrarUsuarios();},
        usuarios() { this.setUsuarios();},
        pre_filtro() {
            if (this.pre_filtro.tipo) {
                for (let tipo in this.tipo) {
                    this.tipo[tipo] = false;
                }
                this.pre_filtro.tipo.forEach(fil => {
                    this.tipo[fil] = true;
                })
            }
            this.estado = this.pre_filtro.finalizado ? 'finalizados' : 'activos';
            if (this.pre_filtro.palabra) this.filtro = this.pre_filtro.palabra;
        }
    },

	computed: {
        ...mapState([
            'usuarios', 'usuario_loggeado'
        ]),
        usingChromeIos() {return navigator.userAgent.match('CriOS');}
    },

    methods: {
        filtrarUsuarios() {
            let types = [];
            for (let type in this.tipo) {
                if (this.tipo[type]) {types.push(type)}
            };
            this.$emit('filtrarUsuarios',  {'filtro': this.filtro, "tipo": types, "estado": this.estado === 'activos' ? 0 : 1}, 'panel');
        },
        setUsuarios() {
            this.personas = [];
            this.usuarios.forEach(usuario => {
                const nombre = usuario.personales.nombre + ' ' + usuario.personales.apellidos;
                const el = {
                    nombre: nombre,
                    id: usuario.id,
                    filtro: true
                }
                this.personas.push(el);
            });
        },
        todosClick() {
            if (this.todos) {
                for (let type in this.tipo) {this.tipo[type] = true;};
            } else {
                for (let type in this.tipo) {this.tipo[type] = false;};
            }
        },
        downloadUsers() {
            this.ws_spinner = true;
            this.ws = 'trlUsersList';
            // this.$store.dispatch('setLoading', true);
        },

        onSuccess(response) {
            this.ws = null;
            this.ws_spinner = false;
            this.$store.dispatch('setLoading', false);
            this.excelData = response.data;
            setTimeout(() => {
                this.$refs.downloadUserBtn.$el.click();
            }, 100);
        },
        trueFalseFormat(value){
            return parseFloat(value) ? 'Si' : 'No';
        },
        trlUserServiceFormat(value){
            let services = '';
            if (value && value.length) {
                value.forEach((e, index) => {
                    if (index === 0) {
                        services = e.data.nombre;
                    } else {
                        services = `${services}, ${e.data.nombre}`;
                    }
                });
            }

            return services;
        },
        dateFormat(value) {
            return parseInt(value) ? this.$moment(parseInt(value)).format('YYYY-MM-DD') : null;
        },
        dateFormatMs(value) {
            return parseInt(value) ? this.$moment(parseInt(value) * 1000).format('YYYY-MM-DD') : null;
        },
        dateFormatYear(value) {
            return parseInt(value) ? this.$moment(parseInt(value) * 1000).format('YYYY') : null;
        },
        formatTrlRrhh(value){
            let rrhh = '';
            if (value && value.length) {
                value.forEach((e, index) => {
                    if (index === 0) {
                        rrhh = `${e.name} ${e.surnames}`;
                    } else {
                        rrhh = `${rrhh}, ${e.name} ${e.surnames}`;
                    }
                });
                
            }

            return rrhh;
        },
        consFormat(value) {
            let cons = '';
            if (value && value.length) {
                cons = `${value[0].name} ${value[0].surnames}`;
            }
            return cons;
        }
	},
    created: function() {
        this.ruta = this.$router.currentRoute.name;
        this.setUsuarios();
    },
    mounted: function() {
        if (this.pre_filtro) { 
            for (let tipo in this.tipo) {
                this.tipo[tipo] = false;
            }
            this.pre_filtro.tipo.forEach(fil => {
                this.tipo[fil] = true;
            })
            this.pre_filtro.finalizado ? this.estado = 'finalizados' : this.estado = 'activos';
        }
    }
}
</script>
<style scoped lang="scss">
.v-application #app .filtros {
    .usuarios-expansion-panel {
        display: none;
    }
    .nav_link {
        padding-left: 0;
        width: 100%;
        border-bottom: 2px solid var(--purple03);
    }
    .tipo {
        padding-left: 0;
        // ::v-deep .v-input--selection-controls__input {
        //     margin-right: 1em;
        // }
    }
    .estado {
        padding-left: 0;
        ::v-deep .v-radio {
            width: calc(50% - 1em);
            .v-input--selection-controls__input {
                margin-right: .5em;
            }
        }
    }
}
@media (max-width: 900px) {
     .v-application #app .filtros {
        .searcher {
            display: none;
        }
        .usuarios-expansion-panel {
            display: block;
        }
        .usuarios-list {
            display: none;
        }
     }
 }
</style>
