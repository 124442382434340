<template>
	<div id="home" v-if="usuario_loggeado.id">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <modal-crear-tarea :usuario="usuario_selected" :servicio="servicio_selected" :tarea_sel="tarea_selected" v-if="modal_tarea" @closeModalTarea="closeModalTarea"/>
        <modal-nuevotrl v-if="modal_nuevotrl && usuario_loggeado.tipo === 'TRL'" @closeModalNuevotrl="closeModalNuevotrl" :preferencias="trl_preferencias" :trl_servicios="trl_servicios"/>
        <panel-filtros-tareas v-if="usuario_loggeado.tipo != 'TRL' && usuario_loggeado.tipo != 'RRHH'" @filtrarUsuarios="filtrarUsuarios"/>
        <panel-consejera v-else-if="usuario_loggeado.id && (usuario_loggeado.tipo === 'TRL' || usuario_loggeado.tipo === 'RRHH')" @openPreferencias="openPreferencias" @openServicios="openServicios"/>
        <div :class="['view-container', 'visible_scrollbar', usuario_loggeado.tipo === 'TRL' ? 'trl' : '']">
            <v-tabs class="tabs" v-model="event_tabs" show-arrows>
                <v-tab href="#calendario" class="tab">
                    <p class="nav_link">{{ $t(localeRoute + 'calendario')}}</p>
                </v-tab>
                <v-tab href="#listado" class="tab">
                    <p class="nav_link">{{ $t(localeRoute + 'listado')}}</p>
                </v-tab>
                <v-tab-item value="calendario">
                    <div class="calendar_header">
                        <div class="controles">
                            <span class="flecha izda" @click="cambiarMes('atras')">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.9707 1L1.00013 7.97057L7.9707 14.9411" stroke="#4F4F4F" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <p class="titulo">{{current_date.format("MMMM YYYY")}} </p>
                            <span class="flecha izda" @click="cambiarMes('adelante')">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.9707 14.9411L14.9413 7.97056L7.9707 0.999994" stroke="#4F4F4F" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <button v-if="usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo === 'Consejera'" class="boton crear_tarea" @click="openModalTarea()">Añadir tarea al calendario</button>
                    </div>
                    <eventos-calendario 
                        ref="calendario" 
                        :usuarios_filtrados="usuarios_filtrados" 
                        @openModalTarea="openModalTarea"
                        @closeModalTarea="closeModalTarea"
                        @changeEventsView="changeEventsView"
                    />
                </v-tab-item>
                <v-tab-item value="listado">
                    <div class="calendar_header">
                        <div class="controles">
                            <span class="flecha izda" @click="cambiarMes('atras')">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.9707 1L1.00013 7.97057L7.9707 14.9411" stroke="#4F4F4F" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <p class="titulo">{{current_date.format("MMMM YYYY")}} </p>
                            <span class="flecha izda" @click="cambiarMes('adelante')">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.9707 14.9411L14.9413 7.97056L7.9707 0.999994" stroke="#4F4F4F" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <button class="boton crear_tarea" v-if="usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo === 'Consejera'" @click="openModalTarea()">Añadir tarea al calendario</button>
                    </div>
                    <eventos-listado 
                        :usuarios_filtrados="usuarios_filtrados" 
                        :current_year="current_year" 
                        :current_month="current_month" 
                        :scroll_to="current_day"
                        @openModalTarea="openModalTarea"
                        @closeModalTarea="closeModalTarea"
                    />
                </v-tab-item>
            </v-tabs>
            <div v-if="usuario_loggeado.tipo === 'TRL'" class="container_destacados">
                <hr>
                <!-- <div class="card" @click="routeTo('Servicios')">
                    <img class="image" src="images/servicios.jpg">
                    <p class="parrafo bold">{{ $t(localeRoute + 'todosnuestrosservicios')}}</p>
                </div> -->
                <div class="card" @click="routeTo('PaginaBasica', 'condiciones_alquiler')">
                    <img class="image" src="images/servicios.jpg">
                    <p class="parrafo bold">{{ $t(localeRoute + 'condicionesalquiler')}}</p>
                </div>
                <div class="card" @click="routeTo('PaginaBasica', 'madrid')">
                    <img class="image" src="images/madrid.jpg">
                    <p class="parrafo bold">{{ $t(localeRoute + 'madrid')}}</p>
                </div>
                <div class="card" @click="routeTo('PaginaBasica', 'espana')">
                    <img class="image" src="images/espana.jpg">
                    <p class="parrafo bold">{{ $t(localeRoute + 'espana')}}</p>
                </div>
                <!-- <div class="card" v-if="usuario_loggeado.personales.ciudad" @click="usuario_loggeado.personales.ciudad.toLowerCase() === 'madrid' ? routeTo('PaginaBasica', 'madrid') : routeTo('PaginaBasica', 'espana')">
                    <img class="image" src="images/madrid.jpg">
                    <p class="parrafo bold">{{ $t(localeRoute + 'bienvenido')}}{{usuario_loggeado.personales.ciudad.toLowerCase() === 'madrid' ? 'Madrid' : 'España' }}</p>
                </div> -->
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
import PanelFiltrosTareas from '@/components/PanelFiltrosTareas.vue'
import PanelConsejera from '@/components/PanelConsejera.vue'
import EventosCalendario from '@/components/EventosCalendario.vue'
import EventosListado from '@/components/EventosListado.vue'
import ModalNuevotrl from '@/components/ModalNuevotrl.vue'
import ModalCrearTarea from '@/components/ModalCrearTarea.vue'

export default {
	name: 'Home',
	components: {
        PanelFiltrosTareas,
        PanelConsejera,
        EventosCalendario,
        EventosListado,
        ModalNuevotrl,
        ModalCrearTarea,
        Service
	},
	props: [],

    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            modal_nuevotrl: true,
            usuarios_filtrados: [],
            current_year: null,
            current_month: null,
            current_day: 0,
            modal_tarea: false,
            usuario_selected: null,
            servicio_selected: null,
            tarea_selected: null,
            trl_preferencias: false,
            trl_servicios: false,
            event_tabs: 'calendario',
            window_with: 0,
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
        usuario_loggeado() {
            this.checkUser();
        },
        idiomaGlobal() {
            this.getMomentDate();
        }
    },

	computed: {
        ...mapState([
            'usuario_loggeado', 'idiomaGlobal'
        ])
    },

    methods: {
        closeModalNuevotrl() {
            this.modal_nuevotrl = false;
            this.trl_preferencias = false;
            this.trl_servicios = false;
        },
        filtrarUsuarios(filtros) {
            this.usuarios_filtrados = filtros;
        },
        getMomentDate() {
            let momentDate = this.current_date ? this.$moment(this.current_date).locale(this.idiomaGlobal) : this.$moment();
            this.current_date = momentDate;
            this.current_year = momentDate.year();
            this.current_month = momentDate.month();
            this.current_day = momentDate.date();
        },

        cambiarMes(direccion) {
            if (direccion === 'adelante') {
                this.current_date.add(1, 'M');
                this.current_month = this.current_date.month();
                this.current_year = this.current_date.year();
                this.$refs.calendario.$refs.calendar.next()
            } else {
                this.current_date.subtract(1, 'M');
                this.current_month = this.current_date.month();
                this.current_year = this.current_date.year();
                this.$refs.calendario.$refs.calendar.prev()
            }
        },
        openModalTarea(usuario, servicio, tarea) {
            tarea ? this.tarea_selected = tarea : this.tarea_selected = null;
            usuario ? this.usuario_selected = usuario : this.usuario_selected = null;
            this.servicio_selected = servicio;
			this.modal_tarea = true;
		},
		closeModalTarea() {
			this.modal_tarea = false;
            this.getTasks();
		},
        openPreferencias() {
            this.trl_preferencias = true;
            this.modal_nuevotrl = true;
        },
        openServicios() {
            this.trl_servicios = true;
            this.modal_nuevotrl = true;
        },
        routeTo(destino, sub) {
            if (!sub) {sub = null;}
            this.$router.push({ name: destino, params: {'desde': 'home_trl', 'pagina': sub }});
        },
        checkUser() {
            this.usuario_loggeado.politica_privacidad ? this.modal_nuevotrl = false : this.modal_nuevotrl = true;
        },
        changeEventsView(fecha) {
            this.event_tabs = 'listado';
            this.current_day = this.$moment(fecha).date();
        },
        checkSize() {
            //RESPONSIVE FOR MOBILE
            this.window_with = window.innerWidth;
            if (this.window_with < 599) {
                this.event_tabs = 'listado';
            } else {
                this.event_tabs = 'calendario';
            }
        },
        getTasks() {
            this.ws_spinner = true;
            this.data = '';
            if (this.usuario_loggeado.tipo === 'Consejera') {this.data = 'cons=' + this.usuario_loggeado.id}
            else if (this.usuario_loggeado.tipo === 'RRHH') {this.data = 'rrhh=' + this.usuario_loggeado.id}
            else if (this.usuario_loggeado.tipo === 'TRL') {this.data = 'trl=' + this.usuario_loggeado.id}
            this.ws = 'taskList';
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'taskList') {
                let eventos = response.data;
                eventos.length ? this.$store.dispatch('setEventos', eventos) : this.$store.dispatch('setEventos', []);
                this.$store.dispatch('setLoading', false);
            } 
        }
	},
    created: function() {
        // this.$store.dispatch('setLoading', true);
        if (!localStorage.getItem('isLogged')) {
            this.$router.push('/');
        }
        this.getMomentDate();
        this.checkUser();
        window.addEventListener('resize', this.checkSize);
        this.window_with = window.innerWidth;
        // if (this.usuario_loggeado.tipo === 'abogado') this.$router.push('/usuarios');
    },
    mounted: function() {
        // this.$store.dispatch('setLoading', false);
        this.checkSize();
        this.getTasks();
    },

    destroyed: function() {
        window.removeEventListener('resize', this.checkSize);
    }
}
</script>
<style scoped lang="scss">
#home {
    .view-container {
        margin-left: 22%!important;
        position: relative;
        .tabs {
            margin-top: 1em;
            .calendar_header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin: 1em 0;
                .controles {
                    display: flex;
                    align-items: center;
                    .titulo {width: 8em; text-align: center; text-transform: capitalize;}
                    .flecha {margin: .7em .5em; cursor: pointer;}
                }
            }
        }
        .container_destacados {
            display: flex;
            margin-top: 2em;
            hr {
                display: none;
                margin-bottom: 2em;
            }
            .card {
                position: relative;
                margin: 0;
                padding: 0;
                overflow: hidden;
                cursor: pointer;
                margin-right: 2em;
                flex: 1;
                &:last-of-type {
                    margin-right: 0;
                }
                .image {
                    width: 100%;
                    min-width: 33%;
                    height: 12em;
                    object-fit: cover;
                }
                .parrafo {font-size: 1.2em; margin: 1em;}
            }
        }
    }
    ::v-deep .v-tabs-bar {
        background: none;
        border-bottom: 1px solid var(--gray03);
        .v-tab {
            text-transform: unset;
            text-align: center;
            &.v-tab--active {
                .nav_link {
                    color: var(--purple03);
                    font-weight: 600;
                }
            }
        }
        .v-tabs-slider-wrapper {color: var(--purple03); height: 3px;}
    }
    ::v-deep .v-tabs-items {
        background-color: transparent!important;
        overflow-y: auto;
        max-height: calc(100vh - 14em);
        overflow-x: hidden;
    }
    .trl::v-deep .v-tabs-items {max-height: unset;}
}

 @media (max-width: 900px) {
     .v-application #app .view .view-container{
        &.trl {
            padding-top: 2em;
        }   
     }
     #home {
        .view-container{
            margin-left: 0!important;
            .tabs {
                margin-top: 0;
                .calendar_header {
                    flex-direction: column-reverse;
                    .controles {
                        justify-content: space-between;
                    }
                    .crear_tarea {
                        margin-bottom: 2em;
                        margin-top: 1em;
                    }
                }
            }
            .container_destacados {
            display: block;
            hr {
                display: block;
            }
            .card {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                margin-bottom: 2em;
                &:nth-child(2) {
                    margin: 0 0 2em;
                }
                .image {
                    height: 8em;
                    width: 30%;
                }
            }
        }
        }
        ::v-deep .v-tabs-bar {
            display: none;
        }
        ::v-deep .v-tabs-items {
            max-height: initial;
            overflow-x: initial;
        }

     }
 }
</style>

