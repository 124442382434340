<template>
    <div class="modal">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <div class="submodal succes" v-if="success">
            <p v-if="success != 'task borrada'" class="titulo">{{editar_tarea ? "Editar tarea" : "Nueva tarea"}}</p>
            <p v-else class="titulo">Eliminar tarea</p>
            <div class="boton fab check">
                <v-icon class="icono">mdi-check</v-icon>
            </div>
            <p v-if="success != 'task borrada'" class="parrafo">{{editar_tarea ? "La tarea se ha guardado correctamente" : "La tarea se ha creado correctamente"}}</p>
            <p v-else class="parrafo">La tarea se ha eliminado correctamente</p>
        </div>
        <div class="submodal" v-else>
            <svg class="cerrar_modal" @click="cerrar_modal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <p class="titulo">{{editar_tarea ? "Editar tarea" : "Nueva tarea"}}</p>
            <p class="titulo bold" v-if="usuario">{{usuario.personales.nombre + ' ' + usuario.personales.apellidos}}</p>
            <v-form class="form" v-model="valid">
                <v-container>
                    <div v-if="editar_tarea" class="boton fab borrar" @click="eliminarTarea">
                        <v-icon class="icono">mdi-delete</v-icon>
                    </div>
                    <v-row>
                        <v-col cols="12" sm="6" v-if="sin_usuario">
                            <v-autocomplete
                                v-model="evento.trl"
                                :items="lista_usuarios"
                                placeholder="Nombre del TRL*"
                                :rules="[rules.required]"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="sin_usuario">
                            <v-autocomplete
                                :disabled="!evento.trl"
                                v-model="evento.servicio"
                                :items="evento.trl ? lista_servicios : ''"
                                placeholder="Servicio*"
                                :rules="[rules.required]"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-combobox
                            v-if="!subservicio"
                                :disabled="!evento.servicio"
                                v-model="evento.name"
                                :items="evento.servicio ? evento_servicio : ''"
                                label="Tarea*"
                                append-icon=''
                                :rules="[rules.required]"
                                @input.native="evento.name = $event.srcElement.value"
                            ></v-combobox>
                            <v-select
                            v-else
                                :disabled="!evento.servicio"
                                v-model="evento.name"
                                :items="lista_subservicios_tareas"
                                label="Tarea*"
                                append-icon=''
                                :rules="[rules.required]"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-input style="display: none;" :value="evento.start" :rules="[rules.required]"></v-input>
                            <v-datetime-picker label="Fecha y hora" v-model="evento.start" date-format="dd/MM/yyyy" :date-picker-props="dateProps">
                                <template slot="dateIcon">
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template slot="timeIcon">
                                    <v-icon>mdi-clock</v-icon>
                                </template>
                                <template slot="actions" slot-scope="{ parent }">
                                    <v-btn style="color: transparent;color: var(--purple03);border: 1px solid var(--purple03);box-shadow: none;" @click.native="parent.okHandler">Cancelar</v-btn>
                                    <v-btn color="primary" @click="parent.okHandler">Hecho</v-btn>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <p class="parrafo">Mostrar en la agenda del TRL</p>
                            <v-radio-group v-model="evento.mostrar_rrhh" class="checkbox_container" row>
                                <v-radio class="checkbox" label="Sí" :value="1"></v-radio>
                                <v-radio class="checkbox" label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <p class="parrafo">Notificar por email al TRL</p>
                            <v-radio-group v-model="evento.notificar_email" class="checkbox_container" row>
                                <v-radio class="checkbox" label="Sí" :value="1"></v-radio>
                                <v-radio class="checkbox" label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Notas"
                                v-model="evento.notas"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-btn class="boton cta" :loading="loading" :disabled="!valid" @click="crearTarea">{{editar_tarea ? "Guardar" : "Crear"}}</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
// import StDatePicker from '@/components/StDatePicker.vue'

export default {
	name: 'ModalCrearTarea',
    components: {Service},
    props: [
        "servicio", "usuario", "tarea_sel", "subservicio"
    ],
    data() {
        return {
            evento: {},
            editar_tarea: false,
            sin_usuario: false,
            lista_usuarios: [],
            lista_servicios: [""],
            lista_subservicios_tareas: [],
            valid: false,
            rules: {
                required: value => !!value || '',
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Formato de email no válido'
                },
            },
            loading: false,
            loader: null,
            idioma_trl: null,
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
        evento_trl() {
            if (this.evento_trl && !this.usuario) {
                // this.$store.dispatch('setLoading', true);
                this.ws_spinner = true;
                this.data = this.evento_trl;
                this.ws = 'getUser';
            }
        },
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 5000)
            this.loader = null
        },
    },

	computed:  {
        ...mapState([
            'eventos', 'usuario_loggeado', 'servicios', 'usuarios'
        ]),
        date_formated() {
            // return this.$moment(this.evento.start).format('DD-MM-YYYY HH:mm');
            return this.$moment(this.evento.start).format('YYYY-MM-DD HH:mm');
        },
        evento_trl() {
            return this.evento.trl;
        },
        evento_servicio() {
            // let usuario = this.usuarios.find(usuario => usuario.id === this.evento.trl);
            // return usuario? usuario.servicios.find(servicio => servicio.id === this.evento.servicio).tareas : '';
            let tareas;
            if (this.idioma_trl != 'en') {
                tareas = this.evento.servicio ? this.servicios.find(servicio => servicio.id === this.evento.servicio).tareas : '';
            } else {
                tareas = this.evento.servicio ? this.servicios.find(servicio => servicio.id === this.evento.servicio).tareas_en : '';
            }
            return tareas;
        },
        dateProps() {
            return {
                min: this.$moment().format('YYYY-MM-DD'),
                'first-day-of-week': 1
            }
        }
	},

    methods: {
        cerrar_modal() {
            this.$emit("closeModalTarea", true);
        },
        crearTarea() {
            this.loader = 'loading';
            // this.evento.start = this.date_formated;
            this.evento.timestamp = new Date(this.evento.start).getTime();
            this.data = {...this.evento};
            this.data.start = this.date_formated;
            if (this.editar_tarea) {
                this.ws = 'taskUpdate';
            } else {
                this.ws = 'taskRegister';
            }
        },
        eliminarTarea(){
            this.data = this.evento.id;
            this.ws_spinner = true;
            this.ws = 'taskRemove';
            // this.$store.dispatch('setLoading', true);
        },
        onSuccess(response) {
            // this.$store.dispatch('setLoading', false);
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'taskRegister') {
                this.$store.dispatch('addEventos', this.evento);
                let data_task = {};
                if (this.usuario_loggeado.tipo === 'Consejera') {data_task = 'cons=' + this.usuario_loggeado.id}
                this.data = data_task;
                this.ws = 'taskList';
                this.success = 'task creada';
                setTimeout(() => this.$emit("closeModalTarea", true), 1500);
            } else if (response.ws === 'taskRemove') {
                let data_task = '';
                if (this.usuario_loggeado.tipo === 'Consejera') {data_task = 'cons=' + this.usuario_loggeado.id}
                this.ws = 'taskList';
                this.data = data_task;
                this.success = 'task borrada';
            } else if (response.ws === 'taskUpdate') {
                let data_task = {};
                if (this.usuario_loggeado.tipo === 'Consejera') {data_task = 'cons=' + this.usuario_loggeado.id}
                this.data = data_task;
                this.ws = 'taskList';
                this.success = 'task actualizada';
            } else if (response.ws === 'taskList') {
                this.$store.dispatch('setLoading', false);
                let eventos = response.data;
                this.$store.dispatch('setEventos', eventos);
                setTimeout(() => this.$emit("closeModalTarea", true), 1500);
            } else if (response.ws = 'getUser') {
                let usuario = this.usuarios.find(us => us.id == this.evento_trl);
                let data = response.data;
                this.lista_servicios = [];
                data.t_rluser_services.forEach(ser => {
                    let servicio;
                    if (!ser.data.oculto) {
                        servicio = {
                            value: this.servicios.find(se => se.id == ser.service_id).id,
                            text: this.servicios.find(se => se.id == ser.service_id).nombre
                        }
                        this.lista_servicios.push(servicio);
                    }
                });
                this.idioma_trl = data.preferred_language || null;

                // usuario.servicios.forEach(servicio => {
                //     this.lista_servicios.push({value: servicio.id, text: servicio.name});
                // });
                this.evento.consejera = this.usuarios.find(usu => usu.id == this.evento.trl).consejera.id;
            } 
        }
	},
    created: function() {
        this.console = window.console;
        if (this.tarea_sel) {
            this.editar_tarea = true;
            this.evento = {...this.tarea_sel};
            this.evento.start = new Date(this.tarea_sel.start.replace(/-/g, "/"));
            this.subservicio ? this.lista_subservicios_tareas = ["Alta " + this.subservicio, "Comprobación " + this.subservicio] : '';
        } else if (this.usuario) {
            this.evento = {
                name: null,
                trl: this.usuario.id,
                consejera: this.usuario.consejera.id,
                creada_por: this.usuario_loggeado.id,
                start: null,
                servicio: this.servicio,
                notas: null,
                mostrar_rrhh: false,
                notificar_email: false
            }
            this.subservicio ? this.lista_subservicios_tareas = ["Alta " + this.subservicio, "Comprobación " + this.subservicio] : '';
            this.idioma_trl = this.usuario.idioma_seleccionado;
        } else {
            this.sin_usuario = true;
            this.evento = {
                name: null,
                trl: null,
                consejera: null,
                creada_por: this.usuario_loggeado.id,
                start: null,
                servicio: null,
                notas: null,
                mostrar_rrhh: false,
                notificar_email: false,
                open: false,
            };
            this.usuarios.forEach(usuario => {
                if (usuario.tipo === 'TRL') {
                    let nombre_completo = usuario.personales.nombre + ' ' + usuario.personales.apellidos;
                    this.lista_usuarios.push({value: usuario.id, text: nombre_completo});
                }
            });
        }
    },
    mounted: function() {
    }
}
</script>
<style scoped lang="scss">
.v-application #app .modal {
    .submodal.succes {
        text-align: center;
        display: flex;
        flex-flow: column;
        padding-top: 1em;
        .boton.check {
            width: 6em;
            height: 6em;
            margin: 3em 0 5em;
            margin-left: calc(50% - 3em);
            text-align: center;
            background-color: #27AE60;
            .icono{color: white;}
        }
        .titulo {
            width: auto;
            margin: 2em auto 1em;
        }
        .parrafo {margin: 0 0 2em;}
    }
    .borrar {
        float: right;
        background-color: white;
        box-shadow: var(--sombra_ext);
    }
}
</style>
