<template>
    <div class="panel_izda filtros">
        <v-text-field
            class="searcher"
            :type="'text'"
            v-model="filtro"
            :placeholder="'Buscar una empresa'"
            :append-icon="'mdi-magnify'"
        ></v-text-field>
        <div class="empresas-list">
            <p class="nav_link">Sector</p>
            <v-container fluid class="tipo checkbox_container">
                <v-checkbox v-for="(sector, index) in lista_sectores" :key="index" 
                    v-model="lista_sectores.find(el => el.nombre === sector.nombre).active" 
                    :label="sector.nombre" class="checkbox">
                </v-checkbox>
            </v-container>
        </div>
        <v-expansion-panels class="empresas-expansion-panel" v-model="panelEmpresas">
            <v-expansion-panel>
                <v-expansion-panel-header>Filtrar empresas ({{num_empresas_filtradas}})</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field
                        :type="'text'"
                        v-model="filtro"
                        :placeholder="'Buscar una empresa'"
                        :append-icon="'mdi-magnify'"
                    ></v-text-field>
                    <p class="nav_link">Sector</p>
                    <v-container fluid class="tipo checkbox_container">
                        <v-checkbox v-for="(sector, index) in lista_sectores" :key="index" 
                            v-model="lista_sectores.find(el => el.nombre === sector.nombre).active" 
                            :label="sector.nombre" class="checkbox">
                        </v-checkbox>
                    </v-container>
                    <button class="boton cta mb-4 w-100" @click="panelEmpresas = !panelEmpresas">Filtrar</button>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'PanelFiltrosEmpresas',
	props: ['num_empresas_filtradas'],

    data() {
        return {
            filtro: "",
            todos: true,
            lista_sectores: [],
            panelEmpresas: 1
        }
    },

    watch: {
        filtro() {
            this.filtrarEmpresas();
        },
        lista_sectores: {
            handler(newComponents, oldComponents) {
                this.filtrarEmpresas();
            },
            deep: true
        },
        empresas() {
            this.listarSectores();
        }
    },

	computed: {
        ...mapState([
            'empresas'
        ]),
    },

    methods: {
        filtrarEmpresas() {
            let sectores = [];
            this.lista_sectores.forEach(sector => {
                sector.active ? sectores.push(sector.nombre) : '';
            });
            this.$emit('filtrarEmpresas',  {'filtro': this.filtro, "sectores": sectores});
        },
        listarSectores() {
            this.lista_sectores = [];
            this.empresas.forEach(empresa => {
                let sector = {
                    nombre: empresa.sector,
                    active: true
                }
                let sector_existe = this.lista_sectores.find(sector => sector.nombre === empresa.sector);
                if (!sector_existe) {
                    this.lista_sectores.push(sector);
                }
            });
            this.lista_sectores.sort((a, b) => a.nombre.localeCompare(b.nombre));
        }
	},
    created: function() {},
    mounted: function() {
        this.listarSectores();
    }
}
</script>
<style scoped lang="scss">
.v-application #app .filtros {
    .empresas-expansion-panel {
        display: none;
    }
    .nav_link {
        padding-left: 0;
        width: 100%;
        border-bottom: 2px solid var(--purple03);
    }
    .tipo {
        padding-left: 0;
        ::v-deep .v-input--selection-controls__input {
            margin-right: 1em;
        }
    }
    .estado {
        padding-left: 0;
        ::v-deep .v-radio {
            width: calc(50% - 1em);
            .v-input--selection-controls__input {
                margin-right: .5em;
            }
        }
    }
}
 @media (max-width: 900px) {
     .v-application #app .filtros {
        .searcher {
            display: none;
        }
        .empresas-expansion-panel {
            display: block;
        }
        .empresas-list {
            display: none;
        }
     }
 }
</style>
