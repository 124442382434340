<template>
    <div class="panel_izda filtros">
        <div class="row back_route" v-if="usuario" @click="$router.push({ name: 'FichaTRL', query: { userId: usuario.enc_id } })">
            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646445 3.64645C0.451183 3.84171 0.451183 4.15829 0.646445 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.73079 7.34027 4.73079 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.73079 0.976311 4.73079 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646445 3.64645ZM16 3.5L0.999999 3.5V4.5L16 4.5V3.5Z" fill="#4F4F4F"/>
            </svg>
            <span class="parrafo">Volver a la ficha de {{usuario.personales.nombre}}</span>
        </div>
        <div class="viviendas-list">
            <v-text-field
                :type="'text'"
                v-model="filtro"
                :placeholder="'Buscar por dirección o barrio'"
                :append-icon="'mdi-magnify'"
            ></v-text-field>
            <v-container>
                <v-row>
                    <p class="parrafo light reset" @click="resetearFiltros">Borrar filtros</p>
                </v-row>
                <v-row>
                    <p class="nav_link">{{ $t(localeRoute + "disponibilidad") }}</p>
                    <v-radio-group v-model="filtros.disponibilidad" class="checkbox_container radio" row>
                        <v-radio class="checkbox" label="Disponible" :value="true"></v-radio>
                        <v-radio class="checkbox" label="No disponible" :value="false"></v-radio>
                    </v-radio-group>
                </v-row>
                <v-row>
                    <p class="nav_link">Zona</p>
                    <v-autocomplete
                        v-model="filtros.filtro_zona"
                        :items="zonas"
                        placeholder="Selecciona"
                        clearable
                        no-data-text="No hay resultados"
                    ></v-autocomplete>
                </v-row>
                <v-row>
                    <p class="nav_link">Precio</p>
                    <v-range-slider
                        v-model="filtros.precio"
                        :max="15000"
                        :min="0"
                        :step="50"
                        hide-details
                        class="range"
                    >
                    </v-range-slider>
                    <p class="small">{{`De ${filtros.precio[0]}€ a ${filtros.precio[1] === 15000 ? 'sin límite' : filtros.precio[1] + '€'}`}}</p>
                </v-row>
                <v-row>
                    <p class="nav_link">Metros cuadrados</p>
                    <v-range-slider
                        v-model="filtros.metros"
                        :max="1000"
                        :min="0"
                        :step="50"
                        hide-details
                        class="range"
                    >
                    </v-range-slider>
                    <p class="small">{{`De ${filtros.metros[0]}m2 a ${filtros.metros[1] === 1000 ? 'sin límite' : filtros.metros[1] + 'm2'}`}}</p>
                </v-row>
                <v-row>
                    <p class="nav_link">Tipo de vivienda</p>
                    <v-container class="checkbox_container">
                        <v-checkbox class="checkbox" v-model="filtros.tipo.piso" label="Piso"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.tipo.casa" label="Casa o chalet"></v-checkbox>
                        <!-- <v-checkbox v-model="tipo.atico" label="Ático"></v-checkbox> -->
                        <!-- <v-checkbox v-model="tipo.duplex" label="Dúplex"></v-checkbox> -->
                    </v-container>
                </v-row>
                <v-row class="numeros">
                    <v-col cols="6" class="sin_padding">
                        <p class="nav_link">Nº habitaciones</p>
                        <div class="input_number">
                            <span class="number_control" @click="filtros.habitaciones > 0 ? filtros.habitaciones-- : ''">-</span>
                            <v-text-field
                                label=""
                                v-model.number="filtros.habitaciones"
                                type="number"
                                min="0"
                            ></v-text-field>
                            <span class="number_control" @click="filtros.habitaciones++">+</span>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <p class="nav_link">Nº baños</p>
                        <div class="input_number">
                            <span class="number_control" @click="filtros.baños > 0 ? filtros.baños-- : ''">-</span>
                            <v-text-field
                                label=""
                                v-model.number="filtros.baños"
                                type="number"
                                min="0"
                            ></v-text-field>
                            <span class="number_control" @click="filtros.baños++">+</span>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <p class="nav_link">Equipamiento</p>
                    <v-autocomplete
                        v-model="filtros.equipamiento"
                        :items="['Amueblado', 'Sin amueblar', 'Parcialmente amueblado']"
                        placeholder="Selecciona"
                        clearable
                        no-data-text="No hay resultados"
                    ></v-autocomplete>
                </v-row>
                <v-row>
                    <p class="nav_link">Estado</p>
                    <v-autocomplete
                        v-model="filtros.estado"
                        :items="['Buenas condiciones', 'Excelente', 'Nueva construcción', 'Reformado', 'A reformar']"
                        placeholder="Selecciona"
                        clearable
                        no-data-text="No hay resultados"
                    ></v-autocomplete>
                </v-row>
                <v-row>
                    <p class="nav_link">Estilo</p>
                    <v-container fluid class="checkbox_container">
                        <v-checkbox class="checkbox" v-model="filtros.estilo.clasico" label="Clásico"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.estilo.moderno" label="Moderno"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.estilo.otro" label="Otro"></v-checkbox>
                    </v-container>
                </v-row>
                <v-row>
                    <p class="nav_link">Características</p>
                    <v-container class="checkbox_container">
                        <v-checkbox class="checkbox" v-model="filtros.checks.aire_acondicionado" label="AC"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.calefaccion" label="Calefacción"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.jardin" label="Jardín"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.terraza" label="Terraza"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.piscina" label="Piscina"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.trastero" label="Trastero"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.zonas_infantiles" label="Zonas infantiles"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.habitacion_servicio" label="Habitación de servicio"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.soleado" label="Soleado"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.cerca_metro" label="Metro/FGC"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.mascotas" label="Admite mascotas"></v-checkbox>
                        <v-checkbox class="checkbox" v-model="filtros.checks.garaje" label="Garaje"></v-checkbox>
                    </v-container>
                </v-row>
            </v-container>
        </div>
        <v-expansion-panels class="viviendas-expansion-panel" v-model="panelViviendas">
            <v-expansion-panel>
                <v-expansion-panel-header>Filtrar viviendas ({{num_viviendas_filtradas}})</v-expansion-panel-header>
                <v-expansion-panel-content >
                    <v-text-field
                        :type="'text'"
                        v-model="filtro"
                        :placeholder="'Buscar por dirección o barrio'"
                        :append-icon="'mdi-magnify'"
                    ></v-text-field>
                    <v-container>
                        <v-row>
                            <p class="parrafo light reset" @click="resetearFiltros">Borrar filtros</p>
                        </v-row>
                        <v-row>
                            <p class="nav_link">{{ $t(localeRoute + "disponibilidad") }}</p>
                            <v-radio-group v-model="filtros.disponibilidad" class="checkbox_container radio" row>
                                <v-radio class="checkbox" label="Disponible" :value="true"></v-radio>
                                <v-radio class="checkbox" label="No disponible" :value="false"></v-radio>
                            </v-radio-group>
                        </v-row>
                        <v-row>
                            <p class="nav_link">Zona</p>
                            <v-autocomplete
                                v-model="filtros.filtro_zona"
                                :items="zonas"
                                placeholder="Selecciona"
                                clearable
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-row>
                        <v-row>
                            <p class="nav_link">Precio</p>
                            <v-range-slider
                                v-model="filtros.precio"
                                :max="15000"
                                :min="0"
                                :step="50"
                                hide-details
                                class="range"
                            >
                            </v-range-slider>
                            <p class="small">{{`De ${filtros.precio[0]}€ a ${filtros.precio[1] === 15000 ? '∞' : filtros.precio[1]} €`}}</p>
                        </v-row>
                        <v-row>
                            <p class="nav_link">Metros cuadrados</p>
                            <v-range-slider
                                v-model="filtros.metros"
                                :max="1000"
                                :min="0"
                                :step="50"
                                hide-details
                                class="range"
                            >
                            </v-range-slider>
                            <p class="small">{{`De ${filtros.metros[0]}m2 a ${filtros.metros[1] === 1000 ? 'sin límite' : filtros.metros[1] + 'm2'}`}}</p>
                        </v-row>
                        <v-row>
                            <p class="nav_link">Tipo de vivienda</p>
                            <v-container class="checkbox_container">
                                <v-checkbox class="checkbox" v-model="filtros.tipo.piso" label="Piso"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.tipo.casa" label="Casa o chalet"></v-checkbox>
                                <!-- <v-checkbox v-model="tipo.atico" label="Ático"></v-checkbox> -->
                                <!-- <v-checkbox v-model="tipo.duplex" label="Dúplex"></v-checkbox> -->
                            </v-container>
                        </v-row>
                        <v-row class="numeros">
                            <v-col cols="6" class="sin_padding">
                                <p class="nav_link">Nº habitaciones</p>
                                <div class="input_number">
                                    <span class="number_control" @click="filtros.habitaciones > 0 ? filtros.habitaciones-- : ''">-</span>
                                    <v-text-field
                                        label=""
                                        v-model.number="filtros.habitaciones"
                                        type="number"
                                        min="0"
                                    ></v-text-field>
                                    <span class="number_control" @click="filtros.habitaciones++">+</span>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <p class="nav_link">Nº baños</p>
                                <div class="input_number">
                                    <span class="number_control" @click="filtros.baños > 0 ? filtros.baños-- : ''">-</span>
                                    <v-text-field
                                        label=""
                                        v-model.number="filtros.baños"
                                        type="number"
                                        min="0"
                                    ></v-text-field>
                                    <span class="number_control" @click="filtros.baños++">+</span>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <p class="nav_link">Equipamiento</p>
                            <v-autocomplete
                                v-model="filtros.equipamiento"
                                :items="['Amueblado', 'Sin amueblar', 'Parcialmente amueblado']"
                                placeholder="Selecciona"
                                clearable
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-row>
                        <v-row>
                            <p class="nav_link">Estado</p>
                            <v-autocomplete
                                v-model="filtros.estado"
                                :items="['Buenas condiciones', 'Excelente', 'Nueva construcción', 'Reformado', 'A reformar']"
                                placeholder="Selecciona"
                                clearable
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-row>
                        <v-row>
                            <p class="nav_link">Estilo</p>
                            <v-container fluid class="checkbox_container">
                                <v-checkbox class="checkbox" v-model="filtros.estilo.clasico" label="Clásico"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.estilo.moderno" label="Moderno"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.estilo.otro" label="Otro"></v-checkbox>
                            </v-container>
                        </v-row>
                        <v-row>
                            <p class="nav_link">Características</p>
                            <v-container class="checkbox_container">
                                <v-checkbox class="checkbox" v-model="filtros.checks.aire_acondicionado" label="AC"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.calefaccion" label="Calefacción"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.jardin" label="Jardín"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.terraza" label="Terraza"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.piscina" label="Piscina"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.trastero" label="Trastero"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.zonas_infantiles" label="Zonas infantiles"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.habitacion_servicio" label="Habitación de servicio"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.soleado" label="Soleado"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.cerca_metro" label="Metro/FGC"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.mascotas" label="Admite mascotas"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="filtros.checks.garaje" label="Garaje"></v-checkbox>
                            </v-container>
                        </v-row>
                    </v-container>
                    <button class="boton cta mb-4 w-100" @click="panelViviendas = !panelViviendas">Filtrar</button>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'PanelFiltrosViviendas',
	props: {
        usuario: Object,
        prefiltros: Object,
        num_viviendas_filtradas: Number
    },

    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            zonas: [],
            filtro: "",
            filtros: {
                filtro_zona: '',
                disponibilidad: true,
                precio: [0, 15000],
                metros: [0, 1000],
                habitaciones: 0,
                baños: 0,
                equipamiento: '',
                estado: '',
                tipo: {
                    casa: false,
                    piso: false,
                },
                estilo: {
                    clasico: false,
                    moderno: false,
                    otro: false
                },
                checks: {
                    aire_acondicionado: false,
                    calefaccion: false,
                    jardin: false,
                    terraza: false,
                    piscina: false,
                    trastero: false,
                    zonas_infantiles: false,
                    habitacion_servicio: false,
                    soleado: false,
                    cerca_metro: false,
                    mascotas: false,
                    garaje: false
                }
            },
            panelViviendas: 1
        }
    },

    watch: {
        filtros: {
            handler(newComponents, oldComponents) {
                this.filtrarViviendas();
            },
            deep: true
        },
        filtro() {this.filtrarViviendas();},
        viviendas() {this.crearFiltrosBarrios();}
    },

	computed: {
        ...mapState([
            'viviendas'
        ]),
    },

    methods: {
        filtrarViviendas() {
            let checks = [];
            let tipo = [];
            let estilo = [];
            for (let check in this.filtros.checks) {
                if (this.filtros.checks[check]) {checks.push(check)}
            };
            for (let est in this.filtros.estilo) {
                if (this.filtros.estilo[est]) {estilo.push(est)}
            };
            for (let tip in this.filtros.tipo) {
                if (this.filtros.tipo[tip]) {tipo.push(tip)}
            };

            let filtros = {
                'filtro': this.filtro,
                'barrio': this.filtros.filtro_zona ? this.filtros.filtro_zona.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '',
                'precio_min': this.filtros.precio[0],
                'precio_max': this.filtros.precio[1] === 15000 ? 10000000000 : this.filtros.precio[1],
                'metros_min': this.filtros.metros[0],
                'metros_max': this.filtros.metros[1] === 1000 ? 100000 : this.filtros.metros[1],
                'habitaciones': this.filtros.habitaciones,
                'baños': this.filtros.baños,
                'equipamiento': this.filtros.equipamiento ? this.filtros.equipamiento : '',
                'estado': this.filtros.estado ? this.filtros.estado : '',
                'tipo': tipo,
                'estilo': estilo,
                'checks': checks,
                'disponibilidad': this.filtros.disponibilidad
            }
            this.$emit('filtrarViviendas',  filtros, [this.filtro, this.filtros]);
        },
        resetearFiltros() {
            this.filtros = {
                filtro_zona: '',
                disponibilidad: true,
                precio: [0, 15000],
                metros: [0, 1000],
                habitaciones: 0,
                baños: 0,
                equipamiento: '',
                estado: '',
                tipo: {
                    casa: false,
                    piso: false,
                },
                estilo: {
                    clasico: false,
                    moderno: false,
                    otro: false
                },
                checks: {
                    aire_acondicionado: false,
                    calefaccion: false,
                    jardin: false,
                    terraza: false,
                    piscina: false,
                    trastero: false,
                    zonas_infantiles: false,
                    habitacion_servicio: false,
                    soleado: false,
                    cerca_metro: false,
                    mascotas: false,
                    garaje: false
                }
            };
            this.filtro = "";
        },
        crearFiltrosBarrios() {
            this.viviendas.forEach(vivienda => {
                let zona_existe = this.zonas.find(zona => zona === vivienda.barrio);
                if (!zona_existe) {
                    this.zonas.push(vivienda.barrio);
                }
            });
        }
	},
    created: function() {
        if (this.prefiltros) {
            this.filtro = this.prefiltros[0];
            this.filtros = this.prefiltros[1];
        }
        this.crearFiltrosBarrios();
        // if (this.usuario) {
        //     this.usuario.preferencias.vivienda.habitaciones ? this.filtros.habitaciones = this.usuario.preferencias.vivienda.habitaciones : '';
        //     this.usuario.preferencias.vivienda.metros ? this.filtros.metros[0] = this.usuario.preferencias.vivienda.metros : '';
        //     this.usuario.preferencias.traslado.presupuesto && typeof this.usuario.preferencias.traslado.presupuesto === 'number' ? this.filtros.precio[1] = this.usuario.preferencias.traslado.presupuesto : '';
        //     this.filtros.tipo.casa = this.usuario.preferencias.vivienda.tipo.casa;
        //     this.filtros.tipo.piso = this.usuario.preferencias.vivienda.tipo.piso;

        //     this.filtros.estilo.clasico = this.usuario.preferencias.vivienda.estilo.clasico;
        //     this.filtros.estilo.moderno = this.usuario.preferencias.vivienda.estilo.moderno;

        //     for (let caracteristica in this.usuario.preferencias.vivienda.caracteristicas) {
        //         this.filtros.checks[caracteristica] = this.usuario.preferencias.vivienda.caracteristicas[caracteristica];
        //     }
        // }
        
    },
    mounted: function() {}
}
</script>
<style scoped lang="scss">
.v-application #app .filtros {
    .viviendas-expansion-panel {
        display: none;
    }
    .reset {
        color: var(--purple03);
        text-align: right;
        width: 100%;
        cursor: pointer;
        &:hover {font-weight: 500;}
    }
    .nav_link {
        padding-left: 0;
        padding-bottom: 0;
        width: 100%;
        color: var(--gray04);
        font-weight: bold;
        font-size: 1em;
    }
    .tipo {
        padding-left: 0;
        ::v-deep .v-input--selection-controls__input {
            margin-right: 1em;
        }
    }
    .estado {
        padding-left: 0;
        ::v-deep .v-radio {
            width: calc(50% - 1em);
            .v-input--selection-controls__input {
                margin-right: .5em;
            }
        }
    }
    .numeros .col {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .checkbox_container {
        display: flex;
        flex-wrap: wrap;
        .checkbox {
            width: 100%; 
            margin-top: 0;
            margin-bottom: .5em;
        }
        &.radio {
            width: 100%;
            .checkbox {margin-right: 0;}
        }
    }
    .range {
        width: 100%;
        margin: .3em 0;
        ::v-deep .v-slider__thumb-container {
            cursor: grab;
        }
    }
    .sin_padding {padding-left: 0;}

    .small {
        margin-bottom: 20px;
    }
}
 @media (max-width: 900px) {
     .v-application #app .filtros {
        .viviendas-expansion-panel {
            display: block;
        }
        .viviendas-list {
            display: none;
        }
        .checkbox_container {
            .checkbox {
                width: 50%; 
                margin-top: 0;
                margin-bottom: -.5em;
            }
        }
     }
 }

</style>
