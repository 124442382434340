import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import DatetimePicker from 'vuetify-datetime-picker'
import VueI18n from 'vue-i18n'
import messages from './locales/locales'
import VueExcelXlsx from "vue-excel-xlsx";
import './registerServiceWorker'
//General styles
import "@/css/styles.scss"

Vue.config.productionTip = false;
const userLang = navigator.language || navigator.userLanguage; 
let localeGlobal = userLang === 'es-ES' ? 'es' : 'en';

//Use i18n 
Vue.use(VueI18n);
Vue.use(DatetimePicker);
Vue.use(VueExcelXlsx);

const i18n = new VueI18n({
  locale: localeGlobal, // set locale,
  messages
});

moment.locale(localeGlobal);
Vue.prototype.$moment = moment;

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
