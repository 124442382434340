<template>
    <div id="modal" class="modal">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <div class="submodal">
            <svg class="cerrar_modal" @click="cerrarModal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <div class="caja_consejeras">
                <div class="consejera" v-for="(consejera, index) in consejeras" :key="index" 
                    @click="elegir(consejera)"
                    :class="{active: consejera.elegida}"
                >
                    <img class="avatar" :src="consejera.personales.foto">
                    <p class="parrafo">{{consejera.personales.nombre + ' ' + consejera.personales.apellidos}}</p>
                </div>
            </div>
            <p class="titulo">{{!modo_update ? 'Consejeras seleccionadas:' : 'Consejera seleccionada'}}</p>
            <div class="caja_seleccionadas" :class="{modo_update: modo_update}">
                <div class="card consejera" v-for="(consejera, index) in elegidas" :key="index" @click="elegir(consejera)">
                    <img class="avatar" :src="consejera.personales.foto">
                    <p class="parrafo">{{consejera.personales.nombre}}</p>
                    <svg class="cerrar" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
                    </svg>
                </div>
            </div>
            <p class="parrafo" v-if="!modo_update">
                Se le asignará a la primera consejera, si no acepta en un plazo de 2h,<br>
                se le asignará a la siguiente y así hasta la 3ª consejera
            </p>
            <p class="parrafo" v-else>
                Se le asignará automáticamente la consejera seleccionada.
            </p>
            <v-btn class="boton cta" :loading="loading" :disabled="!elegidas.length" @click="asignarTRL">Asignar TRL</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'

export default {
	name: 'ModalAsignarConsejera',
    props: ["usuario"],
    components: {Service},
    data() {
        return {
            consejeras: [],
            elegidas: [],
            loading: false,
            loader: null,
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 5000)
            this.loader = null
        },
    },

	computed:  {
        ...mapState([
            'usuarios', 'token'
        ]),
        modo_update() {
            return (this.usuario.consejera && this.usuario.consejera.id != 1) || this.usuario.consejera.asignadas.find(cn => cn.status == 2 || cn.status == 3) ? true : false;
        }
	},

    methods: {
        cerrarModal() {
            this.$emit("closeModalConsejera", true);
        },
        asignarTRL() {
            this.loader = 'loading';
            let trl = this.usuario;
            let calls = [];
            if (!this.modo_update) {
                this.elegidas.forEach((cn, index) => {
                    calls.push({
                        ws: 'trlConsRegister',
                        data: {trl_id: trl.id, cons_id: cn.id, cons_order: index + 1}
                    })
                });
                
                trl.consejera = {
                    id: 1,
                    asignadas: this.elegidas
                }
            } else if (this.modo_update) {
                const excons = trl.consejera.id;
                const nueva_cons = this.elegidas[0].id;

                if (excons) {
                    calls.push({
                        ws: 'trlConsUpdate',
                        data: {trl_id: trl.id, cons_id: excons, cons_order: 1, accepted: 'no'}
                    });
                }

                if (trl.consejera.asignadas.find(cn => cn.id == nueva_cons)) {
                    calls.push({
                        ws: 'trlConsUpdate',
                        data: {trl_id: trl.id, cons_id: nueva_cons, cons_order: 1, accepted: 'yes'}
                    })
                } else {
                    calls.push({
                        ws: 'trlConsRegister',
                        data: {trl_id: trl.id, cons_id: nueva_cons, cons_order: 1, accepted: 'yes'}
                    })
                }
                this.usuario.consejera.id = nueva_cons;
            }
            this.data = {calls: calls};
            this.ws_spinner = true;
            this.ws = 'multiple';
        },
        elegir(consejera) {
            if (!this.modo_update) {
                if (this.elegidas.length < 3) {
                    consejera.elegida = !consejera.elegida;
                    if (consejera.elegida) {
                        this.elegidas.push(consejera);
                    } else {
                        const index = this.elegidas.map(function(e) { return e.id; }).indexOf(consejera.id);
                        this.elegidas.splice(index, 1);
                    }
                } else if (consejera.elegida)  {
                    consejera.elegida = !consejera.elegida;
                    const index = this.elegidas.map(function(e) { return e.id; }).indexOf(consejera.id);
                    this.elegidas.splice(index, 1);
                }
            } else if (this.modo_update) {
                if (this.elegidas.length < 1) {
                    consejera.elegida = !consejera.elegida;
                    if (consejera.elegida) {
                        this.elegidas.push(consejera);
                    } else {
                        const index = this.elegidas.map(function(e) { return e.id; }).indexOf(consejera.id);
                        this.elegidas.splice(index, 1);
                    }
                } else if (consejera.elegida)  {
                    consejera.elegida = !consejera.elegida;
                    const index = this.elegidas.map(function(e) { return e.id; }).indexOf(consejera.id);
                    this.elegidas.splice(index, 1);
                }
            }
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            let cons = [];
            this.elegidas.forEach((el, index) => {
                let con = {
                    cons_order: index + 1,
                    cons: {id: el.id }
                };
                cons.push(con);
            });
            if (response.ws === 'multiple') {
                this.usuario.consejera.asignadas = cons;
                // console.log('consejera uploaded');
                // console.log(response.data);
                this.$emit("closeModalConsejera", true);
            } 
        }
	},
    created: function() {
        this.console = window.console;
        this.consejeras = this.usuarios.filter(usuario => usuario.tipo === 'Admin' || usuario.tipo === 'Consejera');
        this.consejeras.forEach(consejera => consejera.elegida = false);
    },
    mounted: function() {
    }
}
</script>
<style scoped lang="scss">
#modal .submodal {
    .caja_consejeras, .caja_seleccionadas {
        display: flex;
        flex-wrap: wrap;
        padding: 1em;
        .consejera {
            position: relative;
            width: 25%;
            display: flex;
            align-items: center;
            margin: 1em 0;
            &.active {
                opacity: .5;
            }
            .avatar {width: 4em; height: 4em; object-fit: cover; margin-right: 1em;}
        }
    }
    .caja_consejeras {
        cursor: pointer;
    }
    .caja_seleccionadas {
        background-color: var(--gray01);
        padding: 2em;
        justify-content: flex-start;
        min-height: 9em;
        .card.consejera {
            height: fit-content;
            margin-bottom: 0;
            padding: .5em 1em;
            &:not(:last-child) {margin-right: 12%;}
            .cerrar {
                cursor: pointer;
                margin-left: auto;
                padding: .2em;
            }
        }
        &.modo_update {
            width: 25%;
            min-width: 20em;
            margin: 0 auto;
            .card.consejera {width: 100%}
        }
    }
    &>.parrafo {
        width: 75%;
        margin: 1em auto 0;
        // color: var(--gray04);
    }
}

 @media (max-width: 900px) {
     #modal .submodal {
        .caja_consejeras {
            padding: 2em 1em;
            .consejera {
                width: 33%;
            }
        }
        .caja_seleccionadas {
            .card.consejera {
                width: 100%;
                margin: 0 0 1.5em;
                &:not(:last-child) {margin-right: 0;}
            }
        }
     }
 }

 @media (max-width: 760px) {
    #modal .submodal {
        .caja_consejeras, .caja_seleccionadas {
            .consejera {
                width: 50%;
            }
        }
    }
 }
</style>
