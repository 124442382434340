<template>
	<div id="login">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <div class="wrapper-login">
            <div class="izda">
                <img class="logo" src="images/logo.png" />
                <div class="step_login">
                    <div class="idioma" @click="changeLang">
                        <img v-if="idioma === 'es'" class="flag" src="images/flag_en.svg" />
                        <img v-else class="flag" src="images/flag_es.svg" />
                        <p class="parrafo">{{idioma === 'es' ? 'Switch to English' : 'Cambiar a español'}}</p>
                    </div>
                    <p class="titulo" v-if="!reset_password">{{$t(localeRoute + 'area')}}</p>
                    <p class="parrafo" v-if="!reset_password">
                        {{ $t(localeRoute + 'somos')}}
                    </p>
                    <v-form class="form" v-if="!this.$route.query['userId']" v-model="valid">
                        <p v-if="error" class="error_msj">{{error}}</p>
                        <p v-if="info" class="info_msj">{{info}}</p>
                        <p class="parrafo instrucciones" v-if="reset_password">{{$t(localeRoute + 'escribeLaDireccionDeEmail')}}</p>
                        <v-text-field
                            :label="$t(localeRoute + 'email')"
                            :placeholder="$t(localeRoute + 'email')"
                            v-model="nombre_usuario"
                            v-on:blur="error = ''"
                            :rules='[rules.required, rules.email]'
                            ref="email" 
                            required
                        ></v-text-field>
                        <v-text-field
                            v-if="!reset_password"
                            :label="$t(localeRoute + 'contraseña')"
                            :placeholder="$t(localeRoute + 'contraseña')"
                            v-model="contraseña"
                            v-on:blur="error = ''"
                            @keyup.enter="login"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show = !show"
                            :type="show ? 'text' : 'password'"
                            :rules="[rules.required]"
                            required
                        ></v-text-field>
                        <v-btn class="boton" v-if="!reset_password" @click="login" :loading="loading">{{$t(localeRoute + 'acceder')}}</v-btn>
                        <v-btn class="boton" v-else @click="resetPassword" :loading="loading">{{$t(localeRoute + 'recuperar')}}</v-btn>
                        <p class="small reset" @click="reset_password = !reset_password">{{!reset_password ? $t(localeRoute + 'hasolvidado') : $t(localeRoute + 'volverallogin')}}</p>
                    </v-form>
                    <v-form class="form" v-else-if="usuario">
                        <p v-if="error" class="error_msj">{{error}}</p>
                        <p class="parrafo"><b>{{$t(localeRoute + 'tunombre')}}</b> {{nombre_usuario}}</p>
                        <v-text-field
                            :label="!usuario.user_status ? $t(localeRoute + 'creacontraseña') : $t(localeRoute + 'creanuevacontraseña')"
                            v-model="contraseña"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show = !show"
                            @keyup.enter="activateUser"
                            :type="show ? 'text' : 'password'"
                            :hint="$t(localeRoute + 'hard')"
                            :rules="[rules.required, rules.hard]"
                            required
                        ></v-text-field>
                        <v-btn v-if="!usuario.user_status" class="boton" @click="activateUser" :loading="loading">{{$t(localeRoute + 'acceder')}}</v-btn>
                        <v-btn v-else class="boton" @click="rememberPasword" :loading="loading">{{$t(localeRoute + 'restablecer')}}</v-btn>
                    </v-form>
                </div>
            </div>
            <div class="dcha">
                <img src="images/foto_login.jpg" />
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import {webServices} from '@/api/api.js'
import Service from '@/components/Service.vue'
import {locale} from '@/main.js'

export default {
	name: 'Login',
	components: {Service},
	props: ['auto_login'],

    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            usuario: null,
            user_id: null,
            nombre_usuario: null,
            contraseña: null,
            error: '',
            info: '',
            valid: true,
            idioma: 'en',
            loader: null,
            loading: false,
            show: false,
            reset_password: false,
            rules: {
                required: value => !!value || '',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t(this.localeRoute + 'mailnovalido');
                },
                min: v =>  v ? v.length >= 4 || '' : '',
                hard: v => {
                    const pattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/;
                    return pattern.test(v) || this.$t(this.localeRoute + 'nosegurahard');
                }
            },
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 5000)

            this.loader = null;
        }
    },
	computed: {
        ...mapState([
            'usuarios', 'usuario_loggeado', 'token', 'started_point', 'idiomaGlobal', 'servicios'
        ]),
    },

    created: function() {
        console.log(locale);
        this.console = window.console;
        this.user_id = this.$route.query['userId'];
        if (this.user_id) {
            // this.$store.dispatch('setLoading', true);
            this.getUser();
        }

        this.idioma = this.idiomaGlobal;

        if (localStorage.getItem('setStartedPoint')) {
            this.$store.dispatch('setStartedPoint', localStorage.getItem('setStartedPoint'));
            localStorage.removeItem('setStartedPoint');
        }
    },
    mounted: function() {
        setTimeout(() => {
            this.$refs.email.focus();
        }, 500);
    },

    methods: {
        changeLang() {
            this.idioma = this.idioma === 'es' ? 'en' : 'es';
            this.$store.dispatch('setIdioma', this.idioma);
            this.$moment.locale(this.idioma);
            this.$i18n.locale = this.idioma;
            this.$vuetify.lang.current = this.idioma;
        },
        login() {
            if (this.nombre_usuario && this.contraseña) {
                this.loader = 'loading';
                if (localStorage.getItem('toquen')) {
                    this.data = {username: this.nombre_usuario, password: this.contraseña};
                    this.ws = 'userLogin';
                } else {
                    let ws = webServices.getToken();
                    fetch(ws.url, ws.requestOptions)
                        .then(response => {return response.text();})
                        .then(result => {
                            localStorage.setItem('toquen', result);
                            this.$store.dispatch('setToken', result);
                            this.data = {username: this.nombre_usuario, password: this.contraseña};
                            this.ws = 'userLogin';
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                this.error = this.$t(this.localeRoute + 'usuariocontraseñaincorrecto');
            }
        },
        loginOk() {
            if (!this.servicios.length) {
                this.ws = 'serviceList';
            } else {
                let ws = webServices.getUser(this.token, this.user_id);
                let intentos = 0;
                let getUserPostLogin = () => {
                    fetch(ws.url, ws.requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        this.usuario = JSON.parse(result).data;
                        this.$store.dispatch('setUsuarioLoggeado', this.usuario);
                        localStorage.setItem('isLogged', true);
                        localStorage.setItem('usuario_logged', this.usuario.encrypted_id);
                        this.$emit("logged");
                        if (this.usuario.type === 'admin' || this.usuario.type === 'cons') {
                            this.idioma = 'en';
                            this.changeLang();
                        }
                        if (!this.started_point)  {
                            if (this.usuario.type === 'legal') this.$router.push('Usuarios');
                            else {this.$router.push('Home');}
                        } else {
                            this.$router.push({path: this.started_point});
                        }
                    })
                    .catch(error => {
                        this.$emit('error', error);
                        this.$store.dispatch('setLoading', true);
                        if (intentos < 2) {
                            intentos++;
                            getUserPostLogin();
                        } else {
                            this.$store.dispatch('setLoading', false);
                            this.$emit('errorImportante', error);
                        }
                    });
                }
                getUserPostLogin();
            }
        },
        //Para la activación o el restablecimiento de contraseña
        getUser() {
            let ws = webServices.getToken();
            fetch(ws.url, ws.requestOptions)
                .then(response => {return response.text();})
                .then(result => {
                    this.$store.dispatch('setToken', result);
                    this.ws_spinner = true;
                    this.data = this.user_id;
                    this.ws = 'getUserEnc';
                })
        },
        resetPassword() {
            this.loader = 'loading';
            if (localStorage.getItem('toquen')) {
                this.data = this.nombre_usuario;
                this.ws = 'remindPassword';
            } else {
                let ws = webServices.getToken();
                fetch(ws.url, ws.requestOptions)
                    .then(response => {return response.text();})
                    .then(result => {
                        localStorage.setItem('toquen', result);
                        this.$store.dispatch('setToken', result);
                        this.data = this.nombre_usuario;
                        this.ws = 'remindPassword';
                    })
            }
        },
        rememberPasword(){
            this.loader = 'loading';
            this.data = {id: this.usuario.id, new_password: this.contraseña};
            this.ws = 'remindPasswordFromReset';
        },
        activateUser() {
            this.loader = 'loading';
            this.data = {language: this.idioma, user_id: this.user_id};
            this.ws = 'activateUser';
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            // this.$store.dispatch('setLoading', false);
            if (response.ws === 'getUserEnc') {
                this.usuario = response.data;
                this.user_id = this.usuario.id;
                this.nombre_usuario = this.usuario.email;
            } else if (response.ws === 'userLogin') {
                if (response.data.id) {
                    this.user_id = response.data.id;
                    this.loginOk();
                } else {
                    this.loading = false;
                    if (response.data === 'Incorrect password.') {
                        this.error = this.$t(this.localeRoute + 'usuariocontraseñaincorrecto');
                    } else if (response.data === "Username was not found or doesn't exist.") {
                        this.error = this.$t(this.localeRoute + 'usuariocontraseñaincorrecto');
                    } else if (response.data === "User not active.") {
                        this.error = this.$t(this.localeRoute + 'usuarioinactivo');
                    } else {
                        this.error = this.$t(this.localeRoute + 'demasiadosintentos');
                    }
                }
            } else if (response.ws === 'activateUser') {
                this.data = {user_id: this.user_id ,password: this.contraseña, old_password: ''};
                this.ws = 'resetPassword';
            } else if (response.ws === 'remindPassword') {
                this.loading = false;
                this.reset_password = !this.reset_password;
                this.info = this.$t(this.localeRoute + 'setehaenviadounemail');
            } else if (response.ws === 'resetPassword') {
                this.login();
            } else if (response.ws === 'serviceList') {
                this.$store.dispatch('setServicios', response.data);
                this.loginOk();
            } else if (response.ws === 'remindPasswordFromReset') {

                this.loading = false;
                this.$router.replace('/');
                this.nombre_usuario = '';
                this.contraseña = '';
                this.info = this.$t(this.localeRoute + 'tuContrasenaSeHaRestaurado');
            }
        }
	}
}
</script>
<style scoped lang="scss">
#login {
    position: relative;
    width: 100vw;
    // min-height: 100vh;
    padding: 6% 1em 6% 15vw;
    background-image: url("/images/fondo_login.png");
    background-size: 100%;
    .wrapper-login {
        display: flex;
        justify-content: space-between;
    }
    .izda {
        flex: 1 1 27em;
        max-width: 33em;
        .logo {width: 20em; margin-bottom: 2em;}
        .titulo {
            font-size: 2.5em;
        }
        .idioma {
            display: flex;
            align-items: center;
            margin: 2em 0;
            cursor: pointer;
            .flag {
                width: 2em;
                margin-right: 1em;
            }
            .parrafo {margin: 0; border-bottom: 1px solid var(--color_texto)}
        }
        .parrafo {
            margin: 1em 0 2em;
            &.instrucciones {
                background-color: var(--gray02);
                padding: .8em;
                border-radius: 5px;
            }
        }
        .checkbox {width: 45%!important;}
        .boton {width: 100%; margin-top: 1.5em;}
        .small {
            margin-top: 1.5em;
            &.reset {
                color: var(--purple03);
                margin-bottom: .5em;
                cursor: pointer;
            }
            &.volver {color: var(--gray04);}
        }
        .info_msj {
            background-color: #27AE60;
            color: white;
            padding: .7em 1em;
            text-align: center;
            border-radius: 3px;
            min-width: 12em;
        }
    }
    .dcha {
        flex: 0 1 40vw;
        margin-left: 7em;
        img {
            position: relative;
            top: 20vh;
            width: 80%;
            max-width: 25rem;
            min-width: 14rem;
        }
    }
}
@media (max-width: 760px) {
    #login {
        padding: 6% 10%;
        background-image: none;
        .izda {
            width: 100%;
            .logo{
                width: 10em;
                margin-bottom: 0;
            }
        }
        .dcha {
            display: none;
        }
    }
}
</style>
