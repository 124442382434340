<template>
    <div class="panel_izda consejera">
        <div class="bienvenida">
            <p class="titulo">
                {{ $t(localeRoute + "hola") }}<br>
                {{usuario_loggeado.personales.nombre}}
            </p>
            <p v-if="getEmpresa() && usuario_loggeado.tipo === 'TRL'" class="parrafo" v-html="$t(localeRoute + 'nuestracompania', { empresa: getEmpresa().nombre })"></p>
            <p v-else-if="usuario_loggeado.tipo === 'RRHH'" class="parrafo" v-html="$t(localeRoute + 'nuestracompania_rrhh', { empresa: getEmpresa().nombre })"></p>
            <img v-if="getEmpresa() && getEmpresa().logo" class="empresa" :src="getEmpresa().logo" />
        </div>
        <div class="asignada" v-if="usuario_loggeado.tipo === 'TRL' && usuario_loggeado.consejera.id && usuarios.length">
            <span class="fondo"></span>
            <p class="parrafo bold">{{ $t(localeRoute + "consejeraasignada") }}</p>
            <div class="foto-info-wrapper">
                <img class="foto" :src="consejera.personales.foto"/>
                <div class="asignada-info">
                    <p class="parrafo bold">
                        {{consejera.personales.nombre + " " + consejera.personales.apellidos}}
                    </p>
                    <p class="parrafo">
                        <img class="icon" src="images/icon-email.svg">
                        <a :href="'mailto:' + consejera.contacto.email_profesional" target="_blank">{{consejera.contacto.email_profesional}}</a>
                    </p>
                    <p class="parrafo">
                        <img class="icon" src="images/icon-telf.svg">
                        <a :href="'telf:' + consejera.contacto.telefono" target="_blank">{{consejera.contacto.telefono}}</a>
                    </p>
                </div>
            </div>
        </div>
        <div  v-if="usuario_loggeado.tipo === 'TRL'" class="button-wrapper">
            <button class="boton" @click="openPreferencias">{{ $t(localeRoute + "preferencias") }}</button>
            <button class="boton secundario" @click="openServicios">{{ $t(localeRoute + "misservicios") }}</button>
        </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'PanelConsejera',

    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`
        }
    },

    watch: {
    },

	computed: {
        ...mapState([
            'usuario_loggeado', 'usuarios', 'empresas'
        ]),
        consejera() {
            let id = this.usuario_loggeado.consejera.id;
            let usuario = this.usuarios.find( usuario => usuario.id == id );
            !usuario ? usuario = this.usuarios.find( usuario => usuario.tipo === 'Admin' && usuario.nombre === 'Lydia' ) : '';
            return usuario;
        }
    },
    created: function() {},
    mounted: function() {},
    methods: {
        // getConsejera() {
        //     let id = this.usuario_loggeado.consejera.id;
        //     let usuario = {};
        //     if (id) {
        //         usuario = this.usuarios.find( usuario => usuario.id == id );
        //     } else {
        //         usuario = this.usuario.find(usuario => usuario.id == 8);
        //     }
        //     return usuario;
        // },
        getEmpresa() {
            let id = this.usuario_loggeado.personales.empresa;
            let empresa = this.empresas.find( empresa => empresa.id == id );
            return empresa;
        },
        openPreferencias() {
            this.$emit("openPreferencias", true);
        },
        openServicios() {
            this.$emit("openServicios", true);
        }
	}
}
</script>
<style scoped lang="scss">
.v-application #app .consejera {
    position: relative;
    .bienvenida {        
        .titulo {
            font-size: 2em;
            line-height: 1.2em;
        }
        .parrafo {
            margin: 1em 0 .5em;
            b {color: var(--purple03);}
        }
        .empresa {width: 30%; margin-bottom: 2em;}
    }
    .asignada {
        position: relative;
        padding-bottom: 1em;
        margin-bottom: 1.5em;
        .bold {font-size: 1.1em;}
        .fondo {
            position: absolute;
            width: 110%;
            height: 80%;
            z-index: -1;
            left: -2em;
            bottom: 0;
            background-color: var(--purple01);
        }
        p {margin: 1em 0;}
        .foto {
            width: 9.5em;
            height: 9.5em;
            box-shadow: var( --sombra_ext);
            object-fit: cover;
        }
        .parrafo {
            display: flex;
            align-items: center;
            overflow-x: hidden;
            a {
                color: var(--color_texto);
                text-overflow: ellipsis;
            }
            .icon {
                margin-right: .5em;
                height: 1em;
                width: 1em;
                object-fit: contain;
            }
        }
    }
    .boton {
        width: 100%;
        margin: .5em 0;
    }
}
 @media (max-width: 900px) {
    .v-application #app .consejera {
        .bienvenida {
            display: none;
            .titulo {
                margin-right: 15px;
            }
            .parrafo {
                margin: 0 15px 0 0;
            }
            .empresa {
                width: 10%;
            }
        }
        .asignada {
            margin-bottom: 0.5em;
            padding-bottom: 0.5em;
            p {margin: .5em 0;}
            .foto-info-wrapper {
                display: flex;
                align-items: center;
                .foto{
                    max-width: 15%;
                    height: 80px;
                    margin-right: 20px;
                }
            }
        }
        .button-wrapper {
            display: flex;
            justify-content: space-between;
            .boton {
                min-width: 10px;
                width: 45%;
            }
        }
    }
 }
</style>
