<template>
    <div id="modalSimple" class="modal">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <div class="submodal" :class="tipo">
            <svg class="cerrar_modal" v-if="tipo != 'respuesta_cons'" @click="cerrarModal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <div v-if="success === 'contraseña'" class="contenido eliminar">
                <p class="titulo bold">{{$t(localeRoute + 'cambiarcontrasena')}}</p>
                <div class="boton fab check">
                    <v-icon class="icono">mdi-check</v-icon>
                </div>
                <p class="parrafo">{{$t(localeRoute + 'tucontrasenase')}}</p>
            </div>
            <div v-else-if="tipo === 'link_usuario'" class="contenido">
                <p class="parrafo">Se ha creado al {{usuario.tipo}}</p>
                <p class="titulo bold">{{usuario.personales.nombre + ' ' + usuario.personales.apellidos}}</p>
                <p class="parrafo">Copia el siguiente texto para incluir el link de acceso del TRL en el email de bienvenida.</p>
                <p class="parrafo link">
                    <input class="link_copy" type="text" :value="`https://stepsrelocation-panel.com/#/?userId=${enc_id}`" />
                    <v-icon class="icon" @click="copyToClipboard">mdi-content-copy</v-icon>
                </p>
                <button class="boton cta" @click="cerrarModalLink">Cerrar</button>
            </div>
            <div v-else-if="tipo === 'borrar_vivienda'" class="contenido eliminar">
                <p class="titulo bold">Eliminar vivienda</p>
                <p class="parrafo">¿Seguro que quieres eliminar esta vivienda?</p>
                <div class="botones">
                    <button class="boton secundario" @click="cerrarModal">No</button>
                    <button class="boton cta" @click="eliminarVivienda">Sí</button>
                </div>
            </div>
            <div v-else-if="tipo === 'borrar_usuario'" class="contenido eliminar">
                <p class="titulo bold">Eliminar usuario</p>
                <p class="parrafo">¿Seguro que quieres eliminar el usuario <b>{{usuario.personales.nombre + ' ' + usuario.personales.apellidos}}</b>?</p>
                <div class="botones">
                    <button class="boton secundario" @click="cerrarModal">No</button>
                    <button class="boton cta" @click="eliminarUsuario">Sí</button>
                </div>
            </div>
            <div v-else-if="tipo === 'borrar_empresa'" class="contenido eliminar">
                <p class="titulo bold">Eliminar empresa</p>
                <p class="parrafo">¿Seguro que quieres eliminar <b>{{empresa.nombre}}</b>?</p>
                <div class="botones">
                    <button class="boton secundario" @click="cerrarModal">No</button>
                    <button class="boton cta" @click="eliminarEmpresa">Sí</button>
                </div>
            </div>
            <div v-else-if="tipo === 'borrar_servicio' || tipo === 'borrar_paquete'" class="contenido eliminar">
                <p class="titulo bold">Eliminar {{servicio.tareas_en ? 'sevicio' : 'paquete'}}</p>
                <p class="parrafo">¿Seguro que quieres eliminar <b>{{servicio.tareas_en ? servicio.nombre_es : servicio.nombre}}</b>?</p>
                <div class="botones">
                    <button class="boton secundario" @click="cerrarModal">No</button>
                    <button class="boton cta" @click="eliminarServicio">Sí</button>
                </div>
            </div>
            <div v-else-if="tipo === 'borrar_archivo'" class="contenido eliminar">
                <p class="titulo bold">Eliminar archivo</p>
                <p class="parrafo">¿Seguro que quieres eliminar el archivo <b>{{archivo.nombre}}</b>?</p>
                <div class="botones">
                    <button class="boton secundario" @click="cerrarModal">No</button>
                    <button class="boton cta" @click="eliminarArchivo">Sí</button>
                </div>
            </div>
            <div v-else-if="tipo === 'cancelar_servicio'" class="contenido eliminar">
                <p class="titulo bold">{{servicio.estado === 'cancelado' ? 'Reanudar servicio' : 'Cancelar servicio'}}</p>
                <p class="parrafo">
                    ¿Seguro que quieres {{servicio.estado === 'cancelado' ? 'reanudar' : 'cancelar'}} el servicio <b>{{servicio.nombre}}</b>?<br><br>
                    <b v-if="servicio.estado != 'cancelado'">Se eliminarán todas las tareas asociadas a el servicio.</b>
                </p>
                <div class="botones">
                    <button class="boton secundario" @click="cerrarModal">No</button>
                    <button class="boton cta" @click="cancelarServicio">Sí</button>
                </div>
            </div>
            <div v-else-if="tipo === 'cambiar_contraseña'" class="contenido eliminar">
                <p class="titulo bold">{{$t(localeRoute + 'cambiarcontrasena')}}</p>
                <v-form>
                    <p v-if="error" class="error_msj">{{error}}</p>
                    <v-text-field
                        :label="$t(localeRoute + 'contrasenaactual')"
                        v-model="contrasena_actual"
                        v-on:blur="error = ''"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show = !show"
                        :type="show ? 'text' : 'password'"
                        :hint="$t(localeRoute + 'porlomenos4')"
                        :rules="[rules.required, rules.min]"
                        required
                    ></v-text-field>
                    <v-text-field
                        :label="$t(localeRoute + 'nuevacontrasena')"
                        v-model="contrasena_nueva"
                        v-on:blur="error = ''"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                        :type="show1 ? 'text' : 'password'"
                        hint="At least 4 characters"
                        :rules="[rules.required, rules.min]"
                        required
                    ></v-text-field>
                    <v-text-field
                        :label="$t(localeRoute + 'repitelanueva')"
                        v-model="contrasena_nueva_repetir"
                        v-on:blur="error = ''"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show2 = !show2"
                        :type="show2 ? 'text' : 'password'"
                        hint="At least 4 characters"
                        :rules="[rules.required, rules.min]"
                        required
                    ></v-text-field>
                </v-form>
                <div class="botones">
                    <button class="boton secundario" @click="cerrarModal">{{$t(localeRoute + 'cancelar')}}</button>
                    <button class="boton cta" @click="cambiarContrasena">{{$t(localeRoute + 'cambiar')}}</button>
                </div>
            </div>
            <div v-else-if="tipo === 'respuesta_cons'" class="contenido eliminar">
                <p class="titulo centered">Has {{respuesta === 'yes' ? 'aceptado' : 'rechazado'}} ser consejera de {{usuario}}</p>
                <button class="boton cta center" @click="cerrarModalCons">De acuerdo</button>
            </div>
            <div v-else-if="tipo === 'trl_finalizado'" class="contenido finalizado">
                <p class="titulo bold">¡TRL finalizado!</p>
                <p class="parrafo"><b>Se han completado todos los servicios</b> del TRL "{{usuario}}"</p>
                <div class="caja_destacada">
                    <v-checkbox class="check" v-model="enviar_encuesta" label="Enviar formulario de satisfacción."></v-checkbox>
                </div>
                <button class="boton cta center" @click="cerrarTRL">De acuerdo</button>
            </div>
            <div v-else-if="tipo === 'cerrar_trl'" class="contenido finalizado">
                <p class="titulo bold">{{usuario.estado == 0 ? 'Cerrar TRL' : 'Reactivar TRL'}}</p>
                <div v-if="usuario.estado == 0">
                    <p class="parrafo">¿Seguro que quieres cerrar el usuario <b>{{usuario.personales.nombre + ' ' + usuario.personales.apellidos}}</b>?</p>
                    <p class="parrafo">Podrás reactivarlo en cualquier momento desde el botón "Otras acciones"</p>
                    <div class="botones">
                        <button class="boton secundario" @click="cerrarModal">No</button>
                        <button class="boton cta" @click="cerrarTRL">Sí</button>
                    </div>
                </div>
                <div v-else>
                    <p class="parrafo">¿Seguro que quieres reactivar el usuario <b>{{usuario.personales.nombre + ' ' + usuario.personales.apellidos}}</b>?</p>
                    <p class="parrafo">
                        Cuando se reactiva un TRL ya no se finalizará automáticamente al terminar todos sus servicios.<br>
                        Sólo se podrá cerrar manualmente desde el botón "Otras acciones"
                    </p>
                    <div class="botones">
                        <button class="boton secundario" @click="cerrarModal">No</button>
                        <button class="boton cta" @click="cerrarTRL">Sí</button>
                    </div>
                </div>
            </div>
            <div v-else-if="tipo === 'error_servidor'" class="contenido finalizado">
                <p class="subtitulo bold">Ha ocurrido un error de servidor</p>
                <p class="parrafo">Por favor refresca la página</p>
                <button class="boton cta center" @click="refrescarPagina">Refrescar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'

export default {
	name: 'ModalSimple',
    components: { Service },
    props: [
        "tipo", 'usuario', 'vivienda', 'empresa', 'archivo', 'respuesta', 'servicio'
    ],
    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            contrasena_actual: null,
            contrasena_nueva: null,
            contrasena_nueva_repetir: null,
            error: '',
            success: null,
            show: false,
            show1: false,
            show2: false,
            enviar_encuesta: true,
            enc_id: null,
            rules: {
                required: value => !!value || '',
                min: v => v.length >= 4 || 'Min 4 characters',
            },
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
    },

	computed:  {
        ...mapState(['usuario_loggeado', 'token'])
    },

    methods: {
        cerrarModal() {
            if (this.tipo === 'link_usuario') {
                this.ws = 'userList';
            }
            this.$emit("closeModalSimple", true);
        },
        cerrarModalLink() {
            this.data = {queries: '?order_by=recents&type=trl,admin,cons,rrhh'};
            this.ws = 'userList';
            this.$router.push({ name: 'FichaTRL', query: { userId: this.enc_id } });
            this.$emit("closeModalSimple", true);
        },
        copyToClipboard() {
            let copyTextarea = document.querySelector('.link_copy');
            copyTextarea.focus();
            copyTextarea.select();
            try {
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
            } catch (err) {
                console.log('Oops, unable to copy');
            }
        },
        eliminarVivienda(){
            this.$emit("closeModalSimple", "delete");
        },
        eliminarUsuario(){
            this.$emit("closeModalSimple", "delete_usuario");
        },
        eliminarEmpresa(){
            this.$emit("closeModalSimple", "delete_empresa");
        },
        eliminarArchivo(){
            this.$emit("closeModalSimple", "delete_archivo");
        },
        cancelarServicio() {
            this.$emit("closeModalSimple", "cancelar_servicio", this.servicio);
        },
        eliminarServicio() {
            this.$emit("closeModalSimple", "delete_servicio");
        },
        cambiarContrasena() {
            if (this.contrasena_actual && this.contrasena_nueva && this.contrasena_nueva_repetir) {
                if (this.contrasena_nueva === this.contrasena_nueva_repetir) {
                    this.data = {user_id: this.usuario.id ,password: this.contrasena_nueva, old_password: this.contrasena_actual};
                    this.ws = 'resetPassword';
                } else {
                    this.error = 'Las contraseñas no coinciden';
                }
            }
        },
        cerrarTRL() {
            if (this.usuario.estado == 1) {
                this.$emit("closeModalSimple", "reactivar_TRL");
            } else {
                this.enviar_encuesta && this.tipo === 'trl_finalizado' ? this.$emit("closeModalSimple", "cerrar_TRL_con_encuesta") : this.$emit("closeModalSimple", "cerrar_TRL_sin_encuesta");
            }
        },
        cerrarModalCons() {
            this.data = {queries: '?order_by=recents&include_finished=no&cons=' + this.usuario_loggeado.id}
            this.ws = 'userList';
            setTimeout(() => (this.respuesta === 'yes' ? this.$emit("closeModalSimple") : this.$emit("closeModalSimple", 'cons_rechazo')), 500);
        },
        refrescarPagina() {
            location.reload();
            return false;
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'resetPassword') {
                if (response.data === 'Incorrect current password.') {
                    this.error = 'Contraseña actual incorrecta';
                } else {
                    this.success = 'contraseña';
                    setTimeout(() => this.$emit("closeModalSimple"), 1000);
                }
            } else if (response.ws === 'userList') {
                let usuarios = response.data;
                this.$store.dispatch('setUsuarios', usuarios);
            } else if (response.ws === 'getUser') {
                this.$store.dispatch('setLoading', false);
                this.enc_id = response.data.encrypted_id;
            }
        }
	},
    created: function() {},
    mounted: function() {
        this.console = window.console;
        if (this.tipo === 'link_usuario') {
            this.ws_spinner = true;
            this.data = this.usuario.id;
            this.ws = 'getUser';
        }
    }
}
</script>
<style scoped lang="scss">
.v-application #app .modal .submodal {
    min-height: 40vh;
    max-width: 50rem;
    &.borrar_vivienda, &.borrar_usuario, &.borrar_empresa, &.cambiar_contraseña, &.borrar_archivo {
        width: 50vw;
        min-height: 25em;
        height: 40vh;
    }
    &.respuesta_cons {
        width: 50vw;
        min-height: 18em;
        height: 30vh;
    }
    .contenido {
        text-align: center;
        display: flex;
        flex-flow: column;
        // margin-bottom: 2em;
        padding-top: 1em;
        height: 100%;
        min-height: 35vh;
        // align-items: center;
        // justify-content: center;
        .titulo {
            width: auto;
            margin: 2em auto 3em;
            &.centered {margin: 12% auto;}
        }
        .link {
            position: relative;
            width: 50%;
            text-align: left;
            margin: 2em auto;
            padding: .5em 1em;
            background-color: var(--gray02);
            .link_copy {width: 95%;}
            .icon {
                position: absolute;
                right: .2em;
                top: 10%;
                cursor: pointer;
            }
        }
        .boton {
            &.cta {
                width: auto;
                margin-top: auto;
                margin-left: auto;
                &.center {margin: auto}
            }
            &.check {
                width: 6em;
                height: 6em;
                margin: 4em 0;
                margin-left: calc(50% - 3em);
                text-align: center;
                background-color: #27AE60;
                .icono{color: white;}
            }
        }
        &.finalizado {
            .titulo {font-size: 3em; margin: 2rem auto;}
            .parrafo {margin: 1em auto;}
            .caja_destacada {
                margin: 1em auto;
                background-color: var(--gray02);
                padding: .2em 5em;
                border-radius: 3px;
                margin-bottom: 2em;
                .check {
                    width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .botones {
                display: flex;
                justify-content: space-evenly;
                margin: 2em 5em 0;
                .boton.cta {margin-left: 5em;}
            }
        }
        &.eliminar {
            justify-content: space-between;
            .titulo {margin: 0 auto;}
            .botones {
                display: flex;
                justify-content: space-evenly;
                margin: 0 5em;
                .boton.cta {margin-left: 5em;}
            }
        }
    }
}
@media (max-width: 900px) {
    .v-application #app .modal .submodal {
        &.borrar_vivienda, &.borrar_usuario, &.borrar_empresa, &.cambiar_contraseña, &.borrar_archivo, &.respuesta_cons {
            width: 60vw;
            min-height: 25em;
            height: 40vh;
            padding: 2em;
        }
        .contenido {
            &.eliminar {
                .botones {
                    .boton.cta {margin-left: 1em;}
                }
            }
        }
    }
}
@media (max-width: 760px) {
    .v-application #app .modal .submodal {
        &.borrar_vivienda, &.borrar_usuario, &.borrar_empresa, &.cambiar_contraseña, &.borrar_archivo, &.respuesta_cons {
            width: 90vw;
        }
        &.cambiar_contraseña {
           height: 60vh; 
        }
    }
}
</style>
