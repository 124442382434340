<template>
    <div class="listado" v-if="Object.keys(events_this_month).length != 0">
        <div class="card dia" v-for="(day, index) in events_this_month" :key="index" :id="'dia_' + $moment(day[0].start).date()">
            <p class="titulo">{{$moment(day[0].start).format('dddd D')}}</p>
            <div class="eventos">
                <div class="card evento open" v-for="(event, index2) in day" :key="index2">   
                    <div v-if="(usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo === 'Consejera')" class="editar" @click="openModalTarea(getUsuario(event.trl), event.servicio, event)">
                        <svg class="icono" width="15" height="15" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0668 0.495563C9.40606 -0.165188 8.33985 -0.165188 7.6791 0.495563L0.992751 7.18191C0.9477 7.22696 0.913912 7.28328 0.89514 7.3471L0.0166417 10.5232C-0.0209009 10.6546 0.0166417 10.7935 0.114253 10.8874C0.208109 10.9812 0.350772 11.0188 0.478417 10.985L3.65453 10.1065C3.71835 10.0877 3.77466 10.0577 3.81971 10.0089L10.5061 3.31877C11.1631 2.65802 11.1631 1.59181 10.5061 0.931058L10.0668 0.495563ZM1.80743 7.42594L7.28115 1.95222L9.04565 3.71672L3.57193 9.1942L1.80743 7.42594ZM1.45453 8.1355L2.86613 9.5471L0.913912 10.0877L1.45453 8.1355ZM9.97671 2.78942L9.57876 3.18737L7.81425 1.42287L8.21221 1.02491C8.57637 0.660751 9.1733 0.660751 9.53746 1.02491L9.97671 1.46416C10.3409 1.83208 10.3409 2.42526 9.97671 2.78942Z" fill="#4F4F4F"/>
                        </svg>
                    </div>
                    <div class="imagenes" v-if="usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo === 'Consejera' || usuario_loggeado.tipo === 'RRHH'">
                        <img class="avatar trl" :src="getUsuario(event.trl).personales.foto || 'images/avatar_placeholder.jpg'" />
                        <img v-if="getConsejeraUsuario(event.trl)" class="avatar consejera" :src="getConsejeraUsuario(event.trl).personales.foto" />
                    </div>
                    <div class="contenido" v-if="usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo === 'Consejera' || usuario_loggeado.tipo === 'RRHH'">
                        <p class="parrafo trl" @click="routerFicha(getUsuario(event.trl))">{{getUsuario(event.trl).personales.nombre + " " + getUsuario(event.trl).personales.apellidos}}</p>
                        <p class="small">{{$moment(event.start).format('HH:mm')}}</p>
                        <p class="small light servicio">{{getServicio(event.servicio).nombre}}</p>
                        <div>
                            <p class="small semibold name">{{event.name}}</p>
                            <p class="small light" v-if="usuario_loggeado.tipo != 'RRHH' && event.notas"><b>Notas: </b>{{event.notas}}</p>
                        </div>
                    </div>
                    <div class="contenido" v-if="usuario_loggeado.tipo === 'TRL'">
                        <p class="small light">{{$moment(event.start).format('HH:mm')}}</p>
                        <p class="small light name">{{event.name}}</p>
                        <p class="small light servicio">{{getServicio(event.servicio).nombre}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="listado empty_state">
        <p class="parrafo">
            Este mes no tiene ningún evento en el calendario.
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'EventosListado',
    props: ['usuarios_filtrados', 'current_year', 'current_month', 'scroll_to'],
	computed: {
        ...mapState([
            'usuarios', 'eventos', 'servicios', 'usuario_loggeado'
        ]),
    },
    data() {
        return {
            open: false,
            events_filtered: [],
            events_this_month: {}
        }
    },

    watch: {
        scroll_to() {
            if (this.scroll_to) {
                let elem = document.getElementById('dia_' + this.scroll_to);
                const container = document.querySelector('.v-tabs-items');
                setTimeout(() => {
                    const day_pos = elem.offsetTop;
                    container.scrollTo({top: day_pos, block: "start"});
                    this.scroll_to = null;
                }, 200);
            }
        },
        current_month() {
            this.filter_events();
        },
        usuarios_filtrados() {
            // console.log( this.usuarios_filtrados);
            // this.events_filtered = [...this.eventos];
            this.iniciarEventos();

            let that = this;
            let filtrado_check = (evento) => {
                // console.log(evento);
                let checks = that.usuarios_filtrados.filtrados.includes(evento.trl) || (that.getConsejeraUsuario(evento.trl) && that.usuarios_filtrados.filtrados.includes(that.getConsejeraUsuario(evento.trl).id));
                let busqueda_vacia = that.usuarios_filtrados.filtro === '';

                let nombre_completo_trl = (that.getUsuario(evento.trl).personales.nombre + ' ' + that.getUsuario(evento.trl).personales.apellidos).toLowerCase();
                let nombre_completo_conse = that.getConsejeraUsuario(evento.trl) ? (that.getConsejeraUsuario(evento.trl).personales.nombre + ' ' + that.getConsejeraUsuario(evento.trl).personales.apellidos).toLowerCase() : '';
                
                let nombre_trl = nombre_completo_trl.includes(that.usuarios_filtrados.filtro.toLowerCase());
                let nombre_conse = nombre_completo_conse.includes(that.usuarios_filtrados.filtro.toLowerCase());

                return (!busqueda_vacia && (nombre_trl || nombre_conse)) || (busqueda_vacia && checks);
            };

            this.events_filtered = this.events_filtered.filter(filtrado_check);
            // this.events_filtered = this.events_filtered.filter(evento => this.usuarios_filtrados.filtrados.includes(evento.trl) || this.usuarios_filtrados.filtrados.includes(evento.consejera));
            this.filter_events();
        },
        eventos() {
            this.iniciarEventos();
        }
    },
    methods: {
        filter_events: function() {
            this.events_this_month = {};
            this.events_filtered.forEach(event => {
                let time = this.$moment(event.start);
                let year = time.year();
                let month = time.month();
                let day = time.date();

                if ((year == this.current_year) && (month == this.current_month)) {
                    if (!this.events_this_month[day]) {
                        this.events_this_month[day] = [event];
                    } else {
                        this.events_this_month[day].push(event);
                    }
                }
                event.open = false;
            })
        },
        getUsuario(id) {
            let usuario = this.usuarios.find( usuario => usuario.id == id );
            return usuario || null;
        },
        getConsejeraUsuario(id) {
            let consejera = null;
            let trl = this.usuarios.find(usuario => usuario.id == id) ? this.usuarios.find(usuario => usuario.id == id) : null;
            if (this.usuario_loggeado.tipo === 'RRHH') {
                if (trl.consejera.foto) {
                    consejera = {
                        personales: {
                            foto: trl.consejera.foto,
                            nombre: trl.consejera.nombre,
                            apellidos: trl.consejera.apellidos
                        }
                    }
                }
            } else {
                consejera = trl ? this.usuarios.find(usuario => usuario.id == trl.consejera.id ) : null;
            }
            return consejera;
        },
        getServicio(id) {
            let servicio = this.servicios.find( servicio => servicio.id == id );
            return servicio;
        },
        // toggleCard(event) {
        //     event.open = !event.open;
        //     this.open = !this.open;
        // },
        routerFicha(usuario) {
            this.$router.push({ name: 'FichaTRL', query: { userId: usuario.enc_id }, })
        },
        openModalTarea(usuario, servicio, tarea) {
            this.$emit("openModalTarea", usuario, servicio, tarea);
		},
		closeModalTarea() {
            this.$emit("closeModalTarea", true);
		},
        iniciarEventos() {
            this.events_filtered = [...this.eventos];
            if (this.events_filtered.length) { 
                this.events_filtered = this.events_filtered.filter(evento => this.getUsuario(evento.trl) && this.getServicio(evento.servicio).estado != 'cancelado');  
                // this.events_filtered = this.events_filtered.filter(evento => this.getServicio(evento.servicio).estado != 'cancelado');
                if (this.usuario_loggeado.tipo === 'Admin') {
                    this.events_filtered.forEach(event => {
                        event.open = false;
                    });
                } else if (this.usuario_loggeado.tipo === 'Consejera') {
                    this.events_filtered = this.events_filtered.filter(evento => this.getConsejeraUsuario(evento.trl).id == this.usuario_loggeado.id);
                    this.events_filtered.forEach(event => {
                        event.open = false;
                    });
                } else if (this.usuario_loggeado.tipo === 'RRHH') {
                    // this.events_filtered = [...this.eventos].filter(evento => evento.consejera == this.usuario_loggeado.id);
                    // this.events_filtered.forEach(event => {
                    //     event.open = false;
                    // });
                } else if (this.usuario_loggeado.tipo === 'TRL') {
                    this.events_filtered = this.events_filtered.filter(evento => evento.trl == this.usuario_loggeado.id);
                    this.events_filtered.forEach(event => {
                        event.open = false;
                    });
                }
                this.filter_events();
            }
        }
	},
    created: function() {
        this.iniciarEventos();
    },
    mounted: function() {}
}
</script>
<style scoped lang="scss">
.v-application #app .listado {
    min-height: 6em;
    padding: 0 5px;
    &.empty_state .parrafo {padding-top: 2em;}
    .calendar_header {
        display: flex;
        justify-content: space-between;
        margin: 1em 0;
        .controles {
            display: flex;
            align-items: center;
            .titulo {width: 5em; text-align: center;}
            .flecha {margin: 1em .5em; cursor: pointer;}
        }
    }
    .dia {
        .eventos {
            display: flex;
            // align-items: center;
            flex-wrap: wrap;
            margin: 1em 0;
            .evento {
                position: relative;
                display: flex;
                width: 22%;
                min-width: 220px;
                height: 6em;
                margin: 1em;
                padding: 1.8em 1em 1em;
                border: 2px solid var(--purple03);
                &.open {
                    height: auto;
                }
                .editar {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 5px;
                    cursor: pointer;
                    // top: 1em;
                    // right: 1em;
                    // opacity: 1!important;
                    .icono path {fill: var(--gray03);}
                    .icono:hover path {fill: var(--gray04);}
                }
                .imagenes {
                    width: 15%;
                    display: flex;
                    flex-direction: column;
                    margin-right: .8em;
                    .avatar {
                        width: 2.3em;
                        height: 2.3em;
                        margin-top: -.3em;
                        object-fit: cover;
                        &.trl {z-index: 1;}
                        // &.consejera {margin-top: -.3em;}
                    }
                }
                .contenido {
                    width: 75%;
                    .small {font-size: .9em; margin: .5em 0;}
                    .name {
                        &:before {
                            content: "•";
                            margin-right: .2em;
                        }
                        display: flex;
                        line-height: 1.2em;
                    }
                    .trl {
                        cursor: pointer;
                        margin-bottom: .5em;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &:hover {color: var(--purple03);}
                    }
                }
            }
        }
    }
}
@media (max-width: 900px) {
    .v-application #app .listado {
        .dia {
            .eventos {
                display: block;
                .evento {
                    display: block;
                    width: 100%;
                    margin: 1em 0;
                    padding: 1em;
                    .imagenes {
                        flex-direction: row;
                        .avatar {
                            margin-top: 0;
                            min-width: 2.3em;
                            margin-bottom: 10px;
                            &:not(:first-child) {
                                margin-left: -10px;
                            }
                        }
                    }
                }
            }
        }
    }
        
 }
</style>






