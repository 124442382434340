<template>
    <div class="modal">
        <service 
            :ws='ws'
            :data="data" 
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <div class="submodal">
            <svg class="cerrar_modal" @click="cerrar_modal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <p class="titulo">{{vivienda_editar ? 'Editar vivienda' : 'Nueva vivienda'}}</p>
            <v-form class="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="area_titulo">Fotos</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-file-input 
                                id="fileImagen"
                                label="Imagen principal*"
                                :rules="[rules.required]"
                                v-model="nueva_vivienda.foto_principal"
                                accept="image/*" 
                                append-icon="mdi-paperclip"
                                prepend-icon=""
                                @click:append="openFile('fileImagen')"
                                v-on:change="cambio_images = true"
                            >
                                <template v-slot:selection="{ text }">
                                    <!-- <img class="chip" :src="imagePreview(file)" /> -->
                                    <v-chip
                                        small
                                        label
                                        color="primary"
                                    >
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <v-file-input 
                                id="fileImagenes"
                                label="Imágenes"
                                v-model="fotos"
                                accept="image/*"
                                multiple
                                append-icon="mdi-paperclip"
                                prepend-icon=""
                                @click:append="openFile('fileImagenes')"
                                v-on:change="cambio_images = true"
                            >
                                <template v-slot:selection="{ text, index }">
                                    <!-- <img class="chip" :src="imagePreview(file)" /> -->
                                    <v-chip
                                        small
                                        label
                                        color="primary"
                                        close
                                        @click:close="borrarImagenSec(index)"
                                    >
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="area_titulo">General</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Dirección*"
                                ref="seachLocationInput"
                                id="seachLocationInput"
                                type="text"
                                v-model="nueva_vivienda.direccion"
                                placeholder="Dirección"
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-combobox
                                v-model="nueva_vivienda.barrio"
                                :items="barrios_mad"
                                label="Zona / barrio*"
                                append-icon=''
                                :rules="[rules.required]"
                            ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="nueva_vivienda.tipo"
								:rules="[rules.required]"
                                :items="['Piso', 'Casa']"
                                placeholder="Seleccionar"
                                label="Tipo de vivienda*"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                label="Superficie m2"
                                type="number"
                                v-model.number="nueva_vivienda.metros"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                label="Planta / nº piso"
                                v-model="nueva_vivienda.piso"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                label="Nº habitaciones"
                                v-model.number="nueva_vivienda.habitaciones"
                                type="number"
                                min="1"
                                max="10"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                label="Nº baños"
                                type="number"
                                min="1"
                                v-model.number="nueva_vivienda.baños"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                label="Precio"
                                type="number"
                                v-model.number="nueva_vivienda.precio"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                label="Equipamiento*"
                                v-model="nueva_vivienda.equipamiento"
								:rules="[rules.required]"
                                autocomplete="new-text"
                                :items="['Amueblado', 'Sin amueblar', 'Parcialmente amueblado']"
                                placeholder="Seleccionar"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                label="Estado*"
                                placeholder="Seleccionar"
                                autocomplete="new-text"
                                v-model="nueva_vivienda.estado"
								:rules="[rules.required]"
                                :items="['Buenas condiciones', 'Excelente', 'Nueva construcción', 'Reformado', 'A reformar']"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                label="Estilo"
                                placeholder="Seleccionar"
                                autocomplete="new-text"
                                v-model="nueva_vivienda.estilo"
                                :items="['Clásico', 'Moderno', 'Otro']"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" class="sin_titulo">
                            <v-text-field
                                label="Gastos de comunidad"
                                v-model="nueva_vivienda.gastos_comunidad"
                                type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" class="sin_titulo">
                            <v-autocomplete
                                label="Tipo de contrato"
                                placeholder="Seleccionar"
                                v-model="nueva_vivienda.tipo_contrato"
                                :items="['Alquiler', 'Compra', 'Alquiler temporal']"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <p class="parrafo">Disponible</p>
                            <v-radio-group v-model="nueva_vivienda.disponibilidad" class="checkbox_container mitad" row>
                                <v-radio class="checkbox" label="Sí" :value="true"></v-radio>
                                <v-radio class="checkbox" label="No" :value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Condiciones de alquiler"
                                v-model="nueva_vivienda.condiciones_alquiler"
                                maxlength="90"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="area_titulo">Características</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-container class="checkbox_container">
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.aire_acondicionado" label="AC"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.calefaccion" label="Calefacción"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.jardin" label="Jardín"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.terraza" label="Terraza"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.piscina" label="Piscina"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.trastero" label="Trastero"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.zonas_infantiles" label="Zonas infantiles"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.habitacion_servicio" label="Habitación de servicio"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.soleado" label="Soleado"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.cerca_metro" label="Cerca del metro/FGC"></v-checkbox>
                                <v-checkbox class="checkbox" v-model="nueva_vivienda.mascotas" label="Admite mascotas"></v-checkbox>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container> 
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="area_titulo">Garaje</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <p class="parrafos">Tiene garaje</p>
                            <v-radio-group v-model="nueva_vivienda.garaje" class="checkbox_container mitad" row>
                                <v-radio class="checkbox" label="Sí" :value="true"></v-radio>
                                <v-radio class="checkbox" label="No" :value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="3" class="sin_titulo">
                            <v-text-field
                                label="Nº vehículos"
                                v-model="nueva_vivienda.garaje_plazas"
                                :disabled="!nueva_vivienda.garaje"
                                type="number"
                                min="1"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="sin_titulo">
                            <v-checkbox class="checkbox" v-model="nueva_vivienda.garaje_en_presu" label="Incluído en el presupuesto" :disabled="!nueva_vivienda.garaje"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container> 
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="area_titulo">Descripcción</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                             <v-textarea
                                label="Español"
                                :auto-grow="autoGrow"
                                v-model="nueva_vivienda.descripcion"
                                maxlength="6000"
                                rows="3"
                                row-height="12"
                                class="sombra"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-textarea
                                label="English"
                                v-model="nueva_vivienda.descripcion_en"
                                maxlength="6000"
                                rows="3"
                                row-height="12"
                                class="sombra"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container> 
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="area_titulo">Datos de la propiedad</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Propietario"
                                v-model="nueva_vivienda.info_propietario.nombre"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                label="Teléfono"
                                v-model="nueva_vivienda.info_propietario.telefono"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                label="Email"
                                v-model="nueva_vivienda.info_propietario.email"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                label="Notas"
                                v-model="nueva_vivienda.info_propietario.notas"
                                auto-grow
                                rows="1"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container> 
            </v-form>
            <div v-if="!isFotosComprimidas">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="boton cta disabled">{{vivienda_editar ? 'Guardar' : 'Crear'}}</v-btn>
                    </template>
                    <span class="small">Espera unos segundos hasta que se acaben de comprimir las imágenes</span>
                </v-tooltip>
            </div>
            <div v-else class="espacio_botones">
                <v-btn class="boton cta" :loading="loading" :disabled="!valid || !isFotosComprimidas" @click="crearVivienda">{{vivienda_editar ? 'Guardar' : 'Crear'}}</v-btn>
                <p v-if="espera_larga" class="parrafo">Se están optimizando las imágenes, el proceso puede tardar. Por favor espere sin cerrar la ventana <v-icon class="icono">mdi-clock-alert-outline</v-icon></p>
            </div> 
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'

export default {
	name: 'ModalCrearVivienda',
    components: {Service},
    props: ['vivienda_editar'],
    computed:  {
        ...mapState([
            'viviendas', 'usuario_loggeado'
        ]),
        isFotosComprimidas() {
            return this.fotos_comp.length === this.fotos.length;
        }
    },
    watch: {
        // loader () {
        //     const l = this.loader;
        //     this[l] = !this[l];
        //     setTimeout(() => (this[l] = false), 100000);
        //     this.loader = null;
        // },
        'nueva_vivienda.garaje': function(a, b) {
            if (!this.nueva_vivienda.garaje) {
                this.nueva_vivienda.garaje_plazas = 0;
                this.nueva_vivienda.garaje_en_presu = 0;
            } else {
                this.nueva_vivienda.garaje_plazas == 0 ? this.nueva_vivienda.garaje_plazas = 1 : '';
            }
        },
        'nueva_vivienda.foto_principal'() {
            this.foto_principal = null;
            let fot = this.nueva_vivienda.foto_principal;
            const tamaño = (fot.size / (1024*1024)).toFixed(2);
            if (tamaño >  1.00) {
                this.resizeFotos({ foto: fot, tamano: 2700 }, true);
            } else {
                this.foto_principal_comp = fot;
            }
        },
        fotos() {
            this.fotos_comp = [];
            this.fotos.forEach(fot => {
                const tamaño = (fot.size / (1024*1024)).toFixed(2);
                // console.log(tamaño);
                if (tamaño >  1.00) {
                    this.resizeFotos({ foto: fot, tamano: 2700 }, false);
                } else {
                    this.fotos_comp.push(fot);
                }
            });
        }
    },
    data() {
        return {
            nueva_vivienda: {
                id: null,
                creador: null,
                disponibilidad: true,
                barrio: null,
                direccion: null,
                ciudad: null,
                descripcion: null,
                descripcion_en: null,
                foto_principal: null,
                fotos: [],
                mapa: null,
                tipo: null,
                precio: null,
                tipo_contrato: null,
                habitaciones: null,
                metros: null,
                piso: null,
                orientacion: null,
                piscina: null,
                jardin: null,
                terraza: null,
                baños: null,
                zonas_infantiles: false,
                habitacion_servicio: false,
                estado: null,
                equipamiento: null,
                aire_acondicionado: false,
                calefaccion: false,
                estilo: 'Otro',
                centro: null,
                afueras: null,
                cerca_oficina: null,
                cerca_colegio: null,
                cerca_metro: null,
                soleado: false,
                zona_tranquila: false,
                garaje: false,
                garaje_plazas: null,
                garaje_en_presu: false,
                mascotas: false,
                trastero: false,
                gastos_comunidad: null,
                condiciones_alquiler: null,
                info_propietario: {
                    nombre: null,
                    telefono: null,
                    notas: null,
                    email: null
                }
            },
            rules: {
                required: value => !!value || '',
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Formato de email no válido'
                }
            },
            valid: false,
            ws: null,
            data: null,
            cambio_images: false,
            foto_principal_comp: null,
            fotos: [],
            fotos_comp: [],
            autocomplete: null,
            loading: false,
            loader: null,
            espera_larga: false,
            barrios_mad: ['Alcobendas','Algete','Arganzuela','Barajas','Boadilla del Monte','Carabanchel','Centro','Chamartín','Chamberí','Ciudad Lineal','Ciudalcampo','Fuencarral','Fuente del Fresno','Hortaleza','La Moraleja','Las Rozas','Latina','Majadahonda','Moncloa','Moralzarzal','Moratalaz','Paracuellos del Jarama','Pozuelo de Alarcón','Puente de Vallecas','Retiro','Salamanca','San Agustín de Guadalix','San Blas','Santo Domingo','Tetuán','Torrelodones','Usera','Vicálvaro','Villa de Vallecas','Villafranca del Castillo','Villanueva de la Cañada','Villaverde']
        }
    },
    methods: {
        openFile(ref) {
            document.getElementById(ref).click()
        },
        cerrar_modal() {
            this.$emit("closeModalVivienda", true);
        },
        imagePreview(file) {
            let url = URL.createObjectURL(file);
            return url;
        },
        initAutocomplete() {
            this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('seachLocationInput'), {
                componentRestrictions: {'country': 'es'},
                fields: ['name', 'geometry', 'place_id', 'adr_address', 'formatted_address']
            });
        },
        crearVivienda() {
            this.loading = true;
            // let viviendas = this.viviendas;
            this.nueva_vivienda.fotos = this.fotos_comp;
            if (this.vivienda_editar) {
                this.data = this.nueva_vivienda;
                this.ws = 'houseUpdate';
            } else {
                this.nueva_vivienda.creador = this.usuario_loggeado.id;
                this.data = this.nueva_vivienda;
                this.ws = 'houseRegister';
            }
            setTimeout(() => this.espera_larga = true, 4000);
        },
        getFiles(url, result) {
            let that = this;
            async function createFile(){
                let response = await fetch(url, {mode: 'no-cors'});
                let data = await response.blob();
                var index = url.lastIndexOf("/") + 1;
                var filename = url.substr(index);
                // let metadata = {
                //     type: 'image/jpeg'
                // };
                if (typeof result === 'string') {
                    that.nueva_vivienda[result] = new File([data], filename)
                } else {
                    that.fotos.push(new File([data], filename));
                    // that.nueva_vivienda[result[0]][result[1]] = new File([data], filename)
                }
            }
            createFile();
        },
        borrarImagenSec(index) {
            this.fotos.splice(index,1);
        },
        resizeFotos({ foto, tamano }, ppal) {
            const dataURItoBlob = (dataURI) => {
                const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
                    ? atob(dataURI.split(',')[1])
                    : unescape(dataURI.split(',')[1]);
                const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
                const max = bytes.length;
                const ia = new Uint8Array(max);
                for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
                return new Blob([ia], { type: mime });
            };

            const resizeImage = ({ file, maxSize }) => {
                const reader = new FileReader();
                const image = new Image();
                const canvas = document.createElement('canvas');

                const resize = () => {
                    let { width, height } = image;

                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }

                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);

                    const dataUrl = canvas.toDataURL('image/jpeg');

                    return dataURItoBlob(dataUrl);
                };

                return new Promise((ok, no) => {
                    if (!file.type.match(/image.*/)) {
                        no(new Error('Not an image'));
                        return;
                    }

                    reader.onload = (readerEvent) => {
                        image.onload = () => ok(resize());
                        image.src = readerEvent.target.result;
                    };

                    reader.readAsDataURL(file);
                });
            };
            resizeImage({ file: foto, maxSize: tamano }).then(resizedImage => {
                if (!ppal) {
                    this.fotos_comp.push(resizedImage);
                } else {
                    this.foto_principal_comp = resizedImage;
                }
            });
        },
        uploadPhotos(id) {
            let fotos = [];
            this.nueva_vivienda.fotos.forEach((fot, index) => {
                let foto = {img: fot, nombre: this.nueva_vivienda.barrio + '_' + index + '.jpeg'};
                fotos.push(foto);
            });
            this.data = {
                id: id,
                foto_principal: {img: this.foto_principal_comp, nombre: this.nueva_vivienda.barrio + '_main.jpeg'},
                fotos: fotos
            };
            this.ws = 'housePhoto';
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            if (response.ws === 'houseRegister') {
                this.uploadPhotos(response.data)
            } else if (response.ws === 'housePhoto') {
                this.ws = 'houseList';
            } else if (response.ws === 'houseUpdate') {
                if (this.cambio_images) {
                    this.uploadPhotos(this.nueva_vivienda.id);
                } else {
                    this.ws = 'houseList';
                }
            } else if (response.ws === 'houseList') {
                let viviendas = response.data;
                this.$store.dispatch('setViviendas', viviendas);

                this.$emit("closeModalVivienda", this.nueva_vivienda);
            }
        }
	},
    created: function() {
    },
    mounted: function() {
        this.console = window.console;
        if (this.vivienda_editar) {
            this.nueva_vivienda = {
                ...this.vivienda_editar,
                fotos: [...this.vivienda_editar.fotos],
                info_propietario: {...this.vivienda_editar.info_propietario}
            };
            typeof this.nueva_vivienda.foto_principal === 'string' ? this.getFiles(this.nueva_vivienda.foto_principal, 'foto_principal') : '';
            this.nueva_vivienda.fotos.forEach((fot, index) => {
                typeof fot === 'string' ?  this.getFiles(fot, ['fotos', index]) : '';
            });
            this.nueva_vivienda.garaje_plazas ? this.nueva_vivienda.garaje = true : '' ;
        } else {
            this.nueva_vivienda.id = this.viviendas.length;
        }
        this.initAutocomplete();
    }
}
</script>
<style scoped lang="scss">
.v-application #app .modal {
    .espacio_botones {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cta {
            order: 2;
            margin-left: auto!important;
        }
        .parrafo {
            position: relative;
            top: 1em;
            margin-left: auto;
            background-color: var(--purple01);
            padding: 1em 2em;
            flex: 1;
            margin-right: 2em;
            text-align: right;
            .icon {
                font-size: 1.5em;
                margin-right: .5em;
            }
        }
    }
    @media (max-width: 900px) {
        .espacio_botones {
            flex-direction: column;
            .parrafo {
                position: relative;
                top: 1em;
                margin-left: 0;
                margin-right: 0;
                text-align: center;
                .icon {
                    font-size: 1.5em;
                    margin-right: .5em;
                }
            }
        }
    }
}
</style>
