<template>
	<div id="usuarios">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <panel-filtros-personas v-if="usuario_loggeado.tipo != 'RRHH'" @filtrarUsuarios="filtrarUsuarios" :pre_filtro="pre_filtro" :num_usuarios_filtrados="usuarios_filtrados.length"/>
        <modal-crear-usuario v-if="modal_crear_usuario" 
            :tipo_usuario="tipo_usuario" 
            :usuario="usuario_selec"
            @closeModalUsuario="closeModalUsuario" 
            @linkFiltro="pre_filtro = $event"
        />
        <modal-simple v-if="modal_link_trl" :tipo="'link_usuario'" :usuario="nuevo_usuario" @closeModalSimple="closeModalSimple"/>
        <div class="view-container usuarios">
            <div class="subheader">
                <h3 class="titulo">{{ $t(localeRoute + 'usuarios') }}</h3>
                <div class="orden_container" v-if="usuario_loggeado.tipo != 'RRHH'">
                    <p class="parrafo">{{ $t(localeRoute + 'ordenar') }}</p>
                    <v-select
                        :items="[$t(localeRoute + 'recientes'), $t(localeRoute + 'apellidosaz'), $t(localeRoute + 'apellidosza')]"
                        class="selector"
                        v-model="orden_recientes"
                    ></v-select>
                    <p class="parrafo">Filtrar por</p>
                    <v-autocomplete
                        v-if="usuario_loggeado.tipo != 'Consejera'"
                        v-model="filtro_consejera"
                        :items="lista_consejeras"
                        class="selector"
                        :placeholder="$t(localeRoute + 'consejeras')"
                        clearable
                        :no-data-text="$t(localeRoute + 'nohay')"
                    ></v-autocomplete>
                    <v-autocomplete
                        v-model="filtro_empresa"
                        :items="lista_empresas"
                        class="selector"
                        :placeholder="$t(localeRoute + 'empresas')"
                        clearable
                        :no-data-text="$t(localeRoute + 'nohay')"
                    ></v-autocomplete>
                </div>
                <div class="toggle">
                    <p v-if="usuario_loggeado.tipo === 'Admin'" class="boton" @click="toggle_usuario = !toggle_usuario;">{{ $t(localeRoute + 'nuevousuario') }}</p>
                    <ul v-if="toggle_usuario">
                        <li class="crear_usuario" @click="openModalCrearUsuario('TRL')">
                            {{ $t(localeRoute + 'trl') }}
                        </li>
                        <li class="crear_usuario" @click="openModalCrearUsuario('Consejera')">
                            {{ $t(localeRoute + 'consejeras') }}
                        </li>
                        <li class="crear_usuario" @click="openModalCrearUsuario('RRHH')">
                            {{ $t(localeRoute + 'rrhh') }}
                        </li>
                    </ul>
                </div>
            </div>
			<ul class="lista-usuarios" v-if="usuarios_filtrados.length && !loading">
				<li v-for="(usuario, subindex) in usuarios_filtrados" :key="subindex" 
                    :class="{clicable: usuario_loggeado.tipo === 'Admin' || usuario.tipo === 'TRL' || usuario.id == usuario_loggeado.id }"
                >
                    <router-link :to="goToFicha(usuario)" class="card usuario" @click.native='openEditModal(usuario)'>
                        <div class="foto" >
                            <img class="avatar" :src="usuario.personales.foto || 'images/avatar_placeholder.jpg'"/>
                        </div>
                        <span class="tipo" :style="{'background-color': 'var(--color_' + usuario.tipo + ')' }">
                            {{usuario.tipo != 'Consejera' ? usuario.tipo : 'Cons'}}
                        </span>
                        <p class="parrafo bold">{{usuario.personales.nombre + ' ' + usuario.personales.apellidos}}</p>
                        <div class="empresa" v-if="usuario.personales.empresa">
                            <img class="logo" v-if="getEmpresa(usuario.personales.empresa) && getEmpresa(usuario.personales.empresa).logo" :src="getEmpresa(usuario.personales.empresa) ? getEmpresa(usuario.personales.empresa).logo : ''" />
                            <p class="small" v-if="getEmpresa(usuario.personales.empresa)">{{getEmpresa(usuario.personales.empresa).nombre}}</p>
                        </div>
                        <div class="contacto" v-if="usuario.contacto.email_profesional">
                            <img src="images/icon-email.svg">
                            <a @click.stop :href="'mailto:' + usuario.contacto.email_profesional" target="_blank" class="small">{{usuario.contacto.email_profesional}}</a>
                        </div>
                        <div class="contacto" v-if="usuario.contacto.telefono">
                            <img src="images/icon-telf.svg">
                            <a @click.stop :href="usuario.contacto.telefono ? 'tel:' + usuario.contacto.telefono : '#'" class="small">{{usuario.contacto.telefono}}</a>
                        </div>
                        <div class="consejera" v-if="usuario_loggeado.tipo != 'RRHH' && usuario.consejera.id && getConsejera(usuario.consejera.id)">
                            <img class="avatar" :src="getConsejera(usuario.consejera.id).personales.foto">
                            <p class="small semibold">{{getConsejera(usuario.consejera.id).personales.nombre + ' ' + getConsejera(usuario.consejera.id).personales.apellidos}}</p>
                        </div>
                        <div class="consejera usuarios" v-if="usuario_loggeado.tipo != 'RRHH' && (usuario.tipo === 'Admin' || usuario.tipo === 'Consejera')">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <p v-on="on" class="parrafo" @click.stop.prevent="filtroTRLConsejeras(usuario, false)">
                                        <img class="icono" src="images/icon_trl_activo.svg">
                                        {{getNumTrlsActivos(usuario.id)}}
                                    </p>
                                </template>
                                <span class="small">TRL's activos</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <p v-on="on" class="parrafo" @click.stop.prevent="filtroTRLConsejeras(usuario, true)">
                                    <img class="icono" src="images/icon_trl_finalizado.svg">
                                    {{getNumTrlsInactivos(usuario.id)}}
                                </p>
                                </template>
                                <span class="small">TRL's inactivos</span>
                            </v-tooltip>
                        </div>
                        <div class="consejera trls" v-if="(usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo === 'Consejera') && usuario.tipo === 'RRHH' && getRrhhTrls(usuario.id).trls.length">
                            <div class="trl" v-for="(trl, index) in ((getRrhhTrls(usuario.id)).trls)" :key="index" @click.stop="pre_filtro = {'palabra': trl.personales.nombre + ' ' + trl.personales.apellidos, 'tipo': ['TRL']}">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <img class="avatar" v-on="on" :src="trl.personales.foto || 'images/avatar_placeholder.jpg'">
                                    </template>
                                    <span class="small">{{trl.personales.nombre + ' ' + trl.personales.apellidos}}</span>
                                </v-tooltip>
                            </div>
                            <div class="trl button-trl-wrapper" v-if="(getRrhhTrls(usuario.id).showNumber)">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="avatar button-trls-more">
                                            <span class="text">+ {{getRrhhTrls(usuario.id).showNumber}}</span>
                                        </div>
                                    </template>
                                    <span class="small">Ver todos</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </router-link>
				</li>
			</ul>
            <div class="lista-usuarios" v-else>
                <p class="parrafo">{{ $t(localeRoute + 'noencontrado') }}</p>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
import PanelFiltrosPersonas from '@/components/PanelFiltrosPersonas.vue'
import ModalCrearUsuario from '@/components/ModalCrearUsuario.vue'
import ModalSimple from '@/components/ModalSimple.vue'

export default {
	name: 'Usuarios',
	components: {
        Service,
        PanelFiltrosPersonas,
		ModalCrearUsuario,
        ModalSimple
	},
    data() {
		return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
			modal_crear_usuario: false,
            modal_link_trl: false,
            toggle_usuario: false,
			tipo_usuario: "",
            bbd_finalizados_is_empty: false,
            usuarios_filtrados: [],
            usuario_selec: null,
            pre_filtro: null,
            orden_recientes: '',
            nuevo_usuario: null,
            lista_consejeras: [],
            lista_empresas: [],
            filtro_consejera: null,
            filtro_empresa: null,
            consejera_filtrada: null,
            filtros_personas: null,
			filtro_finalizados: false,
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
        usuario_loggeado() {
            this.getUsuarios();
        },
        orden_recientes() {
            this.ordenarUsuarios();
        },
        filtro_consejera() {
            this.consejera_filtrada = null;
            if (this.filtro_consejera) {
                this.usuarios_filtrados = this.usuarios.filter(usu => usu.consejera.id == this.filtro_consejera);
                const conse = this.getConsejera(this.filtro_consejera);
                this.consejera_filtrada = `${conse.personales.nombre} ${conse.personales.apellidos}`
                this.pre_filtro = {
                    tipo: ['TRL'],
                    finalizado: this.filtro_finalizados
                }
            } else {
                this.pre_filtro = { 
                    tipo: ['TRL', 'RRHH', 'Consejera', 'Admin'],
                    finalizado: this.filtro_finalizados
                };
                this.filtro_empresa = null;
            }
            // this.pre_filtro = {'palabra': '', tipo: ['TRL']}
            // this.consejeras_filtradas = this.usuarios_filtrados;
        },
        filtro_empresa() {
            if (this.filtro_empresa && this.consejera_filtrada) {
                this.pre_filtro = {
                    tipo: ['TRL'],
                    finalizado: this.filtro_finalizados
                };
                setTimeout(() => {this.usuarios_filtrados = this.usuarios_filtrados.filter(usu => usu.personales.empresa == this.filtro_empresa);}, 100);
            } else if (this.consejera_filtrada) {
                this.usuarios_filtrados = [...this.usuarios];
                this.pre_filtro = {
                    tipo: ['TRL'], 
                    finalizado: this.filtro_finalizados
                };
            } else if (this.filtro_empresa) {
                this.pre_filtro = { 
                    finalizado: this.filtro_finalizados
                };
                setTimeout(() => {this.usuarios_filtrados = this.usuarios_filtrados.filter(usu => usu.personales.empresa == this.filtro_empresa);}, 100);
            } else {
                this.pre_filtro = { 
                    tipo: ['TRL', 'RRHH', 'Consejera', 'Admin'], 
                    finalizado: this.filtro_finalizados
                };
            }
        },
        usuarios() {
            let usu_steps = this.usuario_loggeado.tipo === 'Consejera' || this.usuario_loggeado.tipo === 'Admin' ? true : false;
            let usu_rrhh = this.usuario_loggeado.tipo === 'RRHH';
            let finalizados_ok = !this.filtros_personas || (this.filtros_personas.estado == 0 || this.filtros_personas.estado == 1 && this.usuarios.find(usu => usu.estado == 1)) ? true : false;
            if (this.usuarios.length && (usu_steps || usu_rrhh) && finalizados_ok) this.iniciarUsuarios();
            // this.usuarios_filtrados = [...this.usuarios];
            // if (this.usuario_loggeado.tipo === 'Consejera' || this.usuario_loggeado.tipo === 'Admin') {
            //     this.usuarios.forEach(usu => {
            //         if (usu.tipo === 'Consejera' || usu.tipo === 'Admin') {
            //             this.lista_consejeras.push({value: usu.id, text: usu.personales.nombre + ' ' + usu.personales.apellidos});
            //         }
            //     });
            //     this.empresas.forEach(empresa => {
            //         this.lista_empresas.push({value: empresa.id, text: empresa.nombre});
            //     });
            // }
        },
        empresas() {
            this.empresas.length && (this.usuario_loggeado.tipo === 'Consejera' || this.usuario_loggeado.tipo === 'Admin') ? this.iniciarEmpresas() : ''; 
        }
    },

	computed:  {
        ...mapState([
            'usuarios', 'empresas', 'usuario_loggeado', 'usuarios_finalizados', 'loading'
        ]),
	},

    methods: {
		openModalCrearUsuario(tipo) {
			this.modal_crear_usuario = true;
			this.tipo_usuario = tipo;
            this.toggle_usuario = false;
		},
		closeModalUsuario(usuario) {
            this.usuario_selec = null;
			this.modal_crear_usuario = false;
            if (usuario.tipo === 'TRL') {
                this.nuevo_usuario = usuario;
                this.modal_link_trl = true;
            } 
		},
        closeModalSimple() {
            this.modal_link_trl = false;
        },
        goToFicha(usuario) {
            if (usuario.tipo === 'TRL') {
                return {
                    name: 'FichaTRL', 
                    query: { userId: usuario.enc_id },
                }
            } else {
                return '';
            }
        },
        openEditModal(usuario) {
            if (usuario.tipo !== 'TRL') {
                if (this.usuario_loggeado.tipo === 'Admin' || (this.usuario_loggeado.tipo === 'Consejera' && usuario.tipo === 'Consejera')) {
                    this.usuario_selec = usuario;
                    this.tipo_usuario = usuario.tipo;
                    this.modal_crear_usuario = true;
                }
            }
        },
        filtroTRLConsejeras(usuario, finalizados) {
            this.filtro_consejera = usuario.id;
            this.filtro_finalizados = finalizados;
        },
        filtrarUsuarios(filtros, from) {
            this.filtros_personas = filtros;
            this.filtro_finalizados = filtros.estado;
            if (this.pre_filtro) {
                this.pre_filtro.palabra = filtros.filtro;
            }
            if (filtros.estado && !this.bbd_finalizados_is_empty && !this.usuarios.find(usu => usu.estado == 1) && from != 'success' && !this.usuarios_finalizados.length) {
				// this.pre_filtro = {tipo: ['TRL']};
				// this.$store.dispatch('setLoading', true);
                this.bbd_finalizados_is_empty = true;
				const tipo = this.usuario_loggeado.tipo;
                this.ws_spinner = true;
				this.data = {queries: '?order_by=recents&include_finished=yes'}
				if (tipo === 'Admin') {this.data.queries += '&type=trl,admin,cons,rrhh,legal';}
				else if (tipo === 'Consejera') {this.data.queries += '&type=trl,cons,rrhh&cons=' + this.usuario_loggeado.id;}
                else if (tipo === 'Legal') {this.data.queries += '&type=trl&available_legal_user_access=yes'}
				this.ws = 'userList';
            } else {
                let filtrado = (usuario) => {
                    let empresa_minusculas = this.empresas.length && usuario.personales.empresa ? this.getEmpresa(usuario.personales.empresa).nombre.toLowerCase() : '';
                    let nombre_completo = usuario.personales.nombre + ' ' + usuario.personales.apellidos;
                    nombre_completo = nombre_completo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    
                    let consejera = usuario.consejera.id ? this.getConsejera(usuario.consejera.id) : false;
                    if (consejera) {
                        consejera = consejera.personales.nombre + ' ' + consejera.personales.apellidos;
                        consejera = consejera.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    }
                    
                    let filtro_form = filtros.filtro ? filtros.filtro.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '';

// //si se filtra por finalizados, sólo muestra TRLS
// 					if (filtros.estado) filtros.tipo = ['TRL'];

                    let tipo = filtros.tipo.includes(usuario.tipo);
                    let estado = usuario.tipo != 'TRL' || filtros.estado == usuario.estado ? true : false;
                    let nombre = nombre_completo.includes(filtro_form) || filtros.filtro === '';
                    let conse = consejera ? consejera.includes(filtro_form) || filtros.filtro === '' : false;
                    let empresa = empresa_minusculas.includes(filtro_form) || filtros.filtro === '';
                    let email = usuario.contacto.email_profesional.includes(filtro_form)  || filtros.filtro === '';
					
                    return tipo && estado && (nombre || empresa || conse || email);
                };
                this.usuarios_filtrados = this.usuarios.filter(filtrado);
                this.ordenarUsuarios();
                if (this.filtro_consejera) setTimeout(() => {this.usuarios_filtrados = this.usuarios_filtrados.filter( usu => usu.consejera.id == this.filtro_consejera);}, 50);
                if (this.filtro_empresa) setTimeout(() => {this.usuarios_filtrados = this.usuarios_filtrados.filter(usu => usu.personales.empresa == this.filtro_empresa);}, 50);
            }
        },
        getEmpresa(id) {
            return this.empresas.find( empresa => empresa.id == id);
        },
        getEmpresaNombre(nombre) {
            return this.empresas.find( empresa => empresa.nombre === nombre ) || null;
        },
        getConsejera(id) {
            return this.usuarios.find( usu => usu.id == id ) || null;
        },
        ordenarUsuarios() {
            if (this.orden_recientes === this.$t(this.localeRoute + 'recientes') ) {
                this.usuarios_filtrados.sort((a, b) => b.id - a.id);
            } else if (this.orden_recientes === this.$t(this.localeRoute + 'apellidosaz')) {
                this.usuarios_filtrados.sort((a, b) => a.personales.apellidos.localeCompare(b.personales.apellidos));
            } else if (this.orden_recientes === this.$t(this.localeRoute + 'apellidosza')) {
                this.usuarios_filtrados.sort((a, b) => b.personales.apellidos.localeCompare(a.personales.apellidos));
            }
        },
        getNumTrlsActivos(id) {
            let num = this.usuarios.filter(usu => usu.tipo === 'TRL' && usu.estado == 0 && usu.consejera.id == id);
            return num.length;
        },
        getNumTrlsInactivos(id) {
            let num = this.usuarios.filter(usu => usu.tipo === 'TRL' && usu.estado == 1 && usu.consejera.id == id);
            return num.length;
        },
        getRrhhTrls(id) {
            const maxNumber = 4;
            let trls = this.usuarios.filter(usu => usu.tipo === 'TRL' && !usu.finalizado_por && usu.personales.rrhh && usu.personales.rrhh.filter(rh => rh == id).length > 0);

            return {
                trls: trls.length >= maxNumber ? trls.slice(0, maxNumber) : trls,
                showNumber: trls.length >= maxNumber ? trls.length - trls.slice(0, maxNumber).length : 0
            }
        },
        iniciarUsuarios() {
            this.usuarios_filtrados = [...this.usuarios];
            this.lista_consejeras = [];
            this.usuarios.forEach(usu => {
                if (usu.tipo === 'Consejera' || usu.tipo === 'Admin') {
                    this.lista_consejeras.push({value: usu.id, text: usu.personales.nombre + ' ' + usu.personales.apellidos});
                }
            });
            this.$store.dispatch('setLoading', false);
        },
        iniciarEmpresas() {
            this.lista_empresas = [];
            this.empresas.forEach(empresa => {
                this.lista_empresas.push({value: empresa.id, text: empresa.nombre});
            });
        },
        getUsuarios() {
            const tipo = this.usuario_loggeado.tipo;
            if (tipo != 'TRL') {
                // this.$store.dispatch('setLoading', true);
                this.ws_spinner = true;
                this.data = {queries: '?order_by=recents&include_finished=no'}
                if (tipo === 'Admin') {this.data.queries += '&type=trl,admin,cons,rrhh,legal';}
                else if (tipo === 'Consejera') {this.data.queries += '&cons=' + this.usuario_loggeado.id;}
                else if (tipo === 'RRHH') {this.data.queries += '&rrhh=' + this.usuario_loggeado.id;}
                else if (tipo === 'Legal') {this.data.queries += '&type=trl&available_legal_user_access=yes'}
                this.ws = 'userList';
            }
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'userList') {
                let usuarios = response.data;
                if (!usuarios.find(usu => usu.trl_status == 1)) {
                    this.$store.dispatch('setUsuarios', [...usuarios, ...this.usuarios_finalizados]);
                } else {
                    this.$store.dispatch('setUsuarios', usuarios);
                }
				if (this.filtro_finalizados) {
					this.filtro_finalizados = false;
					this.pre_filtro = {tipo: ['TRL'], finalizado: true};
                    if (this.usuarios.filter(usu => usu.estado == 1).length) this.$store.dispatch('setUsuariosFinalizados', this.usuarios.filter(usu => usu.estado == 1));
				} 
                // else if (this.filtros_personas) this.filtrarUsuarios(this.filtros_personas, 'success');
				if (this.filtros_personas) this.filtrarUsuarios(this.filtros_personas, 'success');
            } 
            // this.$store.dispatch('setLoading', false);
        }
	},
    created: function() {
        // this.$store.dispatch('setLoading', true);
        this.console = window.console;
        this.orden_recientes = this.$t(this.localeRoute + 'recientes');
        if (!localStorage.getItem('isLogged')) {
            this.$router.push('/');
        }
        if (this.$route.params.pre_filtro) {
            this.pre_filtro = this.$route.params.pre_filtro;
            if (this.$route.params.pre_filtro.finalizado) this.filtro_finalizados = true;
        }
        if (this.$route.params.empresa) {this.filtro_empresa = this.$route.params.empresa;}
        this.usuarios.length && (this.usuario_loggeado.tipo === 'Consejera' || this.usuario_loggeado.tipo === 'Admin') ? this.iniciarUsuarios() : '';
        this.empresas.length && (this.usuario_loggeado.tipo === 'Consejera' || this.usuario_loggeado.tipo === 'Admin') ? this.iniciarEmpresas() : ''; 
        //  if (!this.usuarios.find(usu => usu.estado == 1) && this.usuarios_finalizados.length) {
        //     this.usuarios = {...this.usuarios, ...this.usuarios_finalizados};
        // }
    },
    mounted: function() {
        if (this.usuario_loggeado.id) this.getUsuarios();
    }
}
</script>
<style scoped lang="scss">
#usuarios {
    .subheader {
        display: flex;
        justify-content: space-between;
        .orden_container {
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: 2%;
            .selector {
                margin: 0 1em;
                // padding: .6em 4em .6em 1em;
                // background: #FFFFFF;
                // border: 0.5px solid var(--gray03);
                // box-sizing: border-box;
                // border-radius: 3px;
            }
        }
    }
	.lista-usuarios {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 3%;
        padding: 1em;
        margin: -1em -1em -.5em;
        max-height: calc(100vh - 13.5em);
        overflow-x: scroll;
		.card.usuario {
            position: relative;
            display: flex;
            flex-flow: column;
            min-height: 22em;
            height: 100%;
            margin-bottom: .5em;
            // justify-content: center;
            padding-top: 2em;
            text-align: center;
            &.clicable {
                cursor: pointer;
                .parrafo:hover {color: var(--purple03);}
            }
            a:hover  {color: var(--purple03);}
            .parrafo {
                margin: 1em auto; 
                // cursor: pointer;
            }
            .empresa,.contacto {
                margin: .5em auto;
                display: flex;
                align-items: center;
            }
            .contacto {
                img {    
                    background-color: var(--gray02);
                    border-radius: 2em;
                    padding: .2em;
                    width: 1.2em;
                    height: 1.2em;
                    margin-right: .5em;
                }
            }
            .empresa {
                display: flex;
                align-items: center;
                .logo {
                    width: 4em;
                    height: 3em;
                    margin-right: 0.5em;
                    object-fit: contain;
                }
            }
            .consejera {
                width: 90%;
                margin: auto auto 0;
                border-top: 1px solid var(--gray02);
                padding-top: .5em;
                display: flex;
                justify-content: center;
                align-items: center;
                .avatar {
                    width: 2.5em;
                    height: 2.5em;
                    // object-fit: cover;
                    margin-right: 1em;
                }
                &.usuarios {
                    display: flex;
                    .parrafo {
                        &:hover {font-weight: bold; color: var(--purple03);}
                        display: flex;
                        align-items: center;
                        margin: 0 auto;
                        padding: 1em 2em;
                        .icono {margin-right: .35em; font-size: 25px}
                    }
                }
                &.trls {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    .trl {
                        position: relative;
                        // top: .5em;
						overflow: hidden;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						.avatar {margin: .6em;}
                        .small {display: none;}
                        &.button-trl-wrapper {
                            position: relative;
                            .button-trls-more {
                                background: #eff0ef;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                z-index: 5;
                                .text {
                                    font-size: 12px;
                                    opacity: .5;
                                }
                            }
                        }
                    }

                }
            }
            .foto {
                text-align: center;
                cursor: pointer;
                .avatar {
                    width: 6em;
                    height: 6em;
                    // object-fit: cover;
                }
            }
            .tipo {
                position: absolute;
                top: 15%;
                left: 0;
                width: 25%;
                min-width: 5em;
                font-weight: bold;
                color: white;
                padding: .5em;
                border-radius: 0px 50px 50px 0px;
                text-transform: uppercase;
            }
		}
	}
}

 @media (max-width: 900px) {
    #usuarios {
        .subheader {
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
            .orden_container {
                width: 100%;
                margin: 0;
            }
        }
        .lista-usuarios {
            grid-template-columns: repeat(2, 1fr);
            li {
                margin-bottom: 20px;
                .card.usuario {
                    min-height: 20em;
                    padding: 2em 1em;
                    justify-content: space-around;
                    .parrafo {
                        margin: .7em auto; 
                        font-size: 1.6em;
                    }
                    .consejera {
                        margin: 10px auto 0;
                        padding: 1.6em 0 0;                        
                        &.usuarios {
                            .parrafo {
                                padding: 0 1.7em;
                                .icono {margin-right: .35em; font-size: 25px}
                            }
                        }
                    }
                    .foto {
                        text-align: center;
                        cursor: pointer;
                        .avatar {
                            width: 4em;
                            height: 4em;
                            object-fit: cover;
                        }
                    }
                    .tipo {
                        position: absolute;
                        top: 12%;
                        left: 0;
                        width: 25%;
                        min-width: 5em;
                        font-weight: bold;
                        color: white;
                        padding: .5em;
                        border-radius: 0px 50px 50px 0px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    } 
 }

@media (max-width: 760px) {
    #usuarios {
        .subheader {
            .orden_container {
                flex-direction: column;
                align-items: stretch;
            }
        }
        .lista-usuarios {
            grid-template-columns: repeat(1, 1fr);
        }
    }
 }
</style>

