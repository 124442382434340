<template>
    <div class="modal">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <modal-simple v-if="modal_simple" :tipo="'borrar_usuario'" :usuario="usuario" @closeModalSimple="closeModalSimple"/>
        <div class="submodal succes" v-if="success">
            <p v-if="success != 'usuario_eliminado'" class="titulo bold">{{usuario ? 'Modificar usuario' : 'Crear usuario'}}</p>
            <p v-else class="titulo bold">Eliminar usuario</p>
            <div class="boton fab check">
                <v-icon class="icono">mdi-check</v-icon>
            </div>
            <p v-if="success != 'usuario_eliminado'" class="parrafo">Tu {{nuevo_usuario.tipo}} se ha {{!usuario ? 'creado' : 'modificado'}} correctamente.</p>
            <p v-else class="parrafo">El {{nuevo_usuario.tipo}} se ha eliminado correctamente.</p>
        </div>
        <div class="submodal" v-else>
            <svg class="cerrar_modal" @click="cerrarModal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <p class="titulo">{{usuario ? "Editar usuario" : tipo_usuario === 'TRL' || tipo_usuario === 'RRHH' ? "Nuevo " + tipo_usuario : "Nueva " + tipo_usuario}}</p>
            <v-form class="form" v-if="tipo_usuario === 'TRL'">
                <v-tabs class="tabs preferencias" v-model="asignar_servicios" show-arrows>
                    <v-tab class="tab" href="#personales" v-if="!usuario ? true : usuario.tipo != 'TRL'">
                        <p class="nav_link">DATOS PERSONALES</p>
                    </v-tab>
                    <v-tab class="tab" href="#servicios" :disabled="disabled">
                        <p class="nav_link">SERVICIOS CONTRATADOS</p>
                    </v-tab>
                    <v-tab-item value="personales">
                        <v-form v-model="valid_personales">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <h3 class="area_titulo">Datos personales</h3>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Nombre*"
                                            v-model="nuevo_usuario.personales.nombre"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Apellidos*"
                                            v-model="nuevo_usuario.personales.apellidos"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Email profesional*"
                                            v-model="nuevo_usuario.contacto.email_profesional"
                                            type="email"
                                            :rules="[rules.required, rules.email, rules.duplicated]"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Email personal"
                                            v-model="nuevo_usuario.contacto.email_personal"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            label="Ciudad de realojo"
                                            v-model="nuevo_usuario.personales.ciudad"
                                        ></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="6">
                                        <p class="parrafo">Sexo</p>
                                        <v-radio-group label="Sexo" v-model="nuevo_usuario.personales.sexo" class="checkbox_container" row>
                                            <v-radio class="checkbox" label="Hombre" :value="'hombre'"></v-radio>
                                            <v-radio class="checkbox" label="Mujer" :value="'mujer'"></v-radio>
                                        </v-radio-group>
                                    </v-col> -->
                                    <v-col cols="12" sm="3">
                                        <v-autocomplete
                                            v-model="nuevo_usuario.personales.empresa"
                                            :items="lista_empresas"
                                            placeholder="Empresa"
                                            clearable
                                            no-data-text="No hay resultados"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-autocomplete
                                            class="chips_rrhh"
                                            v-model="nuevo_usuario.personales.rrhh"
                                            :items="lista_rrhh"
                                            placeholder="RRHH"
                                            no-data-text="No hay resultados"
                                            small-chips
                                            deletable-chips
                                            multiple
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <p class="parrafo">Visible para legal</p>
                                        <v-radio-group v-model="nuevo_usuario.personales.visible_abogados" class="checkbox_container" row>
                                            <v-radio class="checkbox" label="Sí" :value="true"></v-radio>
                                            <v-radio class="checkbox" label="No" :value="false"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item value="servicios">
                        <v-container>
                            <v-row>
                                <!-- <v-col cols="12">
                                    <v-text-field
                                        :type="'text'"
                                        v-model="buscador"
                                        :placeholder="'Buscar'"
                                        :append-icon="'mdi-magnify'"
                                    ></v-text-field>
                                </v-col> -->
                                <v-col cols="12">
                                    <h3 class="parrafo area_titulo bold">Paquetes</h3>
                                </v-col>
                            </v-row>
                            <v-row class="lista_paquetes">
                                <div class="card" v-for="(paquete, subindex) in paquetes_filtered" :key="subindex"
                                    @click="togglePaquete(paquete)"
                                    :class="{checked: nuevo_usuario.paquetes.includes(paquete.id)}"
                                >
                                    <!-- <div class="icono">
                                        <img :src="paquete.icono"/>
                                    </div> -->
                                    <!-- <v-icon class="icon">mdi-information-outline</v-icon> -->
                                    <p class="parrafo">{{paquete.nombre}}</p>
                                </div>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="header_servicios">
                                    <h3 class="area_titulo bold">Servicios independientes</h3>
                                    <p class="parrafo light" @click="deseleccionarServicios">Deseleccionar todos los servicios</p>
                                </v-col>
                            </v-row>
                            <v-row v-for="(tipo, index) in servicio_tipos" :key="index" class="todos_servicios">
                                <v-col cols="12">
                                    <h3 class="area_titulo">{{tipo}}</h3>
                                </v-col>
                                <v-row class="lista_servicios">
                                    <div class="servicio" v-for="(servicio, subindex) in servicios_ordenados[tipo]" :key="subindex">
                                        <div class="card"
                                            @click="toggleServicio(servicio)"
                                            :class="{checked: nuevo_usuario.servicios.find(serv => serv.id === servicio.id) && !nuevo_usuario.servicios.find(serv => serv.id === servicio.id).oculto}"
                                        >
                                            <v-icon class="icon" @click.stop="servicio_sel_info = servicio">mdi-information-outline</v-icon>
                                            <p class="parrafo" >
                                                {{servicio.nombre}}<br>
                                                <b>{{nuevo_usuario.servicios.find(serv => serv.id === servicio.id) && nuevo_usuario.servicios.find(serv => serv.id === servicio.id).nombre_sub ? nuevo_usuario.servicios.find(serv => serv.id === servicio.id).nombre_sub : ''}}</b>
                                            </p>
                                            <div class="mostrar_sub" v-if="servicio.sub.length">
                                                <svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2" cy="2" r="2" fill="#A291D6"/><circle cx="10" cy="2" r="2" fill="#A291D6"/><circle cx="18" cy="2" r="2" fill="#A291D6"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="card subcards" v-if="servicio.sub.length && servicio.show_sub">
                                            <p class="parrafo" v-if="servicio.sub[0]" @click="toggleSub(servicio, servicio.sub[0], 0)">{{servicio.sub[0]}}</p>
                                            <p class="parrafo" v-if="servicio.sub[1]" @click="toggleSub(servicio, servicio.sub[1], 1)">{{servicio.sub[1]}}</p>
                                            <p class="parrafo" v-if="servicio.sub[2]" @click="toggleSub(servicio, servicio.sub[2], 2)">{{servicio.sub[2]}}</p>
                                            <p class="parrafo" v-if="servicio.sub[3]" @click="toggleSub(servicio, servicio.sub[3], 3)">{{servicio.sub[3]}}</p>
                                            <p class="parrafo" v-if="servicio.sub[4]" @click="toggleSub(servicio, servicio.sub[4], 4)">{{servicio.sub[4]}}</p>
                                            <p class="parrafo" v-if="servicio.sub[5]" @click="toggleSub(servicio, servicio.sub[5], 5)">{{servicio.sub[5]}}</p>
                                        </div>
                                    </div>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
                <div class="info_modal" v-if="servicio_sel_info">
                    <v-icon class="close_info" @click="servicio_sel_info = null">mdi-close</v-icon>
                    <p class="parrafo bold">
                        {{servicio_sel_info.nombre}}
                    </p>
                    <p class="parrafo">
                        {{servicio_sel_info.info}}
                    </p>
                </div>
            </v-form>
            <v-form class="form" v-if="tipo_usuario === 'RRHH'" v-model="valid_personales">
                <v-container>
                    <v-row>
                        <v-col cols="12" class="titulo_caja">
                            <h3 class="area_titulo">Datos personales</h3>
                            <div v-if="usuario" class="boton fab borrar" @click="modal_simple = true">
                                <v-icon class="icono">mdi-delete</v-icon>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre*"
                                v-model="nuevo_usuario.personales.nombre"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Apellidos*"
                                v-model="nuevo_usuario.personales.apellidos"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-autocomplete
                                v-model="nuevo_usuario.personales.empresa"
                                :items="lista_empresas"
                                :rules="[rules.required]"
                                placeholder="Empresa*"
                                no-data-text="No hay resultados"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Email profesional*"
                                v-model="nuevo_usuario.contacto.email_profesional"
                                :rules="[rules.required, rules.email, rules.duplicated]"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                     <v-row v-if="usuario && usuario.tipo === 'RRHH' && lista_trls[0]">
                         <v-col cols="12">
                            <h3 class="area_titulo">TRL's</h3>
                        </v-col>
                        <v-col cols="12">
                            <v-chip v-for="(usuario, index) in lista_trls" :key="index" 
                                class="chip_trl"
                                @click="linkUsuariosFiltrados(usuario.personales.nombre + ' ' + usuario.personales.apellidos)"
                            >
                                {{usuario.personales.nombre + ' ' + usuario.personales.apellidos}}
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-form class="form" v-if="tipo_usuario === 'Consejera'" v-model="valid_personales">
                <v-container>
                    <v-row>
                        <v-col cols="12" class="titulo_caja">
                            <h3 class="area_titulo">Datos personales</h3>
                            <div v-if="usuario && usuario_loggeado.tipo === 'Admin'" class="boton fab borrar" @click="modal_simple = true">
                                <v-icon class="icono">mdi-delete</v-icon>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre*"
                                v-model="nuevo_usuario.personales.nombre"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Apellidos*"
                                v-model="nuevo_usuario.personales.apellidos"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Email*"
                                v-model="nuevo_usuario.contacto.email_profesional"
                                :rules="[rules.required, rules.email, rules.duplicated]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Teléfono*"
                                v-model="nuevo_usuario.contacto.telefono"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <!-- <v-text-field
                                label="Idiomas"
                                v-model="nuevo_usuario.personales.idiomas"
                            ></v-text-field> -->
                            <v-select
                                v-model="nuevo_usuario.personales.idiomas"
                                :items="['Inglés','Francés','Español', 'Catalán', 'Alemán', 'Italiano', 'Portugués', 'Chino']"
                                label="Idiomas"
                                small-chips
                                deletable-chips
                                multiple
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-file-input 
                                id="fileFotoConsejera"
                                label="Foto*"
                                v-model="nuevo_usuario.personales.foto"
                                accept="image/*" 
                                append-icon="mdi-paperclip"
                                prepend-icon=""
                                @click:append="openFile('fileFotoConsejera')"
                                v-on:change="cambio_foto = true"
                                :rules="[rules.required]"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-form class="form" v-if="tipo_usuario === 'Admin'" v-model="valid_personales">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="area_titulo">Datos personales</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre*"
                                v-model="nuevo_usuario.personales.nombre"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Apellidos*"
                                v-model="nuevo_usuario.personales.apellidos"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Email*"
                                v-model="nuevo_usuario.contacto.email_profesional"
                                :rules="[rules.duplicated]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Teléfono*"
                                v-model="nuevo_usuario.contacto.telefono"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-file-input 
                                id="fileFotoAdmin"
                                label="Foto"
                                v-model="nuevo_usuario.personales.foto"
                                accept="image/*" 
                                append-icon="mdi-paperclip"
                                prepend-icon=""
                                @click:append="openFile('fileFotoAdmin')"
                                v-on:change="cambio_foto = true"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-form class="form" v-if="tipo_usuario === 'Legal'" v-model="valid_personales">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h3 class="area_titulo">Datos personales</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre*"
                                v-model="nuevo_usuario.personales.nombre"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Apellidos*"
                                v-model="nuevo_usuario.personales.apellidos"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Email*"
                                v-model="nuevo_usuario.contacto.email_profesional"
                                :rules="[rules.duplicated]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <div v-if="tipo_usuario === 'TRL' && !usuario" class="botones_trl">
                <v-btn class="boton cta" :disabled="!valid_personales" :class="{'disabled': disabled || !valid_personales, 'secundario': asignar_servicios === 'servicios'}" @click="!disabled ? asignar_servicios === 'personales' ? asignar_servicios = 'servicios' : asignar_servicios = 'personales' : ''">{{asignar_servicios === 'servicios' ? 'Atrás' : 'Siguiente'}}</v-btn>
                <v-btn class="boton cta" @click="crearUsuario" v-if="asignar_servicios === 'servicios'" :loading="loading">Crear</v-btn>
            </div>
            <v-btn v-else-if="tipo_usuario === 'TRL' && usuario" class="boton cta" :loading="loading" :disabled="!valid_personales" :class="{'disabled': disabled || (!valid_personales && !usuario)}" @click="crearUsuario">{{usuario ? "Guardar" : 'Crear'}}</v-btn>
            <v-btn v-else class="boton cta" :loading="loading" :disabled="!valid_personales && !usuario" :class="{'disabled': disabled || (!valid_personales && !usuario)}" @click="crearUsuario">{{usuario ? "Guardar" : 'Crear'}}</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import {webServices} from '@/api/api.js'
import Service from '@/components/Service.vue'
import ModalSimple from '@/components/ModalSimple.vue'

export default {
	name: 'ModalCrearUsuario',
    components: {Service, ModalSimple},
	props: [
        "tipo_usuario",
        "usuario"
    ],
    computed: {
        ...mapState([
            'usuarios', 'paquetes', 'servicios', 'usuario_loggeado', 'empresas', 'token'
        ]),
        empresa_sel() {
            return this.nuevo_usuario.personales.empresa;
        },
        nuevo_usuario_foto() {return this.nuevo_usuario.personales.foto},
        paquetes_filtered() {
            let that = this;
            let filtrados = this.paquetes.filter(paq => {
                let eliminado = paq.deleted;
                let asignado = !!that.nuevo_usuario.paquetes.find(us_paq => us_paq == paq.id);
                return asignado || !eliminado;
            });
            return filtrados;
        }
    },
    data() {
        return {
            open: 0,
            modal_simple: false,
            success: null,
            nuevo_usuario: {
                id: null,
                tipo: this.tipo_usuario,
                creado_por: null,
                politica_privacidad: false,
                politica_privacidad_conyuge: false,
                personales: {
                    nombre: null,
                    apellidos: null,
                    ciudad: null,
                    empresa: null,
                    rrhh: null,
                    idiomas: null,
                    foto: null,
                    nacionalidad: null,
                    idioma_preferido: null,
                    direccion: null,
                    pasaporte: null,
                    pasaporte_imagen: null,
                    visible_abogados: false
                },
                datos_bancarios: {
                    cuenta: null
                },
                contacto: {
                    email_profesional: null,
                    email_personal: null,
                    telefono: null
                },
                consejera: {
                    id: 1,
                    asignadas: []
                },
                preferencias: {
                    traslado: {
                        fecha_traslado: null,
                        fecha_entrada: null,
                        fecha_disponible: null,
                        tiempo_alquiler: null,
                        presupuesto: null
                    },
                    vivienda: {
                        tipo: {
                            piso: false,
                            casa: false
                        },
                        metros: null,
                        habitaciones: null,
                        estado: {
                            amueblada: false,
                            sin_amueblar: false
                        },
                        estilo: {
                            clasico: false,
                            moderno: false
                        },
                        caracteristicas: {
                            ascensor: false,
                            piscina: false,
                            jardin: false,
                            terraza: false,
                            zonas_infantiles: false,
                            habitacion_servicio: false
                        },
                        otras_preferencias: {
                            centro: false,
                            afueras: false,
                            cerca_oficina: false,
                            cerca_colegio: false,
                            cerca_metro: false,
                            soleado: false,
                            zona_tranquila: false
                        },
                        areas_residenciales: null,
                        garaje: false,
                        coches: null,
                        coches_en_presupuesto: null,
                        mascota: null,
                        mascota_tipo: null,
                        otros: null
                    },
                    colegios: {
                        numero_hijos: 0,
                        hijos: [],
                        preferencias: {
                            tipo_colegio: null,
                            tipo_educacion: {
                                alemana: false,
                                americana: false,
                                britanica: false,
                                española: false,
                                francesa: false,
                                otras: false
                            }
                        }
                    }
                },
                servicio_vivienda: {
                    gestion: {
                        fecha_aviso: null,
                        fecha_primer_contacto: null,
                        fecha_llegada: null,
                        fecha_visita: null,
                        fecha_busqueda_colegios: null,
                        fecha_firma_contrato: null,
                        fecha_entrada: null,
                        notas: null,
                        finalizada_por: null
                    },
                    datos_vivienda: {
                        direccion: null,
                        referencia_catastral: null,
                        propietario_nombre: null,
                        propietario_dni: null,
                        propietario_direccion: null,
                        propietario_telefono: null,
                        propietario_email: null,
                        iban: null,
                        encargado_nombre: null,
                        encargado_telefono: null,
                        encargado_email: null,
                        inmobiliaria: null,
                        inmobiliaria_nombre: null,
                        inmobiliaria_telefono: null,
                        inmobiliaria_email: null,
                    },
                    contrato_alquiler: {
                        fecha_inicio: null,
                        duracion_obligatoria: null,
                        preaviso: null,
                        renta: null,
                        contrato_nombre: null,
                        contrato_firmado: null,
                        fianza: null,
                        fianza_pagador: null,
                        deposito: null,
                        deposito_pagador: null,
                        comision: null,
                        comision_pagador: null,
                        inventario: null,
                        contrato_prorrogable: null,
                        presencia_checkin: {
                            inquilino: false,
                            consejera: false,
                            agente: false,
                            propietario: false
                        }
                    },
                    seguro_hogar: {
                        propietario_nombre: null,
                        propietario_telefono: null,
                        propietario_poliza: null,
                        propietario_email: null,
                        inquilino_nombre: null,
                        inquilino_telefono: null,
                        inquilino_poliza: null,
                        inquilino_email: null
                    }
                },
                // servicio_suministros: {
                //     internet: {
                //         servicio: {
                //             compañia: null,
                //             telefono_asociado: null,
                //             num_ref: null,
                //             fecha_alta: null,
                //             comprobacion: null,
                //             otros: null
                //         },
                //         gestion: {
                //             inicio: null,
                //             fin: null,
                //             notas: null,
                //             finalizada_por: null
                //         },
                //     },
                //     agua: {
                //         servicio: {
                //             compañia: null,
                //             telefono_asociado: null,
                //             num_ref: null,
                //             fecha_alta: null,
                //             comprobacion: null,
                //             otros: null
                //         },
                //         gestion: {
                //             inicio: null,
                //             fin: null,
                //             notas: null,
                //             finalizada_por: null
                //         },
                //     },
                //     luz: {
                //         servicio: {
                //             compañia: null,
                //             telefono_asociado: null,
                //             num_ref: null,
                //             fecha_alta: null,
                //             comprobacion: null,
                //             otros: null
                //         },
                //         gestion: {
                //             inicio: null,
                //             fin: null,
                //             notas: null,
                //             finalizada_por: null
                //         },
                //     },
                //     gas: {
                //         servicio: {
                //             compañia: null,
                //             telefono_asociado: null,
                //             num_ref: null,
                //             fecha_alta: null,
                //             comprobacion: null,
                //             otros: null
                //         },
                //         gestion: {
                //             inicio: null,
                //             fin: null,
                //             notas: null,
                //             finalizada_por: null
                //         },
                //     }
                // },
                paquetes: [],
                servicios: []
            },
            lista_empresas: [],
            asignar_servicios: 'personales',
            servicios_ordenados: {},
            servicio_tipos: [],
            servicios_copia: [],
            lista_rrhh: [],
            servicio_sel_info: null,
            lista_trls: [],
            disabled: true,
            valid_personales: false,
            buscador: '',
            cambio_foto: false,
            loading: false,
            loader: null,
            rules: {
                required: value => !!value || '',
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Formato de email no válido'
                },
                duplicated: value => {
                    let existe = !this.usuario && this.usuarios.find(usu => usu.contacto.email_profesional.toLowerCase() === value.toLowerCase());
                    return !existe || 'Ya existe un usuario con este email.'
                },
            },
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 5000)
            this.loader = null
        },
        nuevo_usuario: {
            handler(newComponents, oldComponents) {
                if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                    this.disabled = false;
                } else {
                    this.disabled = true;
                }
            },
            deep: true
        },
        empresa_sel() {
            this.lista_rrhh = [];
            let rrhhs = this.usuarios.filter(usuario => usuario.tipo === 'RRHH' && usuario.personales.empresa == this.empresa_sel );
            rrhhs.forEach(rrhh => {
                let nombre_completo = rrhh.personales.nombre + ' ' + rrhh.personales.apellidos;
                this.lista_rrhh.push({value: rrhh.id, text: nombre_completo});
            })
        },
        buscador() {
            setTimeout(() => {
                let el = this.$el.querySelector('#show_' + this.url_show_id);
                el = el.previousSibling;
                el.scrollIntoView({block: "start", behavior: "smooth"});
                // container.scrollTop = el.offsetTop - 200;
            }, 200);
        },
    },
    methods: {
        openFile(ref) {
            document.getElementById(ref).click()
        },
        cerrarModal() {
            this.$emit("closeModalUsuario", true);
        },
        deseleccionarServicios() {
            this.nuevo_usuario.servicios = [];
            this.nuevo_usuario.paquetes = [];
        },
        togglePaquete(paquete) {
            if (!this.nuevo_usuario.paquetes.includes(paquete.id)) {
                this.nuevo_usuario.paquetes.push(paquete.id);
                paquete.servicios.forEach(id => {
                    if (!this.nuevo_usuario.servicios.find(serv => serv.id === id)) {
                        if (!id[0]) {
                            let servicio = this.servicios.find(serv => serv.id == id);
                            this.toggleServicio(servicio, 'paquete');
                        } else {
                            const serv_id = id[0];
                            const serv_sub = id[1];
                            let servicio_con_sub = this.servicios.find(servi => servi.id == serv_id);
                            servicio_con_sub.nombre_sub = servicio_con_sub.sub[serv_sub];
                            servicio_con_sub.show_sub = false;
                            this.toggleServicio(servicio_con_sub, 'paquete');
                        }
                    }
                })
            } else {
                const index = this.nuevo_usuario.paquetes.indexOf(paquete.id);
                this.nuevo_usuario.paquetes.splice(index, 1);
                paquete.servicios.forEach(id => {
                    if (!id[0]) {
                        let servicio = this.servicios.find(serv => serv.id == id);
                        this.toggleServicio(servicio, 'paquete');
                    } else {
                        const serv_id = id[0];
                        const serv_sub = id[1];
                        let servicio_con_sub = this.servicios.find(servi => servi.id == serv_id);
                        servicio_con_sub.nombre_sub = servicio_con_sub.sub[serv_sub];
                        servicio_con_sub.show_sub = false;
                        this.toggleServicio(servicio_con_sub, 'paquete');
                    }
                })
            }
        },
        toggleServicio(servicio, origen) {
            let existe = this.nuevo_usuario.servicios.find(serv => serv.id === servicio.id);
            if (origen === 'paquete') {
                if (!existe) {
                    this.nuevo_usuario.servicios.push(servicio);
                } else {
                    const index = this.nuevo_usuario.servicios.indexOf(existe);
                    this.nuevo_usuario.servicios.splice(index, 1);
                }
            } else if (!servicio.sub.length || origen === 'sub') {
                if (!existe) {
                    this.nuevo_usuario.servicios.push(servicio);
                } else {
                    const index = this.nuevo_usuario.servicios.indexOf(existe);
                    this.nuevo_usuario.servicios.splice(index, 1);
                } 
            } else {
                if (!existe) {
                    this.servicios_copia.forEach(se => se.show_sub = false);
                    servicio.show_sub = true;
                    this.open++;
                } else {
                    this.servicios_copia.forEach(se => se.show_sub = false);
                    servicio.nombre_sub = '';
                    const index = this.nuevo_usuario.servicios.indexOf(existe);
                    this.nuevo_usuario.servicios.splice(index, 1);
                } 
            }
        },
        toggleSub(servicio, sub_servicio, sub_index) {
            // let nombre_original = this.servicios.find( el => el.id === servicio.id ).nombre;
            // servicio.nombre = nombre_original + ' ' + sub_servicio;
            servicio.nombre_sub = sub_servicio;
            servicio.sub_index = sub_index;
            servicio.show_sub = false;
            this.toggleServicio(servicio, 'sub');
        },
        linkUsuariosFiltrados(nombre) {
            this.$emit('linkFiltro', {'palabra': nombre, 'tipo': ['TRL']});
            this.$emit("closeModalUsuario", true);
            // this.$router.push({ name: 'Usuarios', params: {'pre_filtro': {'empresa': nombre, 'tipo': 'TRL'} }});
        },
        crearUsuario() {
            this.loader = 'loading';
            if (!this.usuario) {
                this.nuevo_usuario.contacto.email_profesional = this.nuevo_usuario.contacto.email_profesional.toLowerCase();
                if (this.tipo_usuario === 'Consejera') {
                    if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                        this.data = this.nuevo_usuario;
                        this.data.personales.idioma = JSON.stringify(this.data.personales.idioma);
                        this.ws = 'consRegister';
                    }
                } else if (this.tipo_usuario === 'RRHH') {
                    if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                        this.data = this.nuevo_usuario;
                        this.ws = 'rrhhRegister';
                    }
                } else if (this.tipo_usuario === 'TRL') {
                    if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                        this.data = this.nuevo_usuario;
                        this.ws = 'trlRegister';
                    }
                } else if (this.tipo_usuario === 'Legal') {
                    if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                        this.data = this.nuevo_usuario;
                        this.ws = 'legalRegister';
                    }
                }
            } else {
                if (this.tipo_usuario === 'Consejera') {
                    if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                        this.data = this.nuevo_usuario;
                        this.data.personales.idioma = JSON.stringify(this.data.personales.idioma);
                        this.ws = 'consUpdate';
                    }
                } else if (this.tipo_usuario === 'RRHH') {
                    if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                        this.data = this.nuevo_usuario;
                        this.ws = 'rrhhUpdate';
                    }
                } else if (this.tipo_usuario === 'Admin') {
                    if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                        this.data = this.nuevo_usuario;
                        this.ws = 'adminUpdate';
                    }
                } else if (this.tipo_usuario === 'Legal') {
                    if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                        this.data = this.nuevo_usuario;
                        this.ws = 'legalUpdate';
                    }
                } else if (this.tipo_usuario === 'TRL') {
                    let calls = [];
                    //actualizar servicios
                    let servicios_añadidos = this.nuevo_usuario.servicios.filter(nue_ser => !this.usuario.servicios.find(ser => ser.id == nue_ser.id));
                    let servicios_eliminados = this.usuario.servicios.filter(ser => !this.nuevo_usuario.servicios.find(nue_ser => nue_ser.id == ser.id));
                    servicios_añadidos.forEach(serv_nu => {
                        serv_nu.oculto = false;
                        calls.push({
                            ws: 'trlServiceUpdate',
                            data: {
                                id: this.usuario.id, 
                                service_id: serv_nu.id, 
                                servicio: serv_nu.nombre_sub ? {
                                    nombre_sub: serv_nu.nombre_sub,
                                    sub_index: serv_nu.sub_index
                                } : {}
                            }
                        })
                    })
                    servicios_eliminados.forEach(serv_el => {
                        serv_el.oculto = true;
                        calls.push({
                            ws: 'trlServiceUpdate',
                            data: {id: this.usuario.id, service_id: serv_el.id, servicio: serv_el}
                        });
                    })
                    // actualizar paquetes
                    let paquetes_añadidos = this.nuevo_usuario.paquetes.filter(nue_paq => !this.usuario.paquetes.find(paq => paq == nue_paq));
                    let paquetes_eliminados = this.usuario.paquetes.filter(paq => !this.nuevo_usuario.paquetes.find(nue_paq => nue_paq == paq));
                    if (paquetes_añadidos.length || paquetes_eliminados.length) {
                        if (paquetes_añadidos.length >= paquetes_eliminados.length) {
                        //si los borrados son el mismo numero que los eliminados, actualiza los paquetes antiguos con el nuevo id
                        //o si añade más que borra, actualiza los ids de los borrados, y la diferencia los añade
                            paquetes_añadidos.forEach((pack_nu, index) => {
                                calls.push({
                                    ws: 'trlPackUpdate',
                                    data: {user_id: this.usuario.id, new_pack_id: paquetes_añadidos[index], old_pack_id: paquetes_eliminados[index] || 0}
                                })
                            })
                        } else if (paquetes_añadidos.length < paquetes_eliminados.length) {
                        //si borra más que añade, se actualizan los borrados de más dejandolos sin pack_id
                            paquetes_eliminados.forEach((pack_nu, index) => {
                                calls.push({
                                    ws: 'trlPackUpdate',
                                    data: {user_id: this.usuario.id, new_pack_id: paquetes_añadidos[index] || 0, old_pack_id: paquetes_eliminados[index]}
                                })
                            })
                        }
                    }
                    this.data = {calls: calls};
                    this.ws = 'multiple';
                }
            }
        },
        closeModalSimple(action) {
            this.modal_simple = false;
            if (action === 'delete_usuario') {
                this.data = {trl: this.usuario.id, updater: this.usuario_loggeado.id};
                this.ws_spinner = true;
                this.ws = 'deleteUser';
                // this.$store.dispatch('setLoading', true);
            }
        },
        getFiles(url) {
            let that = this;
            async function createFile(){
                let response = await fetch(url,{mode: 'no-cors'});
                let data = await response.blob();
                var index = that.nuevo_usuario.personales.foto.lastIndexOf("/") + 1;
                var filename = that.nuevo_usuario.personales.foto.substr(index);
                that.nuevo_usuario.personales.foto = new File([data], filename);
            }
            createFile();
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'consRegister' || response.ws === 'rrhhRegister') {
                if (this.nuevo_usuario.personales.foto) {
                    this.data = {
                        id: response.data,
                        img: this.nuevo_usuario.personales.foto,
                        nombre: this.nuevo_usuario.personales.foto.name
                    };
                    this.ws = 'userPhoto';
                } else {
                    this.success = 'usuario_creado';
                    this.data = {queries: '?order_by=recents&type=trl,admin,cons,rrhh'};
                    this.ws = 'userList';
                }
            } else if (response.ws === 'trlRegister') {
                this.nuevo_usuario.id = response.data;
                this.$store.dispatch('setLoading', false);
                this.success = 'usuario_creado';
                setTimeout(() => this.$emit("closeModalUsuario", this.nuevo_usuario), 1000);
            } else if (response.ws === 'consUpdate' || response.ws === 'rrhhUpdate' || response.ws === 'adminUpdate'  || response.ws === 'legalUpdate') {
                if (this.cambio_foto) {
                    this.data = {
                        id: this.nuevo_usuario.id,
                        img: this.nuevo_usuario.personales.foto,
                        nombre: this.nuevo_usuario.personales.foto.name
                    };
                    this.ws = 'userPhoto';
                } else {
                    this.success = 'usuario_modificado';
                    this.data = {queries: '?order_by=recents&include_finished=no'}
                    const tipo = this.usuario_loggeado.tipo;
                    if (tipo === 'Admin') {this.data.queries += '&type=trl,admin,cons,rrhh,legal';}
                    else if (tipo === 'Consejera') {this.data.queries += '&cons=' + this.usuario_loggeado.id;}
                    else if (tipo === 'RRHH') {this.data.queries += '&rrhh=' + this.usuario_loggeado.id;}
                    this.ws = 'userList';
                }
            } else if (response.ws === 'userPhoto') {
                this.success = 'usuario_creado';
                this.data = {queries: '?order_by=recents&include_finished=no'}
                const tipo = this.usuario_loggeado.tipo;
                if (tipo === 'Admin') {this.data.queries += '&type=trl,admin,cons,rrhh';}
                else if (tipo === 'Consejera') {this.data.queries += '&cons=' + this.usuario_loggeado.id;}
                else if (tipo === 'RRHH') {this.data.queries += '&rrhh=' + this.usuario_loggeado.id;}
                this.ws = 'userList';
            } else if (response.ws === 'deleteUser') {
                this.success = 'usuario_eliminado';
                this.data = {queries: '?order_by=recents&type=trl,admin,cons,rrhh'};
                this.ws = 'userList';
            } else if (response.ws === 'userList') {
                let usuarios = response.data;
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('setUsuarios', usuarios);
                setTimeout(() => this.$emit("closeModalUsuario", this.nuevo_usuario), 2000);
            } else if (response.ws === 'multiple') {
                this.$store.dispatch('setLoading', false);
                this.success = 'usuario_creado';
                setTimeout(() => this.$emit("closeModalUsuario", this.nuevo_usuario), 2000);
                // this.data = this.usuario.id;
                // this.ws = 'getUser';
            }

            // if (response.ws === 'trlRegister') {
            //     this.$store.dispatch('setLoading', false);
            //     this.$router.push({ name: 'FichaTRL', params: {usuario: this.nuevo_usuario.id} })
            // }
        }
	},
    created: function() {
        this.console = window.console;
        if (this.usuario) {
            if (this.tipo_usuario != 'TRL') {
                if (this.usuario.contacto.email_profesional) {
                    this.nuevo_usuario = {
                        ...this.usuario,
                        personales: {...this.usuario.personales},
                        contacto: {...this.usuario.contacto}
                    }
                } else if (this.usuario.id === this.usuario_loggeado.id) {
                    this.nuevo_usuario = {
                        ...this.usuario_loggeado,
                        personales: {...this.usuario_loggeado.personales},
                        contacto: {...this.usuario_loggeado.contacto}
                    }
                }
                if (this.nuevo_usuario.personales.empresa) this.nuevo_usuario.personales.empresa = this.nuevo_usuario.personales.empresa.toString();
                if (this.nuevo_usuario.personales.foto) this.getFiles(this.nuevo_usuario.personales.foto);
                this.lista_trls = this.usuarios.filter(usu => usu.tipo === 'TRL' && !usu.finalizado_por && usu.personales.rrhh && usu.personales.rrhh.filter(rh => rh == this.usuario.id).length > 0);
                
            } else {
                this.nuevo_usuario = {
                    ...this.usuario,
                    personales: {...this.usuario.personales},
                    contacto: {...this.usuario.contacto},
                    servicios: [...this.usuario.servicios],
                    paquetes: [...this.usuario.paquetes]
                };
                this.asignar_servicios = 'servicios';
                this.valid_personales = true;
            }
        } else {
            this.nuevo_usuario.creado_por = this.usuario_loggeado.id;
        //descargar usuarios finalizados para evitar duplicados
            if (this.tipo_usuario === 'TRL') {
                let data = {queries: '?order_by=recents&include_finished=yes&type=trl,admin,cons,rrhh,legal'}
                let ws = webServices.userList(this.token, data);
                fetch(ws.url, ws.requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        debugger;
                        let response = JSON.parse(result);
                        if (response.code == '200') {
                            let usuarios = JSON.parse(result).data;
                            this.$store.dispatch('setUsuarios', usuarios);
                        }
                    })
            }
        }
        this.empresas.forEach(empresa => {
            let empresa_lista = {
                value: empresa.id,
                text: empresa.nombre
            }
            this.lista_empresas.push(empresa_lista);
        });
        this.servicios_copia = this.servicios.map(object => ({ ...object }));
        this.servicios_copia.forEach(servicio => {
            let eliminado = servicio.deleted;
            let asignado = !!this.nuevo_usuario.servicios.find(ser => ser.id == servicio.id);
            if (asignado || !eliminado) {
                let tipo = this.servicios_ordenados[servicio.tipo];
                if (!tipo) {
                    this.servicios_ordenados[servicio.tipo] = [servicio];
                    this.servicio_tipos.push(servicio.tipo);
                } else {
                    tipo.push(servicio);
                }
            }
        });
        for (const tip in this.servicios_ordenados) {
            this.servicios_ordenados[tip].sort((a, b) => a.order - b.order);
        }

        // this.servicio_tipos = []
    },
    mounted: function() {}
}
</script>
<style scoped lang="scss">
.v-application #app .modal {
    .submodal {
        position: relative;
        ::v-deep .v-tab--active {
            .nav_link{ color: var(--purple03);}
        }
        .titulo_caja {
            .area_titulo {
                float: left;
            }
            .borrar {
                float: right;
                background-color: white;
                box-shadow: var(--sombra_ext);
            }
        }
        .info_modal {
            position: absolute;
            width: 70%;
            min-height: 15em;
            top: 25%;
            left: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            padding: 5em;
            background-color: var(--gray01);
            box-shadow: var(--sombra_ext_dark);
            .parrafo {margin: 1em auto;}
            .close_info {
                position: absolute;
                top: 1em;
                right: 1em;
            }
        }
        .header_servicios {
            .bold {display: inline-block;}
            .light { 
                display: inline-block;
                margin-left: 1em;
                color: var(--purple03);
                cursor: pointer;
                &:hover {font-weight: 500;}
            }
        }
        .form {
            max-height: 65vh;
            overflow-y: scroll;
            .todos_servicios .area_titulo { 
                color: var(--purple03);
                font-weight: bold;
                font-size: 1.2em;
            }
            .lista_paquetes, .lista_servicios {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 1em 2%;
                margin: 1em;
                .card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    transition: all .2s ease-in;
                    height: 8.5em;
                    cursor: pointer;
                    &:hover>.parrafo {color: var(--purple03);}
                    .icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: .5em .5em 1em 1em;
                        &:hover {color: var(--purple03);}
                    }
                    .mostrar_sub {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        text-align: center;
                        padding: .5em 0 2em;
                    }
                    .parrafo {
                        text-align: center;
                        padding: 1.1em 0;
                        width: 100%;
                        // max-height: 3.5em;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 2;
                        // -webkit-box-orient: vertical;  
                        // overflow: hidden;
                    }
                    &.checked {
                        background-color: var(--purple01);
                        box-shadow: var(--sombra_ext_dark);
                        border: 1px solid var(--purple03);
                    }
                }
                .servicio {
                    position: relative;
                    .subcards {
                        // display: flex;
                        position: absolute;
                        height: auto;
                        top: 9em;
                        z-index: 100;
                        box-shadow: var(--sombra_ext_dark);
                        width: 100%;
                        &:hover>.parrafo {color: inherit;}
                        .parrafo {
                            padding-bottom: .5em;
                            margin-bottom: .5em;
                            &:hover {color: var(--purple03);}
                            &:not(:last-child) {border-bottom: 1px solid var(--gray03);}
                        }
                    }
                }
            }
            .lista_paquetes .card {
                height: 7em;
                justify-content: center;
            }
            .lista_servicios .card {
                display: flex;
                flex-flow: column;
                align-items: center;
                // justify-content: space-between;
            }
        }
        .botones_trl {
            display: flex;
            justify-content: flex-end;
            .boton.cta:last-child {margin-left: 1em;}
        }
        .chip_trl {
            margin-right: 1em;
            margin-bottom: .5em;
            background-color: var(--purple01);
        }
        .chips_rrhh ::v-deep .v-input__slot {
            min-height: 36px;
        }
        &.succes {
            .check {
                width: 6em;
                height: 6em;
                margin: 4em 0;
                margin-left: calc(50% - 3em);
                text-align: center;
                background-color: #27AE60!important;
                .icono{color: white;}
            }
            .titulo {margin: 1em 0;}
            .parrafo {text-align: center;}
        } 
        // .boton {
        //     margin: 2em 0 0;
        //     float: right;
        // }
    }
}
@media (max-width: 900px) {
    .v-application #app .modal {
        .submodal {
            .form {
                max-height: 56vh;
                .lista_paquetes, .lista_servicios {
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: .5em 2%;
                }
            }
        }
    }
}

@media (max-width: 760px) {
    .v-application #app .modal {
        .submodal {
            .form {
                .lista_paquetes, .lista_servicios {
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 0;
                }
            }
        }
    }
}
</style>
