<template>
    <div class="modal">
        <service 
            :ws='ws'
            :data="data" 
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <div class="submodal">
            <svg class="cerrar_modal" v-if="trl.politica_privacidad || trl_servicios" @click="cerrarModal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <div class="slide visible_scrollbar" v-if="current_slide === 1">
                <p v-if="!trl_servicios" class="titulo">{{ $t(localeRoute + 'bienvenido')}}, {{trl.personales.nombre}}</p>
                <p v-else class="titulo">{{ $t(localeRoute + 'tusservicios')}}, {{trl.personales.nombre}}</p>
                <div class="slide_1 visible_scrollbar">
                    <div class="row logos" v-if="!trl_servicios">
                        <img v-if="getEmpresa() && getEmpresa().logo" class="imagen empresa" :src="getEmpresa().logo">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.11374 12.5116L5.45715 15.1609C4.97382 15.6429 4.33281 15.9061 3.65067 15.9061C2.24182 15.9061 1.09692 14.7643 1.09692 13.3594C1.09692 12.6791 1.36086 12.0398 1.84419 11.5578L6.60891 6.80622C7.57557 5.84222 9.25865 5.84564 10.2219 6.80622L11 6.03365C9.622 4.65602 7.21564 4.65602 5.83422 6.03023L1.06949 10.7819C0.380492 11.4724 0 12.3851 0 13.3594C0 15.366 1.63852 17 3.65067 17C4.62761 17 5.54285 16.6206 6.23185 15.9334L9.59458 12.58C9.44032 12.5936 9.2895 12.6244 9.13525 12.6244C8.7856 12.6244 8.44282 12.5834 8.11374 12.5116Z" fill="#B4B4B7"/>
                            <path d="M13.3528 0C12.3758 0 11.4606 0.378409 10.7716 1.06364L7.40885 4.40795C7.5631 4.39432 7.71393 4.36364 7.86818 4.36364C8.21782 4.36364 8.55375 4.41136 8.88283 4.48295L11.5463 1.83409C12.0296 1.35341 12.6706 1.09091 13.3528 1.09091C14.7616 1.09091 15.9065 2.22955 15.9065 3.63068C15.9065 4.30909 15.6426 4.94659 15.1592 5.42727L10.3945 10.1659C9.42786 11.1273 7.74478 11.1239 6.77812 10.1625L6 10.933C6.689 11.6216 7.60766 12 8.5846 12C9.56154 12 10.4768 11.6216 11.1658 10.9364L15.9305 6.19773C16.6195 5.5125 17 4.59886 17 3.63068C17.0034 1.62955 15.3649 0 13.3528 0Z" fill="#B4B4B7"/>
                        </svg>
                        <img class="imagen" src="images/logo.png">
                    </div>
                    <p class="parrafo" v-if="!trl_servicios" v-html="$t(localeRoute + 'nuestracompania', { empresa: getEmpresa() ? getEmpresa().nombre : '' })"></p>
                    <p class="titulo bold" v-if="!trl_servicios">{{ $t(localeRoute + 'tusservicios')}}</p>
                    <div class="tab_container" v-if="trl.servicios.length" :class="{slider: trl.servicios.length > 4}">
                        <span class="flecha" @click="moverTab(0)">
                            <svg width="27" height="50" viewBox="0 0 27 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.9719 1L1.46484 25L25.9719 49" stroke="#A291D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        <div ref="tabs" class="tabs">
                            <div 
                                v-for="(servicio, index) in trl.servicios" :key="index"
                                class="card" 
                                :class="{'active':  contenido_titulo === servicio.nombre}"
                                @click="cambiarContenido(servicio.id)" 
                            >
                                <p class="parrafo" v-if="servicio.original">
                                    {{servicio.original['nombre_' + idiomaGlobal] + (servicio.sub_index ? (' – ' + servicio.original['variantes_' + idiomaGlobal][servicio.sub_index]) : servicio.nombre_sub ? (' – ' + servicio.nombre_sub) : '')}}
                                </p>
                                <p class="parrafo" v-else>
                                    {{servicio.nombre + (servicio.nombre_sub ? (' – ' + servicio.nombre_sub) : '')}}
                                </p>
                            </div>
                            <div class="card"></div>
                        </div>
                        <span class="flecha" @click="moverTab(1)">
                            <svg width="27" height="50" viewBox="0 0 27 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.46461 1L25.9717 25L1.4646 49" stroke="#A291D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </div>
                    <div class="contenido">
                        <p class="titulo">{{contenido_titulo}}</p>
                        <p class="parrafo">{{contenido_parrafo}}</p>
                    </div>
                </div>
            </div>
            <div class="slide politica visible_scrollbar" v-if="current_slide === 2">
                <p class="titulo">{{ $t(localeRoute + 'bienvenidomadrid')}} {{trl.personales.nombre}}</p>
                <div>
                    <p class="titulo centered">
                        {{ $t(localeRoute + 'aceptacionpolitica')}}
                    </p>
                    <p class="parrafo bold">
                        {{ $t(localeRoute + 'parapodercomenzar')}}
                    </p>
                    <p class="parrafo texto_politica" v-html="$t(localeRoute + 'politica')">
                    </p>
                    <v-row class="checks">
                        <v-col cols="12" sm="6">
                            <p class="parrafo bold">{{ $t(localeRoute + 'titular')}}</p>
                            <v-checkbox class="small" v-model="privacidad.titular" :label="$t(localeRoute + 'heleido')"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="trl.servicios.find(serv => serv.id == 4) && trl.servicios.find(serv => serv.id == 9)">
                            <p class="parrafo bold">{{ $t(localeRoute + 'conyuge')}}</p>
                            <v-checkbox class="small" v-model="privacidad.conyuge" :label="$t(localeRoute + 'soloencaso')"></v-checkbox>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="slide" v-if="current_slide === 3">
                <p class="titulo">{{trl.personales.nombre}}, {{ $t(localeRoute + 'indicanos')}}</p>
                <div>
                    <p>{{ $t(localeRoute + 'conlassiguientes')}}</p>
                    <v-tabs class="preferencias" v-model="pref_tabs" show-arrows>
                        <v-tab href="#personales">
                            <p class="nav_link">{{ $t(localeRoute + 'datospersonalestab')}}</p>
                        </v-tab>
                        <v-tab href="#traslado">
                            <p class="nav_link">{{ $t(localeRoute + 'preferenciastraslado')}}</p>
                        </v-tab>
                        <v-tab href="#colegios">
                            <p class="nav_link">{{ trl.servicios.find(serv => serv.id == 9) ? $t(localeRoute + 'colegios') : $t(localeRoute + 'tabhijos')}}</p>
                        </v-tab>

                        <v-tab-item value="personales">
                            <v-form class="form visible_scrollbar" v-model="valid_personales">
                                <v-container class="nota_scroll">
                                    <v-row>
                                        <v-col cols="12" class="no_padding_v">
                                            <p>Haz scroll hasta el final para rellenar todos los datos.</p>
                                            <div class='icon-scroll'></div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" class="no_padding_v">
                                            <h3 class="area_titulo">{{ $t(localeRoute + 'datospersonales') }}</h3>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'nombre') + '*'"
                                                v-model="trl.personales.nombre"
                                                :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'apellidos') + '*'"
                                                v-model="trl.personales.apellidos"
                                                :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'nacionalidad')"
                                                v-model="trl.personales.nacionalidad"
                                            ></v-text-field>
                                        </v-col>
                                        <!-- <v-col cols="3">
                                            <v-text-field
                                                label="Idioma preferido"
                                                v-model="trl.personales.idioma_preferido"
                                            ></v-text-field>
                                        </v-col> -->
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'direcionactual')"
                                                v-model="trl.personales.direccion"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'pasaporte')"
                                                v-model="trl.personales.pasaporte"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-file-input 
                                                id="filePasaporte"
                                                :label="$t(localeRoute + 'imagenpasaporte')"
                                                 v-model="trl.personales.pasaporte_imagen"
                                                accept="image/*" 
                                                append-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @click:append="openFile('filePasaporte')"
                                                v-on:change="cambio_pasaporte = true;"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" class="no_padding_v">
                                            <h3 class="area_titulo">{{ $t(localeRoute + 'datoscontacto') }}</h3>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'emailprofesional') + '*'"
                                                disabled
                                                v-model="trl.contacto.email_profesional"
                                                type="email"
                                                :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'emailpersonal')"
                                                v-model="trl.contacto.email_personal"
                                                type="email"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'movil')"
                                                v-model="trl.contacto.telefono"
                                                type="tel"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" class="no_padding_v">
                                            <h3 class="area_titulo">{{ $t(localeRoute + 'datosconyuge') }}</h3>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            {{ $t(localeRoute + 'sealoja') }}
                                            <v-radio-group v-model="conyuge" class="checkbox_container" row>
                                                <v-radio class="checkbox" :label="$t(localeRoute + 'si')" :value="true"></v-radio>
                                                <v-radio class="checkbox" :label="$t(localeRoute + 'no')" :value="false"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="conyuge" class="datos_conyuge">
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'nombre')"
                                                v-model="trl.conyuge.nombre"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'apellidos')"
                                                v-model="trl.conyuge.apellidos"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                :label="$t(localeRoute + 'nacionalidad')"
                                                v-model="trl.conyuge.nacionalidad"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                :label="$t(localeRoute + 'movil')"
                                                v-model="trl.conyuge.telefono"
                                                type="tel"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'email')"
                                                v-model="trl.conyuge.email"
                                                type="email"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-tab-item>
                        <v-tab-item value="traslado">
                            <v-form class="form visible_scrollbar">
                                <v-container class="nota_scroll">
                                    <v-row>
                                        <v-col cols="12" class="no_padding_v">
                                            <p>Haz scroll hasta el final para rellenar todos los datos.</p>
                                            <div class='icon-scroll'></div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" class="no_padding_v">
                                            <h3 class="area_titulo">{{ $t(localeRoute + 'traslado') }}</h3>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <st-date-picker :label="$t(localeRoute + 'fechatraslado')" :old_fecha="trl.preferencias.traslado.fecha_traslado" @nueva_fecha="trl.preferencias.traslado.fecha_traslado = $event;" :futuro="true"/>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <st-date-picker :multiple="true" :label="$t(localeRoute + 'fechadisponible')" :old_fecha="trl.preferencias.traslado.fecha_disponible" @nueva_fecha="trl.preferencias.traslado.fecha_disponible = $event;" :futuro="true"/>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <st-date-picker :label="$t(localeRoute + 'fechaentrar')" :old_fecha="trl.preferencias.traslado.fecha_entrada" @nueva_fecha="trl.preferencias.traslado.fecha_entrada = $event;" :futuro="true"/>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                :label="$t(localeRoute + 'cuantotiempo')"
                                                v-model="trl.preferencias.traslado.tiempo_alquiler"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="trl.preferencias.traslado.presupuesto"
                                                :label="$t(localeRoute + 'presupuesto')"
                                                :hint="$t(localeRoute + 'recuerde')"
                                                persistent-hint
                                                type="number"
                                                suffix="€"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" class="no_padding_v">
                                            <h3 class="area_titulo">{{ $t(localeRoute + 'vivienda') }}</h3>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <p class="area_titulo">{{ $t(localeRoute + 'traslado') }}</p>
                                            <v-container class="checkbox_container mitad" fluid>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.tipo.piso" :label="$t(localeRoute + 'piso')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.tipo.casa" :label="$t(localeRoute + 'casa')"></v-checkbox>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="sin_titulo">
                                            <v-text-field
                                                :label="$t(localeRoute + 'metroscuadrados')"
                                                v-model="trl.preferencias.vivienda.metros"
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" class="sin_titulo">
                                            <v-text-field
                                                :label="$t(localeRoute + 'nhabitaciones')"
                                                v-model="trl.preferencias.vivienda.habitaciones"
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <p class="area_titulo">{{ $t(localeRoute + 'estado') }}</p>
                                            <v-container class="checkbox_container mitad izda">
                                                <v-checkbox class="checkbox_big" v-model="trl.preferencias.vivienda.estado.amueblada" :label="$t(localeRoute + 'amueblada')"></v-checkbox>
                                                <v-checkbox class="checkbox_big" v-model="trl.preferencias.vivienda.estado.sin_amueblar" :label="$t(localeRoute + 'sinamueblar')"></v-checkbox>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12" sm="6" :class="[!$vuetify.breakpoint.xs ? 'no_padding_dcha' : '']">
                                            <p class="area_titulo">{{ $t(localeRoute + 'estilo') }}</p>
                                            <v-container :class="['checkbox_container', 'mitad', !$vuetify.breakpoint.xs ? 'dcha' : '']">
                                                <v-checkbox class="checkbox_big" v-model="trl.preferencias.vivienda.estilo.clasico" :label="$t(localeRoute + 'clasico')"></v-checkbox>
                                                <v-checkbox class="checkbox_big" v-model="trl.preferencias.vivienda.estilo.moderno" :label="$t(localeRoute + 'moderno')"></v-checkbox>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="area_titulo">{{ $t(localeRoute + 'caracteristicas') }}</p>
                                            <v-container class="checkbox_container">
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.caracteristicas.piscina" :label="$t(localeRoute + 'piscina')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.caracteristicas.jardin" :label="$t(localeRoute + 'jardin')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.caracteristicas.terraza" :label="$t(localeRoute + 'terraza')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.caracteristicas.zonas_infantiles" :label="$t(localeRoute + 'zonasinfantiles')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.caracteristicas.habitacion_servicio" :label="$t(localeRoute + 'habitacionservicio')"></v-checkbox>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="area_titulo">{{ $t(localeRoute + 'otraspreferencias') }}</p>
                                            <v-container class="checkbox_container">
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.otras_preferencias.centro" :label="$t(localeRoute + 'centro')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.otras_preferencias.afueras" :label="$t(localeRoute + 'afueras')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.otras_preferencias.cerca_oficina" :label="$t(localeRoute + 'cercaoficina')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.otras_preferencias.cerca_colegio" :label="$t(localeRoute + 'cercacolegio')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.otras_preferencias.cerca_metro" :label="$t(localeRoute + 'cercametro')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.otras_preferencias.soleado" :label="$t(localeRoute + 'soleado')"></v-checkbox>
                                                <v-checkbox class="checkbox" v-model="trl.preferencias.vivienda.otras_preferencias.zona_tranquila" :label="$t(localeRoute + 'zonatranquila')"></v-checkbox>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                :label="$t(localeRoute + 'preferenciasareas')"
                                                v-model="trl.preferencias.vivienda.areas_residenciales"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-row>
                                                <v-col cols="12">
                                                    <p class="area_titulo">{{ $t(localeRoute + 'garaje') }}</p>
                                                    <v-radio-group v-model="trl.preferencias.vivienda.garaje" class="checkbox_container" row>
                                                        <v-radio class="checkbox" :label="$t(localeRoute + 'si')" :value="true"></v-radio>
                                                        <v-radio class="checkbox" :label="$t(localeRoute + 'no')" :value="false"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                     <p class="area_titulo">{{ $t(localeRoute + 'ncoches') }}</p>
                                                    <v-radio-group v-model="trl.preferencias.vivienda.coches" class="checkbox_container" row :disabled="!trl.preferencias.vivienda.garaje">
                                                        <v-radio class="checkbox" label="1" :value="1"></v-radio>
                                                        <v-radio class="checkbox" label="2" :value="2"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12" sm="6" class="sin_titulo">
                                                    <v-checkbox class="checkbox" :disabled="!trl.preferencias.vivienda.garaje" v-model="trl.preferencias.vivienda.coches_en_presupuesto" :label="$t(localeRoute + 'incluidoenpresupuesto')"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-row>
                                                <v-col cols="12">
                                                    <p class="area_titulo">{{ $t(localeRoute + 'traemascota') }}</p>
                                                    <v-radio-group v-model="trl.preferencias.vivienda.mascota" class="checkbox_container" row>
                                                        <v-radio class="checkbox" :label="$t(localeRoute + 'si')" :value="true"></v-radio>
                                                        <v-radio class="checkbox" :label="$t(localeRoute + 'no')" :value="false"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :label="$t(localeRoute + 'encasoafirmativo')"
                                                        v-model="trl.preferencias.vivienda.mascota_tipo"
                                                        :disabled="!trl.preferencias.vivienda.mascota"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                :label="$t(localeRoute + 'otros')"
                                                v-model="trl.preferencias.vivienda.otros"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-tab-item>
                        <v-tab-item value="colegios">
                            <v-form class="form visible_scrollbar">
                                <v-container class="nota_scroll">
                                    <v-row>
                                        <v-col cols="12" class="no_padding_v">
                                            <p>Haz scroll hasta el final para rellenar todos los datos.</p>
                                            <div class='icon-scroll'></div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container v-if="!trl.servicios.find(serv => serv.id == 9)">
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            ¿Se realojan tus hijos contigo?
                                            <v-radio-group v-model="tiene_hijos" class="checkbox_container" row
                                                :hint="'Te preguntamos esto porque...'"
                                                persistent-hint
                                            >
                                                <v-radio class="checkbox" :label="$t(localeRoute + 'si')" :value="true"></v-radio>
                                                <v-radio class="checkbox" :label="$t(localeRoute + 'no')" :value="false"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container v-if="trl.servicios.find(serv => serv.id == 9) || tiene_hijos">
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <p class="area_titulo label">{{ $t(localeRoute + 'nhijos') }}</p>
                                            <div class="num_hijos" :class="'num_' + numero_cajas_hijos">
                                                <!-- <svg @click="numero_cajas_hijos = 1" class="hijo" width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.95703 6.19813C7.95703 4.71428 9.16711 3.5 10.6739 3.5C12.1807 3.5 13.3908 4.71428 13.3908 6.19813C13.3908 7.68197 12.1807 8.89625 10.6739 8.89625C9.16711 8.89625 7.95703 7.68197 7.95703 6.19813ZM10.6739 0.5C7.52287 0.5 4.95703 3.04485 4.95703 6.19813C4.95703 9.35141 7.52287 11.8963 10.6739 11.8963C13.8249 11.8963 16.3908 9.35141 16.3908 6.19813C16.3908 3.04485 13.8249 0.5 10.6739 0.5ZM6.4571 13.0944C4.94304 13.0944 3.48966 13.6931 2.41703 14.7609C1.34418 15.829 0.740234 17.2791 0.740234 18.7925V20.8915C0.740234 21.72 1.41181 22.3915 2.24023 22.3915C3.06866 22.3915 3.74023 21.72 3.74023 20.8915V18.7925C3.74023 18.0791 4.02484 17.3935 4.53362 16.887C5.04262 16.3802 5.73439 16.0944 6.4571 16.0944H14.8908C15.6135 16.0944 16.3053 16.3802 16.8143 16.887C17.3231 17.3935 17.6077 18.0791 17.6077 18.7925V20.8915C17.6077 21.72 18.2793 22.3915 19.1077 22.3915C19.9361 22.3915 20.6077 21.72 20.6077 20.8915V18.7925C20.6077 17.2791 20.0038 15.829 18.9309 14.7609C17.8583 13.6931 16.4049 13.0944 14.8908 13.0944H6.4571Z" fill="#5C31F1"/>
                                                    </svg> 
                                                    <svg @click="numero_cajas_hijos = 2" class="hijo" width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.95703 6.19813C7.95703 4.71428 9.16711 3.5 10.6739 3.5C12.1807 3.5 13.3908 4.71428 13.3908 6.19813C13.3908 7.68197 12.1807 8.89625 10.6739 8.89625C9.16711 8.89625 7.95703 7.68197 7.95703 6.19813ZM10.6739 0.5C7.52287 0.5 4.95703 3.04485 4.95703 6.19813C4.95703 9.35141 7.52287 11.8963 10.6739 11.8963C13.8249 11.8963 16.3908 9.35141 16.3908 6.19813C16.3908 3.04485 13.8249 0.5 10.6739 0.5ZM6.4571 13.0944C4.94304 13.0944 3.48966 13.6931 2.41703 14.7609C1.34418 15.829 0.740234 17.2791 0.740234 18.7925V20.8915C0.740234 21.72 1.41181 22.3915 2.24023 22.3915C3.06866 22.3915 3.74023 21.72 3.74023 20.8915V18.7925C3.74023 18.0791 4.02484 17.3935 4.53362 16.887C5.04262 16.3802 5.73439 16.0944 6.4571 16.0944H14.8908C15.6135 16.0944 16.3053 16.3802 16.8143 16.887C17.3231 17.3935 17.6077 18.0791 17.6077 18.7925V20.8915C17.6077 21.72 18.2793 22.3915 19.1077 22.3915C19.9361 22.3915 20.6077 21.72 20.6077 20.8915V18.7925C20.6077 17.2791 20.0038 15.829 18.9309 14.7609C17.8583 13.6931 16.4049 13.0944 14.8908 13.0944H6.4571Z" fill="#5C31F1"/>
                                                    </svg> 
                                                    <svg @click="numero_cajas_hijos = 3" class="hijo" width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.95703 6.19813C7.95703 4.71428 9.16711 3.5 10.6739 3.5C12.1807 3.5 13.3908 4.71428 13.3908 6.19813C13.3908 7.68197 12.1807 8.89625 10.6739 8.89625C9.16711 8.89625 7.95703 7.68197 7.95703 6.19813ZM10.6739 0.5C7.52287 0.5 4.95703 3.04485 4.95703 6.19813C4.95703 9.35141 7.52287 11.8963 10.6739 11.8963C13.8249 11.8963 16.3908 9.35141 16.3908 6.19813C16.3908 3.04485 13.8249 0.5 10.6739 0.5ZM6.4571 13.0944C4.94304 13.0944 3.48966 13.6931 2.41703 14.7609C1.34418 15.829 0.740234 17.2791 0.740234 18.7925V20.8915C0.740234 21.72 1.41181 22.3915 2.24023 22.3915C3.06866 22.3915 3.74023 21.72 3.74023 20.8915V18.7925C3.74023 18.0791 4.02484 17.3935 4.53362 16.887C5.04262 16.3802 5.73439 16.0944 6.4571 16.0944H14.8908C15.6135 16.0944 16.3053 16.3802 16.8143 16.887C17.3231 17.3935 17.6077 18.0791 17.6077 18.7925V20.8915C17.6077 21.72 18.2793 22.3915 19.1077 22.3915C19.9361 22.3915 20.6077 21.72 20.6077 20.8915V18.7925C20.6077 17.2791 20.0038 15.829 18.9309 14.7609C17.8583 13.6931 16.4049 13.0944 14.8908 13.0944H6.4571Z" fill="#5C31F1"/>
                                                    </svg>
                                                    <svg @click="numero_cajas_hijos = 4" class="hijo" width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.95703 6.19813C7.95703 4.71428 9.16711 3.5 10.6739 3.5C12.1807 3.5 13.3908 4.71428 13.3908 6.19813C13.3908 7.68197 12.1807 8.89625 10.6739 8.89625C9.16711 8.89625 7.95703 7.68197 7.95703 6.19813ZM10.6739 0.5C7.52287 0.5 4.95703 3.04485 4.95703 6.19813C4.95703 9.35141 7.52287 11.8963 10.6739 11.8963C13.8249 11.8963 16.3908 9.35141 16.3908 6.19813C16.3908 3.04485 13.8249 0.5 10.6739 0.5ZM6.4571 13.0944C4.94304 13.0944 3.48966 13.6931 2.41703 14.7609C1.34418 15.829 0.740234 17.2791 0.740234 18.7925V20.8915C0.740234 21.72 1.41181 22.3915 2.24023 22.3915C3.06866 22.3915 3.74023 21.72 3.74023 20.8915V18.7925C3.74023 18.0791 4.02484 17.3935 4.53362 16.887C5.04262 16.3802 5.73439 16.0944 6.4571 16.0944H14.8908C15.6135 16.0944 16.3053 16.3802 16.8143 16.887C17.3231 17.3935 17.6077 18.0791 17.6077 18.7925V20.8915C17.6077 21.72 18.2793 22.3915 19.1077 22.3915C19.9361 22.3915 20.6077 21.72 20.6077 20.8915V18.7925C20.6077 17.2791 20.0038 15.829 18.9309 14.7609C17.8583 13.6931 16.4049 13.0944 14.8908 13.0944H6.4571Z" fill="#5C31F1"/>
                                                    </svg>
                                                -->
                                                
                                                <svg @click="numero_cajas_hijos = 1" class="hijo" width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.95703 6.19813C7.95703 4.71428 9.16711 3.5 10.6739 3.5C12.1807 3.5 13.3908 4.71428 13.3908 6.19813C13.3908 7.68197 12.1807 8.89625 10.6739 8.89625C9.16711 8.89625 7.95703 7.68197 7.95703 6.19813ZM10.6739 0.5C7.52287 0.5 4.95703 3.04485 4.95703 6.19813C4.95703 9.35141 7.52287 11.8963 10.6739 11.8963C13.8249 11.8963 16.3908 9.35141 16.3908 6.19813C16.3908 3.04485 13.8249 0.5 10.6739 0.5ZM6.4571 13.0944C4.94304 13.0944 3.48966 13.6931 2.41703 14.7609C1.34418 15.829 0.740234 17.2791 0.740234 18.7925V20.8915C0.740234 21.72 1.41181 22.3915 2.24023 22.3915C3.06866 22.3915 3.74023 21.72 3.74023 20.8915V18.7925C3.74023 18.0791 4.02484 17.3935 4.53362 16.887C5.04262 16.3802 5.73439 16.0944 6.4571 16.0944H14.8908C15.6135 16.0944 16.3053 16.3802 16.8143 16.887C17.3231 17.3935 17.6077 18.0791 17.6077 18.7925V20.8915C17.6077 21.72 18.2793 22.3915 19.1077 22.3915C19.9361 22.3915 20.6077 21.72 20.6077 20.8915V18.7925C20.6077 17.2791 20.0038 15.829 18.9309 14.7609C17.8583 13.6931 16.4049 13.0944 14.8908 13.0944H6.4571Z" fill="#5C31F1"/>
                                                    <path d="M12.6345 28H10.6016V20.1638L8.1748 20.9165V19.2634L12.4165 17.744H12.6345V28Z" fill="#373737"/>
                                                </svg>
                                                <svg @click="numero_cajas_hijos = 2" class="hijo" width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.2168 6.19813C7.2168 4.71428 8.42688 3.5 9.93367 3.5C11.4405 3.5 12.6506 4.71428 12.6506 6.19813C12.6506 7.68197 11.4405 8.89625 9.93367 8.89625C8.42688 8.89625 7.2168 7.68197 7.2168 6.19813ZM9.93367 0.5C6.78264 0.5 4.2168 3.04485 4.2168 6.19813C4.2168 9.35141 6.78264 11.8963 9.93367 11.8963C13.0847 11.8963 15.6506 9.35141 15.6506 6.19813C15.6506 3.04485 13.0847 0.5 9.93367 0.5ZM5.71687 13.0944C4.20281 13.0944 2.74943 13.6931 1.6768 14.7609C0.603946 15.829 0 17.2791 0 18.7925V20.8915C0 21.72 0.671576 22.3915 1.5 22.3915C2.32843 22.3915 3 21.72 3 20.8915V18.7925C3 18.0791 3.28461 17.3935 3.79339 16.887C4.30239 16.3802 4.99416 16.0944 5.71687 16.0944H14.1506C14.8733 16.0944 15.5651 16.3802 16.0741 16.887C16.5829 17.3935 16.8675 18.0791 16.8675 18.7925V20.8915C16.8675 21.72 17.5391 22.3915 18.3675 22.3915C19.1959 22.3915 19.8675 21.72 19.8675 20.8915V18.7925C19.8675 17.2791 19.2636 15.829 18.1907 14.7609C17.1181 13.6931 15.6647 13.0944 14.1506 13.0944H5.71687Z" fill="#5C31F1"/>
                                                    <path d="M13.7164 28H6.69618V26.6072L10.0093 23.076C10.4642 22.5789 10.7995 22.1452 11.0152 21.7747C11.2356 21.4042 11.3458 21.0525 11.3458 20.7195C11.3458 20.2647 11.2309 19.9083 11.0012 19.6503C10.7714 19.3877 10.4431 19.2564 10.0164 19.2564C9.55679 19.2564 9.19335 19.4159 8.92605 19.7347C8.66343 20.0489 8.53213 20.464 8.53213 20.9798H6.49219C6.49219 20.3561 6.63991 19.7863 6.93535 19.2705C7.23548 18.7546 7.65753 18.3513 8.20152 18.0606C8.7455 17.7652 9.36217 17.6174 10.0515 17.6174C11.1067 17.6174 11.925 17.8707 12.5065 18.3771C13.0927 18.8836 13.3858 19.5988 13.3858 20.5226C13.3858 21.0291 13.2545 21.5449 12.9919 22.0701C12.7292 22.5954 12.2791 23.2073 11.6413 23.9061L9.31293 26.361H13.7164V28Z" fill="#373737"/>
                                                </svg>
                                                <svg @click="numero_cajas_hijos = 3" class="hijo" width="21" height="29" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.47656 6.19813C7.47656 4.71428 8.68664 3.5 10.1934 3.5C11.7002 3.5 12.9103 4.71428 12.9103 6.19813C12.9103 7.68197 11.7002 8.89625 10.1934 8.89625C8.68664 8.89625 7.47656 7.68197 7.47656 6.19813ZM10.1934 0.5C7.0424 0.5 4.47656 3.04485 4.47656 6.19813C4.47656 9.35141 7.0424 11.8963 10.1934 11.8963C13.3444 11.8963 15.9103 9.35141 15.9103 6.19813C15.9103 3.04485 13.3444 0.5 10.1934 0.5ZM5.97663 13.0944C4.46257 13.0944 3.00919 13.6931 1.93656 14.7609C0.863712 15.829 0.259766 17.2791 0.259766 18.7925V20.8915C0.259766 21.72 0.931342 22.3915 1.75976 22.3915C2.58819 22.3915 3.25976 21.72 3.25976 20.8915V18.7925C3.25976 18.0791 3.54437 17.3935 4.05315 16.887C4.56215 16.3802 5.25392 16.0944 5.97663 16.0944H14.4103C15.133 16.0944 15.8248 16.3802 16.3338 16.887C16.8426 17.3935 17.1272 18.0791 17.1272 18.7925V20.8915C17.1272 21.72 17.7988 22.3915 18.6272 22.3915C19.4556 22.3915 20.1272 21.72 20.1272 20.8915V18.7925C20.1272 17.2791 19.5233 15.829 18.4504 14.7609C17.3778 13.6931 15.9244 13.0944 14.4103 13.0944H5.97663Z" fill="#5C31F1"/>
                                                    <path d="M8.76447 21.9787H9.84775C10.3636 21.9787 10.7458 21.8497 10.9943 21.5918C11.2429 21.3339 11.3672 20.9915 11.3672 20.5648C11.3672 20.1521 11.2429 19.8309 10.9943 19.6011C10.7505 19.3713 10.4128 19.2564 9.9814 19.2564C9.59217 19.2564 9.26625 19.3643 9.00364 19.58C8.74102 19.791 8.60972 20.0677 8.60972 20.41H6.57681C6.57681 19.8754 6.71984 19.3971 7.0059 18.975C7.29665 18.5483 7.69995 18.2153 8.2158 17.9762C8.73633 17.737 9.30846 17.6174 9.93216 17.6174C11.0154 17.6174 11.8642 17.8777 12.4786 18.3982C13.0929 18.9141 13.4001 19.6269 13.4001 20.5367C13.4001 21.0056 13.257 21.437 12.971 21.831C12.6849 22.2249 12.3097 22.5274 11.8455 22.7384C12.4223 22.9447 12.8514 23.2542 13.1328 23.6669C13.4188 24.0796 13.5618 24.5673 13.5618 25.13C13.5618 26.0398 13.2289 26.769 12.563 27.3177C11.9018 27.8664 11.0248 28.1407 9.93216 28.1407C8.90985 28.1407 8.07277 27.8711 7.42093 27.3318C6.77377 26.7925 6.4502 26.0797 6.4502 25.1933H8.4831C8.4831 25.5779 8.62613 25.8921 8.91219 26.1359C9.20294 26.3798 9.55934 26.5017 9.9814 26.5017C10.4644 26.5017 10.8419 26.3751 11.1139 26.1219C11.3906 25.8639 11.5289 25.524 11.5289 25.1019C11.5289 24.0796 10.9662 23.5684 9.84072 23.5684H8.76447V21.9787Z" fill="#373737"/>
                                                </svg>
                                                <svg @click="numero_cajas_hijos = 4" class="hijo" width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.73633 6.19813C7.73633 4.71428 8.94641 3.5 10.4532 3.5C11.96 3.5 13.1701 4.71428 13.1701 6.19813C13.1701 7.68197 11.96 8.89625 10.4532 8.89625C8.94641 8.89625 7.73633 7.68197 7.73633 6.19813ZM10.4532 0.5C7.30217 0.5 4.73633 3.04485 4.73633 6.19813C4.73633 9.35141 7.30217 11.8963 10.4532 11.8963C13.6042 11.8963 16.1701 9.35141 16.1701 6.19813C16.1701 3.04485 13.6042 0.5 10.4532 0.5ZM6.2364 13.0944C4.72234 13.0944 3.26896 13.6931 2.19633 14.7609C1.12348 15.829 0.519531 17.2791 0.519531 18.7925V20.8915C0.519531 21.72 1.19111 22.3915 2.01953 22.3915C2.84796 22.3915 3.51953 21.72 3.51953 20.8915V18.7925C3.51953 18.0791 3.80414 17.3935 4.31292 16.887C4.82192 16.3802 5.51369 16.0944 6.2364 16.0944H14.6701C15.3928 16.0944 16.0846 16.3802 16.5936 16.887C17.1024 17.3935 17.387 18.0791 17.387 18.7925V20.8915C17.387 21.72 18.0586 22.3915 18.887 22.3915C19.7154 22.3915 20.387 21.72 20.387 20.8915V18.7925C20.387 17.2791 19.7831 15.829 18.7102 14.7609C17.6376 13.6931 16.1842 13.0944 14.6701 13.0944H6.2364Z" fill="#5C31F1"/>
                                                    <path d="M13.7105 24.1452H14.8712V25.7842H13.7105V28H11.6776V25.7842H7.47816L7.38672 24.5039L11.6565 17.7581H13.7105V24.1452ZM9.41259 24.1452H11.6776V20.5296L11.544 20.7617L9.41259 24.1452Z" fill="#373737"/>
                                                </svg>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div v-if="numero_cajas_hijos > 0">
                                        <v-row v-for="(hijo, index) in trl.preferencias.colegios.hijos" :key="index" class="hijo">
                                            <v-col cols="12" class="no_padding_v">
                                                <h3 class="area_titulo">{{ $t(localeRoute + 'hijo') }} {{index + 1}}</h3>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    :label="$t(localeRoute + 'nombre')"
                                                    v-model="hijo.nombre"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    :label="$t(localeRoute + 'apellidos')"
                                                    v-model="hijo.apellidos"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" v-if="trl.servicios.find(serv => serv.id == 9)">
                                                <p class="area_titulo light">{{ $t(localeRoute + 'sexo') }}</p>
                                                <v-radio-group v-model="hijo.sexo" class="checkbox_container mitad" row>
                                                    <v-radio class="checkbox" :label="$t(localeRoute + 'hombre')" value="hombre"></v-radio>
                                                    <v-radio class="checkbox" :label="$t(localeRoute + 'mujer')" value="mujer"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col cols="12" sm="3" class="sin_titulo">
                                                <v-text-field
                                                    :label="$t(localeRoute + 'fechanacimiento')"
                                                    v-model="hijo.nacimiento"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" class="sin_titulo" v-if="trl.servicios.find(serv => serv.id == 9)">
                                                <v-text-field
                                                    :label="$t(localeRoute + 'curso')"
                                                    v-model="hijo.curso"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" class="sin_titulo" v-if="trl.servicios.find(serv => serv.id == 9)">
                                                <v-text-field
                                                    :label="$t(localeRoute + 'idiomas')"
                                                    v-model="hijo.idiomas"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="trl.servicios.find(serv => serv.id == 9)">
                                            <v-col cols="12">
                                                <h3 class="area_titulo">{{ $t(localeRoute + 'preferencias') }}</h3>
                                            </v-col>
                                            <v-col cols="12">
                                                <p class="area_titulo">{{ $t(localeRoute + 'tipocolegio') }}</p>
                                                <div class="checkbox_container">
                                                    <v-checkbox class="checkbox" :label="$t(localeRoute + 'concertado')" v-model="trl.preferencias.colegios.preferencias.tipo_colegio.concertado"></v-checkbox>
                                                    <v-checkbox class="checkbox" :label="$t(localeRoute + 'privado')" v-model="trl.preferencias.colegios.preferencias.tipo_colegio.privado"></v-checkbox>
                                                    <v-checkbox class="checkbox" :label="$t(localeRoute + 'publicos')" v-model="trl.preferencias.colegios.preferencias.tipo_colegio.publico"></v-checkbox>
                                                </div>
                                            </v-col>
                                            <v-col cols="12">
                                                <p class="area_titulo">{{ $t(localeRoute + 'tipoeducacion') }}</p>
                                                <div class="checkbox_container">
                                                    <v-checkbox class="checkbox" v-model="trl.preferencias.colegios.preferencias.tipo_educacion.alemana" :label="$t(localeRoute + 'alemana')"></v-checkbox>
                                                    <v-checkbox class="checkbox" v-model="trl.preferencias.colegios.preferencias.tipo_educacion.americana" :label="$t(localeRoute + 'americana')"></v-checkbox>
                                                    <v-checkbox class="checkbox" v-model="trl.preferencias.colegios.preferencias.tipo_educacion.britanica" :label="$t(localeRoute + 'britanica')"></v-checkbox>
                                                    <v-checkbox class="checkbox" v-model="trl.preferencias.colegios.preferencias.tipo_educacion.española" :label="$t(localeRoute + 'espanola')"></v-checkbox>
                                                    <v-checkbox class="checkbox" v-model="trl.preferencias.colegios.preferencias.tipo_educacion.francesa" :label="$t(localeRoute + 'francesa')"></v-checkbox>
                                                    <v-checkbox class="checkbox" v-model="trl.preferencias.colegios.preferencias.tipo_educacion.otras" :label="$t(localeRoute + 'otras')"></v-checkbox>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-container>
                            </v-form>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </div>
            <div v-if="current_slide != 3 && !trl_servicios">
                <div v-if="disabled && current_slide === 2">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <button v-on="on" class="cta boton" @click="avanzarSlide" :class="{disabled: disabled && current_slide != 1}">{{ $t(localeRoute + 'continuar') }}</button>
                        </template>
                        <span class="small">{{ $t(localeRoute + 'paracontinuar') }}</span>
                    </v-tooltip>
                </div>
                <div v-else>
                    <button class="cta boton" @click="avanzarSlide" :class="{disabled: disabled && current_slide != 1}">{{ $t(localeRoute + 'continuar') }}</button>
                </div> 
            </div>
            <div class="cta" v-else-if="!trl_servicios">
                <v-btn class="boton_secundario" color="primary" outlined v-if="$vuetify.breakpoint.xs && pref_tabs != 'personales'" @click="atrasarTab">Atrás</v-btn>
                <v-btn 
                    v-if="pref_tabs != 'colegios'" 
                    class="boton"
                    @click="avanzarTab"
                    :disabled="!valid_personales"
                >
                    {{ $t(localeRoute + 'continuar') }}
                </v-btn>
                <v-btn v-else class="boton" :loading="loading" @click="guardar">{{ $t(localeRoute + 'guardarsalir') }}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
import StDatePicker from '@/components/StDatePicker.vue'

export default {
	name: 'ModalNuevotrl',
    components: {StDatePicker, Service},
    props: ['preferencias', 'trl_servicios', 'usuario'],
    computed: {
        ...mapState([
            'usuario_loggeado', 'usuarios', 'empresas', 'idiomaGlobal'
        ]),
    },
    data() {
        return {
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            current_slide: 1,
            contenido_titulo: null,
            contenido_parrafo: null,
            disabled: false,
            trl: {},
            pref_tabs: null,
            conyuge: false,
            numero_cajas_hijos: 0,
            tiene_hijos: false,
            privacidad: {
                titular: false,
                conyuge: false
            },
			hijo_vacio: [],
            created: true,
            cambio_pasaporte: false,
            valid_personales: false,
            rules: {
                required: value => !!value || '',
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Formato de email no válido'
                },
            },
            loading: false,
            loader: null,
            ws: null,
            data: null
        }
    },

    watch: {
        privacidad: {
            handler(newComponents, oldComponents) {
                if (this.privacidad.titular && this.privacidad.conyuge) {
                    this.disabled = false;
                } else {
                    this.disabled = true;
                }
            },
            deep: true
        },
        conyuge() {
            if (this.conyuge && !this.preferencias) {
                this.trl.conyuge = {
                    nombre: null,
                    apellidos: null,
                    nacionalidad: null,
                    telefono: null,
                    email: null
                }
            } else if (this.conyuge && !this.trl.conyuge) {
                this.trl.conyuge = {
                    nombre: null,
                    apellidos: null,
                    nacionalidad: null,
                    telefono: null,
                    email: null
                }
            }
        },
        numero_cajas_hijos: {
            handler(newComponents, oldComponents) {
                let hijo = {
                    nombre: null,
                    apellidos: null,
                    nacimiento: null,
                    sexo: null,
                    curso: null,
                    idiomas: null
                };
                if (this.created && !this.preferencias) {
                    this.trl.preferencias.colegios.hijos = [hijo];
                } else if (oldComponents > newComponents) {
                    const hijos_a_borrar = oldComponents - newComponents;
                    this.trl.preferencias.colegios.hijos.splice(hijos_a_borrar * -1, hijos_a_borrar);
                } else if (!this.created && oldComponents < newComponents) {
                    const hijos_a_añadir = newComponents - oldComponents;
                    for (let i = 0; i < hijos_a_añadir; i++) {
                        this.trl.preferencias.colegios.hijos.push({...hijo});
                    }
                } else if (!this.trl.preferencias.colegios.hijos.length && oldComponents == 0 && newComponents == 1) {
					this.trl.preferencias.colegios.hijos.push(hijo);
				}
                this.created = false;
            }
        },
        loader() {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 5000)
            this.loader = null
        },
        tiene_hijos() {
            if (this.tiene_hijos && !this.trl.preferencias.colegios.hijos.length) {
                this.numero_cajas_hijos = 1;
            } else if (!this.tiene_hijos) {
                this.trl.preferencias.colegios.hijos = [];
                this.numero_cajas_hijos = 0;
            }
        }
    },
    created: function() {
        this.console = window.console;
        //según de donde entre, cargar datos de trl loggeado, o de trl cargado por cons/admin
        if (!this.usuario) {
            this.trl = {...this.usuario_loggeado};
            this.trl.servicios[0] ? this.cambiarContenido(this.trl.servicios[0].id) : '';
            !this.trl_servicios && this.trl.personales.pasaporte_imagen && !this.trl.personales.pasaporte_imagen.name ? this.getFiles(this.trl.personales.pasaporte_imagen) : '';
        } else {
            this.trl = {...this.usuario};
            !this.trl_servicios && !this.usuario_loggeado.tipo === 'TRL' && this.trl.personales.pasaporte_imagen && !this.trl.personales.pasaporte_imagen.name ? this.getFiles(this.trl.personales.pasaporte_imagen) : '';
            if (this.trl_servicios && this.trl.servicios[0]) this.cambiarContenido(this.trl.servicios[0].id);
        }

        //si entra desde el botón de preferencias
        if (this.preferencias) { 
            this.current_slide = 3;
            this.conyuge = this.trl.conyuge ? true : false;
            if (this.usuario_loggeado.tipo === 'TRL') {
                if (this.trl.preferencias.colegios.numero_hijos == 0) this.trl.preferencias.colegios.numero_hijos = 1;
                if (this.usuario_loggeado.servicios.find(serv => serv.id == 9) || this.trl.preferencias.colegios.hijos[0].nombre) {
                    this.tiene_hijos = true;
                    this.numero_cajas_hijos = this.trl.preferencias.colegios.numero_hijos;
                } else {
                    this.numero_cajas_hijos = 0; 
                    this.tiene_hijos = false;
                }
            }
        //si entra por primera vez, y tiene el servicio de coles, se le carga una caja de hijo vacía
        } else if (!this.trl_servicios) {
            if (this.usuario_loggeado.servicios.find(serv => serv.id == 9)) {
                this.numero_cajas_hijos = 1;
            } else { this.numero_cajas_hijos = 0;}
        }

        //si tiene servicio con conyuje o hijos
        if (this.trl.servicios.find(serv => serv.id == 4) && this.trl.servicios.find(serv => serv.id == 9)) {
            this.privacidad.conyuge = false;
        } else {this.privacidad.conyuge = true;}
    },
    mounted: function() {},
    methods: {
        openFile(ref) {
            document.getElementById(ref).click()
        },
        cerrarModal() {
            this.$emit("closeModalNuevotrl", true);
        },
        avanzarSlide() {
            if (this.current_slide != 2) {
                this.current_slide++;
            } else {
                if (this.privacidad.titular && this.privacidad.conyuge) {
                    this.aceptarPrivacidad();
                    this.disabled = false;
                    this.current_slide++;
                } else {
                    this.disabled = true;
                }
            }  
        },
        cambiarContenido(id) {
            let servicio = this.trl.servicios.find(servicio => servicio.id == id);
            if (servicio.original) {
                this.contenido_titulo = servicio.original['nombre_' + this.idiomaGlobal] + (servicio.sub_index ? (' – ' + servicio.original['variantes_' + this.idiomaGlobal][servicio.sub_index]) : servicio.nombre_sub ? (' – ' + servicio.nombre_sub) : '');
                this.contenido_parrafo = servicio.original['descripcion_' + this.idiomaGlobal];
            } else {
                this.contenido_titulo = servicio.nombre + (servicio.nombre_sub ? (' – ' + servicio.nombre_sub) : '');
                this.contenido_parrafo = servicio.info;
            }
        },
        avanzarTab() {
            if (this.pref_tabs === "personales") {
                this.pref_tabs = "traslado"
            } else if (this.pref_tabs === "traslado") {
                this.pref_tabs = "colegios"
            } 
        },
        atrasarTab() {
            if (this.pref_tabs === "traslado") {
                this.pref_tabs = "personales"
            } else if (this.pref_tabs === "colegios") {
                this.pref_tabs = "traslado"
            } 
        },
        getEmpresa() {
            let id = this.trl.personales.empresa;
            let empresa = this.empresas.find( empresa => empresa.id == id );
            return empresa;
        },
        aceptarPrivacidad() {
            this.data = {id: this.trl.id, privacidad: this.privacidad, updater: this.usuario_loggeado.id};
            this.ws = 'trlPrivacyPolicy';
        },
        getFiles(url) {
            let that = this;
            async function createFile(){
                let response = await fetch(url,{mode: 'no-cors'});
                let data = await response.blob();
                var index = url.lastIndexOf("/") + 1;
                var filename = url.substr(index);
                that.trl.personales.pasaporte_imagen = new File([data], filename);
            }
            createFile();
        },
        guardar() {
            this.loader = 'loading';
            let calls = [];
            this.trl.updater = this.usuario_loggeado.id;
            calls.push({ws: 'trlPersonalDataReg', data: this.trl});
            calls.push({ws: 'trlRelocation_data', data: this.trl});
            if (this.numero_cajas_hijos > 0) {calls.push({ws: 'trlSchoolPreferences', data: this.trl});} 
            if (this.cambio_pasaporte) {
                calls.push({
                    ws: this.trl.personales.pasaporte_imagen ? 'trlIdentityPhoto' : 'deleteTrlIdentityPhoto', 
                    data: {id: this.trl.id, img: this.trl.personales.pasaporte_imagen, updater: this.usuario_loggeado.id}
                })
            }
            this.data = {calls: calls};
            this.ws = 'multiple';
        },
        moverTab(dir) {
            if (dir) {
                const container = this.$refs.tabs;
                // container.scrollLeft = container.scrollLeft + 100;
                container.scrollTo({
                    left: container.scrollLeft + 200,
                    behavior: 'smooth'
                });   
            } else {
                const container = this.$refs.tabs;
                container.scrollTo({
                    left: container.scrollLeft > 0 ? container.scrollLeft - 200 : container.scrollLeft,
                    behavior: 'smooth'
                }); 
            }
        },
        newTabPrivacidad() {
            let routeData = this.$router.resolve({name: 'PoliticaPrivacidad', params: {'pagina': 'privacidad'}});
            window.open(routeData.href, '_blank');
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.trl.updater = this.usuario_loggeado.id;
            if (response.ws === 'trlPersonalDataReg') {
                this.data = this.trl;
                this.ws = 'trlRelocation_data';
            } else if (response.ws === 'trlRelocation_data') {
                if (this.numero_cajas_hijos > 0) {
                    this.data = this.trl;
                    this.ws = 'trlSchoolPreferences';
                } else if (this.cambio_pasaporte) {
                    this.data = {id: this.trl.id, img: this.trl.personales.pasaporte_imagen, updater: this.usuario_loggeado.id};
                    this.ws = 'trlIdentityPhoto';
                }
            } else if (response.ws === 'trlSchoolPreferences') {
                if (this.cambio_pasaporte) {
                    this.data = {id: this.trl.id, img: this.trl.personales.pasaporte_imagen, updater: this.usuario_loggeado.id};
                    this.ws = 'trlIdentityPhoto';
                }
            } else if (response.ws === 'getUser') {
                this.$store.dispatch('setUsuarioLoggeado',response.data);
                this.$emit("closeModalNuevotrl", true);
            }

            if (response.ws === 'multiple' || (response.ws === 'trlSchoolPreferences' && !this.cambio_pasaporte) || (response.ws === 'trlRelocation_data' && !this.numero_cajas_hijos && !this.cambio_pasaporte)) {
                if (this.usuario_loggeado.tipo === 'TRL') {
                    this.data = this.trl.id;
                    this.ws = 'getUser';
                } else {
                    this.$emit("closeModalNuevotrl", true);
                }
            }
        }
	}
}
</script>
<style scoped lang="scss">
.v-application #app .modal .submodal {
    display: flex;
    flex-flow: column;
    justify-content: space-between; 
    min-height: 75vh;
    max-height: 90vh;
    .slide {
        // height: 60vh;
        margin-bottom: 2em;
        overflow: auto;
        & > .titulo {
            font-size: 2em;
            margin: .6em 0 .4em;

        }
        .slide_1 {
            margin: 0 auto;
            text-align: center;
            max-height: 87%;
            overflow-y: auto;
            .logos {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                .imagen {
                    height: 4em;
                    width: 8em;
                    margin: 1.7em 1.2em;
                    object-fit: contain;
                }
                svg {width: 1.2em; height: 1.2em;}
            }
            &>.parrafo {max-width: 45em; margin: 0 auto;}
            &>.titulo.bold {margin: 1.5em 0 0;}
            .tab_container {
                margin: 1em auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .flecha {
                    display: none; filter: 
                    drop-shadow(var(--sombra_ext));
                    cursor: pointer;
                }
                &.slider {
                    .flecha {display: unset;}
                    .tabs {justify-content: flex-start;}
                }
                .tabs {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow-x: scroll;
                    scroll-snap-type: mandatory;
                    scroll-snap-points-x: repeat(10em);
                    scroll-snap-type: x mandatory;
                    scroll-padding: 1em;
                    margin: 0 1em;
                    padding: 1em 2em;
                    .card {
                        position: relative;
                        scroll-snap-align: start;
                        margin: 0 1em;
                        min-width: 10.5em;
                        height: 8em;
                        padding: 1em;
                        transition: all .2s ease-in;
                        top: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.active {
                            top: -3px;
                            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
                            border: 1px solid rgba(0,0,0,.2);
                        }
                        &:last-of-type {
                            visibility: hidden;
                        }
                        cursor: pointer;
                    }
                }
            }
            .contenido {
                border: 1px solid rgba(0,0,0,.1);
                border-radius: 4px;
                padding: 1em;
                .titulo {
                    margin: 0 0 .5em;
                    text-align: left;
                }
                .parrafo {text-align: left;}
            }
        }
        &.politica {
            // max-height: 90%;
            // overflow-y: auto;
            .texto_politica {
                // max-height: 35vh;
                // overflow: auto;
                margin: 1em 0;
            }
            .checks {
                max-width: 100%;
                .small::v-deep.v-input {
                    margin-top: .5em;
                    .v-label {
                        font-size: 1.1em;
                        color: var(--color_texto);
                        font-family: var(--nunito);
                    }
                }
            }
        }
        .form {
            height: 50vh;
            margin-top: 1em;
            .nota_scroll {
                background-color: var(--purple01);
                padding: 1.5em 2em;
                position: relative;
                .icon-scroll,
                .icon-scroll:before {
                    position: absolute;
                    top: 60%;
                    right: 1em;
                    transform: scale(0.6);
                }
                .icon-scroll {
                    width: 40px;
                    height: 60px;
                    margin-left: -20px;
                    margin-top: -38px;
                    box-shadow: inset 0 0 0 1.5px var(--color_texto);
                    border-radius: 25px;
                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: var(--color_texto);
                        left: 16px;
                        top: 8px;
                        border-radius: 4px;
                        animation-duration: 1.5s;
                        animation-iteration-count: infinite;
                        animation-name: scroll;
                    }
                }

                @keyframes scroll {
                    0% {opacity: 1}
                    100% {
                        opacity: 0;
                        transform: translateY(43px);
                    }   
                }
            }
            .datos_conyuge {
                margin-top: -2em;
            }
            .checkbox_container.mitad {
                &.izda {padding-right: 0;}
                &.dcha {padding-left: 0;}
                .checkbox_big {
                    width: 50%;
                }
            }
            .no_padding_dcha {
                padding-left: 0;
            }
            .area_titulo.label {font-weight: normal; margin-bottom: .8em; font-size: 1em;}
            .hijo {
                margin-bottom: 1em;
                .area_titulo.light {font-weight: normal; font-size: 1.1em;}
                .area_titulo:not(.light) {margin-bottom: -1em;}
            }
            .num_hijos {
                display: flex;
                .hijo {
                    cursor: pointer;
                    transition: all .2 ease-in;
                    path {fill: var(--gray03);}
                }
                &.num_1>:first-child path {fill: var(--purple03);}
                &.num_2>:nth-child(-n+2) path {fill: var(--purple03);}
                &.num_3>:nth-child(-n+3) path {fill: var(--purple03);}
                &.num_4 .hijo path {fill: var(--purple03);}
            }
        }
    }
    .cta {
        align-self: flex-end;
        // margin: 2em 0 0;
        .boton_secundario {
            margin: 2em 1em 0;
            display: none;
            text-transform: capitalize;
        }
        &.boton {width: 20%;}
        .boton {
            width: 20%;
            margin: 2em .5em 0;
            &.disabled {
                opacity: .5; 
                cursor: not-allowed;
            }
        }
        &.disabled {
            position: relative;
        }
    }
    .preferencias {
        width: 100%;
        display: block;
        margin-top: 1em;
    }
}

@media (max-width: 900px) {
    .v-application #app .modal .submodal {
        min-height: 50vh;
        max-height: 90vh;
        .slide {
            // height: 65vh;
            margin-bottom: 0;
            & > .titulo {
                font-size: 1.7em;
                margin: .7em 0 .3em;
            }
            .slide_1 {
                .tab_container {
                    &.slider {
                        .flecha {
                            transform: scale(.6);
                        }
                    }
                    .tabs {
                        margin: 0 .7em;
                        padding: .7em 1.7em;
                        .card {
                            margin: 0 1em;
                            min-width: 10.5em;
                            height: 8em;
                            padding: 1em;
                        }
                    }
                }
            }
            &.politica {
                // max-height: 70vh;
            }
            .form {
                // height: 42vh;
                .nota_scroll {
                    p {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media (max-width: 760px) {
    .v-application #app .modal .submodal {
        min-height: 70vh;
        max-height: 83vh;
        margin: 30px auto;
        .slide {
            // height: 80vh;
            & > .titulo {
                margin: 1.6em 0 .4em;
            }
            .slide_1 {
                .tab_container {
                    .tabs {
                        .card {
                            margin: 0 .7em;
                            min-width: 9.5em;
                            height: 6em;
                            padding: .7em;
                        }
                    }
                }
            }
        }
        .cta .boton_secundario {display: inline-block;}
    }
}
</style>
