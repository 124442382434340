<template>
    <div>
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import {webServices} from '@/api/api.js'
import Service from '@/components/Service.vue'

export default {
	name: 'ServiciosBack',
    components: {Service},
    data() {
        return {
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            logoutTimer: null,
            ult_con: null,
            ws: null,
            data: null,
            ws_spinner: false
        }
    },

    watch: {
        usuario_loggeado() {
            if (this.usuario_loggeado.id) {
                this.getUsuarios();
            }
        },
        ult_con(newComponents, oldComponents) {
            oldComponents ? (newComponents - oldComponents) > 1800000 ? this.autoLogout() : '': '';
        }
    },

	computed: {
        ...mapState([
            'usuarios', 'paquetes', 'servicios', 'viviendas', 'empresas', 'usuario_loggeado', 'eventos', 'token', 'sectores', 'loading'
        ]),
    },

    methods: {
        autoLogin() {
            console.log('autologin');
            let ult_log_pasado = this.ult_con ? (new Date().getTime() - this.ult_con) < 1800000 : true;
            !ult_log_pasado ? console.log('timeout logout') : '';
            if (localStorage.getItem('isLogged') && localStorage.getItem('toquen') && localStorage.getItem('usuario_logged') && ult_log_pasado) {
                console.log('loggeado y con token');
                this.$store.dispatch('setToken', localStorage.getItem('toquen'));
                this.getUsuarioLoggeado();
            }
        },
        autoLogout() {
            console.log('autologout');
            localStorage.removeItem('isLogged');
            localStorage.removeItem('usuario_logged');
            localStorage.removeItem('ult_con');
            localStorage.removeItem('toquen');
            if (this.$router.currentRoute.path != '/Login' && this.$router.currentRoute.path != '/') {
                this.$router.push('/');
                this.$store.dispatch('setLoading', false);
            } 
        },
        setTimer() {
            this.logoutTimer = setTimeout(() => {
                this.autoLogout();
            }, 1800000);
        },
        resetTimer() {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = null; 
            this.setTimer();
            this.ult_con = new Date().getTime();
            localStorage.setItem('ult_con', this.ult_con);
        },
        apiLogin() {
            let ws = webServices.getToken();
            fetch(ws.url, ws.requestOptions)
                .then(response => {return response.text();})
                .then(result => {
                    localStorage.setItem('toquen', result);
                    this.$store.dispatch('setToken', result);
                    this.getUsuarioLoggeado();
                })
                .catch(error => console.log('error', error));
        },
        getData() {
            let calls = [];
            let data_task = {};
            if (this.usuario_loggeado.tipo === 'Consejera') {data_task = 'cons=' + this.usuario_loggeado.id}
            else if (this.usuario_loggeado.tipo === 'RRHH') {data_task = 'rrhh=' + this.usuario_loggeado.id}
            else if (this.usuario_loggeado.tipo === 'TRL') {data_task = 'trl=' + this.usuario_loggeado.id}

            calls.push({ws: 'packList', data: ''});
            calls.push({ws: 'companyList', data: ''});
            calls.push({ws: 'houseList', data: ''});
            calls.push({ws: 'taskList', data: data_task});
            if (this.usuario_loggeado.tipo === 'TRL') {
                calls.push({ws: 'fileList', data: this.usuario_loggeado.id});
            } 
            this.data = {calls: calls};
            this.ws = 'multiple';
        },
        getUsuarios() {
            const tipo = this.usuario_loggeado.tipo;
            this.data = {queries: '?order_by=recents&include_finished=no'}
            if (tipo === 'Admin') {this.data.queries += '&type=trl,admin,cons,rrhh,legal';}
            else if (tipo === 'Consejera') {this.data.queries += '&cons=' + this.usuario_loggeado.id;}
            else if (tipo === 'RRHH') {this.data.queries += '&rrhh=' + this.usuario_loggeado.id;}
            else if (tipo === 'TRL') {this.data.queries += '&type=cons,admin'}
            else if (tipo === 'Legal') {this.data.queries += '&type=trl&available_legal_user_access=yes'}
            // else {this.data.queries += '&type=admin,cons,rrhh,trl'}
            this.ws_spinner = true;
            this.ws = 'userList';
        },
        getUsuarioLoggeado() {
            if (!this.servicios.length) {
                this.ws = 'serviceList';
            } else {
                let intentos = 0;
                let ws = webServices.getUserEnc(this.token, localStorage.getItem('usuario_logged'));
                let fetchUser = () => {
                    fetch(ws.url, ws.requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            if (JSON.parse(result).code != '401') {
                                this.$store.dispatch('setUsuarioLoggeado', JSON.parse(result).data);
                                this.$emit("logged");
                                this.$store.dispatch('setLoading', false);
                            } else {
                                if (JSON.parse(result).message === "Expired JWT Token") {
                                    this.apiLogin();
                                } else {
                                    localStorage.removeItem('isLogged');
                                    this.autoLogin();
                                }
                            }
                        })
                        .catch(error => {
                            console.log('error', error);
                            this.$store.dispatch('setLoading', true);
                            if (intentos < 2) {
                                intentos++;
                                fetchUser();
                            } else {
                                this.$store.dispatch('setLoading', false);
                                this.$emit('errorImportante', error);
                            }
                        });
                }
                fetchUser();
            }
        },
        esImagen(formato) {
            return formato === 'jpg' || formato === 'jpeg' || formato === 'png' || formato === 'apng' ? true : false
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'getUser') {
                if (JSON.parse(response.data).code != '401') {
                    this.$store.dispatch('setUsuarioLoggeado', JSON.parse(response.data).data);
                    this.$emit("logged");
                    // this.$store.dispatch('setLoading', false);
                } else {
                    this.apiLogin();
                }
            } 
            else if (response.ws === 'userList') {
                let usuarios = response.data;
                this.$store.dispatch('setUsuarios', usuarios);
                this.$store.dispatch('setLoading', false);
                this.getData();
            } else if (response.ws === 'multiple') {
                let paquetes = response.data[0].data;
                this.$store.dispatch('setPaquetes', paquetes);

                let empresas = response.data[1].data;

                empresas.forEach(emp => {
                    emp.sector = this.sectores.find(sec => sec.id == emp.sector_id).nombre;
                });
                this.$store.dispatch('setEmpresas', empresas);

                let viviendas = response.data[2].data;
                this.$store.dispatch('setViviendas', viviendas);

                let eventos = response.data[3].data;
                eventos.length ? this.$store.dispatch('setEventos', eventos) : '';
                // this.$store.dispatch('setLoading', false);

                if (this.usuario_loggeado.tipo === 'TRL') {
                    let archivos = [];
                    response.data[4].data.forEach(ar => {
                        let tipo = JSON.parse(ar.type);

                        let formato = ar.filename.split('.').pop().toLowerCase();
                        if (formato === 'pdf') { formato = 'pdf'}
                        else if (formato === 'doc' || formato === 'txt' || formato === 'docx' || formato === 'rtf') { formato = 'doc'}
                        else { this.esImagen(formato) ? '' : formato = 'img'}
                        archivos.push({
                            id: ar.id,
                            nombre: ar.filename,
                            fecha: this.$moment(parseInt(ar.datetime) * 1000).format('YYYY-MM-DD HH:mm'),
                            subido_por: tipo[1],
                            tipo: tipo[0],
                            formato: formato,
                            url: ar.url
                        })
                    });
                    this.$store.dispatch('setArchivos', archivos);
                } 
            } else if (response.ws === 'serviceList') {
                this.$store.dispatch('setServicios', response.data);
                this.getUsuarioLoggeado();
            } else {
                debugger;
            }
        }
	},
    created: function() {
    },
    mounted: function() {
        this.console = window.console;
        this.$store.dispatch('setLoading', true);
        if (this.$router.currentRoute.path != '/Login' && this.$router.currentRoute.path != '/') {
            this.ult_con = localStorage.getItem('ult_con') ? localStorage.getItem('ult_con') : null;
            this.autoLogin();
        } else {
            localStorage.removeItem('isLogged');
            localStorage.removeItem('usuario_logged');
            localStorage.removeItem('toquen');
            this.$store.dispatch('setLoading', false);
        }
        this.events.forEach(event => {
            window.addEventListener(event, this.resetTimer);
        });
        this.setTimer();
    }
}
</script>
