<template>
    <div class="panel_izda filtros">
        <v-text-field
            class="searcher"
            :type="'text'"
            v-model="filtro"
            :placeholder="'Buscar un servicio o paquete'"
            :append-icon="'mdi-magnify'"
        ></v-text-field>
        <div class="servicios-list">
            <p class="nav_link">Categorías</p>
            <v-container fluid class="tipo checkbox_container">
                <v-checkbox class="checkbox" label="Todos" :disabled="disableChecks" v-model="checkAll" @click.native="setCategoriesChecks"></v-checkbox>
                <v-checkbox v-for="(categoria, index) in categorias" :key="index" 
                    v-model="categoria.active" 
                    :label="categoria.nombre" 
                    :disabled="disableChecks"
                    class="checkbox">
                </v-checkbox>
            </v-container>
        </div>
        <v-expansion-panels class="categorias-expansion-panel">
            <v-expansion-panel>
                <v-expansion-panel-header>Filtrar servicios/paquetes</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field
                        :type="'text'"
                        v-model="filtro"
                        :placeholder="'Buscar un servicio o paquetet'"
                        :append-icon="'mdi-magnify'"
                    ></v-text-field>
                    <p class="nav_link">Categorias</p>
                    <v-container fluid class="tipo checkbox_container">
                        <v-checkbox class="checkbox" label="Todos" :disabled="disableChecks" v-model="checkAll" @click.native="setCategoriesChecks"></v-checkbox>
                        <v-checkbox v-for="(categoria, index) in categorias" :key="index" 
                            v-model="categorias.find(el => el.nombre === categoria.nombre).active" 
                            :label="categoria.nombre" class="checkbox">
                        </v-checkbox>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'PanelFiltrosServicios',
	props: ['lista_categorias', 'disableChecks'],

    data() {
        return {
            filtro: "",
            categorias: [],
            todos: true,
            checkAll: true,
            manualCheckAllFlag: false
        }
    },

    watch: {
        filtro() {
            this.filtrarServicios();
        },
        categorias: {
            handler(newComponents, oldComponents) {
                if (this.categorias.length) this.filtrarServicios();
                
                this.setCheckAllStatus();
            },
            deep: true
        },
        lista_categorias() {
            this.categorias = [...this.lista_categorias];
        }
    },

	computed: {
        ...mapState([
            'servicios'
        ]),
    },

    methods: {
        filtrarServicios() {
            let categorias_filtradas = [];
            this.categorias.forEach(cat => {
                if (cat.active) categorias_filtradas.push(cat.nombre);
            });
            this.$emit('filtrarServicios',  {'filtro': this.filtro, "categorias": categorias_filtradas});
        },

        setCheckAllStatus() {
            if (!this.manualCheckAllFlag) {
                for (let i=0; i < this.categorias.length; i++) {
                    if (this.categorias[i].active) {
                        this.checkAll = true;
                    } else {
                        this.checkAll = false;
                        return;
                    }
                }
            } else {
                this.manualCheckAllFlag = false;
            }
        },

        setCategoriesChecks() {
            this.categorias.forEach((el) => {
                el.active = this.checkAll;
            });
            this.manualCheckAllFlag = true;
        }
	}
}
</script>
<style scoped lang="scss">
.v-application #app .filtros {
    .categorias-expansion-panel {
        display: none;
    }
    .nav_link {
        padding-left: 0;
        width: 100%;
        border-bottom: 2px solid var(--purple03);
    }
    .tipo {
        padding-left: 0;
        ::v-deep .v-input--selection-controls__input {
            margin-right: 1em;
        }
    }
    .estado {
        padding-left: 0;
        ::v-deep .v-radio {
            width: calc(50% - 1em);
            .v-input--selection-controls__input {
                margin-right: .5em;
            }
        }
    }
}
 @media (max-width: 900px) {
     .v-application #app .filtros {
        .searcher {
            display: none;
        }
        .categorias-expansion-panel {
            display: block;
        }
        .servicios-list {
            display: none;
        }
     }
 }
</style>
