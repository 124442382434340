<template>
    <div class="modal">
        <service 
            :ws='ws'
            :data="data" 
            :spinner="ws_spinner"
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <modal-simple v-if="modal_simple" :tipo="'borrar_' + tipo_servicio" :servicio="servicio" @closeModalSimple="closeModalSimple"/>
        <div class="submodal succes" v-if="success">
            <p v-if="success != 'servicio borrado'" class="titulo">{{servicio ? 'Editar' : 'Nuevo'}} {{tipo_servicio}}</p>
            <p v-else class="titulo bold">Eliminar {{tipo_servicio}}</p>
            <div class="boton fab check">
                <v-icon class="icono">mdi-check</v-icon>
            </div>
            <p v-if="success != 'servicio borrado'" class="parrafo">Tu {{tipo_servicio}} se ha {{!servicio ? 'creado' : 'modificado'}} correctamente.</p>
            <p v-else class="parrafo">El {{tipo_servicio}} se ha eliminado correctamente.</p>
        </div>
        <div class="submodal" v-else>
            <svg class="cerrar_modal" @click="cerrarModal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <p class="titulo">{{servicio ? 'Editar' : 'Nuevo'}} {{tipo_servicio === 'servicio' ? 'servicio' : 'paquete'}}</p>
            <v-form class="form" v-if="tipo_servicio === 'servicio'" v-model="valid_serv">
                <p class="centered" v-if="nuevo_servicio.id === 43">*El servicio de suministros es especial. No se puede eliminar ni se pueden modificar sus tareas ni variantes</p>
                <p class="centered" v-else-if="nuevo_servicio.id === 42">*El servicio de cierre es especial. No se puede eliminar ya que tiene campos extras.</p>
                <p class="centered" v-else-if="nuevo_servicio.id === 4 || nuevo_servicio.id === 9">*Este servicio es especial. No se puede eliminar ya que afecta al funcionamiento de algún campo de información del TRL.</p>
              	<v-container>
                    <v-row>
						<v-col cols="10">
                            <h3 class="area_titulo">General</h3>
                        </v-col>
                        <v-col cols="2">
                            <div v-if="servicio && servicio.id != 43 && servicio.id != 42 && servicio.id != 4 && servicio.id != 9" class="boton fab borrar" @click="modal_simple = true">
                                <v-icon class="icono">mdi-delete</v-icon>
                            </div>
                        </v-col>
					</v-row>
					<v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre ES*"
                                v-model="nuevo_servicio.nombre_es"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre EN*"
                                v-model="nuevo_servicio.nombre_en"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-textarea
                                label="Descripción ES*"
                                v-model="nuevo_servicio.descripcion_es"
                                :rules="[rules.required]"
                                rows="3"
                                row-height="12"
                                class="sombra"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-textarea
                                label="Descripción EN*"
                                v-model="nuevo_servicio.descripcion_en"
                                :rules="[rules.required]"
                                rows="3"
                                row-height="12"
                                class="sombra"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6">
							<v-combobox
                                label="Categoría*"
                                append-icon=''
                                v-model="nuevo_servicio.categoria"
                                :items="lista_categorias"
                                :rules="[rules.required]"
                                hint="Selecciona una de la lista o crea una nueva escribiendo un nombre"
                                persistent-hint
                                @input.native="onInputChange"
                            ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="nuevo_servicio.id != 43">
							<p class="parrafo">Fecha inicio/fin</p>
							<v-radio-group 
                                class="checkbox_container" 
                                v-model="nuevo_servicio.fecha_inicio" 
                                row
                                hint="Importante: si eliges “No” el servicio no aparecerá en la ficha del usuario, solo la verá el TRL a modo informativo. Ej.- Paquete bienvenida"
                                persistent-hint
                            >
                                <v-radio class="checkbox" label="Sí" :value="1"></v-radio>
                                <v-radio class="checkbox" label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6">
							<v-select
                                v-model="nuevo_servicio.orden"
                                :items="lista_servicios"
                                label="Orden*"
                                hint="Selecciona el servicio precedente. El nuevo servicio será colocado detrás del elegido en esta lista."
                                persistent-hint
                            ></v-select>
                        </v-col>
                    </v-row>
             	</v-container>
				<v-container v-if="nuevo_servicio.id != 43" class="service-variants">
                    <v-row>
						<v-col cols="12">
                            <h3 class="area_titulo">Variantes de servicio</h3>
                            <p>Podrá tener un máximo de 4.<br> Ej.- Compra vivienda Normal | Premium (rellenar solo si tiene variantes dentro del mismo servicio)</p>
                        </v-col>
                    </v-row>
                    <div v-for="(serviceVariant, index) in serviceVariants" :key="index" class="row-langs">
                        <v-text-field
                            v-model="serviceVariant[0]"
                            class="input"
                            :disabled="editServiceVariantsIndex === index ? false : true"
                        ></v-text-field>
                        <v-text-field
                            v-model="serviceVariant[1]"
                            class="input"
                            :disabled="editServiceVariantsIndex === index ? false : true"
                        ></v-text-field>
                        <div class="action-buttons" v-if="editServiceVariantsIndex === index ? false : true">
                            <div class="boton fab" @click="editServiceVariants(index)" :disabled="disabledOnEditServiceVariants">
                                <v-icon class="icono">mdi-pencil-outline</v-icon>
                            </div>
                            <div class="boton fab" @click="deleteServiceVariants(index)" :disabled="disabledOnEditServiceVariants">
                                <v-icon class="icono">mdi-trash-can-outline</v-icon>
                            </div>
                        </div>
                        <div class="action-buttons confirm" v-else>
                            <div class="boton fab check" @click="confirmEditServiceVariants(index)" :disabled="!serviceVariant[0] || !serviceVariant[1]">
                                <v-icon class="icono">mdi-check</v-icon>
                            </div>
                            <div class="boton fab close" @click="cancelEditServiceVariants(index)">
                                <v-icon class="icono">mdi-close</v-icon>
                            </div>
                        </div>                        
                    </div>
					<div v-if="serviceVariants.length < 4" class="row-langs">
                        <v-text-field
                            v-model="serviceVariantsEs"
                            label="Nombre opción ES"
                            class="input"
                            :disabled="disabledOnEditServiceVariants"
                        ></v-text-field>
                        <v-text-field
                            v-model="serviceVariantsEn"
                            label="Nombre opción EN"
                            class="input"
                            :disabled="disabledOnEditServiceVariants"
                        ></v-text-field>
                        <div class="action-buttons">
                            <v-btn class="boton create-btn" block small :disabled="!serviceVariantsEs || !serviceVariantsEn" @click="addServiceVariants">Crear</v-btn>
                        </div>
					</div>
                    <p v-else class="mt-4 d-flex align-center"><v-icon class="icon mr-2" size="20">mdi-information-outline</v-icon>Están creados el máximo número de variantes, elimina alguno si quieres añadir otro.</p>
				</v-container>
				<v-container v-if="nuevo_servicio.fecha_inicio && nuevo_servicio.id != 43" class="tasks">
                    <v-row >
						<v-col cols="12">
                            <h3 class="area_titulo">Tareas</h3>
                        </v-col>
                    </v-row>
                    <div v-for="(task, index) in tasks" :key="index" class="row-langs">
                        <v-text-field
                            v-model="task[0]"
                            class="input"
                            :disabled="editTaskIndex === index ? false : true"
                        ></v-text-field>
                        <v-text-field
                            v-model="task[1]"
                            class="input"
                            :disabled="editTaskIndex === index ? false : true"
                        ></v-text-field>
                        <div v-if="editTaskIndex === index ? false : true" class="action-buttons">
                            <div class="boton fab" @click="editTask(index)" :disabled="disabledOnEditTasks">
                                <v-icon class="icono">mdi-pencil-outline</v-icon>
                            </div>
                            <div class="boton fab" @click="deleteTask(index)" :disabled="disabledOnEditTasks">
                                <v-icon class="icono">mdi-trash-can-outline</v-icon>
                            </div>
                        </div>
                        <div v-else class="action-buttons confirm">
                            <div class="boton fab check" @click="confirmEditTask(index)" :disabled="!task[0] || !task[1]">
                                <v-icon class="icono">mdi-check</v-icon>
                            </div>
                            <div class="boton fab close" @click="cancelEditTask(index)">
                                <v-icon class="icono">mdi-close</v-icon>
                            </div>
                        </div>
                    </div>
					<div class="row-langs">
                        <v-text-field
                            v-model="taskEs"
                            label="Nombre tarea ES*"
                            class="input"
                            :disabled="disabledOnEditTasks"
                        ></v-text-field>
                        <v-text-field
                            v-model="taskEn"
                            label="Nombre tarea EN*"
                            class="input"
                            :disabled="disabledOnEditTasks"
                        ></v-text-field>
                        <div class="action-buttons">
                            <v-btn class="boton create-btn" block small :disabled="!taskEs || !taskEn" @click="addTask">Crear</v-btn>
                        </div>
					</div>
				</v-container>
            </v-form>
            <v-form class="form" v-if="tipo_servicio === 'paquete'" v-model="valid_paq">
              	<v-container>
                    <div v-if="servicio" class="boton fab borrar" @click="modal_simple = true">
                        <v-icon class="icono">mdi-delete</v-icon>
                    </div>
                    <v-row>
						<v-col cols="12">
                            <h3 class="area_titulo">General</h3>
                        </v-col>
					</v-row>
					<v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre*"
                                v-model="nuevo_paquete.nombre"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
							<v-select
                                label="Orden*"
                                v-model="nuevo_paquete.orden"
                                :items="lista_paquetes"
                                hint="Selecciona el paquete precedente. El nuevo paquete será colocado detrás del elegido en esta lista."
                                persistent-hint
                            ></v-select>
                        </v-col>
                    </v-row>
             	</v-container>
				<v-container>
					<v-row>
						<v-col cols="12">
                            <h3 class="area_titulo">Servicios</h3>
                        </v-col>
                    </v-row>
                    <v-row v-for="(tipo, index) in servicio_tipos" :key="index" class="todos_servicios">
                        <v-col cols="12">
                            <h3 class="area_titulo">{{tipo}}</h3>
                        </v-col>
                        <v-row class="lista_servicios">
                            <div class="servicio" v-for="(servi, subindex) in servicios_ordenados[tipo]" :key="subindex">
                                <div class="card"
                                    @click.stop="toggleServicio(servi)"
                                    :class="{checked: nuevo_paquete.servicios.find(serv => serv.id === servi.id)}"
                                >
                                    <p class="parrafo" >
                                        {{servi.nombre}}<br>
                                        <b>{{showSubName(servi)}}</b>
                                    </p>
                                    <div class="mostrar_sub" v-if="servi.sub.length">
                                        <svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2" cy="2" r="2" fill="#A291D6"/><circle cx="10" cy="2" r="2" fill="#A291D6"/><circle cx="18" cy="2" r="2" fill="#A291D6"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="card subcards" v-if="servi.sub.length && servi.show_sub">
                                    <p class="parrafo" v-for="(sub, iindex) in servi.sub" :key="iindex"
                                        @click="toggleSub(servi, servi.sub[iindex], iindex)"
                                    >
                                        {{sub}}
                                    </p>
                                </div>
                            </div>
                        </v-row>
                    </v-row>
				</v-container>
            </v-form>
            <v-btn class="boton cta" :disabled="tipo_servicio === 'servicio' ? !valid_serv : (!valid_paq || empty_paq)" :loading="loading" @click="crearServicio(tipo_servicio === 'servicio' ? nuevo_servicio : nuevo_paquete)">{{servicio ? "Guardar" : 'Crear'}}</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'
import ModalSimple from '@/components/ModalSimple.vue'

export default {
	name: 'ModalCrearServicio',
    components: {Service, ModalSimple},
	props: ['servicio', 'tipo_servicio', 'servicios_ordenados', 'servicio_tipos'],
    computed: {
        ...mapState([
          'paquetes', 'servicios'
        ]),
        empty_paq() {
            return !this.nuevo_paquete.servicios.length;
        }
    },
    data() {
        return {
            modal_simple: false,
            success: null,
            nuevo_servicio: {
                orden: 1,
                categoria: '',
                nombre_es: '',
                nombre_en: '',
                descripcion_es: '',
                descripcion_en: '',
                fecha_inicio: 1,
                variantes_es: [],
                variantes_en: [],
                tareas_en: [],
                tareas_es: [],
                tareas: [[], []]
            },
            nuevo_paquete: {
                nombre: '',
                orden: 1,
                servicios: []
            },
			lista_servicios: [],
			lista_categorias: [],
            disabled: true,
            valid_serv: false,
            valid_paq: false,
            loading: false,
            loader: null,
            rules: {
                required: value => !!value || '',
            },
            ws: null,
            data: null,
            ws_spinner: false,
            tasks: [],
            taskEs: '',
            taskEn: '',
            editTaskIndex: -1,
            tempOriginalTasks: [],
            disabledOnEditTasks: false,
            serviceVariants: [],
            serviceVariantsEs: '',
            serviceVariantsEn: '',
            editServiceVariantsIndex: -1,
            tempOriginalserviceVariants: [],
            disabledOnEditServiceVariants: false
        }
    },

    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 5000)
            this.loader = null
        },
        nuevo_servicio: {
            handler(newComponents, oldComponents) {
                // if (this.nuevo_usuario.personales.nombre && this.nuevo_usuario.personales.apellidos && this.nuevo_usuario.contacto.email_profesional) {
                //     this.disabled = false;
                // } else {
                //     this.disabled = true;
                // }
            },
            deep: true
        },
    },
    methods: {
        cerrarModal(tipo) {
            this.$emit("closeModalServicio", tipo);
        },
        closeModalSimple(action) {
            this.modal_simple = false;
            if (action === 'delete_servicio') {
                this.data = this.servicio.id;
                if (this.servicio && this.tipo_servicio === 'servicio') {
                    this.ws_spinner = true;
                    this.ws = 'serviceRemove';
                } else if (this.servicio && this.tipo_servicio === 'paquete') {
                    this.ws_spinner = true;
                    this.ws = 'packRemove';
                }
            }
        },
        crearServicio(servicio) {
            let servicioId = servicio.categoria && servicio.categoria.value;
            this.loader = 'loading';
            if (this.tipo_servicio === 'servicio') {
                if (!this.servicio) {
                    if (this.lista_categorias.find(el => el.value === servicioId)) {
                        this.data = servicio;
                        this.ws = 'serviceRegister';
                    } else {
                        this.data = servicio.categoria;
                        this.ws = 'serviceTypeRegister';
                    }
                } else {
                    if (this.lista_categorias.find(el => el.value === servicioId)) {
                        this.data = servicio;
                        this.ws = 'serviceUpdate';
                    } else {
                        this.data = servicio.categoria;
                        this.ws = 'serviceTypeRegister';
                    }
                }
            } else if (this.tipo_servicio === 'paquete') {
                if (!this.servicio) {
                    this.data = servicio;
                    this.ws = 'packRegister';
                } else {
                    this.data = servicio;
                    this.ws = 'packUpdate';
                }
            }
        },
        toggleServicio(servicio, origen) {
            let existe = !!this.nuevo_paquete.servicios.find(serv => serv.id === servicio.id);
            if (!servicio.sub.length || origen === 'sub') {
                if (!existe) {
                    this.nuevo_paquete.servicios.push({id: servicio.id, sub: servicio.sub_id > -1 ? servicio.sub_id : null});
                } else {
                    this.nuevo_paquete.servicios = this.nuevo_paquete.servicios.filter(serv => serv.id != servicio.id);
                } 
            } else {
                if (!existe) {
                    servicio.show_sub = true;
                    this.open++;
                } else {
                    servicio.nombre_sub = '';
                    this.nuevo_paquete.servicios = this.nuevo_paquete.servicios.filter(serv => serv.id != servicio.id);
                } 
            }
        },
        toggleSub(servicio, sub_servicio, id_sub) {
            servicio.sub_id = id_sub;
            servicio.show_sub = false;
            this.toggleServicio(servicio, 'sub');
        },
        showSubName(servi) {
            let sub = null;
            let ser_in_paquete = this.nuevo_paquete.servicios.find(serv => serv.id === servi.id);
            if (ser_in_paquete && ser_in_paquete.sub != -1) {
                sub = servi.sub[ser_in_paquete.sub]
            }
            return sub;
        },
        listarPaquetes() {
            this.lista_paquetes = this.paquetes.map((paq, index) => {
                return {text: (index + 1) + '.' + paq.nombre, value: paq.orden};
            })
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            //CREAR SERVICIO
            if (response.ws === 'serviceRegister') { 
                this.success = 'sevicio creado';               
                this.ws = 'serviceList';
                console.log('servicio registrado'); 
            //EDITAR SERVICIO
            } else if (response.ws === 'serviceUpdate') { 
                this.success = 'sevicio editado'; 
                this.ws = 'serviceList';
                console.log('servicio editado'); 
            //LISTAR SERVICIOS
			} else if (response.ws === 'serviceList') {
                this.$store.dispatch('setServicios', response.data);
                setTimeout(() => this.cerrarModal(), 1000);
            //LISTAR PAQUETES
            } else if (response.ws === 'packList') {
                this.success = 'paquete editado'; 
                this.$store.dispatch('setPaquetes', response.data);
                setTimeout(() => this.cerrarModal('paquete'), 1000);
            //CREAR TIPO DE SERVICIO
            } else if (response.ws === 'serviceTypeRegister') {
				this.data = this.nuevo_servicio;
				this.data.categoria = {value: response.data};
				if (this.servicio) {
                    this.ws = 'serviceUpdate';
                } else {
                    this.ws = 'serviceRegister';
                }
            //LISTAR TIPO DE SERVICIOS
            } else if (response.ws === 'serviceTypeList') {
                this.lista_categorias = response.data.map(cat => {
                    return {text: cat.name, value: cat.id};
                });
            //CREAR PAQUETE
            } else if (response.ws === 'packRegister') {
                if (this.nuevo_paquete.servicios.length) {
                    let calls = [];
                    this.nuevo_paquete.servicios.forEach(serv => {
                        let data = {
                            service_id: serv.id,
                            pack_id: response.data,
                        }
                        data.subservice = serv.sub !== null ? serv.sub : -1;
                        calls.push({ws: 'servicePackRegister', data: data});
                    })
                    this.data = {calls: calls};
                    this.ws = 'multiple';
                }
            //CAMBIAR SERVICIOS DE PAQUETES
            } else if (response.ws === 'packUpdate') { 
                let servicios_añadidos = this.nuevo_paquete.servicios.filter(nue_paq => !this.servicio.servicios_pre.find(paq => paq.id == nue_paq.id));
                let servicios_eliminados = this.servicio.servicios_pre.filter(paq => !this.nuevo_paquete.servicios.find(nue_paq => nue_paq.id == paq.id));
                let servicios_cambiados = [];
                if (this.servicio.servicios_pre.length && !!this.servicio.servicios_pre.find(ser => ser.sub)) {
                    this.nuevo_paquete.servicios.forEach(nue_paq => {
                        if (!this.servicio.servicios_pre.find(paq => paq.sub == nue_paq.sub)) {
                            nue_paq.id_tabla = this.servicio.servicios_pre.find(paq => (paq.id === nue_paq.id) && (paq.sub != nue_paq.sub)).id_tabla;
                            servicios_cambiados.push(nue_paq);
                        }
                    });
                }
                let calls = [];
                if (servicios_añadidos.length) {
                    servicios_añadidos.forEach(ser => {
                        let data = {
                            service_id: ser.id,
                            pack_id: this.servicio.id,
                        };
                        data.subservice = ser.sub !== null ? ser.sub : -1;
                        calls.push({ws: 'servicePackRegister', data: data});
                    });
                }
                if (servicios_eliminados.length) {
                    servicios_eliminados.forEach(ser => {
                        calls.push({ws: 'servicePackRemove', data: ser.id_tabla});
                    });
                }
                if (servicios_cambiados.length) {
                    servicios_cambiados.forEach(ser => {
                        calls.push({
                            ws: 'servicePackUpdate', 
                            data: {
                                id: ser.id_tabla,
                                service_id: ser.id, 
                                pack_id: this.servicio.id, 
                                subservice: ser.sub
                            }
                        });
                    });
                }
                if (calls.length) {
                    this.data = {calls: calls};
                    this.ws = 'multiple';
                    console.log('paquete editado'); 
                } else {
                    console.log('paquete subido ok');
                    this.success = 'sevicio creado';
                    this.ws = 'packList';
                }
            //AÑADIR SERVICIOS A PAQUETE
			} else if (response.ws === 'multiple') {
                console.log('paquete subido ok');
                this.success = 'sevicio creado';
                this.ws = 'packList';
            //BORRAR SERVICIO
            } else if (response.ws === 'serviceRemove') {
                this.success = 'servicio borrado';
                this.ws = 'serviceList';
            //BORRAR PAQUETE
            } else if (response.ws === 'packRemove') {
                this.success = 'servicio borrado';
                this.ws = 'packList';
            }
        },

        onInputChange(ev) {
            this.nuevo_servicio.categoria = ev.srcElement.value;
        },

        addTask() {
            this.tasks.push([this.taskEs, this.taskEn]);
            this.nuevo_servicio.tareas_es.push(this.taskEs);
            this.nuevo_servicio.tareas_en.push(this.taskEn);
            this.taskEs = ''; 
            this.taskEn = '';
        }, 
        deleteTask(id) {
            this.tasks.splice(id, 1);
            this.nuevo_servicio.tareas_es.splice(id, 1);
            this.nuevo_servicio.tareas_en.splice(id, 1);
        },
        editTask(id) {
            this.disabledOnEditTasks = true;
            this.editTaskIndex = id;
            this.tempOriginalTasks = [...this.tasks[id]];
        },
        confirmEditTask(id) {
            this.editTaskIndex = -1;
            this.disabledOnEditTasks = false;
            this.nuevo_servicio.tareas_es[id] = this.tasks[id][0];
            this.nuevo_servicio.tareas_en[id] = this.tasks[id][1];
        },
        cancelEditTask(id) {
            this.editTaskIndex = -1;
            this.disabledOnEditTasks = false;
            this.tasks.splice(id, 1, this.tempOriginalTasks);
        },
        addServiceVariants() {
            this.serviceVariants.push([this.serviceVariantsEs, this.serviceVariantsEn]);
            this.nuevo_servicio.variantes_es.push(this.serviceVariantsEs);
            this.nuevo_servicio.variantes_en.push(this.serviceVariantsEn);
            this.serviceVariantsEs = ''; 
            this.serviceVariantsEn = '';
        }, 
        deleteServiceVariants(id) {
            this.serviceVariants.splice(id, 1);
            this.nuevo_servicio.variantes_es.splice(id, 1);
            this.nuevo_servicio.variantes_en.splice(id, 1);
        },
        editServiceVariants(id) {
            this.disabledOnEditServiceVariants = true;
            this.editServiceVariantsIndex = id;
            this.tempOriginalServiceVariants = [...this.serviceVariants[id]];
        },
        confirmEditServiceVariants(id) {
            this.editServiceVariantsIndex = -1;
            this.disabledOnEditServiceVariants = false;
            this.nuevo_servicio.variantes_es[id] = this.serviceVariants[id][0];
            this.nuevo_servicio.variantes_en[id] = this.serviceVariants[id][1];
        },
        cancelEditServiceVariants(id) {
            this.editServiceVariantsIndex = -1;
            this.disabledOnEditServiceVariants = false;
            this.serviceVariants.splice(id, 1, this.tempOriginalServiceVariants);
        }
	},
    created: function() {
        this.console = window.console;
		this.lista_servicios = this.servicios.map((serv, index) => {
			return {text: (index + 1) + '. ' + serv.nombre, value: serv.order};
		});
        if (this.servicio && this.tipo_servicio === 'servicio') {
            this.nuevo_servicio = {...this.servicio};
        } else if (this.servicio && this.tipo_servicio === 'paquete') {
            this.nuevo_paquete = {...this.servicio};
            this.nuevo_paquete.servicios = [...this.nuevo_paquete.servicios_pre];
        }
        this.listarPaquetes();
    },
    mounted: function() {
        if (this.tipo_servicio === 'servicio') this.ws = 'serviceTypeList';
        if (this.nuevo_servicio.tareas_es.length) {
            this.nuevo_servicio.tareas_es.forEach((el) => {
                this.tasks.push([el]);
            })
            this.nuevo_servicio.tareas_en.forEach((el, index) => {
                this.tasks[index].push(el);
            });
        } 

        if (this.nuevo_servicio.variantes_es.length) {
            this.nuevo_servicio.variantes_es.forEach((el) => {
                this.serviceVariants.push([el]);
            })
            this.nuevo_servicio.variantes_en.forEach((el, index) => {
                this.serviceVariants[index].push(el);
            })
        }
    }
}
</script>
<style scoped lang="scss">
.v-application #app .modal .submodal {
    .lista_servicios {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1em 2%;
        margin: 1em;
        .card {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            transition: all .2s ease-in;
            height: 8.5em;
            cursor: pointer;
            &:hover>.parrafo {color: var(--purple03);}
            .icon {
                position: absolute;
                top: 0;
                right: 0;
                padding: .5em .5em 1em 1em;
                &:hover {color: var(--purple03);}
            }
            .mostrar_sub {
                position: absolute;
                bottom: 0;
                width: 100%;
                text-align: center;
                padding: .5em 0 2em;
            }
            .parrafo {
                text-align: center;
                padding: 1.1em 0;
                width: 100%;
            }
            &.checked {
                background-color: var(--purple01);
                box-shadow: var(--sombra_ext_dark);
                border: 1px solid var(--purple03);
            }
        }
        .servicio {
            position: relative;
            .subcards {
                position: absolute;
                height: auto;
                top: 9em;
                z-index: 100;
                box-shadow: var(--sombra_ext_dark);
                width: 100%;
                &:hover>.parrafo {color: inherit;}
                .parrafo {
                    padding-bottom: .5em;
                    margin-bottom: .5em;
                    &:hover {color: var(--purple03);}
                    &:not(:last-child) {border-bottom: 1px solid var(--gray03);}
                }
            }
        }
    }
    &.succes {
        text-align: center;
        display: flex;
        flex-flow: column;
        padding-top: 1em;
        .boton.check {
            width: 6em;
            height: 6em;
            margin: 3em 0 5em;
            margin-left: calc(50% - 3em);
            text-align: center;
            background-color: #27AE60;
            .icono{color: white;}
        }
        .titulo {
            width: auto;
            margin: 2em auto 1em;
        }
        .parrafo {margin: 0 0 2em;}
    }
    .borrar {
        float: right;
        background-color: white;
        box-shadow: var(--sombra_ext);
    }
    .tasks, .service-variants {
        .row-langs {
            display: flex;
            align-items: center;
            .v-input {
                margin-right: 2em;
                min-width: 6em;
            }
        }
        .action-buttons {
            min-width: 130px;
            display: flex;
            justify-content: space-around;
            .boton {
                box-shadow: var(--sombra_ext);
                cursor: pointer;
                &.fab {
                    background-color: white;
                }
                .icono {
                    color: var(--gray04);
                    font-size: 1.5em;
                }
                &.check {
                    .icono{color: #27AE60;}
                }
                &.close .icono{color: #EB5757;}
                &[disabled] {
                    .icono {
                        color: var(--gray04);
                    }

                }
            }
        }
    }
    @media (max-width: 760px) {
        .lista_servicios {
            margin-bottom: 0;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1em;
            margin: .5em;
            .servicio .card {padding: .7em}
        }
        .tasks, .service-variants {
            .row-langs {
                flex-wrap: wrap;
                .v-input {
                    width: 100%;
                    margin-right: 1em;
                }
                .action-buttons {
                    min-width: 75px;
                    .boton.fab {
                        transform: scale(.8);
                    }
                }
            }
        }
    }
}
</style>