export default {
  en: {
    message: {
      app: {
        home: 'Home',
        usuarios: 'Users',
        viviendas: 'Housing',
        empresas: 'Companies',
        documentacion: 'Documents',
        puedesubir: 'Please upload your photo if you wish',
        puedeeditar: 'Edit your photo if you wish',
        cambiarcontrasena: 'Change password',
        salir: 'Log out',
        politicadeprivacidad: 'Privacy Policy'
      },
      panelfiltrosviviendas: {
        disponibilidad: 'Availability'
      },
      panelfiltrospersonas: {
        buscar: 'Search',
        tipo: 'Type',
        todos: 'All',
        trls: 'TRLs',
        rrhh: 'HHRR',
        consejeras: 'Counselor',
        admin: 'Admin',
        estado: 'Status',
        activos: 'Actives',
        finalizados: 'Finalized'
      },
      panelfiltrostareas: {
        buscartrl: 'Search TRL',
        consejeras: 'Counselor',
        trls: 'TRLs',
        todas: 'All',
        notienes: 'No TRL associated',
      },
      login: {
        area: 'Private area',
        somos: 'We are a Company founded in year 2.000 and our Counsellors are experts in international mobility and they have expertise in every area needed in a Relocation Service. We are looking forward to start working with you!',
        email: 'Email',
        contraseña: 'Password',
        acceder: 'Access',
        hasolvidado: 'Forgot your password?',
        recuperar: 'Recover password',
        volverallogin: 'Back to login',
        almenos4: 'At least 4 characters',
        min4: 'Min 4 characters',
        almenos8: 'At least 8 characters',
        hard: 'It must contain at least 8 characters, including a capital letter, a lowercase letter, a number and a special character.',
        nosegurahard: 'Unsecured password, remember that it must contain at least 8 characters, including a capital letter, a lowercase letter, a number and a special character',
        mailnovalido: 'Invalid email format',
        usuariocontraseñaincorrecto: 'Invalid email or password',
        escribeLaDireccionDeEmail: 'Enter the email address associated with your account and we will send you the instructions to reset your password. They may end up in your spam or junk mail folder, please check that too.',
        setehaenviadounemail: 'An email has been sent with instructions to reset your email',
        tuContrasenaSeHaRestaurado: 'Your password has been changed correctly',
        demasiadosintentos: 'Too many wrong attempts, wait 15 minutes to try again',
        creacontraseña: 'Create your password',
        creanuevacontraseña: 'Create new password',
        tunombre: 'Username',
        restablecer: 'Reset password',
        usuarioinactivo: "User still inactive, click on the link sent by email to activate it"
      },
      documentacion: {
        documentacion: 'Documentation',
        subirarchivo: 'Upload file',
        tusdocumentos: 'Your documents',
        todaviano: 'You haven`t uploaded any documents yet',
        documentossubidos: 'Files uploaded by Steps Relocation',
        recuerdaquelosnombres: 'Remember that the file names must not contain accents, punctuation characters or symbols.<br>Maximum file size 16Mb.',
        archivolimite: 'The file cannot be uploaded because it exceeds the size limit of 16Mb',
        archivocaracteres: 'The file cannot be uploaded because it contains special characters that are not supported.'
      },
      usuarios: {
        usuarios: 'Users',
        ordenar: 'Sort',
        recientes: 'Recent',
        apellidosaz: 'Last name a-z',
        apellidosza: 'Last name z-a',
        filtrarpor: 'Filter by',
        consejeras: 'Counselor',
        nohay: 'No results',
        empresas: 'Companies',
        nuevousuario: 'New user',
        trl: 'TRL',
        rrhh: 'HHRR',
        noencontrado: 'No users found'
      },
      home: {
        calendario: 'Calendar',
        listado: 'List',
        // todosnuestrosservicios: 'All our services',
        informacionimportante: 'Important information',
        bienvenido: 'Welcome to ',
        condicionesalquiler: 'Rental conditions',
        espana: 'Welcome to Spain',
        madrid: 'Welcome to Madrid'
      },
      panelconsejera: {
        hola: 'Hello',
        nuestracompania: 'Our Company, <b>Steps Relocation, SL</b> has been hired by <span class="bold">{empresa}</span>, to help you with the relocation.',
        nuestracompania_rrhh: 'Our Company, <b>Steps Relocation, SL</b> has been hired by <span class="bold">{empresa}</span>, to help you with the relocation of your employee.',
        consejeraasignada: 'Your assigned counsellor is',
        preferencias: 'Preferences',
        misservicios: 'My services'
      },
      modalnuevotrl: {
        bienvenido: 'Welcome',
        bienvenidomadrid: 'Welcome to Madrid',
        nuestracompania: `Our Company, <b>Steps Relocation, SL</b> has been hired by your employer or by yourself to provide you and your family, relocation services. Therefore, we need you to register yourself in our intranet through a username and password, and to provide us your personal data, strictly necessary for the provision of the services.`,
        tusservicios: 'Please find below the hired services',
        aceptacionpolitica: 'Acceptance of the Privacy Policy',
        parapodercomenzar: 'Likewise, we inform you that you have the right to complain directly to the Spanish Agency of Data Protection (https://www.aepd.es) if you are dissatisfied with any aspect of the way in which we maintain, process or use your personal data.',
        politica: `<b>Basic Privacy Policy Information:</b><br />
        <b>Data of the responsible:</b> Steps Relocation, SL  •  
        <b>Tax id:</b> B83325571.  •  
        <b>Address:</b> c/ Castillo de Aysa 2D  •  
        <b>Phone:</b> +34. 810526434  •  
        <b>CEO:</b> Dª Lydia Martínez García  •  
        lmartinez@stepsrelocation.com<br />
        <b>Purpose:</b> we collect personal information from our customers in order to provide relocation and relocation-related services to them.<br />
        <b>Legal Basis:</b> Contract with you or your Company (Employer).<br />
        <b>Recipients:</b> We do not disclose your personal data to any third party, unless we are required to do so by law. We may need to transfer the data to Steps Relocation affiliates (partners) in countries outside of the European Economic Area (EEA) in order to fulfil the relocation services. Steps relocation transfers the data in a way that does not outweigh your rights or freedoms and in accordance with Article 44 of the GDPR. We inform you that some of your data could be transferred to your Employer.<br />
        <b>Data storage and retention:</b> Unless your contract or the law provides otherwise, Steps Relocation will not retain your data for longer than six years.<br />
        <b>Rights:</b> You will always can exercise the rights to Access, correct, deletion, portability, limitation and opposite of the treatment to Steps Relocation, S.L , Administration Department, Calle Castillos de Aysa, 2D, 28043 Madrid or through the email lopd@stepsrelocation.com<br />
        Likewise, we inform you that you have the right to complain directly to the Spanish Agency of Data Protection (https://www.aepd.es) if you are dissatisfied with any aspect of the way in which we maintain, process or use your personal data.<br />
        <b class="link"><a href="/#/politicaPrivacidad" target="blank">+ more info about Steps Relocation Privacy Statement.</a></b>`,
        titular: 'Transferee',
        heleido: 'Hereby I authorize Steps Relocation, SL to post on their website stepsrelocation.com, my assessment related with the quality of services received and provided by them within a period of three (3) years.',
        conyuge: 'Partner',
        soloencaso: 'I have read, understand and accept the previous notice.',
        indicanos: ' please indicate us your preferences',
        conlassiguientes: 'With the following questionnaire, we will obtain a clear photo of your requirements, that will allow us to provide you a customized service matching your needs',
        datospersonalestab: 'YOURSELF',
        preferenciastraslado: 'ABOUT YOUR MOVE',
        colegios: 'SCHOOLS',
        tabhijos: 'CHILDREN',
        datospersonales: 'Yourself',
        nombre: 'First name',
        apellidos: 'Last name',
        nacionalidad: 'Nationality',
        direcionactual: 'Current address',
        pasaporte: 'Passport',
        imagenpasaporte: 'Passport image',
        datoscontacto: 'Contact',
        emailprofesional: 'Professional email',
        emailpersonal: 'Personal email',
        movil: 'Mobile',
        datosconyuge: 'Spouse/partner',
        sealoja: 'Is your partner/spouse relocating with you? (yes/no)',
        email: 'email',
        traslado: 'About your move',
        fechatraslado: 'Date of  your arrival destination ',
        fechadisponible: 'Date/s you are available for house hunting visits',
        fechaentrar: 'Date you wish to enter a property',
        cuantotiempo: 'For how long do you wish to rent the propery',
        presupuesto: 'What is your budget?',
        recuerde: 'Utilities are not included in the rent*',
        vivienda: 'Housing',
        tipovivienda: 'Type of housing',
        piso: 'Flat',
        casa: 'House',
        metroscuadrados: 'How many sq. meters?',
        nhabitaciones: 'Bedrooms required',
        estado: 'State',
        amueblada: 'Furnished',
        sinamueblar: 'Unfurnished',
        estilo: 'Style',
        clasico: 'Classic',
        moderno: 'Modern',
        otro: 'Other',
        caracteristicas: 'Characteristics',
        piscina: 'Swimming pool',
        jardin: 'Garden',
        terraza: 'Terrace',
        zonasinfantiles: 'Children area',
        habitacionservicio: 'Maid`s room',
        otraspreferencias: 'Other preferences',
        centro: 'Downtown',
        afueras: 'In the suburbs',
        cercaoficina: 'Near to the office',
        cercacolegio: 'Near school',
        cercametro: 'Near to the metro or Commuter Rail',
        soleado: 'Sunny',
        zonatranquila: 'Quiet zone',
        preferenciasareas: 'Residential areas you prefer',
        garaje: 'Parking (yes/no)',
        ncoches: 'Number of cars',
        incluidoenpresupuesto: 'Is this included in the budget? ',
        traemascota: 'Are you planning to come with a pet? (yes/no)',
        si: 'Yes',
        no: 'No',
        encasoafirmativo: 'If yes, indicate breed and size',
        otros: 'Others',
        nhijos: 'Number of children',
        hijo: 'Child',
        sexo: 'Sex',
        hombre: 'Man',
        mujer: 'Woman',
        fechanacimiento: 'Birth date',
        curso: 'Grade',
        idiomas: 'Languajes',
        preferencias: 'Preferences',
        tipocolegio: 'School preferences',
        concertado: 'Semi-private school',
        privado: 'Private',
        publicos: 'Public',
        tipoeducacion: 'Type of education',
        alemana: 'German',
        americana: 'American',
        britanica: 'British',
        espanola: 'Spanish',
        francesa: 'French',
        otras: 'Others',
        paracontinuar: 'To continue you must accept the Privacy Policy',
        continuar: 'Continue',
        guardarsalir: 'Save and close'
      },
      pag_servicios: {
        nuestrosservicios: 'Our services',
        vivienda: 'Housing',
        otros: 'Others',
        establecimiento: 'Establishment',
        inmigración: 'Immigration',
        coches: 'Cars',
        fiscal: 'Fiscal',
        volver: 'Go back'
      },
      servicios: {
        1: {
          nombre: 'Temporary accommodation',
          info: 'We will provide a shortlist of available temporary accommodation properties; we will accompany you to visit 3 options, we will negotiate lease contract and finally we will coordinate reservation of the selected accommodation. Lease Contract signature accompanied.'
        },
        2: {
          nombre: 'Furniture rental',
          info: 'We will organize the short-term rental furniture. We offer our support throughout the selection, reservation, payments and the delivery day.'
        },
        3: {
          nombre: 'Employer registration (needed to hired domestic help)',
          info: 'Employers that hire workers for the first time should request their own registration as employers. This procedure it is mandatory prior to hire the domestic help.'
        },
        4: {
          nombre: 'Spouse support',
          info: 'We inform you about activities, associations, clubs, NOG’s, education, courses, etc, to make the relocation a great experience.'
        },
        5: {
          nombre: 'Telephone support',
          info: 'We provide you telephone support from Monday to Friday in working hours (9:00 to 19:00).'
        },
        6: {
          nombre: 'Highly qualified residence permit entrepreneur’s',
          info: 'full information and advice about the procedure. Preparation of the file. Booking the relevant appointment. Assistance to the submission of the application and permit collection and delivery to the foreign national.'
        },
        7: {
          nombre: 'Residence permit entrepreneur’s',
          info: 'Full information and advice about the procedure. Preparation of the file. Booking the relevant appointment. Assistance to the submission of the application and permit collection and delivery to the foreign national. Assistance for consular process (not accompanied).'
        },
        8: {
          nombre: 'Domestic help',
          info: 'We will put you in touch with domestic help agencies and introduce you to the manager. We will monitor the interviews and we will perform Social Security Registration of the employee and you as “employer”.'
        },
        9: {
          nombre: 'School finding',
          info: 'Advise on the different schools and selection based on the previously established criteria. Coordination of the appointments and accompanied visits to 3 schools. Assistance with filling out the enrolment and follow up.'
        },
        10: {
          nombre: 'Parking search',
          info: 'We will search for parking place, close to the new apartment and according to the requirements and budget. Contract negotiation and coordination of payments.'
        },
        11: {
          nombre: 'Home finding',
          info: 'Identification of needs. We will send you via e-mail sufficient available options in the market that match with the requirements set out in the questionnaire (as long as it is realistic). One day accompanied visits to about 6/8 pre-selected properties. Visits will be performed from Monday to Friday in working hours. Contract negotiation, Lease Contract signature accompanied and photo inventory.'
        },
        12: {
          nombre: 'Driving license exchange for EU',
          info: 'Full information and assessment about the procedure, requirements and documents. Preparation on the file and submission. Follow up with Traffic Department and trouble-shooting. You will need a valid driver’s licence in force. Application will be submitted by a specialized agent.'
        },
        13: {
          nombre: 'EU Certificate of Registration',
          info: 'full information and advice about the process. Preparation of the application package. Booking the relevant appointment. Assistance to the submission of the application and collection of the certificate. This certificate includes the NIE number.'
        },
        14: {
          nombre: 'House purchase',
          info: 'We will advice about the different areas. Selection of house properties that will match with the preferences previously expressed in the questionnaire (as long as they are realistic).  Organization of the appointments with the realtors and landlords. One day of accompanied visits. Negotiation of the sale/purchase agreement, check the Property Registry and check if the taxes are up to date. Negotiation of the “arras” contract (private contract previous to the purchase in which purchase conditions are settled and when you will pay 10% of the value of the property. Accompaniment to the notary’s office.'
        },
        15: {
          nombre: 'Labour office notification (communication of transfer)',
          info: 'Full assistance and advice about the process. Preparation of the COT document to be signed by the representative of the home company. Submission of the signed COT to the Spanish Labour Authorities.'
        },
        16: {
          nombre: 'Administrative procedures',
          info: 'We will inform you about the administrative procedures you might need to be completely settle in your new destination country.'
        },
        17: {
          nombre: 'Opening a bank account',
          info: 'Your counsellor will find the most convenient branch of your selected Bank, and will help you with the necessary paperwork to obtain credit cards, etc and will accompany you to the Bank.'
        },
        19: {
          nombre: 'Car de-registration',
          info: 'Full information and assessment about the procedure, requirements and documents.'
        },
        20: {
          nombre: 'Town Hall de-registration',
          info: 'Full information and assessment about the procedure, requirements and documents. '
        },
        21: {
          nombre: 'EU Certificate de-registration',
          info: 'Full information and assessment about the procedure, requirements and documents.'
        },
        22: {
          nombre: 'Town Hall Registration',
          info: 'Full information and assessment about the procedure, requirements and documents. Personal accompaniment to the Local Council.'
        },
        23: {
          nombre: 'Entrepreneurs - Renovation',
          info: ''
        },
        25: {
          nombre: 'Move management',
          info: 'we will contact the moving company and will confirm packing, collection and delivery date and coordinate the whole process.'
        },
        26: {
          nombre: 'Car legalization',
          info: 'full information about the procedure, preparation of the file, submission of the application.  Coordination of the appointment at the MOT (worthiness test) and car workshop if needed. Obtention of the new plates.'
        },
        27: {
          nombre: 'Social security healthcare registration ',
          info: 'This procedure is mandatory prior to requesting the Spanish individual health cards for all of you. Information about the procedure, preparation of the file, submission of the application and collection of the resolution.'
        },
        28: {
          nombre: 'Inventory',
          info: 'Home inspection and walk through, verification of current conditions and photo inventory of items.'
        },
        29: {
          nombre: 'Look&see trip',
          info: 'Your counsellor will explain you the living conditions, residential areas, services and preview of representative communities and housing, explanation of medical facilities, local authorities, among other explanations while in a pre-move familiarization trip. We will show you up to 4 housing options. One day accompanied.'
        },
        30: {
          nombre: 'Accompany additional support 1 day',
          info: 'Face to face drive around. One day or two half days (from 10:00 to 13:30) down town tour with the counsellor. You can choose covering any specific need you might have. Lunch in one of the trendiest restaurants in Madrid.'
        },
        31: {
          nombre: 'Pets',
          info: 'organization an appointment with the local veterinary center, organizing pet walking service and finding pet’s residences for when you are away.'
        },
        32: {
          nombre: 'Contract negotiation',
          info: 'Assistance during lease negotiation. Reviewing & adapting rental contract. Accompanied assistance during the signing and during the handover of keys.'
        },
        33: {
          nombre: 'NIE Certificate',
          info: 'Full information and advice about the procedure. Preparation of the file. Booking the relevant appointment. Assistance to the submission of the application.'
        },
        34: {
          nombre: 'Obtention of Social Security Number (NAF)',
          info: 'Full information about the procedure, preparation of the file, submission of the application and collection of the resolution stating the Social Security Number.'
        },
        35: {
          nombre: 'Onboarding support',
          info: 'Support new starters joining the business and acting as a point of contact for any queries you may have prior to your starting date and during your induction period. Coordination and setting up the desk, computer and other resources.'
        },
        36: {
          nombre: 'Welcome package',
          info: 'Welcome letter, initial information about the city of destination. Study of your personal and family needs, and briefing call (if necessary). Personal access to our intranet.'
        },
        37: {
          nombre: 'VISAR program',
          info: 'Full information and advice about the procedure. Preparation of the file. Booking the relevant appointment. Assistance to the submission of the application and permit collection and delivery to the foreign national.'
        },
        38: {
          nombre: 'Application for the special tax regime for inbounds expatriates',
          info: 'Eligibility review. Explanation to the employee of the specific characteristics of this regime and its formal obligations. Tax register. Notification of the option for the special system applicable according to tax form 149. Preparation of the necessary support documentation.'
        },
        39: {
          nombre: 'Registration at the Embassy or Consulate',
          info: 'Full information and advice about the procedure. Preparation of the file. Booking the relevant appointment, if possible.'
        },
        40: {
          nombre: 'Renewal of EU Certificate of registration',
          info: 'Full information and advice about the process. Preparation of the application package. Booking the relevant appointment. Assistance to the submission of the application and collection of the certificate. This certificate includes the NIE number.'
        },
        41: {
          nombre: '2nd home finding',
          info: 'Identification of needs. We will send you via e-mail all the available options in the market that match with the requirements set out in the questionnaire (as long as it is realistic). One day accompanied visits to about 6/8 pre-selected properties. Visits will be performed from Monday to Friday in working hours. Contract negotiation, Lease Contract signature accompanied and photo inventory.'
        },
        42: {
          nombre: 'Closing service',
          info: ''
        },
        43: {
          nombre: 'Utilities hook-up',
          info: 'Arrange set-up of water, electricity, gas, internet and phone in your new home and performing direct debit payments.'
        },
        44: {
          nombre: 'Checking supplies',
          info: ''
        },
        45: {
          nombre: 'Sanitary cards',
          info: 'Full information and assessment about the procedure and personal accompaniment to the Health Center. '
        },
        46: {
          nombre: 'SER (Regulated parking service)',
          info: 'It is the possibility to park in the street in your neighborhood. Full information and assessment about the procedure and personal accompaniment at the application.'
        },
        47: {
          nombre: 'Change of personal details at Social Security',
          info: 'Full information and advice about the process. Preparation of the application. '
        },
        48: {
          nombre: 'Highly qualified residence permit dependant entrepreneur’s',
          info: 'full information and advice about the procedure. Preparation of the file. Booking the relevant appointment. Assistance to the submission of the application and permit collection and delivery to the foreign national.'
        },
        49: {
          nombre: 'Residence permit dependant entrepreneur’s',
          info: 'Full information and advice about the procedure. Preparation of the file. Booking the relevant appointment. Assistance to the submission of the application and permit collection and delivery to the foreign national. Assistance for consular process (not accompanied).'
        },
        50: {
          nombre: 'EU Certificate of Registration',
          info: 'full information and advice about the process. Preparation of the application package. Booking the relevant appointment. Assistance to the submission of the application and collection of the certificate. This certificate includes the NIE number.'
        },
        51: {
          nombre: 'Entrepreneurs dependant - Renovation',
          info: ''
        },
        52: {
          nombre: 'Renewal of EU Certificate of registration – dependant',
          info: 'Full information and advice about the process. Preparation of the application package. Booking the relevant appointment. Assistance to the submission of the application and collection of the certificate. This certificate includes the NIE number.'
        }
      },
      viviendas: {
          viviendas: 'Housing',
          ordenar: 'Sort:',
          fechadeincorporacion: 'Date to enter',
          ultimamodificacion: 'Last change',
          precio: 'Price',
          seleccionadassegun: 'selected according to your preferences',
          emes: '€/month',
          ultmodificacion: 'Last change',
          notenemosaunviviendas: 'We do not have any homes with these characteristics',
          suconsejeraaun: 'Your counselor has not yet assigned you any housing',
          hab: 'room',
          ascensor: 'Lift/elevator',
          piscina: 'Swimming pool',
          jardin: 'Garden',
          terraza: 'Terrace',
          piso: 'Flat',
          casa: 'House'
      },
      fichavivienda: {
          estaViviendaNoExiste: 'This house is no longer available or has been eliminated',
          volver: 'back to Housing',
          disponible: 'Available',
          nodisponible: 'Not available',
          emes: '€/month',
          caracteristicasbasicas: 'Basic characteristics',
          planta: 'Floor',
          casa: 'House',
          habitaciones: 'Rooms',
          banos: 'Bathrooms',
          piscina: 'Swimming pool',
          jardin: 'Garden',
          terraza: 'Terrace',
          zonasinfantiles: 'Children area',
          habitaciondeservicio: 'Maid`s room',
          soleado: 'Sunny',
          zonatranquila: 'Quiet zone',
          aireacondicionado: 'Air conditioner',
          calefaccion: 'Heating',
          trastero: 'Storage Room',
          cercametro: 'Close to the underground/FGC',
          estado: 'Status',
          Excelente: 'Excelent',
          'Buenas condiciones': 'Good conditions',
          'Nueva construcción': 'New construction',
          Reformado: 'Renovated', 
          'A reformar': 'To renovate',
          estilo: 'Style',
          'Clásico': 'Classic', 
          Moderno: 'Modern',
          Otro: 'Other',
          equipamiento: 'Furnishing',
          Amueblado: 'Furnished', 
          'Sin amueblar': 'Unfurnished', 
          'Parcialmente amueblado': 'Partially furnished',
          garaje: 'Parking',
          plazas: ' parking area',
          inluidoenelprecio: 'Included',
          noincluidoenprecio: 'Not included',
          mascota: 'Pets',
          mascotasi: 'Yes',
          gastosdecomunidad: 'Community fees',
          tipodecontrato: 'Type of contract:',
          Alquiler: 'Rent',
          Compra: 'Buy',
          'Alquiler temporal': 'Temporary rental',
          condicionesalquiler: 'Rental conditions:',
          barrio: 'Neighborhood',
          descripcion: 'Description',
          piso: 'Flat',
          casa: 'House'
      },
      paginabasica: {
          volver: 'Go back',
          politicadeprivacidad: 'Privacy Policy',
          lerecomendamosquelea: 
              `<p><b>WE INFORM YOU THAT BY ACCESING OUR WEBSITE YOU BECOME A USER. AS A USER, YOU DECLARE THAT YOU HAVE THE NECESSARY LEGAL CAPACITY TO UNDERSTAND AND ACCEPT THE PRIVACY POLICY IN ITS ENTIRELY.</b></p>
              <p><b>1.- Who is the data controller responsible for processing your personal data?</b></p>
              <p>Steps Relocation, SL, with registered address at calle Velázquez, 109, 2º izq. 28006 Madrid, holder of Tax ID no. B83325571, represented by the CEO Lydia Martinez García (lmartinez@stepsrelocation.com)</p>
              <p><b>2.- What kind of information do we collect?</b></p>
              <p>2.1 We collect the information that you provide to us through the web contact questionnaire. The data that we may collect are as follow: name and surname, home address, email address, mobile phone number.</p>
              <p>2.2 Other data that you provide us through the intranet questionnaire. The data that we may collect are as follow: name and surname, nationality, email address, mobile phone number, home address, National Identity Document/ Foreign National Identity Document/ Passport number, date of birth and sex. Filiation, marital status and familiar status. Name, surname and birth of your children (if applies).</p>
              <p><b>3.- How and for what purposes do we process your personal data?</b></p>
              <p>In this column we provide a detailed explanation of the purposes for which we process your personal data, legal basis, and how long we store your personal data:</p>
              <p>3.1 Contacts.</p>
              <p>Purpose: to provide a customer service aimed at giving response to queries and dealing with requests for information from users. To prepare quotes or offers of Relocation services in response to the request you have made. <br />
              Legal Basis: consent.</p>
              <p>Storage period: Your personal data will be processed by Steps Relocation until you either exercise your rights to erasure or to object or withdraw your consent. Once the maximum period for the retention of personal has elapsed (1 year from your last communication), Steps Relocation will anonymise or delete the user’s personal information.</p>
              <p>3.2 Client.</p>
              <p>Purpose: to manage request for relocation services and compliance with the existing service agreement between the client and Steps Relocation such as: house finding, moving, school search, immigration procedures, settling-in services, driver license exchanges, etc.)</p>
              <p>Legal basis: performance of the existing agreement between Steps Relocation and the Client.</p>
              <p>Storage period: Personal data will be processed by Steps Relocation while the service agreement between Steps Relocation and the customer is in force. Subsequently, access to the personal data provided by the client will be dully blocked during the statutorily stipulated limitation period for any legal actions that may arise as a result of or in connection with the agreement entered into between Steps Relocation and the client.</p>
              <p>Once maximum period for the retention of personal data has elapsed (6 years) Steps Relocation will anonymise or delete the client’s personal information.</p>
              <p><b>4.- Who do we share your personal data with?</b></p>
              <p>4.1 Data communication:</p>
              <p>a) Steps Relocation will only share your personal data with those third parties that require such information for the correct provision of their services.</p>
              <p>
              b) In addition, we may share your personal data with other professionals, lawyers and collaborators located out the EEE or in USA during the performance and execution of the relocation services that you have engaged even if these countries has not the same level of data protection. Steps Relocation assures you that the share of personal details will be done according to the Art. 44 of the current GDPR Regulation. This is: <br />
              - a recipient declared of adequate level by the European Commission (according to the information published by the AEPD), or<br />
              - certified in Privacy Shield, or<br />
              - to a recipient with appropriate safeguards, through the subscription of the relevant Standard Contractual Clauses adopted by the Commission, <br />
              - and/or with the express consent of the data subject.</p>
              <p>You can always request evidence by sending an email to: <a href="mailto:lopd@stepsrelocation.com">lopd@stepsrelocation.com</a></p>
              <p>4.2 Client’s data processor</p>
              <p>In those instances where Steps Relocation, in order to provide the relocation services, acts as the client’s data processor, and therefore may access and process personal data owned by the client, Steps Relocation will comply with the following legal obligations, pursuant to the provisions set forth in the Article 28 of the GDPR: <br />
              • Process personal data to which we have access solely for the purpose of providing the relocation services requested by the data controller, in accordance with their instructions. <br />
              • Ensure that those employees and collaborators under our authority have undertaken to keep secrecy or confidentiality with regard to the processed personal data, even after their relationship with Steps Relocation has terminated. <br />
              • Appoint other data processors only when strictly necessary for the provision of the relocation services requested by the client, in which case, appropriate technical and organisational measures will be adopted.</p>
              <p>We make sure that our service providers also have suitable security standards for the protection of personal data to which they have or may have access, in accordance with data protection legislation applicable from time to time. Steps Relocation executes the necessary contractual documentation to engage data processors, where appropriate guarantees regarding processing of personal data, confidentiality and cancellation, destruction or return of such data are established.</p>
              <p>In addition, we may share your personal data with other third parties, for instance:</p>
              <p>• Legal advisors, <br />
              • Real Estate Agencies <br />
              • Schools <br />
              • Banks (for the opening a bank account)<br />
              • Furniture rental companies<br />
              • Cultural training companies <br />
              • Moving companies<br />
              • Etc.</p>
              <p>
              <b>5.- What are your resultant rights when you provide your personal data to us?</b></p>
              <p>You can find below the rights you may exercise as a user of the website and intranet of Steps Relocation:</p>
              <p>• Right to access<br />
              • Right to rectification <br />
              • Right to erasure<br />
              • Right to restrict processing<br />
              • Right to data portability<br />
              • Rigth to object</p>
              <p><b>6.- What does the right of access imply?</b></p>
              <p>You have the right to have Steps Relocation confirm whether or not we are processing your personal data and, if we are the right to know what information we are processing.</p>
              <p>You may exercise this right every six months, unless there is legitimate cause to request it more than once in that six-month period, and we will inform you of the actions resulting from your request within one month.</p
              <p>How can you exercise this right? You can exercise your right by submitting your request either by email to <a href="mailto:lopd@stepsrelocation.com">lopd@stepsrelocation.com</a> or by way of dully signed request in writing posted to Calle Velázquez, 109. 2º izq, 28006 Madrid. In either case, you must attach or enclose a photocopy of your ID card or password.</p>
              <p><b>7.- What does the right of rectification imply?</b></p>
              <p>This refers to the possibility of having inaccurate or incomplete data rectified. To do this, you must state in your rectification request which personal information you wish to be modified.</p>
              <p>How can you exercise this right? You can exercise your right by submitting your request either by email to <a href="mailto:lopd@stepsrelocation.com">lopd@stepsrelocation.com</a> or by way of dully signed request in writing posted to Calle Velázquez, 109. 2º izq, 28006 Madrid. In either case, you must attach or enclose a photocopy of your ID card or password. <br />
              <br /><b>8.- What does the right of erasure?</b></p>
              <p>This right enables you to request the removal of your personal data. Once this right is exercised, your data will not be immediately deleted, but will be blocked in such a way as to prevent their processing, without prejudice to their being made available to government and public agencies, judges and courts for the purpose of dealing with any liabilities that may have derived from their processing during the limitation period. Once this period has elapsed, your personal data will be duly anonymised or deleted.</p>
              <p>How can you exercise this right? You can exercise your right by submitting your request either by email to <a href="mailto:lopd@stepsrelocation.com">lopd@stepsrelocation.com</a> or by way of dully signed request in writing posted to Calle Velázquez, 109. 2º izq, 28006 Madrid. In either case, you must attach or enclose a photocopy of your ID card or password.</p>
              <p><b>9.- What does the right of restrict processing?</b></p>
              <p>This right allows you to request us to stop processing your personal data in the following circumstances:</p>
              <p>• While Steps Relocation verifies your personal data after you have contested their accuracy.<br />
              • While Steps Relocation determines if our legitimate interest prevails over your legitimate interest as the data subject after you have exercised your right to object to our processing your personal information.</p>
              <p>Additionally, this right enables you to request Steps Relocation to retain your personal data when:<br />
              • The processing of your personal information is unlawful and, as the data subject, you object to the deletion of your data and request instead that restrictions to the use of such information are applied. <br />
              • Steps Relocation no longer requires your personal data for any of the processing purposes, but still needs to hold them for the filing, exercise or defence of any complaint.</p>
              <p>How can you exercise this right? You can exercise your right by submitting your request either by email to <a href="mailto:lopd@stepsrelocation.com">lopd@stepsrelocation.com</a> or by way of dully signed request in writing posted to Calle Velázquez, 109. 2º izq, 28006 Madrid. In either case, you must attach or enclose a photocopy of your ID card or password.</p>
              <p><b>10.- What does the right of portability?</b></p>
              <p>You have the right to copy and transfer data from our database to a data controller other than Steps Relocation. This right may only be exercised when the data processing is based on the performance of a contract or on your consent, and such processing is performed by automated means.<br />
              How can you exercise this right? You can exercise your right by submitting your request either by email to <a href="mailto:lopd@stepsrelocation.com">lopd@stepsrelocation.com</a> or by way of dully signed request in writing posted to Calle Velázquez, 109. 2º izq, 28006 Madrid. In either case, you must attach or enclose a photocopy of your ID card or password.</p>
              <p><b>11.- What does the right of object?</b></p>
              <p>You may at any time object to our processing your personal data, except in those cases where such processing is statutorily required. Hence, except in those instances where it is legally established that you cannot exercise this right (for example, when the legitimate basis for the processing of your personal data is the performance of a contract or compliance with a legal obligation on our part), you have the right to object at any time, for reasons related to your particular circumstances, to the processing of your personal data. Furthermore, you may exercise this right when the processing of your personal data is based on our legitimate interest. Should you exercise this right, Steps Relocation will stop processing your personal information, except if we determine that there are compelling legitimate reasons for such processing that prevail over your interests, rights and freedoms, or for the filing, exercise or defence of any complaint.</p>
              <p>How can you exercise this right? You can exercise your right by submitting your request either by email to <a href="mailto:lopd@stepsrelocation.com">lopd@stepsrelocation.com</a> or by way of dully signed request in writing posted to Calle Velázquez, 109. 2º izq, 28006 Madrid. In either case, you must attach or enclose a photocopy of your ID card or password.</p>
              <p>Additionally, we inform you that , should you consider that Steps Relocation has not properly fulfilled your rights, you may file a complaint with the Spanish Data Protection Agency (AEPD). If you require further information about how to request protection from the AEPD, you can visit their website http://www.aepd.es or contact them on the following telephone numbers 901 100 099 and 912 663 517.</p>`,
          condicionesalquiler: 
            `<h3 class="titulo">Rental conditions in Spain in General (they can vary depending on the
                “Comunidad autónoma”)</h3><br>
              <p>In Spain leases are regulated by the “Ley de Arrendamientos Urbanos” or
              “LAU”. Rental agreements usually have a duration of one year, automatically
              renewable up to five years. After five years, the contract can be extended three
              more years. The first six months are of obligatory stay, although some owners
              refuse to have tenants that don’t sign one year minimum mandatory.</p>
              <p>In Spain, accommodations are rented in the condition you visit them. There is
              no obligation for the owner to do even minor repairs or refurbishments in the
              house between periods of tenancies. All the works you would like to be done
              must be discussed and approved before the lease signature and listed in the
              lease.</p>
              <p>Most accommodations are empty. If the apartment is unfurnished the kitchen
              is still equipped with electric appliances and furnished with cabinets. If
              needed, you can rent furniture. We can help with that..</p>
              <p>The Royal Decree Law 7/2019 (commonly known as LAU), of March the 1st,
              which regulates the leases is very protective for the tenant; only specific terms
              defined by this law apply and particular clauses cannot go against the law.
              However, we have to remain very vigilant about the specific conditions part of
              the lease added by the landlord or agent (i.e. obligation to hire a gardener to
              maintain the garden in good condition).</p>
              <p>Rental agreements are exclusively written in Spanish, there is no legal English
              version available. It can be in the name of a company or in the name of an
              individual.</p>
              <p>No rental can be 100% secured before the lease is signed by both parts or you
              have made a reservation payment.</p>
              At the lease signature, the tenant will have to pay for: 1) the security deposit,
              2) the real estate agency fee, if any, 3) additional guarantees, 4) first month
              rent (or prorate).</p>
              <p><strong>IMPORTANT NOTE</strong>: If the apartment/house is more than 300 sqm or the rent
              is over 5.000 euros, the LAW does not apply and renting conditions can be
              negotiated in terms of Duration or Guarantees among other things.</p>
              
              <h3 class="subtitulo">Bond</h3>
              <p>It’s usually one month’s rent but it can be more depending on the property.
              The Bond is refunded normally one month after the tenant leaves the house as
              long as there are no damages and the bills are up to date.</p> 
              
              <h3 class="subtitulo">Additional guarantees</h3>
              <p>Covers possible unpayments and they can be:</p>
              <p>1) “Depósito”: Money you deposit in the Land lord’s account.</p>
              
              <p>2) Bank Guarantee: Your Bank blocks the agreed quantity in your Bank
              Account and produces a document signed by a Notary, to give it to the land
              lord. With this, Land Lord can go to the Bank and get the money. It takes
              longer and Bank will charge a minimum maintenance fee.</p>
              
              <h3 class="subtitulo">Agency fee (if any)</h3>
              <p>The agency fee is usually one month’s rent + 21% VAT and is usually paid by
              the tenant. This can vary depending on where you are in Spain, in some cases,
              it’s calculated in the form of a percentage on the annual price of the rent.</p>
              
              <h3 class="subtitulo">Tenant’s and landlord’s obligations (In General Terms)</h3>
              <p>Rent has to be paid the first 5 days of the month.</p>
              <p>The tenant has to let the owner know of any problems in the apartment within
              24 hours.</p>
              <p>The landlord will be responsible for repairing issues that are due to normal
              wear and tear.</p>
              <p>The tenant will not perform any works in the house without the written
              permission from the landlord.</p>
              <p>The tenant will not sublet the apartment in any case.</p>
        
              <h3 class="subtitulo">Reasons for lease termination</h3> 
              <ul>
                <li>- lack of payment of one month or any other pending quantities</li>
                <li>- if the apartment is not destinated to the established use</li>
                <li>- non observance of the contract obligations</li>
                <li>- damages caused on purpose or works performed without the
                agreement of the landlord</li>
                <li>- when there are unhealthy, dangerous, illegal or annoying activities in
                the apartment</li>
                <li>- the landlord needs to occupy the apartment for himself or any first
                degree relative, or adoption or in case of his or her divorce. This can only be
                after the 1st year and with a few months warning</li>
              </ul>
              
              <h3 class="subtitulo">Insurance</h3>
              <p>In Spain, it is not mandatory to hire a home insurance policy. However, we
              recommend that you do. The tenant should insure civil liability and personal
              belongings. Some owners ask for it when negotiating conditions.</p>
              
              <h3 class="subtitulo">Additional expenses for the tenant</h3>
              <p>Utilities such as water, gas, electricity, phone and internet.</p>
              <p>Insurance.</p>,
              'madrid:'
            <h3 class="titulo">Welcome to Madrid</h3><br>
            <p>
                Madrid is the capital of Spain and a very lively and cosmopolitan city. It is Spain’s Financial Centre and home of the Central Government (Spain is divided in 17 autonomous communities). The official language is Spanish, but in other Spanish communities, you will be able to hear Catalan, Basque, Galician or Valencian. Don’t worry, they speak Spanish too!
            </p>
            <p>
                Madrid is situated in the centre of the country on the Castilian plateau, at an altitude of 646m, making Madrid the highest capital in Europe.<br />
                The population of Madrid city is about 4 million. If you add the amount of people living in the out-skirts and the surrounding villages that number rises considerably to around 7 million people.
            </p>
            <p>
                Madrid is very well situated if you like to ski. Good ski slopes are just an hour away in the Sierra de Guadarrama. The Mediterranean Sea is only 350 km away and big Spanish cities like Barcelona, Sevilla or Bilbao are very well connected by AVE (the Spanish bullet train). European major cities like Paris, Rome and London are just a few hours away by plane.
            </p>
            <p>
                The temperature in Madrid can be very drastic from winter to summer. While the summer is dry, hot and sunny with temperatures that can be well over 40ºC/104ºF, Spring and Autumn are mild and usually beautiful, not too cold or too hot. Winter here is cold with some days below 0ºC/32ºF.
            </p>
            <p>
                Spain has one of the lowest crime rates in Europe, however pickpocketing still happens. Madrid is not a city with a high level of crime but, as in any big cosmopolitan city, be careful and stay alert especially when using the ATM machines as you would anywhere else.
            </p>
            <img src="images/infotrl/escudo_madrid.png" height="200px" style="display:block; margin: 0 auto;"/>
            <p>
                The Coat of arms of Madrid is a bear leaning on a arbutus with an open royal crown and seven stars. Each of these stars represent the main stars in Ursa Major.<br /><br />
                Legend says that the bear was chosen in honor of Alfonso XI that captured one in the mountains surrounding the capital.<br />
                The bear was at first leaning on a tower but was later changed to artbutus, since there were quite common in the area during Medieval times.
            </p>
            <h3 class="subtitulo">People of Madrid</h3>
            <p>
                There are more <strong>15.000</strong> restaurants and bars in Madrid. Spanish people enjoy eating out (restaurants and bars expenses are often first item in their budget). They are fond of traditional Spanish food, especially seafood, but over the last years they have also learnt to appreciate new tastes. As a result, you will find restaurants of every variety and culture.
            </p>
            <p>
                Ask for our restaurant recommendations. It’s only a small selection, but you’ll see what we are talking about.
            </p>
            <p>
                Typical Madrilenian dishes are, <strong>“cocido”</strong> (a stew with chickpeas, vegetables and meat); <strong>“tortilla de patatas”</strong>, a delicious potato and egg omelette; <strong>“cordero asado”</strong>, roasted lamb and <strong>“cochinillo”</strong>, roasted pigglet.…
            </p>
            <p>
                Spaniards have their own habits and eat much later than the rest of their European neighbors! Restaurants in Madrid will not open until 9.pm but most restaurants open and start serving at 20:00. If you do not wish a big meal in the evening, you can always go to the many “tapas bars”, cafeterias and snack bars which serve food all day long.
            </p>
            <p>
                Outside of the smaller towns and villages, it is hard to spot a truly Castilian personality. Movement of population has made the people “Spanish” in the general sense, especially in the case of the Madrilenians since the population is composed in a very high percentage by people from everywhere.
            </p>
            <p>
                If we were to try and picture what a person from Madrid is like, we must say that they are considered quite <strong>open</strong>. Time with their families is very important. As mentioned before, the Spaniards enjoy the good things of life.
            </p>
            <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
            <img src="images/infotrl/gentemadrid1.png" height="200px" style="margin: 0 10px 10px 0; flex: 0 1 auto;"/>
            <div style="margin-right: 10px; flex: 0 1 auto; max-heigth:180px">
              <img src="images/infotrl/gentemadrid2.png" width="100%"/>
            </div>
            <img src="images/infotrl/gentemadrid3.png" height="200px" style="margin-right: 10px; flex: 0 1 auto;"/>
          </div>
            <p>
                August is still a pretty dead month for activity in Madrid. Many family owned stores or boutiques will close for two or more weeks in August. Therefore, if you want to come to Madrid for house hunting or any other thing apart from tourism, it is better that you change your plans and leave it for a different month.
            </p>
            <p>
                The Spanish people take a great pride in their appearance; they are as a rule, very elegantly dressed. This does not mean that dressing casual is not acceptable, it’s actually very popular, but you will rarely see a Spaniard looking scruffy casual. Business attire in Spain is formal, however some companies have recently introduced their casual dress code, especially on Fridays.<br />
                Be on the look-out for how they dress at your company to dress accordingly.
            </p>
            <h3 class="subtitulo">Parks in Madrid</h3> 
            <strong>CASA DE CAMPO</strong>
            <p>Until 1931 the Casa de Campo was a royal game park originally acquired by Felipe II in 1559. The land was then handed over to the people of Madrid and it became an area for leisure. Located on the western fringes of Madrid, across the Manzanares river, it’s one of Europe’s largest urban open spaces. Today the park possesses many sports and recreational amenities. The grounds are also the sites of Madrid’s zoo and amusement park (named “Parque de Atracciones”).</p>
            <strong>MONTE DE EL PARDO</strong>
            <p>It’s a wooded parkland close to 40.000 acres situated to the north of Madrid. The area is a National Patrimony so unfortunately, some of it is closed for public visits. It also boasts of a Natural Park status due to its abundant diversity in flora and fauna, including protected birds’ species.</p>
            <strong>PARQUE DEL OESTE</strong>
            <p>
                With fewer visitors and a more casual feel than Retiro Park, Parque del Oeste is the ideal place to meander through the collection of birch, cedar, cypress and fir trees on Summer evenings. First designed by landscape architect Cecilio Rodríguez in the early 20th century, it is situated on what was once a huge waste disposal site. During the Civil War the park sheltered Republicans from the invading Nationalist soldiers, resulting in the area being virtually ruined.<br />
                The park is the location of several attractions including La Rosaleda, a rose garden where every May a rose festival is held. A fountain by Juan Villanueva in Paseo de Camoens and cable cars going to and from Casa de Campo.<br />
                The south corner of the park near Plaza de España is home to the 4th century Egyptian Debod Temple. This monument built where military barracks used to be, the temple was a gift in 1970 from the Egyptian government in recognition of assistance received from Spain in saving valuable monuments preceding the flood of plains of land due to the erection of the Aswan Dam.
            </p>
            <strong>REAL JARDÍN BOTÁNICO</strong>
            <p>The garden was founded in 1755 under Fernando VI in order to showcase plant and tree species, which had been gathered on overseas journeys. Today, it is home to over 30.000 species.</p> 
            <strong>PARQUE DEL RETIRO</strong>
            <p>Retiro was once a forested royal hunting ground for Felipe II. The Duke of Olivares, as part of the Buen Retiro Palace, designed the park in the 1630s for Felipe IV. The Buen Retiro Palace was used until the era of Carlos II before being opened in part to the public in the 1770s. Unfortunately, the majority of the palace was ruined during the Napoleonic Wars. The 1.2 sq Km/0.75 sq miles of park is best visited on the weekends when street artists, puppet shows and street performers emerge. There is also the prospect of riding in a horse-drawn carriage or hiring a rowboat on the lake. Highlights: Palacio de Cristal (Glass Palace), the artificial lake and the rose garden.</p>
          <h3 class="subtitulo">WHERE TO LIVE IN MADRID</h3>
          <p><strong>Chamberí</strong></p>
          <p>DESCRIPTION: is located just to the East of Moncloa and stretches to the  Castellana, after which it becomes Salamanca. Chamberí boasts beautiful architecture. Some of its buildings are absolutely gorgeous, such as Museo Sorolla, Institute International and even the British Council. It also has quaint plazas such as Plaza de Chamberí and Plaza de Olavide. 
          One of the best streets for wining and dinning is calle Ponzano, where you can find great restaurants like “Bar Lambuzo” or “Sala de Despiece”. I really recommend living in Chamberí if you want to live close to the centre, but not right in it. Here, you are able to walk to all your favourite places, and have everything close by without being in a touristic neighbourhood. Of course, Chamberí is an old neighbourhood, and as such, the flats will not be as good quality as in Salamanca, or the shops and bars will not be as fashionable and trendy as in Chueca or Malasaña, but if you’re looking for an authentic, practical and “village-like” neighbourhood, go for Chamberí.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/chamberi1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/chamberi2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/chamberi3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Malasaña</strong></p>
          <p>DESCRIPTION: Malasaña is known as the most hipster/alternative part of the city, mainly due to its history as a rebellious neighbourhood that fought for what they believed in (in 1802, the residents here staged an uprising against Napoleon in what is now the main plaza, called Dos de Mayo). Fuencarral is the main street and runs parallel to Chueca’s Calle Hortaleza, north of Gran Vía. The narrow, colorful streets are full of cafés, restaurants, trendy and vintage shops, and neighbourhood cooperatives. You can also find interesting street art, high-end tattoo parlors, uber-trendy eateries, and experimental pop-up shops selling everything from costumes and goth clothing to home goods and cupcakes. You’ll find the streets, shops, and plazas full of a diverse mix of people at any hour of day or night, and its main metro stop Tribunal is the second-most-popular meeting spot for groups of friends going out for the night. Their nightlife scene has a little bit of everything but this neighbourhood is definitely home to the indie-alternative youth culture and boasts some of the most popular nightclubs catering to this clientele. Whether or not you choose to live here, you must check out the colourful, funky streets for yourself and join the groups meeting at Metro Tribunal for a night out unlike any other.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/malasaña1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/malasaña2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/malasaña3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Chamartin</strong></p>
          <p>DESCRIPTION: in the North of the city lies a more modern Madrid, which homes the district known as Chamartín. The area is technically the Northern limit of the city centre and quite larger than others. The region itself is divided into several other smaller sections like Castilla and Prosperidad but all sections are easily connected with Madrid’s metro system. The neighbourhood is located between Las Tablas and Salamanca and is considered one of the business districts of Madrid. Here you can find locations like Plaza de Castilla, the Chamartín train station, the Santiago Bernabéu stadium and the famous Gate of Europe. The border of the district is marked by the huge Paseo de la Castellana – a major roadway in Madrid connecting to the large M30 and M11 motorways! Chamartín is a major hub for banks, corporate offices, modern art galleries and event venues especially along the Paseo and between all the major metro stops. But since the area is so large, its has also become a very popular residential neighbourhood, with many schools and apartment clusters throughout! </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/chamartin1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/chamartin2.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Hortaleza</strong></p>
          <p>DESCRIPTION: what is considered today the Hortaleza district in Madrid, was originally a small village located on the top of a hill, skirted by streams and troughs. A village that saw its fields of vine and cereal converted later into large farms producing local produce for the enjoyment of the nobility. Today, Hortaleza is one of the five districts in Madrid with the youngest population. As of 2005, individuals from France made up 1,424 of the residents. This French community settled around the main campus of the prestigious “Lycée Français”. In Hortaleza, the French are the third largest foreign resident population after the Ecuadorians and Colombians. There is a French bookshop, Frañol, located there. Sanchinarro, an independent community before 1950, is now a part of Hortaleza. 13.500 houses were scheduled to be in Sanchinarro in a period around 2005. 11.500 apartment units were planned for Ciudad Aeroportuaria-Valdebebas, another development in Hortaleza.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/hortaleza1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/hortaleza2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/hortaleza3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>La Latina</strong></p>
          <p>DESCRIPTION: located just south of Plaza Mayor, this neighbourhood is made up of beautiful Latin architecture and small colourful alleyways home of 18th-century buildings. Here you’ll see tons of streets lined up with restaurants and their respective “Terrazas”, as well as traditional bars and interesting antique shops. On Sunday mornings, La Latina hosts Madrid’s famous outdoor flea market, “El Rastro”, which engulfs La Cava Baja and the entire surrounding area and is packed with vendors and shoppers alike. The basilica’s elegant dome stands as a backdrop to the area’s bustling streets, restaurants and taverns, which are dotted with interesting graffiti and street art. Perhaps the most popular neighbourhood for a tapas crawl with friends, this eclectic and vibrant barrio is a unique place to spend time exploring one of the oldest parts of Madrid.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/latina1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/latina2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/latina3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Retiro</strong></p>
          <p>DESCRIPTION: Retiro is Madrid’s most popular city park and also refers to the residential neighbourhood which borders the park’s eastern side. It’s a great neighbourhood to live in if you enjoy the quiet, as you can leave the city’s hustle and bustle on the other side of the park. Inside Retiro you can enjoy plenty of beautiful attractions, such as the lake in the photo below, as well as a glass palace, rose garden and inviting green lawns. Also, many free activities are held regularly, from boot camp and running clubs, to roller skating and yoga lessons. The district of Retiro brings together 6 different neighbourhoods: Jerónimos, Ibiza, Estrella, Niño Jesús, Pacífico and Adelfas, being Jerónimos the one most luxurious and representative.
          Jeronimos’ area: one of Retiro’s neighbourhoods is Jerónimos, next to the Retiro Park and, more specifically, in the area delimited by Calle Alcalá, Menéndez Pelayo Avenue and Prado and Reina Cristina promenades. This enclave is made up of stately buildings with spacious premium homes that enjoy great demand and boast the maximum prices of the city, even above Salamanca.</p>
          <p>This area, of great interest in the city, has the great attraction of its proximity to the Retiro Park, one of the largest green spaces in Madrid. In addition, it houses historical monuments of the capital such as the Puerta de Alcalá, the Palacio de Cibeles, the Prado Museum and the Palacio de la Bolsa (Madrid’s Stock Exchange Palace), amongst other buildings.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/retiro1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/retiro2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/retiro3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Salamanca</strong></p>
          <p>DESCRIPTION: Salamanca is Madrid’s most upscale neighbourhood. Let’s call it Madrid’s “Upper East Side”. Located just above Retiro Park and to the East of the Castellana, this neighbourhood’s two main streets are Serrano and Velázquez (as are the metro stops with the same name). It is much quieter than the other neighbourhoods of Madrid. As in most fancy neighbourhoods, you will find the high-end shoe stores, top-notch restaurants and prime real estate. Barrio Salamanca is no exception. However, chic nightclubs and lounges aside, you will also be pleasantly surprised to find many hidden gems. After all, Salamanca is a neighbourhood where people live in, and they too have mom and pop shops and friendly bars. You just have to search for them…</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/salamanca1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/salamanca2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/salamanca3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Argüelles</strong></p>
          <p>DESCRIPTION: Moncloa / Argüelles has everything you could possibly need, just a short walk or metro ride away from the city centre!
          Moncloa has a wonderful vibe, and is packed with shops, bars, supermarkets and great restaurants. It’s in close proximity to Gran Vía and all the shopping and theatre that you could wish for.
          It’s got a great transport network as Moncloa Station is home to the line 3 and line 6 of the metro, as well as a wide range of bus routes. The A-6 road, which is the main road leading to the northwest of the country also exits from here. There are other close Metro stops on line 3 like Argüelles, Ventura Rodríguez and Plaza de España.</p>
          <p>This neighbourhood is perfect for you if you like:
          <ul>
            <li>– having a lot of shops and activities close by. You’ll find Gran Vía, full of international shops like Starbucks and home to all the big theaters in Madrid</li>
            <li>– running  or just walking around your neighbourhood. You’ll love the Moncloa park, very nice and quiet, and just a little further is Plaza de España and the beautiful “Templo de Debod” (right picture, below).</li>
            <li>– living in a nice neighbourhood, full of activities, but very quiet by night.</li>
          </ul>
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/arguelles1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/arguelles2.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Justicia</strong></p>
          <p>DESCRIPTION: the poshest part of the city with Salamanca, it is where many designers, political parties, banks and lawyers live. Next to all the buzzing activity of Chueca and Malasaña, it is nearly “Parisian” in its style.
          As always in Madrid’s city centre, the different neighbourhoods have their own personality, but each one is also influenced by the very diverse neighbours it has. For example, in Alonso Martínez, the influence of Chueca and Malasaña make it the perfect place for designer shops, famous artists hangouts, luxury chocolate and pastry shops, lounge bars and unique apartments.<br>
          I really recommend living in Justicia area if you want to live in the most exclusive and luxurious neighbourhood of Madrids’ city centre, where the Madrid way of life mixes with the style and fashion of Parisian-looking buildings.
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/justicia1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/justicia2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/justicia3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Palacio</strong></p>
          <p>DESCRIPTION: this is the historic heart of Madrid where many of the oldest and most beautiful buildings are to be found. We can find the Royal Palace, surrounded by gorgeous gardens and cafés with terraces to sit out in the sun. The main theatre in Madrid is also located in this area with world class drama and opera being performed throughout the year. Much more relaxed than the noisy Malasaña/Chueca but still very central these areas and only a 10 minute walk away from Sol and the Plaza Mayor. Not many students or young people live in this areas as their proximity to the beautiful Palace call for a higher price in rent.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/palacio1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/palacio2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/palacio3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Pozuelo de Alarcón</strong></p>
          <p>DESCRIPTION: very accessible and comfortable livable area with high standards of quality especially for families and only 15-20 minutes from Madrid!<br>
          The difference with other outskirt suburbs is its proximity with Madrid. This is due 2 “Metro Ligero” lines (tramway), 1 “Cercanías” lines (aboveground proximity train), and half a dozen bus lines go from Pozuelo to Madrid, which means that you can be in the centre in less than 30 minutes. By car, it takes 25 minutes to get everywhere in Madrid.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/pozueloalarcon1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/pozueloalarcon2.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>La Moraleja</strong></p>
          <p>DESCRIPTION: we find ourselves next to Adolfo Suárez Madrid Barajas airport, enclosed by the A1, R2, and M40 highways. Pertaining to the municipality of Alcobendas, it has three sub-neighbourhoods: El Soto, La Moraleja, & El Encinar de los Reyes. 
          <ul>
            <li>•	El Soto: close to the metro, schools, and shopping canters, located in the north end of La Moraleja. El Soto offers mainly flats and town homes as accommodation, and most are located within gated communities.</li>
            <li>•	La Moraleja is the most exclusive. Depending on the location of the home it can overlook the city of Alcobendas or Madrid. This sub-neighbourhood is made up of larger plots of land, and mainly separate single family homes.</li>
            <li>•	El Encinar is located on the southern portion of the neighbourhood. This neighbourhood is very unique because it used to be the housing area for the former US military base in Madrid, therefore it has American style homes. You can find both flats and single family homes; however, this area is the furthest from the metro. There is a bus route through the entire neighbourhood but it is not as reliable or widely used by residents.</li>
          </ul>
          Some of the best schools in Spain are located in La Moraleja, such as the International College Spain (ICS), Kings College, and St. George’s; plenty of green spaces, parks and sports facilities.
          La Moraleja has </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/moraleja.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Boadilla del Monte</strong></p>
          <p>DDESCRIPTION: Boadilla del Monte is one of the most elite municipalities throughout the Community of Madrid. Located just 14 kilometres from the centre of the city, it is the ideal place for those who want to escape the hustle and bustle that characterizes this city. This is certainly a quiet town, with a great atmosphere and excellent transportation links to the capital.
          Boadilla del Monte is in the middle of a magnificent natural setting that offers excellent views. In fact, this town is surrounded by over 750 hectares of forest, which offers its residents pure and clean air to breathe. Due to the long history of the town, surprisingly, we can find architectural and cultural spots like the Palace of “Infante Don Luis” and the fountain of Ventura Rodríguez.
          In Boadilla del Monte, there is a large variety of real estate ranging from apartments to detached villas and townhouses. What they all have in common is comfort and luxury, allowing guests to enjoy gardens, pools, terraces and many other essential elements to create a relaxing environment. In addition, as shown by the latest statistics, buying a house in this town is a great investment because the economic crisis has not affected the real estate market in the area and properties continue to appreciate in value. 
          Let's not forget that this town has many schools of enormous prestige at national level. There are several colleges and language academies as well as the Official School of Languages and the university campus of San Pablo. It also has several medical centres, both public and private.
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/boadilla.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <h3 class="subtitulo">TRANSPORTATION IN</h3><p>The standard driving in Spain is a bit different when compared to other countries. Getting familiar with Madrid will take you some weeks, but try to keep in mind that Madrid has three highways or ring-roads that surround the town: the M-30, the M-40 and M-50. If you need to get to the opposite side of town any of these three are easy options for someone not familiar with cutting through the centre of the city. Be aware that these highways have many speed cameras and police in moving cars with radar detectors.</p>

          <h3 class="subtitulo">PARKING</h3><p>We recommend leaving your car in any of the public car parks in town or using the “green or blue zones” with parking meters. Blue zones you can park up to four hours, green zones up to two hours. If unfortunately your car has been towed call 914 068 810 or go to this <a href="https://www.emtmadrid.es/EMTGruas/LocalizaVehiculo" target="_blank">link</a> to see where they took it. The fine will be around 180€ to get your car back.</p>

          <h3 class="subtitulo">PUBLIC TRANSPORT</h3><p>You need a card to get in and out of the transportation system. This card can be bought and recharged at any metro and train station. You can also buy monthly commuter passes for the commuter trains, metro, suburban buses and city buses. This can be ordered <a href="https://tarjetatransportepublico.crtm.es/CRTM-ABONOS/entrada.aspx" target="_blank">online</a>. The public transport in Madrid includes <a href="https://www.metromadrid.es/es" target="_blank">metro</a>, <a href="https://www.metromadrid.es/es" target="_blank">autobús</a>, <a href="https://www.metromadrid.es/es" target="_blank">cercanías</a> and <a href="https://www.metromadrid.es/es" target="_blank">metro ligero</a>.</p>

          <h3 class="subtitulo">TAXI AND PRIVATE CARS</h3><p>It is usually very easy to find a taxi in Madrid (there are over 15.000). You can hail any of them in the street. If you prefer, you can use some apps: Free Now and TXMAD. You also have Uber and Cabify as private drivers.</p>

          <h3 class="subtitulo">ELECTRIC CARS</h3><p> They have become very popular. You pay a minute by minute fee. The only restraint is that you have to park them in the designated area and have a valid drivers license. You have different companies: <a href="https://www.share-now.com/" target="_blank">ShareNow</a>, <a href="https://zity.eco" target="_blank">Zity</a>, <a href="https://www.emov.eco/" target="_blank">Emov</a>, <a href="https://www.wible.es/" target="_blank">Wible</a>. The prices, model of cars and areas where you can use them vary, so don’t hesitate to fully investigate.</p>
          <img src="images/infotrl/certificados.png" width="500px" style="margin:0 auto; display: block"/>
          `,
          espana: 
            `<h3 class="titulo">WELCOME TO SPAIN</h3>
            <blockquote>Congratulations for choosing Spain as your destination. It’s considered one of the best countries regarding quality of life, so you won’t regret it. From Steps Relocation we will work very hard to make everything easier for you. In our team most of the Counsellors have been expats themselves, so we know how hard and exciting it can be!<br>
                Nuestro equipo está compuesto de expatriados también, así que empatizamos totalmente contigo<br>
                Looking forward to start working for you,<br><br>
                Lydia Martínez - CEO</blockquote>
                <img src="images/infotrl/espana_intro1_en.jpg" width="100%" /><br/>
                <img src="images/infotrl/espana_intro2_en.jpg" width="100%" /><br/>
            <p>
                Spain, officially the Kingdom of Spain, is a country in southwestern Europe with some pockets of territory across the Strait of Gibraltar and the Atlantic Ocean. Its continental European territory is situated on the Iberian Peninsula. It includes two archipelagoes: the Canary Islands, and the Balearic Islands in the Mediterranean Sea.<br>
                With an area of 505,990 km2 (195,360 sq mi), Spain is the largest country in Southern Europe.<br>
                With a population exceeding 47 million, Spain is the sixth-most populous country in Europe. The capital and largest city is Madrid; other major urban areas include Barcelona, Valencia, Seville, Zaragoza, Málaga, and Bilbao.
            </p>
            <p>Visitors can generally expect a Mediterranean climate, characterized by hot, dry summers and mild, rainy winters..<br>
                Spain has one of the lowest crime rates in Europe, however pickpocketing still happens, as in any other country.</p>
            <h3 class="subtitulo">FOOD AND WINE</h3>
            <p>The Spanish people enjoy the good things in life, and their local dishes and wines are on top of the list. Fish and seafood are excellent and included in the daily diet, as well as the olive oil. <br>
                Spaniards have their own habits and eat much later than the rest of their European neighbors! You should expect Restaurants to open normally at 9.00pm, however, If you prefer eating earlier, you can always go to the many “tapas bars”, snack bars which serve food all day long</p>
            <h3 class="subtitulo">WINES</h3>
            <p>One of the greatest things about being an expat in Spain is the discovery of wine. Here you have some info.<br>
                <b>Crianza</b> wine bottled aged at least 6 months in an oak cask plus 2 years in coops or bottles.<br/>
                <b>Reserva</b> this wine is aged a minimum of 12 months in an oak cask plus 3 years in coops or bottles.<br>
                <b>Gran Reserva</b> it must age more than 2 years in oak, plus 5 years in coops or bottles.<br>
                In Spain wines are “divided” in <b>“Denominaciones de Origen” (DO)</b>. This is the system used to recognise and protect a product with regards to its quality, unique characteristics, origin and tradition.<br>
                There are 70 “Denominaciones de Origen” in Spain and we invite to discover them all. But the most important in red wines are <b>Rioja, Ribera del Duero, Valdepeñas and Navarra and in white wine Rias Baixas, Rueda and Ribeiro</b>.
            </p>
            <h3 class="subtitulo">HOW TO PREPARE YOUR FAMILY FOR THE CHANGE</h3>
            <p>
                Preparing your family for the move is essential, here are a few tips:<br>
                • Try to <b>learn a little Spanish</b> before you arrive. Your effort will be compensated. Read as much as you can about Spain, the culture, the history... Remember there is a lot of information available on the Internet.<br>
                • <b>Encourage</b> the children to discover as much as they can about their new destination. Children are normally worried about the unknown and have difficulty imagining what their life would be like in Spain. Tell them about the number of the amusement parks and water parks they will enjoy. The more information they get and the more you include them in all your plans, the better they will accept the change.<br>
                • Be prepared to feel a bit of sadness as you begin to say goodbye to family and friends. Remember that Spain is a wonderful country to visit, so invite everyone to come and see you (and be prepared because they will!)<br>
                Remember this is a <b>family project</b>, look forward to it and be determined to enjoy it.<br>
                We will provide you with all the support and lots of useful information so you can <b>feel positive, happy, excited and not alone</b>.
            </p>
            <h3 class="subtitulo">MEDICAL</h3>
            <p>
                The Public Health Attention in Spain is very good. Social Security Medical Attention is very good and it is free if you are registered. Best doctors work in the public hospitals. <br>
Some companies provide private medical insurance too, so their employees have both options. If not, we can provide information on local private medical insurance. But for serious issues, Public Health System is excellent.
            </p>
            <h3 class="subtitulo">EDUCATION</h3>
            <p>
                There are three kinds of schools:<br>
                PRIVATE SCHOOLS. You have two kinds: <br>
                1) Spanish schools. They follow the Spanish education system. Usually they have several hours in the week to learn the English language and after “1º de ESO” (12- 13 years old) the children can choose a second language<br>
                2) International schools. They follow other Education Curriculums, approved by the Ministry of Education in Spain and children have some hours weekly of Spanish language and another additional language included into the curriculum. <br>
                “CONCERTADOS” (Charter school). These schools are less expensive than the private schools because they receive funds from the government. There are certain rules for registering children. These schools mainly work with a “points system” given by the number of brothers and sisters in the same school, distance to residence or work, etc. It’s necessary to be registered in the town hall.<br>
                PUBLIC SCHOOLS. Public schools in Spain are free. They follow the rules given by the Ministry of Education. But in Spain Government has obligation to provide tuition to all children over 6 years old until they are 16 years old.
            </p>
            <h3 class="subtitulo">DOMESTIC HELP</h3>
            <p>
                To find domestic help, the market is full of agencies who will find somebody for you, and will guarantee his or her services for several months (this means that if you don’t like that person or that person leaves, they have to find you another one immediately). The problem is that these agencies usually charge you a month’s salary.<br>
                INTERNA. The “interna” is live-in help. This person will have its own room in your house and sleep there. They will clean, cook, iron and take care of children as negotiated with you in the interview. They will work normally 40 hours per week. They are subject to the minimum mandatory salary in Spain. You will have to also add the social security cost and they are entitled to payed holiday.<br>
                EXTERNA. Performs the same tasks as an but does not live in your house. Normally they work a fixed number of hours agreed previously. Minimum salary applies here too, and it is calculated on the bases of the hours of work agreed. If they don’t reach 40 hours/week, you will pay accordingly both salary and social security. Payed holiday applies here too.<br>
                ASSISTANT. Will work a few hours a week and you will pay between 10€ or 12€ per hour. It is subject to social security too. <br>
                BABYSITTING. Negotiate rates for long evenings. A good place to look is at a school.
            </p>
            <img src="images/infotrl/certificados.png" width="500px" style="margin:0 auto; display: block"/>
            `
        },
      modalsimple: {
        cambiarcontrasena: 'Change password',
        tucontrasenase: 'Your password has been changed',
        contrasenaactual: 'Current password',
        nuevacontrasena: 'New password',
        repitelanueva: 'Repeat password',
        porlomenos4: 'At least 4 characters',
        cancelar: 'Cancel',
        cambiar: 'Change',
      }
    },
  },

  es: {
    message: {
      app: {
        home: 'Home',
        usuarios: 'Usuarios',
        viviendas: 'Viviendas',
        empresas: 'Empresas',
        documentacion: 'Documentación',
        puedesubir: 'Puede subir su foto para que nos pongamos cara',
        puedeeditar: 'Puede editar su foto',
        cambiarcontrasena: 'Cambiar contraseña',
        salir: 'Salir',
        politicadeprivacidad: 'Política de privacidad'
      },
      panelfiltrosviviendas: {
        disponibilidad: 'Disponibilidad'
      },
      panelfiltrospersonas: {
        buscar: 'Buscar',
        tipo: 'Tipo',
        todos: 'Todos',
        trls: 'TRLs',
        rrhh: 'RRHH',
        consejeras: 'Consejeras',
        admin: 'Admin',
        estado: 'Estado',
        activos: 'Activos',
        finalizados: 'Finalizados'
      },
      panelfiltrostareas: {
        buscartrl: 'Buscar TRL',
        consejeras: 'Consejeras',
        trls: 'TRLs',
        todas: 'Todas',
        notienes: 'No tienes ningún TRL asociado'
      },
      login: {
        area: 'Área privada',
        somos: 'Somos una empresa formada por profesionales que llevan dedicados más de 20 años a los traslados internacionales, con una amplia experiencia en todos los sectores que constituyen un servicio de relocation.',
        email: 'Email',
        contraseña: 'Contraseña',
        acceder: 'Acceder',
        hasolvidado: '¿Has olvidado tu contraseña?',
        recuperar: 'Recuperar contraseña',
        volverallogin: 'Volver al login',
        almenos4: 'Al menos 4 caracteres',
        min4: 'Mínimo 4 caracteres',
        almenos8: 'Al menos 8 caracteres',
        hard: 'Debe contener al menos 8 caracteres de entre ellos una mayúscula, una minúscula, un número y un caracter especial',
        nosegurahard: 'Contraseña no segura, recuerda que debe contener al menos 8 caracteres de entre ellos una mayúscula, una minúscula, un número y un caracter especial',
        mailnovalido: 'Formato de email no válido',
        usuariocontraseñaincorrecto: 'El email / contraseña introducidos no son correctos',
        escribeLaDireccionDeEmail: 'Escribe la dirección de email asociada a tu cuenta y te enviaremos las indicaciones para restablecer tu contraseña. Es posible que acaben en tu carpeta de correo no deseado o spam, por favor revísala también.',
        setehaenviadounemail: 'Se te ha enviado un email, sigue las instrucciones para recuperar tu contraseña',
        tuContrasenaSeHaRestaurado: 'Tu contraseña se ha cambiado correctamente',
        demasiadosintentos: 'Has realizado demasiados intentos erróneos, espera 15 minutos para volver a intentarlo',
        creacontraseña: 'Crea tu contraseña',
        creanuevacontraseña: 'Crea una nueva contraseña',
        tunombre: 'Tu nombre de usuario:',
        restablecer: 'Restablecer contraseña',
        usuarioinactivo: "Usuario todavía inactivo, pincha en el link enviado por email para activarlo"
      },
      documentacion: {
        documentacion: 'Documentación',
        subirarchivo: 'Subir un archivo',
        tusdocumentos: 'Tus documentos',
        todaviano: 'Todavía no has subido ningún documento',
        documentossubidos: 'Documentos subidos por Steps Relocation',
        recuerdaquelosnombres: 'Recuerda que los nombres de los archivos no deben contener tildes ni caracteres de puntuación o símbolos.<br>Tamaño de archivo máximo 16Mb.',
        archivolimite: 'No se puede subir el archivo porque supera el límite de tamaño de 16megas.',
        archivocaracteres: 'No se puede subir el archivo porque contiene caracteres especiales que no están admitidos.'
      },
      usuarios: {
        usuarios: 'Usuarios',
        ordenar: 'Ordenar',
        recientes: 'Recientes',
        apellidosaz: 'Apellidos a-z',
        apellidosza: 'Apellidos z-a',
        filtrarpor: 'Filtrar por',
        consejeras: 'Consejeras',
        nohay: 'No hay resultados',
        empresas: 'Empresas',
        nuevousuario: 'Nuevo usuario',
        trl: 'TRL',
        rrhh: 'RRHH',
        noencontrado: 'No se han encontrado usuarios'
      },
      home: {
        calendario: 'Calendario',
        listado: 'Listado',
        // todosnuestrosservicios: 'Todos nuestros servicios',
        informacionimportante: 'Información importante',
        // bienvenido: 'Bienvenido a ',
        condicionesalquiler: 'Condiciones de alquiler',
        espana: 'Bienvenidos a España',
        madrid: 'Bienvenidos a Madrid'
      },
      panelconsejera: {
        hola: 'Hola',
        nuestracompania: 'Nuestra Compañía <b>Steps Relocation, SL</b> ha sido contratada por su empresa, <span class="bold">{empresa}</span>, para la prestación a Vd. y a su familia, de los servicios de “relocation”.',
        nuestracompania_rrhh: 'Nuestra Compañía <b>Steps Relocation, SL</b> ha sido contratada por su empresa, <span class="bold">{empresa}</span>, para la prestación a su trabajador, de los servicios de “relocation”.',
        consejeraasignada: 'Su consejera asignada es',
        preferencias: 'Preferencias',
        misservicios: 'Mis servicios'
      },
      modalnuevotrl: {
        bienvenido: 'Bienvenido',
        bienvenidomadrid: 'Bienvenido a Madrid',
        nuestracompania: 'Nuestra Compañía <b>Steps Relocation, SL</b> ha sido contratada por su empresa, <span class="bold">{empresa}</span>, para la prestación a Vd. y a su familia, de los servicios de “relocation”.',
        tusservicios: 'Estos son tus servicios',
        aceptacionpolitica: 'Aceptación de la Política de Privacidad',
        parapodercomenzar: 'Para poder comenzar con sus gestiones, primero necesitamos que acepte la Política de Privacidad para autorizarnos a poder guardar sus datos. Puede consultar nuestra política de Privacidad haciendo click aquí.',
        politica: `Sus datos personales no serán publicados. Los datos de carácter personal aportados, así como los datos de sus hijos menores de 14 años, y que se encuentran bajo su patria potestad, se tratan de conformidad con la normativa reguladora de la protección de datos de carácter personal vigente en cada momento, a día de hoy, en la Unión Europea, el Reglamento UE/2016/679, Reglamento General de Protección de Datos (RGPD). Sus datos se incorporan al fichero denominado “Clientes (Familia)” responsabilidad de Steps Relocation, SL.<br>
        <b>Información básica de la política de privacidad:</b><br>
        <b>Datos del responsable</b> Steps Relocation, SL  •  <b>CIF</b> B83325571  •  <b>Domicilio social</b> c/ Castillo de Aysa 2D  •  <b>Tfno.</b> (+34.) 810 526 434  •  <b>CEO</b> Dª Lydia Martínez García  • <b>Email</b> lmartinez@stepsrelocation.com<br>
        Finalidad: La finalidad del tratamiento es la prestación del servicio de “relocation” al Cliente.<br>
        <b>Base jurídica</b> el Contrato suscrito.<br>
        <b>Destinatarios</b> No se ceden datos a terceros, salvo obligación legal. Steps Relocation, SL podrá comunicar los datos personales a otros partners ubicados fuera del EEE, en caso de que los servicios deban ser prestados fuera de España, asegurando que dicha transferencia no supondrá ningún riesgo para los derechos y libertades de los interesados puesto que se efectuará de conformidad con lo dispuesto en el art.  44 y siguientes del Reglamento Europeo de protección de datos.<br>
        <b>Plazo de conservación de su información</b> Los datos personales que proporcione se conservarán durante un plazo de 6 años a partir de la realización de los servicios, manteniéndolos bloqueados hasta la prescripción de posibles acciones de responsabilidad.<br>
        <b>Derechos:</b> Puede ejercer sus derechos de acceso, rectificación, supresión y portabilidad de sus datos, limitación y oposición a su tratamiento, cuando procedan ante Steps Relocation, SL, Dpto. Administración, con domicilio en c/ Castillo de Aysa 2D, 28043 Madrid  o en la dirección de correo electrónico lopd@stepsrelocation.com<br>
        Asimismo, le informamos de que puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente, la Agencia Española de Protección de Datos (https://www.aepd.es), especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos.<br>
        <b class="link"><a href="/#/politicaPrivacidad" target="blank">Información sobre la política de privacidad.</a></b>`,
        titular: 'Titular',
        heleido: 'He leído, entiendo y acepto la Política de Privacidad.',
        conyuge: 'Cónyuge',
        soloencaso: 'Solo en caso de que sus hijos vayan a ser trasladados con usted.',
        indicanos: 'indícanos tus preferencias',
        conlassiguientes: 'Con las siguientes preguntas, obtendremos una imagen clara de sus requisitos de traslado, que nos permitirán proporcionarle un servicio personalizado de acuerdo a sus necesidades y preferencias.',
        datospersonalestab: 'DATOS PERSONALES',
        preferenciastraslado: 'PREFERENCIAS TRASLADO',
        colegios: 'COLEGIOS',
        tabhijos: 'HIJOS',
        datospersonales: 'Datos personales',
        nombre: 'Nombre',
        apellidos: 'Apellidos',
        nacionalidad: 'Nacionalidad',
        direcionactual: 'Dirección actual',
        pasaporte: 'Pasaporte',
        imagenpasaporte: 'Imagen del pasaporte',
        datoscontacto: 'Datos de contacto',
        emailprofesional: 'Email profesional',
        emailpersonal: 'Email personal',
        movil: 'Móvil',
        datosconyuge: 'Datos de cónyuge',
        sealoja: '¿Se realoja tu cónyuge contigo?',
        email: 'email',
        traslado: 'Traslado',
        fechatraslado: 'Fecha traslado',
        fechadisponible: 'Fecha/s disponible/s para visitas',
        fechaentrar: 'Fecha en la que le gustaría entrar en la vivienda',
        cuantotiempo: '¿Cuánto tiempo quiere alquilar la vivienda?',
        presupuesto: '¿Cuál es su presupuesto?',
        recuerde: 'Recuerde que los gastos (suministros) no se suelen incluir en la renta.',
        vivienda: 'Vivienda',
        tipovivienda: 'Tipo de vivienda',
        piso: 'Piso',
        casa: 'Casa',
        metroscuadrados: 'Metros cuadrados deseados',
        nhabitaciones: 'Número de habitaciones',
        estado: 'Estado',
        amueblada: 'Amueblada',
        sinamueblar: 'Sin amueblar',
        estilo: 'Estilo',
        clasico: 'Clásico',
        moderno: 'Moderno',
        otro: 'otro',
        caracteristicas: 'Características',
        piscina: 'Piscina',
        jardin: 'Jardín',
        terraza: 'Terraza',
        zonasinfantiles: 'Zonas infantiles',
        habitacionservicio: 'Habitación de servicio',
        otraspreferencias: 'Otras preferencias',
        centro: 'En el centro',
        afueras: 'En las afueras',
        cercaoficina: 'Cerca de la oficina',
        cercacolegio: 'Cerca del colegio',
        cercametro: 'Cerca del metro/FGC',
        soleado: 'Soleado',
        zonatranquila: 'Zona tranquila',
        preferenciasareas: 'Preferencias de áreas residenciales',
        garaje: 'Garaje',
        ncoches: 'Número de coches',
        incluidoenpresupuesto: 'Incluido en el presupuesto',
        traemascota: '¿Trae mascota?',
        si: 'Sí',
        no: 'No',
        encasoafirmativo: 'En caso afirmativo, indíquenos de qué tipo',
        otros: 'Otros',
        nhijos: 'Número de hijos',
        hijo: 'Hijo/a',
        sexo: 'Sexo',
        hombre: 'Hombre',
        mujer: 'Mujer',
        fechanacimiento: 'Fecha de nacimiento',
        curso: 'Curso',
        idiomas: 'Idiomas',
        preferencias: 'Preferencias',
        tipocolegio: 'Tipo de colegio',
        concertado: 'Concertado',
        privado: 'Privado',
        publicos: 'Públicos',
        tipoeducacion: 'Tipo de educación',
        alemana: 'Alemana',
        americana: 'Americana',
        britanica: 'Británica',
        espanola: 'Española',
        francesa: 'Francesa',
        otras: 'Otras',
        paracontinuar: 'Para continuar debes de aceptar la Política de privacidad',
        continuar: 'Continuar',
        guardarsalir: 'Guardar y salir'
      },
      pag_servicios: {
        nuestrosservicios: 'Nuestros servicios',
        vivienda: 'Vivienda',
        otros: 'Otros',
        establecimiento: 'Establecimiento',
        inmigración: 'Inmigración',
        coches: 'Coches',
        fiscal: 'Fiscal',
        volver: 'Volver atrás'
      },
      servicios: {
        1: {
          nombre: 'Alojamiento temporal',
          info: 'Elaboraremos una breve lista de opciones de alojamiento temporal que estén disponibles y te acompañamos a visitar 3 opciones, negociamos condiciones favorables y coordinamos la reserva de la vivienda seleccionada. Acompañamiento a la firma del contrato.'
        },
        2: {
          nombre: 'Alquiler de muebles',
          info: 'Organizamos el alquiler temporal de muebles para tu nueva casa. Ofrecemos apoyo durante la selección, reserva, pagos y soporte telefónico el día de la entrega.'
        },
        3: {
          nombre: 'Alta como empleador',
          info: 'Necesario para poder contratar ayuda doméstica. Todas las personas que contratan trabajadores por primera vez, deben solicitar el registro como empleadores en la Seguridad Social Española.'
        },
        4: {
          nombre: 'Apoyo a la pareja',
          info: 'Le informamos sobre diferentes actividades, asociaciones, clubs, ONG’s, educación, cursos, etc, para que tu experiencia en el nuevo destino sea productiva.'
        },
        5: {
          nombre: 'Asistencia telefónica',
          info: 'Le prestamos asistencia telefónica de lunes a viernes en horario laboral (de 9:00 a 19:00)'
        },
        6: {
          nombre: 'Aut. residencia - altamente cualificados',
          info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida tras la llegada del extranjero a España.'
        },
        7: {
          nombre: 'Aut. residencia - emprendedores',
          info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida tras la llegada del extranjero a España.'
        },
        8: {
          nombre: 'Ayuda doméstica',
          info: 'Te pondremos en contacto con agencias de servicio domestico y te presentaremos al gerente. Haremos seguimiento de las entrevistas. Alta en la seguridad social del empleado y empleador.'
        },
        9: {
          nombre: 'Búsqueda de colegio',
          info: 'Asesoramiento sobre diferentes colegios basándonos en tu cuestionario. Coordinación de visitas y acompañamiento a 3 colegios.  Asistencia con los requisitos de matriculación. Seguimiento sobre el proceso de admisión.'
        },
        10: {
          nombre: 'Búsqueda de parking',
          info: 'Buscamos una plaza de parking cerca de tu nuevo apartamento, de acuerdo a tus requerimientos y presupuesto. Negociación del contrato y coordinación de pagos'
        },
        11: {
          nombre: 'Búsqueda de vivienda',
          info: 'Identificación de las necesidades. Te enviaremos por email opciones que cuadren con los requerimientos descritos en el cuestionario (siempre que sean realistas). Un día de visitas acompañadas a unas 6-8 opciones preseleccionadas por ti. Las visitas serán de lunes a viernes en horario laboral. Negociación de contrato, acompañamiento a la firma e inventario fotográfico.'
        },
        12: {
          nombre: 'Canje carnet de conducir (EU)',
          info: 'Información completa sobre el trámite, documentación y requisitos. Preparación del expediente y reserva de cita previa. Acompañamiento a la presentación y seguimiento con el departamento de tráfico.  Gestión de problemas. Tu carnet de conducir debe estar en vigor.'
        },
        13: {
          nombre: 'Certificado de Registro UE',
          info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades. Acompañamiento a la presentación. Este certificado incluye el número NIE.'
        },
        14: {
          nombre: 'Compra vivienda',
          info: 'Asesoramiento sobre las diferentes zonas. Selección de viviendas que se ajusten a las preferencias previamente descritas en el cuestionario (siempre que sean realistas). Organización de visitas con agentes inmobiliarios y propietarios. Un día de visitas acompañadas. Negociación del contrato de compra-venta, comprobación en el Registro de la Propiedad y estado del pago de impuestos. Negociación del contrato de arras (contrato privado previo a la compra del contrato en el que se establecen las condiciones de compra y en las que se abona el 10% del valor de la propiedad. Acompañamiento al notario.'
        },
        15: {
          nombre: 'Comunicación de desplazamiento',
          info: 'Información y asesoramiento sobre el procedimiento de comunicación del desplazamiento a la oficina de empleo. Preparación de expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades.'
        },
        16: {
          nombre: 'Trámites administrativos',
          info: 'Te informaremos sobre los trámites administrativos necesarios para estar completamente instalado en tu nuevo país.'
        },
        17: {
          nombre: 'Apertura de cuenta bancaria',
          info: 'Tu consejera encontrará la sucursal más conveniente del banco elegido y te ayudará con la presentación de la documentación para obtener tarjetas de crédito, etc y le acompañaremos a la sucursal bancaria.'
        },
        19: {
          nombre: 'Des-registro coche',
          info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente y presentación ante las autoridades.'
        },
        20: {
          nombre: 'Des-registro empadronamiento',
          info: 'Información y asesoramiento sobre el trámite, requisitos y documentos. '
        },
        21: {
          nombre: 'Des-registro certificado de registro UE',
          info: 'información y asesoramiento sobre el procedimiento, requisitos y documentos. Presentación ante las autoridades.'
        },
        22: {
          nombre: 'Empadronamiento',
          info: 'Información y asesoramiento sobre el trámite, requisitos y documentos. Acompañamiento al Ayuntamiento.'
        },
        23: {
          nombre: 'Emprendedores - Renovacion',
          info: ''
        },
        25: {
          nombre: 'Gestión de mudanzas',
          info: 'Contactamos con la empresa de mudanzas, confirmamos el embalaje, la recogida y fechas de entrega. Coordinación de todo el proceso.'
        },
        26: {
          nombre: 'Legalización vehículo',
          info: 'Información complete sobre el proceso, preparación del expediente y presentación de la solicitud. Coordinación de la cita con el centro de ITV así como en el taller si es necesario. Obtención y envío de las nuevas matrículas.'
        },
        27: {
          nombre: 'Inclusión de beneficiarios Seguridad Social',
          info: 'Este procedimiento es obligatorio previo a la solicitud de las tarjetas sanitarias. Información sobre el trámite, preparación del expediente, presentación de la solicitud y recogida de la resolución.'
        },
        28: {
          nombre: 'Inventario',
          info: 'Inspección de la vivienda, verificación de las condiciones de la misma y realización de inventario fotográfico.'
        },
        29: {
          nombre: 'Look&see trip',
          info: 'Tu consejero te explicará las condiciones de vida, zonas residenciales y características de alquiler en un viaje de familiarización antes de tu traslado. Información sobre centros de salud, administraciones, etc. Te enseñaremos un máximo de 4 ejemplos de vivienda.'
        },
        30: {
          nombre: 'Acompañamiento adicional acompañado - 1dia',
          info: 'Acompañamiento durante un día o  dos medios días (de 10:00 a 13:30) a un tour por el centro de la ciudad. El tiempo de su consejero puede utilizarse para cubrir cualquier necesidad específica que tengas. Comida en uno de los restaurantes de moda de Madrid.'
        },
        31: {
          nombre: 'Mascotas',
          info: 'Organizamos una cita con un veterinario local, gestionamos servicios de paseadores de perros y buscamos residencia para cuando estés fuera.'
        },
        32: {
          nombre: 'Negociación de contrato',
          info: 'Asistencia durante la negociación. Revisión y adaptación del contrato de arrendamiento. Acompañamiento durante la firma y la entrega de llaves.'
        },
        33: {
          nombre: 'Certificado NIE',
          info: 'Información sobre el trámite, preparación del expediente, presentación de la solicitud y recogida de la resolución.'
        },
        34: {
          nombre: 'Nº Seg. Social (NAF)',
          info: 'Información sobre el trámite, preparación del expediente, presentación de la solicitud y recogida de la resolución.'
        },
        35: {
          nombre: 'Onboarding support',
          info: 'Apoyo a los nuevos empleados que se incorporan a la compañía actuando como punto de contacto para cualquier consulta que pueda tener antes de la fecha de inicio y durante el periodo de adaptación. Coordinación con los diferentes departamentos para la obtención del escritorio, ordenador y otros recursos.'
        },
        36: {
          nombre: 'Paquete de bienvenida',
          info: 'Carta/email de presentación, información sobre la ciudad de destino. Estudio de las necesidades personales y/o de la familia y llamada de contacto si fuera necesario. Acceso a la Intranet de Steps Relocation.'
        },
        37: {
          nombre: 'Programa Visar',
          info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida.'
        },
        38: {
          nombre: 'Régimen tributario especial expatriados',
          info: 'Revisión del caso. Explicación al empleado de las características especificaciones de este régimen y las obligaciones formales. Registro fiscal. Notificación de la opción por el régimen especial de acuerdo al modelo 149. Preparación del expediente.'
        },
        39: {
          nombre: 'Registro Consulado/Embajada',
          info: 'Información sobre el trámite y preparación del expediente. Obtención de cita previa siempre que sea posible.'
        },
        40: {
          nombre: 'Renovación certificado de Registro UE',
          info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades. Acompañamiento a la presentación. Este certificado incluye el número NIE.'
        },
        41: {
          nombre: 'Segunda búsqueda de vivienda',
          info: 'Identificación de las necesidades. Te enviaremos por email todas las opciones disponibles en el mercado que cuadren con los requerimientos descritos en el cuestionario (siempre que sean realistas). Un día de visitas acompañadas a unas 6/8 opciones pre-seleccionadas por ti. Las visitas serán de lunes a viernes en horario laboral. Negociación del contrato, acompañamiento a la firma e inventario fotográfico.'
        },
        42: {
          nombre: 'Servicio cierre',
          info: ''
        },
        43: {
          nombre: 'Suministros',
          info: 'Conexión de suministros de agua, electricidad, gas, internet y teléfono. Organización de domiciliaciones bancarias.'
        },
        44: {
          nombre: 'Suministros comprobación',
          info: ''
        },
        45: {
          nombre: 'Tarjetas sanitarias',
          info: 'Información sobre el trámite y acompañamiento personal al Centro de Salud.'
        },
        46: {
          nombre: 'SER (Servicio de estacionamiento Regulado)',
          info: 'Este trámite permite aparcar en la calle, dentro de los límites de tu barrio. Información sobre el procedimiento y acompañamiento a la solicitud.'
        },
        47: {
          nombre: 'Variación de datos en la Seguridad Social',
          info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. '
        },
        48: {
          nombre: 'Aut. residencia - altamente cualificados – dependiente',
          info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida tras la llegada del extranjero a España.'
        },
        49: {
          nombre: 'Aut. residencia - emprendedores – dependiente',
          info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida tras la llegada del extranjero a España.'
        },
        50: {
          nombre: 'Certificado de Registro UE – dependiente',
          info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades. Acompañamiento a la presentación. Este certificado incluye el número NIE.'
        },
        51: {
          nombre: 'Emprendedores - Renovacion',
          info: ''
        },
        52: {
          nombre: 'Renovación certificado de Registro UE – dependiente',
          info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades. Acompañamiento a la presentación. Este certificado incluye el número NIE.'
        }
      },
      viviendas: {
          viviendas: 'Viviendas',
          seleccionadassegun: 'seleccionadas según tus preferencias',
          ordenar: 'Ordenar:',
          fechadeincorporacion: 'Fecha de incorporación',
          ultimamodificacion: 'Última modificación',
          precio: 'Precio',
          emes: '€/mes',
          ultmodificacion: 'Últ. modificación',
          notenemosaunviviendas: 'No tenemos ninguna viviendas con estas características',
          suconsejeraaun: 'Su consejera aún no le ha asignado ninguna vivienda',
          hab: 'hab.',
          ascensor: 'ascensor ',
          piscina: 'piscina ',
          jardin: 'jardín ',
          terraza: 'terraza ',
          piso: 'Piso',
          casa: 'Casa'
      },
      fichavivienda: {
          estaViviendaNoExiste: 'Esta vivivienda ya no está disponible o ha sido eliminada',
          volver: 'Volver a Viviendas',
          disponible: 'Disponible',
          nodisponible: 'No disponible',
          emes: '€/mes',
          caracteristicasbasicas: 'Características básicas',
          planta: 'Planta',
          casa: 'Casa',
          habitaciones: 'habitaciones',
          banos: ' baños',
          piscina: 'Piscina',
          jardin: 'Jardín',
          terraza: 'Terraza',
          zonasinfantiles: 'Zonas infantiles',
          habitaciondeservicio: 'Habitación de servicio',
          soleado: 'Soleado',
          zonatranquila: 'Zona tranquila',
          aireacondicionado: 'Aire acondicionado',
          calefaccion: 'Calefacción',
          trastero: 'Trastero',
          cercametro: 'Cerca del metro/FGC',
          estado: 'Estado',
          Excelente: 'Excelente',
            'Buenas condiciones': 'Buenas condiciones',
            'Nueva construcción': 'Nueva construcción',
            Reformado: 'Reformado', 
            'A reformar': 'A reformar',
          estilo: 'Estilo',
          'Clásico': 'Clásico', 
          equipamiento: 'Equipamiento',
          Amueblado: 'Amueblado', 
          'Sin amueblar': 'Sin amueblar', 
          'Parcialmente amueblado': 'Parcialmente amueblado',
          Moderno: 'Moderno',
          Otro: 'Otro',
          garaje: 'Garaje',
          plazas: ' plazas',
          inluidoenelprecio: 'Incluido en el precio',
          noincluidoenprecio: 'No incluido en el precio',
          mascota: 'Mascotas',
          mascotasi: 'Sí',
          gastosdecomunidad: 'Gastos de comunidad:',
          tipodecontrato: 'Tipo de contrato:',
          Alquiler: 'Alquiler',
          Compra: 'Compra',
          'Alquiler temporal': 'Alquiler temporal',
          condicionesalquiler: 'Condiciones de alquiler:',
          barrio: 'Barrio',
          descripcion: 'Descripción',
          piso: 'Piso',
          casa: 'Casa'
      },
      paginabasica: {
          volver: 'Volver atrás',
          politicadeprivacidad: 'Politica de privacidad',
          lerecomendamosquelea: 
            `<p><strong>LE RECOMENDAMOS QUE LEA Y COMPRENDA NUESTRA POLÍTICA DE PRIVACIDAD ANTES DE FACILITAR SUS
                        DATOS PERSONALES Y OTORGAR EL CONSENTIMIENTO PARA SU TRATAMIENTO.</strong></p>
                <p><strong>1</strong><strong>.- ¿Quién es el responsable del tratamiento de tus datos?.</strong></p>
                <p>Steps Relocation, SL, con domicilio en c/ Castillo de Aysa nº 2D, 28043 Madrid, provista de NIF nº
                    B83325571, representada por su Consejera Delegada, Dª Lydia Martínez García
                    (lmartinez@stepsrelocation.com).</p>
                <p><strong>&nbsp;2.- ¿Qué datos tuyos tratamos?.</strong></p>
                <p>2.1 Datos que nos han sido facilitados por el usuario (Contacto) mediante formulario web, y que se
                    corresponden con los siguientes datos personales: nombre, apellidos, dirección, email y teléfono.
                </p>
                <p>2.2 Datos que nos han sido facilitados por la Familia (Cliente) mediante formulario en papel y a
                    través de la intranet: nombre y apellidos de los padres o tutores, móvil y teléfono fijo, dirección,
                    nº de pasaporte, DNI o tarjeta de residencia, email; nombre, apellidos, fecha de nacimiento y
                    colegio de los hijos.</p>
                <p><strong>3.- ¿Con qué finalidad tratamos tus datos personales y cuál es la base
                        jurídica?</strong><strong>&nbsp;</strong></p>
                <p>Tratamos tus datos con las siguientes finalidades, base jurídica y plazos de conservación, descritas
                    a continuación:</p>
                <p>3.1 Contactos.</p>
                <p>Finalidad: gestionar la relación comercial (atender las consultas y/o cuestiones planteadas).</p>
                <p>Base jurídica: consentimiento.</p>
                <p>Plazos de conservación: Los datos personales que proporcione se conservarán mientras no solicite su
                    supresión y, en caso de que no la solicite, transcurrido el plazo de 1 año a partir de la última
                    confirmación de interés por su parte.</p>
                <p>3.2 Cliente.</p>
                <p>Finalidad: prestación del servicio de relocation al Cliente (ejem: servicios de búsqueda de vivienda,
                    mudanza, colegio para los hijos, procesos migratorios y de establecimiento, canjes de permisos de
                    conducir).</p>
                <p>Base jurídica: el Contrato suscrito.</p>
                <p>Plazos de conservación: Los datos personales que proporcione se conservarán durante un plazo de 6
                    años a partir de la realización de los servicios, de acuerdo a la política sobre eliminación
                    documental de Steps Relocation, S.L, manteniéndolos bloqueados hasta la prescripción de posibles
                    acciones de responsabilidad.</p>
                <p><strong>4.- ¿Quién es el destinatario de tus datos?</strong></p>
                <p>4.1 Comunicación de datos:</p>
                <ol>
                    <li>Los datos personales tratados se comunican a terceros, en cumplimiento de obligaciones legales.
                    </li>
                    <li>Asimismo, Steps Relocation, SL, para la efectiva prestación del servicio, podrá comunicar los
                        datos personales a otros <em>partners </em>ubicados fuera del EEE<em>,</em> que no proporcionen
                        un nivel de protección de datos equiparable al de la Unión Europea, como Estados Unidos. En
                        tales casos, le informamos de que transferimos sus datos con garantías adecuadas y siempre
                        guardando la seguridad de los mismos, asegurando que dicha transferencia se efectuará de
                        conformidad con lo dispuesto en el art. 44 y siguientes del Reglamento Europeo de Protección de
                        Datos, a:</li>
                </ol>
                <ul>
                    <li>un destinatario declarado de nivel adecuado por la Comisión Europea (de acuerdo a la información
                        publicada por la AEPD), o</li>
                    <li>certificado en Privacy Shield, o</li>
                    <li>a un destinatario con las garantías adecuadas, mediante la suscripción de las correspondientes
                        Cláusulas contractuales tipo adoptadas por la Comisión,</li>
                    <li>y/o con el consentimiento expreso del interesado.</li>
                </ul>
                <p>Puede solicitar evidencia de las garantías existentes enviando un email a <a
                        href="mailto:lopd@stepsrelocation.com">lopd@stepsrelocation.com</a>.</p>
                <p>4.2 Encargados del tratamiento</p>
                <p>Steps Relocation contará con la colaboración de terceros proveedores de servicios que pueden tener
                    acceso a sus datos personales y que tratarán los referidos datos en nombre y por cuenta de la
                    Compañía, como consecuencia de su prestación de servicios. Steps Relocation sigue unos criterios
                    estrictos de selección de proveedores de servicios con el fin de dar cumplimiento a sus obligaciones
                    en materia de protección de datos, y se compromete a suscribir con ellos el correspondiente contrato
                    de tratamiento de datos.</p>
                <p>En concreto, Steps Relocation contratará la prestación de servicios por parte de terceros proveedores
                    que desempeñan su actividad, a título enunciativo y no limitativo, en los siguientes sectores:
                    servicios de Relocation (búsqueda de vivienda, búsqueda de colegios, aperturas de cuenta bancaria,
                    etc. )suministro de mobiliario, integración cultural, servicios de mudanzas, servicios jurídicos,
                    contables y de gestoría, y empresas proveedoras de servicios informáticos.</p>
                <p><strong>5.- ¿Cuáles son sus derechos cuando nos facilita sus datos?</strong></p>
                <p>Los derechos que le asisten en relación al tratamiento de datos personales por parte de STEPS
                    RELOCATION son:</p>
                <ul>
                    <li><u>Derecho a solicitar el acceso</u> a tus datos personales</li>
                    <li><u>Derecho a solicitar su rectificación o supresión</u></li>
                    <li><u>Derecho a solicitar la limitación de tu tratamiento</u></li>
                    <li><u>Derecho a oponerse al tratamiento</u></li>
                    <li><u>Derecho a la portabilidad</u> de los datos</li>
                </ul>
                <p><strong>6.- ¿En qué consiste el derecho de acceso?.</strong></p>
                <p>Tendrá derecho a obtener confirmación de si se están tratando o no datos personales que le
                    conciernen, y a la siguiente información: los fines del tratamiento; las categorías de los datos que
                    se tratan; los destinatarios o las categorías de destinatarios a los que se han comunicado o serán
                    comunicados; de ser posible, el plazo previsto de conservación de los datos personales o, de no ser
                    posible, los criterios utilizados para determinar este plazo.</p>
                <p>En estos casos, le facilitaremos una copia de los datos personales objeto de tratamiento. Además,
                    cuando lo solicite por medios electrónicos, la información se facilitará en un formato electrónico
                    de uso común.</p>
                <p>Podrá ejercitar este derecho cada seis meses, salvo que exista causa legítima para solicitarlo, más
                    de una vez, en dicho periodo de seis meses y le informaremos sobre las actuaciones derivadas de su
                    petición en el plazo de un mes.</p>
                <p><u>Para ejercitar el derecho de acceso, </u>envíe un email a &nbsp;<a
                        href="mailto:belen@kinsaleconsulting.com">lopd@stepsrelocation.com</a> o remita una carta a la
                    atención del Dpto. Administración, con domicilio en c/ Castillo de Aysa 2D, 28043 Madrid.</p>
                <p><strong>7.- ¿En qué consiste el derecho de rectificación?</strong></p>
                <p>Tendrá derecho a obtener la rectificación de los datos personales inexactos que le conciernen, para
                    ello, tendrá que acompañar, cuando sea preciso, la documentación justificativa de la inexactitud o
                    carácter incompleto de los datos objeto del tratamiento.</p>
                <p><u>Para ejercitar el derecho de rectificación, </u>envíe un email a&nbsp; <a
                        href="mailto:belen@kinsaleconsulting.com">lopd@stepsrelocation.com</a> o remita una carta a la
                    atención del Dpto. Administración, con domicilio en c/ Castillo de Aysa 2D, 28043 Madrid.</p>
                <p><strong>8.- ¿En qué consiste el derecho de supresión?</strong></p>
                <p>Tendrá derecho a obtener la supresión de los datos personales que le conciernen cuando concurran una
                    de las circunstancias siguientes: que no sean necesarios en relación con los fines para los que
                    fueron recogidos o tratados; que retire el consentimiento y el tratamiento no se base en otro
                    fundamento jurídico; que se opongas al tratamiento y no prevalezcan otros motivos legítimos para el
                    tratamiento; que hayan sido tratados ilícitamente.</p>
                <p><u>Para ejercitar el derecho de supresión, </u>envíe un email a&nbsp; &nbsp;<a
                        href="mailto:belen@kinsaleconsulting.com">lopd@stepsrelocation.com</a> o remita una carta a la
                    atención del Dpto. Administración, con domicilio en c/ Castillo de Aysa 2D, 28043 Madrid.</p>
                <p><strong>9.- ¿En qué consiste el derecho a la limitación del tratamiento?</strong></p>
                <p>Tendrá derecho a obtener la limitación del tratamiento de los datos cuando se cumpla alguna de las
                    condiciones siguientes:</p>
                <ol>
                    <li>cuando haya impugnado la exactitud de tus datos personales, durante el plazo que permita al
                        responsable del fichero verificar la exactitud de los mismos;</li>
                    <li>en el supuesto de que considere que el tratamiento es ilícito y el responsable del fichero se
                        haya opuesto a la supresión de los datos personales y solicite en su lugar la limitación de su
                        uso;</li>
                    <li>que el responsable del fichero ya no necesite los datos personales para los fines del
                        tratamiento, pero Vd. los necesite para la formulación, el ejercicio o la defensa de
                        reclamaciones;</li>
                    <li>en el supuesto de que se haya opuesto al tratamiento, mientras se verifica si los motivos
                        legítimos del responsable del fichero prevalecen sobre los suyos.</li>
                </ol>
                <p>Cuando haya obtenido la limitación del tratamiento conforme el presente apartado, será informado por
                    el responsable del fichero.</p>
                <p><u>Para ejercitar el derecho a la limitación del tratamiento, </u>envíe un email a &nbsp;<a
                        href="mailto:belen@kinsaleconsulting.com">lopd@stepsrelocation.com</a> o remita una carta a la
                    atención del Dpto. Administración, con domicilio en c/ Castillo de Aysa 2D, 28043 Madrid.</p>
                <p><strong>10.- ¿En qué consiste el derecho a la portabilidad de los datos?</strong></p>
                <p>Tendrá derecho a recibir los datos personales que le conciernan, que haya facilitado al responsable
                    del tratamiento, en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a
                    otro responsable del tratamiento cuando: el tratamiento esté basado en el consentimiento, y se
                    efectúe por medios automatizados.</p>
                <p>Al ejercer su derecho a la portabilidad de los datos, tendrá derecho a que los datos personales se
                    transmitan directamente de responsable a responsable cuando sea técnicamente posible.</p>
                <p>El derecho a la portabilidad no se extenderá a los datos que el responsable del fichero hubiera
                    inferido a partir de datos que se deriven directamente del uso por dicho responsable de los
                    servicios prestados.</p>
                <p><u>Para ejercitar el derecho de portabilidad, </u>envíe un email a &nbsp;<a
                        href="mailto:belen@kinsaleconsulting.com">lopd@stepsrelocation.com</a> o remita una carta a la
                    atención del Dpto. Administración, con domicilio en c/ Castillo de Aysa 2D, 28043 Madrid.</p>
                <p><strong>11.- ¿En qué consiste el derecho de oposición?</strong></p>
                <p>Tendrá derecho a oponerse al tratamiento de datos que le conciernen, en cualquier momento. En caso de
                    que ejercite este derecho, el responsable del fichero dejará de tratar sus datos personales, salvo
                    que acreditemos motivos legítimos imperiosos para que el tratamiento prevalezca sobre sus intereses,
                    derechos y libertades, o para la formulación, del ejercicio o la defensa de reclamaciones.</p>
                <p><u>Para ejercitar el derecho de oposición, </u>envíe un email a&nbsp; &nbsp;<a
                        href="mailto:belen@kinsaleconsulting.com">lopd@stepsrelocation.com</a> o remita una carta a la
                    atención del Dpto. Administración, con domicilio en c/ Castillo de Aysa 2D, 28043 Madrid.</p>
                <p>Adicionalmente, le informamos que puede dirigirse a la <u>Agencia Española de Protección de Datos</u>
                    para obtener información adicional acerca de sus derechos.</p>
                <p>También le informamos, en el caso de que se haya otorgado el consentimiento para alguna finalidad
                    específica, que tiene derecho a retirar el consentimiento en cualquier momento, sin que ello afecte
                    a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
                <p>Asimismo, le informamos que puede presentar una reclamación ante la Autoridad de Control en materia
                    de Protección de Datos competente, la Agencia Española de Protección de Datos (https://www.aepd.es),
                    especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos.</p>`,
          condicionesalquiler: 
          `<h3 class="titulo">Condiciones de alquiler en España</h3><br>
          <p>En España los contratos están regulados por la “Ley de Arrendamientos Urbanos” o “LAU”. 
          La duración de los contratos habitualmente son de 1 año renovable a 5. Después de cinco años, el contrato puede ser ampliado a otros tres. 
          Los primeros seis meses son de obligatorio cumplimiento. Hay propietarios que exigen un año de obligado cumplimiento y se niegan a tener un inquilino que no lo asegure.</p>
          <p>En la mayoría de los casos, las viviendas se alquilan en la condición en las que están durante la visita. 
          No hay obligación para el propietario de hacer reparaciones menores o renovar la vivienda durante, al menos, los cinco años de arrendamiento. 
          Por ello, todos los cambios que te gustaría hacer en la vivienda, deben ser discutidos y aprobados antes de la firma del contrato de arrendamiento y ser enumerados en el mismo.</p>
          <p>La mayoría de los alojamientos se alquilan vacíos. Si la vivienda está sin amueblar, la cocina suele está equipada con electrodomésticos y armarios.  Si es necesario, se pueden alquilar muebles.</p>
          <p>El Real Decreto Ley 7/2019, del 1 de marzo, que regula los alquileres es muy beneficioso para los inquilinos. Sólo se aplican los términos específicos definidos por esta ley y las cláusulas particulares no pueden ir en contra de la ley.</p>
          <p>Los contratos de alquiler están escritos exclusivamente en castellano, no hay ninguna versión legal en inglés que pueda existir y pueden estar a nombre de una empresa o a nombre de un particular.</p>
          <p>Ningún alquiler estará 100% asegurado antes de que el contrato de arrendamiento se haya firmado por ambas partes o que hayas hecho un pago de reserva.</p>
          <p>En la firma del contrato de arrendamiento, el inquilino tendrá que pagar: 1) la fianza 2) los honorarios de la agencia inmobiliaria si los hay 3) garantías adicionales y 4) primer mes de renta (o prorrateo correspondiente).</p>
          <p><strong>NOTA IMPORTANTE</strong>: Si el piso tiene más de 300 metros cuadrados, es considerado “suntuoso” o el alquiler supera los 5000€, la Ley de Arrendamientos Urbanos no se aplica y 
          las condiciones de alquiler y garantías son negociadas en cada caso por la propiedad y el inquilino.</p>
          <h3 class="subtitulo">Fianza</h3>
          <p> La fianza legal suele ser un mes de alquiler, pero puede variar dependiendo de la vivienda. Se devuelve mas o menos en un mes tras la devolución de la vivienda, 
          siempre que no haya desperfectos en la vivienda y que los servicios se encuentren al corriente de pago.</p> 
          <h3 class="subtitulo">Garantías adicionales</h3>
          <p>Cubre impago y es algo que dependerá de cada propietario. Puede ser un depósito o un aval bancario.</p>
          <p>El aval bancario es una práctica normal en España y consiste en un depósito bancario para cubrir impagos. El banco bloquea el dinero en tu cuenta, y produce un documento firmado por Notario, que se entrega al propietario de la vivienda. Normalmente no está listo antes de 10 dias y el Banco pasa una tarifa mínima de mantenimiento.</p>
          <h3 class="subtitulo">Gastos de agencia (en caso de que se use)</h3>
          <p>La comisión de la agencia suele ser de 1 mes de renta + 21% de IVA y la suele pagar el inquilino. Esto puede variar dependiendo de en qué zona de España te encuentres. En algunos casos se calcula un porcentaje sobre el precio anual de la renta.</p>
          <h3 class="subtitulo">Obligaciones del inquilino y arrendador</h3>
          <p>La renta debe ser abonada antes del día 5 cada mes.</p>
          <p>El arrendatario tiene que comunicar al propietario cualquier problema de la vivienda en un plazo de 24 horas.</p>
          <p>El arrendatario se hace responsable de arreglar el deterioro por uso normal de la vivienda al igual que daños provocados por mal uso de la vivienda.</p>
          <p>El propietario se hará cargo de la reparación y/o sustitución de los servicios comunes que dejasen de funcionar.</p>
          <p>El arrendatario no podrá subarrendar la vivienda en ningún caso.</p> 
          <h3 class="subtitulo">Causas de terminación de arriendo (en general)</h3> 
          <ul>
            <li>- la falta de pago de una mensualidad</li>
            <li>- cuando en la vivienda arrendada no se destine al uso establecido</li>
            <li>- no cumplir con las obligaciones del contrato</li>
            <li>- daños causados por obras realizadas sin el permiso del arrendador</li>
            <li>- cuando en la vivienda tengan lugar actividades molestas, insalubres, nocivas, peligrosas o ilícitas</li>
            <li>- necesidad del arrendador de ocupar la vivienda arrendada a partir del primer año para destinarla a vivienda permanente para sí o sus familiares. Dicha comunicación deberá hacerse con algunos meses de antelación a la fecha en la 
            que la vivienda se vaya a necesitar</li>
          </ul>
          <h3 class="subtitulo">Seguro de vivienda</h3>
          <p>En España, no es obligatorio contratar una póliza de seguro de vivienda. Sin embargo, recomendamos contratar una 
          póliza de seguros de hogar. El inquilino debe asegurar la responsabilidad civil y objetos personales. De hecho, muchos propietarios insisten en ponerlo en el contrato como obligatorio. 
          <h3 class="subtitulo">Gastos adicionales para el inquilino</h3>
          <p>Los suministros tales como agua, gas, electricidad, teléfono e internet. Seguro de hogar y responsabilidad civil.</p>`,
          madrid:
          `
          <h3 class="titulo">Bienvenidos a Madrid</h3><br>
          <p>Madrid es la capital de España y una ciudad muy agradable, divertida y cosmopolita. Es el centro financiero y la sede del gobierno central (España está dividida en 17 comunidades autónomas). El idioma oficial es el castellano, pero en otras comunidades autónomas podrán escuchar catalán, gallego, valenciano, euskera... ¡no te preocupes, son bilingües!<br />
          Está situado en el centro del país, en la meseta castellana, a una altitud de 646 metros, lo que la convierte en la capital más alta de Europa. Muy bien situada para esquiar, ya que hay muy buenas pistas a solo una hora, en la Sierra de Guadarrama.<br />
          Tiene unos 3,5 millones de habitantes en el área metropolitana y llegamos hasta los 6,5 millones si<br />
          contamos los alrededores.<br />
          El mar Mediterráneo está a unos 350 km y las grandes ciudades de España como Barcelona, Sevilla o Bilbao están muy bien conectadas mediante AVE (tren de alta velocidad). Las ciudades como París, Londres, Roma y casi todas las capitales europeas están solo a un par de horas de avión.<br />
          Las temperaturas de Madrid son muy diferentes entre el verano y el invierno. Los meses de verano son extremadamente secos y calientes, con temperaturas que superan a veces los 40ºC, mientras que los inviernos son fríos y secos. Los meses de primavera y otoño son suaves y agradables.<br />
          España tiene uno de los índices de criminalidad más bajos de Europa. Pese a eso hay que tener cuidado con los ladrones de bolsos y carteras. Madrid no es una ciudad con mucha criminalidad, pero es mejor mantenerse alerta y tener cuidado al sacar dinero de un cajero, por ejemplo.<br />
          </p>

          <img src="images/infotrl/escudo_madrid.png" height="200px" style="display:block; margin: 0 auto;"/>
          <p>El escudo de Madrid es un oso apoyado en un madroño, de corona abierta y siete estrellas. Cada una de sus estrellas representa a las correspondientes estrellas principales de la constelación de la Osa Mayor, que se recorta sobre la sierra del Guadarrama.<br />
            Cuenta la leyenda que se eligió un oso como emblema en homenaje a un oso pardo que el rey Alfonso XI cazó en uno de los montes cercanos a la capital.<br />
            El oso estaba al principio unido a una torre, que sería sustituida posteriormente por un madroño. Estos árboles eran habituales en las afueras de la ciudad durante toda la época medieval.<br />
            </p>

          <h3 class="subtitulo">La gente de Madrid</h3>
          <p>Fuera de las ciudades y pueblos más pequeños, es difícil ver un verdadero castellano. El movimiento de la población ha hecho a la gente ser “española” en sentido general, especialmente a los madrileños, ya que <strong>Madrid está habitada por gente de todos los sitios de España.</strong><br />
           Sin embargo, si tuviésemos que describir a la gente de Madrid, deberíamos decir que son bastante abiertos. El tiempo que pasan con su familia es muy importante y como hemos dicho anteriormente, les encanta comer fuera y socializar.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
            <img src="images/infotrl/gentemadrid1.png" height="200px" style="margin: 0 10px 10px 0; flex: 0 1 auto;"/>
            <div style="margin-right: 10px; flex: 0 1 auto; max-heigth:180px">
              <img src="images/infotrl/gentemadrid2.png" width="100%"/>
            </div>
            <img src="images/infotrl/gentemadrid3.png" height="200px" style="margin-right: 10px; flex: 0 1 auto;"/>
          </div>
          <p><strong>Agosto</strong> es un mes en el que Madrid está casi vacío, cosa que a muchos de nosotros nos encanta, ya que podemos llegar a tiempo a cualquier sitio, no hay atascos, y se encuentra sitio en el restaurante que uno desea si este está abierto. Sin embargo, no es un mes apropiado para buscar piso, ya que es posible que muchas agencias cierren e incluso que los dueños de los pisos a visitar estén fuera. Es mejor cambiar los planes y buscar uno o dos meses antes.</p>
          <p> A los madrileños les importa mucho su apariencia. Normalmente van elegantemente vestidos. Esto no quiere decir que no se acepten los atuendos deportivos (que son muy populares), pero raramente se ve a un madrileño salir a tomar algo o a trabajar con pinta descuidadamente abandonada. En la vida profesional, en la mayoría de los casos se usa corbata para los hombres y las mujeres visten “formalmente”. Últimamente hay que decir que las empresas han empezado a prescindir de la corbata y a introducir la vestimenta “casual” en las oficinas, sobre todo los viernes. Fíjate cuál es el código de vestimenta de tu oficina para ir en acorde. </p>
          <h3 class="subtitulo">JARDINES DE MADRID</h3> 
          <strong>CASA DE CAMPO</strong>
          <p>Situado sobre la margen derecha del Manzanares, es uno de los parques más importantes de Madrid, con sus casi <strong>1800 hectáreas</strong>, es el pulmón que oxigena Madrid. En época de Felipe II fue <strong>coto de caza</strong> de los reyes de España. Durante la Segunda República pasó a ser propiedad del pueblo de Madrid. Dentro de su perímetro se encuentra el Zoológico de Madrid, el Parque de Atracciones y un lago artificial. En torno a este lago, algunos agradables paseos que trepan por las colinas de la Casa de Campo permiten contemplar la vista más hermosa de Madrid al atardecer, cuando el sol se refleja en los muros del Palacio Real y de la catedral de la Almudena. Es el auténtico <strong>“skyline”</strong> de Madrid, más representativo de la ciudad que el perfil de sus rascacielos modernos.</p>
          <strong>MONTE DE EL PARDO</strong>
          <p> Actualmente es un parque natural, poblado con especies autóctonas, cerrado al público, salvo en algunas zonas.</p>
          <strong>PARQUE DEL OESTE</strong>
          <p>El mejor jardín paisajista de Madrid, se extiende por un largo terraplén que va desde la calle del Pintor Rosales hasta la ribera del Manzanares.</p>
          <strong>REAL JARDÍN BOTÁNICO</strong>
          <p> Jardín histórico trazado en 1781, promovido por Carlos III. Reúne más de <strong>30.000 especies vegetales</strong> de todo el mundo y un invernadero con plantas tropicales.</p> 
          <strong>PARQUE DEL RETIRO</strong>
          <p>Es el parque <strong>histórico-artístico</strong> más importante de la ciudad, creado durante el reinado de Felipe IV. Se encuentra situado en pleno corazón de la ciudad y cuenta con una superficie de <strong>118 hectáreas</strong> arboladas. Adornados sus paseos con numerosas estatuas y grupos escultórico, obra de importantes artistas, en el interior de su recinto se encuentra el observatorio astronómico edificado en 1790, el Palacio de Velázquez que alberga exposiciones temporales y el Palacio de Cristal, nacido como invernadero en el siglo pasado y hoy también sala de exposiciones. Tiene numerosos quioscos donde descansar tomando un refresco o un café. El Retiro se mantiene <strong> todos los días del año.</strong> El acceso es libre, excepto en los jardines de Cecilio Rodríguez (abiertos de 09:00 a 17:00 de lunes a viernes) y otras dependencias municipales. En el quiosco de la música la banda municipal actúa las mañanas de los domingos de primavera y verano. Tanto en la zona central donde se encuentra el estanque, como en los paseos circundantes multitud de artistas, músicos, magos o malabaristas muestran sus habilidades a los paseantes, sobre todo los fines de semana.</p>
          <h3 class="subtitulo">DONDE VIVIR EN MADRID (* documento AREAS)</h3>
          <p><strong>Chamberí</strong></p>
          <p>DESCRIPCIÓN: se encuentra al este de Moncloa y se extiende hasta la Castellana, donde ya se convierte en Salamanca. Chamberí puede presumir de una arquitectura magnífica; algunos de sus edificios son absolutamente preciosos como el Museo Sorolla, el Institute International e incluso el British Council. Puede presumir también plazas acogedoras y llenas de ambiente como la Plaza de Camberí o la Plaza de Olavide. Una de las mejores calles para tomar algo y cenar es la Calle Ponzano, donde puedes encontrar restaurantes exquisitos como el Bar Lambuzo o Sala del Despiece.
          Recomiendo Chamberí si quieres vivir cerca del centro pero no en pleno centro. Aquí, tendrás la posibilidad de andar a todas partes y vivir tus sitios preferidos sin tener que compartirlo demasiado con turistas. 
          Chamberí es un barrio antiguo, con lo cual la calidad de las casas no llega al nivel que podríamos encontrar en el barrio Salamanca y las tiendas y bares no marcan tanta tendencia como Malasaña y Chueca, pero si buscas un barrio auténtico, con la sensación de vivir en un pueblo con las comodidades modernas y prácticas, Chamberí es tu barrio.
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/chamberi1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/chamberi2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/chamberi3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Malasaña</strong></p>
          <p>DESCRIPCIÓN: Malasaña es conocido como la parte más “hipster” y alternativa de la ciudad. Esto se debe a su pasado rebelde, con vecinos que luchaban por lo que amaban (en 1802, los residentes organizaron un levantamiento contra Napoleón en la que hoy en día es la Plaza Dos de Mayo). La calle principal, al norte de Gran Vía, es la calle Fuencarral, paralela a la calle Hortaleza de Chueca. Las coloridas calles estrechas están llenas de cafés, restaurantes y tiendas vintage y variopintas. También podemos encontrar arte callejero, a los tatuadores más “in” del momento y “pop-up stores” que venden desde disfraces y ropa gótica hasta elementos de decoración y cupcakes. Encontrarás las calles, tiendas y plazas llenas de gente a cualquier hora del día o de la noche y su principal parada de metro, Tribunal, es el segundo punto de encuentro más popular para salir por la noche. La oferta noctura de Tribunal es amplia y encontramos de todo pero sobre todo se caracteriza por dar la bienvenida a un público indie y alternativo con locales eclécticos. Aunque decidas no vivir aquí, te recomendamos que visites sus calles tanto de día como de noche.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/malasaña1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/malasaña2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/malasaña3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Chamartin</strong></p>
          <p>DESCRIPCIÓN: en el norte de la ciudad encontramos un Madrid más moderno, que da lugar al barrio de Chamartín. Esta zona marca el límite por el norte del centro de la ciudad y es más grande que los demás barrios. La zona está subdividida en áreas más pequeñas como Castilla y Prosperidad, pero todas están muy bien conectadas con el metro. El barrio se encuentra entre los barrios de Las Tablas y Salamanca y se considera el centro de negocios de Madrid. Aquí puedes encontrar la estación de trenes de Chamartín, Plaza Castilla, el estadio de Santiago Bernabéu y la Puerta de Europa. La delimitación del barrio es dada por el Paseo de la Castellana, una de las grandes arterias de Madrid que conectan con las autopistas M30 y M11. Grandes bancos y empresas están instalados aquí pero también modernas galerías de arte  y locales organizadores de eventos, especialmente a lo largo del Paseo de la Castellana y entre las estaciones de metro. Dado que esta zona es tan grande, también se ha convertido en un área residencial, lo cual nos permite encontrar muchos colegios y bloques residenciales.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/chamartin1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/chamartin2.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Hortaleza</strong></p>
          <p>DESCRIPCIÓN: lo que hoy consideramos como el barrio de Hortaleza, originalmente era un pueblo pequeñito a lo alto de una colina, rodeado de ríos y abrevaderos. Este pueblo fue transformando sus viñedos y campos de cereal en granjas muy grandes y así tener la capacidad de producir productos locales para los nobles. A día de hoy, Hortaleza es uno de los cinco barrios con la población más joven de Madrid. Debido a la localización del prestigioso “Lycée Français”, se ha formado una gran comunidad francesa y componen la tercera población más extendida de la zona. Hay incluso una tienda que ofrece libros en francés, llamada “Frañol”. Sanchinarro, una comunidad independiente hasta 1950, ahora forma parte de Hortaleza. La zona ha vivido un gran crecimiento en urbanismo en los últimos años.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/hortaleza1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/hortaleza2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/hortaleza3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>La Latina</strong></p>
          <p>DESCRIPCIÓN: al sur de la Plaza Mayor encontramos La Latina, este precioso barrio compuesto por arquitectura latina y pequeñas callejuelas que nos maravillan con edificios del siglo XVIII. Aquí encontrarás las calles llenas de restaurantes con sus terrazas, bares tradicionales y tiendas de antigüedades. Los domingos por la mañana La Latina se convierte en el mercado conocido como “El Rastro” donde podemos encontrar puestos tradicionales pero también contemporáneos. La increíble bóveda de la Basílica de San Francisco El Grande proporciona el fondo escenario perfecto para las el barrio más popular para ir de tapeo con los amigos. Ecléctico y vibrante, este barrio es único para pasar tiempo dentro de él y explorar una de las partes más antiguas de Madrid.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/latina1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/latina2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/latina3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Retiro</strong></p>
          <p>DESCRIPCIÓN: Retiro es el parque más grande y popular de Madrid y así se llama el barrio que situado al este del parque. Es perfecto para disfrutar de tranquilidad ya que el bullicio se queda al otro lado del parque. Dentro del Retiro puedes disfrutar de su lago, el Palacio de Cristal, el Jardín de Rosas y sus amplias zonas de césped. Hay muchas actividades como entrenamientos y clubs de running, o clases de patines y yoga. El barrio de Retiro se compone de seis distritos: Jerónimos, Ibiza, Estrella, Niño Jesús, Pacífico y Adelfas. Jerónimos es sin duda el más lujoso y representativo. 
          Jeronimos: una de las zonas del Retiro, cerca al parque y más específicamente delimitado por la calle Alcalá, la Avenida Menéndez Pelayo y los Paseos Prado y Reina Cristina. Este enclave está formado por edificios señoriales con casas espaciosas y lujosas que alardean de una gran demanda pese a tener los precios más altos de Madrid, incluso por encima de Salamanca.
          Esta zona disfruta de su proximidad al parque del Buen Retiro, el pulmón de Madrid y de su cercanía a monumentos históricos como la Puerta de Alcalá, el Palacio de Cibeles, el Museo del Prado y el Palacio de la Bolsa entre otros.
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/retiro1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/retiro2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/retiro3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Salamanca</strong></p>
          <p>DESCRIPCIÓN: Salamanca es el barrio más lujoso de Madrid. Se encuentra “encima” del Parque del Buen Retiro y al este del Paseo de la Castellana. Las dos grandes arterias de este barrio son la calle Velázquez y Serrano y sus dos principales estaciones de metro comparten el nombre. Es un barrio tranquilo de Madrid y al igual que los barrios lujosos de otras ciudades, es aquí donde encontramos las tiendas de grandes diseñadores de ropa y zapatos, los restaurantes de major calidad y las propiedades de más demanda. El barrio Salamanca consigue, sin embargo, equilibrar el estilo de vida de alto nivel con una vida de barrio y podemos encontrar bares y mercados tradicionales y acogedores.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/salamanca1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/salamanca2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/salamanca3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Argüelles</strong></p>
          <p>DESCRIPCIÓN: Moncloa / Argüelles tiene todo lo que puedas necesitar, ¡a tan solo unos pasos del centro!
          Esta zona tiene una energía fantástica y está llena de tiendas, bares, supermercados y grandes restaurantes. A tiro de piedra está la Gran Vía, con todos los teatros y tiendas que deseas. Su gran comunicación con el resto de la ciudad hace que vivir en esta zona sea muy cómodo. Las líneas de metro 3 y 6 pasan por aquí, y la estación de Moncloa de autobuses te llevarán a donde necesites. Es también muy conveniente para salir de la ciudad en coche, ya que la autopista A-6 es muy accessible. 
          Este barrio es perfecto si te gusta:
          <ul>
          <li>– tener muchas tiendas y actividades cerca</li>
          <li>– correr o andar por tu barrio. Te encantará el parque de Moncloa y Plaza España, con su Templo de Debod (tercera foto)</li>
          <li>– vivir en un barrio bonito con muchas actividades pero que sea tranquilo por la noche.</li>
          </ul>
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/arguelles1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/arguelles2.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Justicia</strong></p>
          <p>DESCRIPCIÓN: Justicia es la zona más “pija” de Madrid junto con el barrio Salamanca. Aquí es donde viven los grandes diseñadores, políticos y altos cargos de grandes bancos y bufetes de abogados. Con un estilo que se podría considerer incluso parisino, esta zona está muy cerca de toda la actividad de Chueca y Malasaña.
          Todas las zonas de Madrid tienen su personalidad pero cada una tiene influencia de sus residents. Así, en Alonso Martínez podemos encontrar tiendas de diseño, zonas de encuentro de artistas, tiendas de chocholate y pastelerías de lujo, bares chill out y apartamentos únicos, ¡todo en unas cuantas calles!
          Recomendamos vivir en Justicia si quieres vivir en la zona más exclusiva y lujosa del centro de Madrid, donde la vida madrileña se mezcla con edificios parisinos.
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/justicia1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/justicia2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/justicia3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Palacio</strong></p>
          <p>DESCRIPCIÓN: Palacio es el centro histórico de Madrid donde encontramos los más antiguos y maravillosos edicios de la capital. El Palacio Real (que da, en parte, nombre a la zona) está rodeado de preciosos jardines y cafés tradicionales con terraces en las cuales podremos disfrutar del sol. El Teatro Real está a escasos minutos y ofrece todo el año un programa de gran calidad. Esta zona es mucho más tranquila que Malasaña y Chueca pero sigue estando muy cerca de lo que ofrecen. Debido a los altos precios de la zona (causados por la proximidad al Palacio Real), no viven muchos estudiantes o jóvenes aquí.</p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/palacio1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/palacio2.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/palacio3.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Pozuelo de Alarcón</strong></p>
          <p>DESCRIPCIÓN: área muy accessible y cómoda con grandes calidades, ¡a tan solo 15-20 minutos de Madrid!
          La diferencia de Pozuelo de Alarcón con otras zonas de las afueras de Madrid, es su buena comunicación con la ciudad. Dos líneas de Metro Ligero, una línea de Cercanías y media docena de autobuses conectan Pozuelo con Madrid a diario. Puedes estar en el centro de Madrid en 30 minutos. En coche, con tan solo 25 minutos de trayecto puedes estar en cualquier zona de la ciudad.
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/pozueloalarcon1.png" height="200px" style="margin-right: 10px;"/>
          <img src="images/infotrl/pozueloalarcon2.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>La Moraleja</strong></p>
          <p>DESCRIPCIÓN: nos encontramos cerca del aeropuerto Adolfo Suárez Madrid Barajas, rodeados de la A1, la R2 y la M40. Perteneciente a la municipalidad de Alcobendas, La Moraleja tiene 3 subdivisiones: El Soto, La Moraleja, & El Encinar de los Reyes. 
          <ul>
          <li>•	El Soto: cerca del metro, colegios y zonas comerciales, se encuentra al norte de La Moraleja. El Soto ofrece sobre todo apartamentos y adosados, la mayoría en urbanizaciones cerradas.</li> 
          <li>•	La Moraleja es lo más exclusivo. Dependiendo de dónde se encuentre la casa, podemos ver Alcobendas o Madrid. Está formado por parcelas más grandes, la mayoría con chalets independientes.</li>
          <li>•	El Encinar está al sur. Es único porque anteriormente era donde se encontraba la base military estadounidense y como consecuencia las viviendas tienen el estilo del país. Es la zona más alejada del metro y aunque haya un autobús que pasa por toda la zona, no es muy fiable y es poco utilizado por los vecinos.</li>
          </ul>
          Algunas de las mejores escuelas se encuentran en La Moraleja, como el International College Spain (ICS), Kings College, y el St. George’s; rodeados de zonas verdes, parques y clubs de deporte. 
          La Moraleja tiene su propia seguridad y la mayoría de las viviendas se encuentran en urbanizaciones cerradas. 
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/moraleja.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <p><strong>Boadilla del Monte</strong></p>
          <p>DESCRIPCIÓN: Boadilla del Monte es una de las municipalidades más selectas de la Comunidad de Madrid. Se encuentra a tan solo 14 kilómetros del centro de la ciudad y es la zona ideal para los que quieren escapar del bullicio. Es una pequeña ciudad tranquila, con buen ambiente y buena comunicación con la capital.
          Está en el medio de una zona verde que ofrece excelentes vistas. De hecho, lo rodean 750 hectáreas de bosque, lo cual proporciona aire limpio. Debido a la larga historia de esta pequeña ciudad podemos encontrar sitios de interés cultural y arquitectónico como el Palacio del Infante Don Luis y la Fuente de Ventura Rodríguez.
          En Boadilla del Monte hay una gran variedad de propiedades, desde apartamentos hasta adosados y chalets independientes. Lo que todos tienen en común es lujo. Los residentes pueden disfrutar de jardines, piscinas, terrazas y muchos otros elementos básicos para crear un entorno de relax. Además, como lo demuestran las últimas estadísticas, comprar en esta ciudad es una gran inversión ya que no se vio afectada por la crisis económica y las viviendas siguen aumentando su valor.

          No olvidemos que esta ciudad tiene escuelas de gran prestigio a nivel nacional. Hay diversos colegios y academias de idiomas como la Escuela Oficial de Idiomas o un campus de la Universidad de San Pablo. También tiene varios centros médicos, tanto privados como públicos.
          </p>
          <div style="margin-bottom: 40px; display: flex; justify-content:space-between; flex-wrap: wrap;">
          <img src="images/infotrl/boadilla.png" height="200px" style="margin-right: 10px;"/>
          </div>
          <h3 class="subtitulo">MOVERSE POR MADRID</h3><p>Acostumbrase a conducir en Madrid puede llevarte algunas semanas, pero intenta recordar que Madrid tiene tres grandes autopistas que rodean la ciudad: la M-30, la M-40 y la M-50. Si tienes  que cruzar la ciudad cualquiera de estas opciones es buena si no te sientes cómodo atravesando la ciudad. Ten en cuenta también que hay muchos radares (fijos y móviles).</p>

          <h3 class="subtitulo">PARKING</h3><p> Aparcar en la ciudad es extremadamente complicado. Recomendamos que dejes el coche en los parkings públicos del centro o bien utilizar la zona de aparcamiento “azul o verde” de la calle con el sistema de parquímetro. En la zona azul se puede aparcar hasta 4 horas y en la zona verde hasta 2 horas. Si aparcas en un sitio prohibido y el coche se lo ha llevado la grúa, puedes llamar al 914 068 810 o meter tu matrícula en este <a href="https://www.emtmadrid.es/EMTGruas/LocalizaVehiculo" target="_blank">enlace</a> para saber dónde se han llevado tu coche. La multa será de aproximadamente 180€ para recuperarlo.</p>

          <h3 class="subtitulo">TRANSPORTE PÚBLICO</h3><p> Se necesita una tarjeta magnética para entrar y salir del sistema de transporte público en Madrid. Esta tarjeta puede adquirirse y ser recargada en cualquier estación de metro y tren. También puedes adquirir un abono mensual que le permite usar el transporte público sin límite de veces. Funciona por zonas y para el cercanías, metro y autobuses. Este abono se puede solicitar <a href="https://tarjetatransportepublico.crtm.es/CRTM-ABONOS/entrada.aspx" target="_blank"></a> online. El sistema de transporte público en Madrid incluye <a href="https://www.metromadrid.es/es" target="_blank">metro</a>, <a href="https://www.metromadrid.es/es" target="_blank">autobús</a>, <a href="https://www.metromadrid.es/es" target="_blank">cercanías</a> y <a href="https://www.metromadrid.es/es" target="_blank">metro ligero</a>.</p>

          <h3 class="subtitulo">TAXI Y SERVICIOS PRIVADOS</h3><p> Hay más de 15.000 taxis en Madrid, solo hay que levantar la mano para pedir uno. También se puede pedir vía app: Free Now, TXMAD. También están disponibles servicios privados como Uber y Cabify.</p>

          <h3 class="subtitulo">COCHE ELÉCTRICO</h3><p> Los coches eléctricos se han vuelto muy populares en la ciudad. Se paga por los minutos consumidos. El único límite es tener carné de conducir y aparcar en la zona delimitada. Algunas empresas: <a href="https://www.share-now.com/" target="_blank">ShareNow</a>, <a href="https://zity.eco" target="_blank">Zity</a>, <a href="https://www.emov.eco/" target="_blank">Emov</a>, <a href="https://www.wible.es/" target="_blank">Wible</a>. Los precios varían, así que no dudes en investigar</p>
          <img src="images/infotrl/certificados.png" width="100%" style="margin:0 auto; display: block"/>
          `,
          espana: `
            <h3 class="titulo">Bienvenidos a España</h3>
            <blockquote>¡Felicidades por elegir España como destino! Es considerado como uno de los mejores países en calidad
                de vida, así que has hecho una gran elección. Desde Steps Relocation trabajaremos para hacer el
                traslado más fácil.<br>
                Nuestro equipo está compuesto de expatriados también, así que empatizamos totalmente contigo<br>
                Deseando conocerte pronto,<br><br>
                Lydia Martínez - CEO</blockquote>
                <img src="images/infotrl/espana_intro1_es.jpg" width="100%" /><br/>
                <img src="images/infotrl/espana_intro2_es.jpg" width="100%" /><br/>
            <p>España es un país miembro de la Unión Europea, y cuya forma de gobierno es la Monarquía
                Parlamentaria. Su territorio, con capital en Madrid, está organizado en diecisiete comunidades
                autónomas, formadas a su vez por cincuenta provincias; y dos ciudades autónomas.<br>
                Tiene una extensión de 505 370 km², por lo que es el cuarto país más extenso del continente. Con una
                altitud media de 650 metros sobre el nivel del mar es uno de los países más montañosos de Europa. Su
                población supera los 48 millones de habitantes.<br>
                El producto interior bruto coloca a la economía española entre las 15 primeras del Mundo. España es
                una potencia turística y además, el octavo país del mundo con mayor presencia de multinacionales.
            </p>
            <p>España tiene un clima muy diverso a lo largo de todo su territorio. Predomina el carácter
                mediterráneo en casi toda su geografía. En el norte y noreste suele ser un clima nuboso con lluvias
                ocasionales.<br>
                España tiene un índice de criminalidad muy bajo, sin embargo, como en cualquier otro país, hay que
                poner atención, especialmente en sitios turísticos</p>
            <h3 class="subtitulo">COMIDA, VINO Y HORARIO</h3>
            <p>Los españoles tienen fama de disfrutar mucho de la vida: los platos locales y los vinos son la mejor
                forma de hacerlo. El pescado y el marisco son excelentes por su frescura, y el excelente aceite de
                oliva, se usa a diario en la cocina.<br>
                ¡Cuidado con los horarios! Los españoles comen mucho más tarde que sus vecinos europeos. La mayoría
                de los restaurantes han flexibilizado su horario, pero hay muchos cuya apertura no se produce antes
                de las 9 de la noche. Si no deseas una cena copiosa, siempre puedes ir a uno de los muy comunes
                bares de tapas, que sirven comida todo el día.</p>
            <h3 class="subtitulo">VINOS</h3>
            <p>
                Una de las mejores cosas de ser un expatriado en España es el descubrimiento de sus vinos. Para
                entenderlos mejor:<br>
                <b>Crianza</b>: vino que se deja envejecer 6 meses en barricas y dos años en botella.<br>
                <b>Reserva</b> el vino se deja envejecer un mínimo de 12 meses en barrica de roble y 3 años en
                botella.<br>
                <b>Gran Reserva</b> debe envejecer más de dos años en barrica de roble y luego 5 años en
                botella.<br>
                En España los vinos se “dividen” por <b>Denominaciones de Origen (DO)</b>. Constituye el sistema
                utilizado en nuestro país para el reconocimiento de una calidad diferenciada, consecuencia de
                características propias y diferenciales debidas al medio geográfico en el que se producen las
                materias primas, se elaboran los productos y a la influencia del factor humano que participa en las
                mismas.<br>
                Hay 70 Denominaciones de Origen en España y te invitamos a descubrirlas todas, pero las más
                importantes y una buena manera de empezar son <b>Rioja, Ribera del Duero, Valdepeñas y Navarra en
                    tintos y Rias Baixas, Rueda y Ribeiro en blancos</b>.<br>
            </p>
            <h3 class="subtitulo">CÓMO PREPARAR A LA FAMILIA PARA EL CAMBIO</h3>
            <p>
                Preparar a la familia para un traslado es esencial, te dejamos algunos consejos:<br>
                • <b>Leer</b> tanto como sea posible acerca de España, la cultura, la historia... hay un montón de
                información en internet.<br>
                • <b>Animar</b> a los niños a <b>descubrir</b> todo lo que puedan acerca de su nuevo destino. Hay
                que contarles que aquí encontrarán muchos parques de diversiones y parques acuáticos, los viajes que
                harán a la costa o a esquiar... si se les incluye en los planes, aceptarán mejor el cambio.<br>
                • Según vaya acercándose el momento de venir a vivir, recuerda que España es un país maravilloso
                para que tus amigos y familiares te visiten. Decid a todo el mundo que vengan a veros... ¡pero tened
                en cuenta que lo harán! <br>
                • Es importante para vosotros y especialmente para los niños, <b>mantener los lazos</b> con los amigos que
                dejasteis atrás. Animadlos a que mantengan el contacto. <br>
                Recuerda que esto es un proyecto de familia. Este traslado es un nuevo trabajo para el “responsable”
                del traslado, pero es una aventura para todos. Venid con la mentalidad de disfrutarlo.<br>
                Os daremos todo el apoyo imaginable para que os sintáis <b>positivos, felices y acompañados</b>.</p>
            <h3 class="subtitulo">ASUNTOS MÉDICOS</h3>
            <p>
                La medicina pública (Seguridad social) es <b>excelente</b> en España y cuenta con los mejores médicos y
                equipos.<br>
                Hay posibilidad de contratar sanidad privada y no es algo excesivamente caro. En el mercado hay
                bastantes opciones. Pregunta primero en la empresa donde te han contratado si ofrecen ese beneficio
                para los empleados antes de comenzar la búsqueda.</p>
            <h3 class="subtitulo">EDUCACIÓN</h3>
            <p>
                Existen tres tipos de colegios:<br>
                LOS PRIVADOS. Tienes dos tipos <br>
                1) Nacionales, que siguen el sistema educativo español, y que suelen tener varias horas semanales
                dedicadas al inglés y a partir de 1º de ESO un segundo idioma. <br>
                2) Internacionales, en los que se sigue el sistema educativo diferente al español. Hay Currículums
                Británicos, americanos, IB, francés, alemán, italiano.<br>
                LOS CONCERTADOS. Reciben una ayuda económica del gobierno y están obligados a aceptar una serie de
                normas dictadas por el Ministerio de Educación, etc. pero son más económicos que los privados. Para
                acceder a ellos existe un sistema de puntos, que se otorgan por cercanía al domicilio o trabajo, si
                tienen hermanos en el centro, entre otros, y es necesario estar empadronado.<br>
                LOS PÚBLICOS. La enseñanza es gratuita a excepción de las tasas de escolarización. Se rigen por las
                normas del Ministerio. Lo que puede pasar, es que no asignen el colegio preferido por la familia.
                Pero tienen obligación de no dejar ningún niño sin escolarizar.</p>
            <h3 class="subtitulo">AYUDA DOMÉSTICA</h3>
            <p>
                Para encontrar ayuda doméstica se puede usar una agencia, que garantiza sus servicios durante varios
                meses (si no encaja bien la persona que te han encontrado, puedes solicitar un cambio). <br>
                INTERNA. Una persona interna vive en la casa. Esperará tener su propia habitación. Se hará cargo de
                la limpieza, cocina y niños según lo negociado en la entrevista. Hay un salario mínimo regulado por
                El Gobierno y están sujetas a Seguridad Social que hay que pagar aparte. La jornada laboral no debe
                superar las 40 horas semanales.<br>
                EXTERNA. Mismas obligaciones que la persona interna, pero no vive en casa. El salario se calcula en
                base al salario mínimo interprofesional, dependiendo de las horas contratadas.<br>
                UNA ASISTENTA. Trabajará sólo algunas horas al día y se le paga entre 10 y 12 euros por hora.
                Igualmente, hay obligación de registrar las horas trabajadas para el alta en la Seguridad Social.
                <br>
                SERVICIOS DE CANGURO. Lo mejor es pedírselo a una vecina joven o alguien conocido y pagar unos 10
                euros la hora. Negocia tarifas para noches largas.<br>
                <br>
            </p>
            <img src="images/infotrl/certificados.png" width="100%" style="margin:0 auto; display: block"/>
            `
          },
      modalsimple: {
        cambiarcontrasena: 'Cambiar contraseña',
        tucontrasenase: 'Tu contaseña se ha modificado correctamente.',
        contrasenaactual: 'Contraseña actual',
        nuevacontrasena: 'Nueva contraseña',
        repitelanueva: 'Repite la nueva contraseña',
        porlomenos4: 'Por lo menos 4 caracteres',
        cancelar: 'Cancelar',
        cambiar: 'Cambiar',
      }
    }
  }
}