<template>
    <div id="calendario" v-if="events_filtered.length">
        <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            :type="type"
            :events="events_filtered"
            :event-overlap-mode="'stack'"
            :event-overlap-threshold="200"
            @click.stop
            @click:event="toggleCard"
            @click:more="viewDay"
            :class="{'open': open, 'trl': usuario_loggeado.tipo === 'TRL'}"
        >
            <template v-slot:event="{event}" :class="{'open': event.open}">
                <div v-if="usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo === 'Consejera' || usuario_loggeado.tipo === 'RRHH'" class="evento" :class="{'open': event.open}">
                    <div v-if="event.open && (usuario_loggeado.tipo === 'Admin' || usuario_loggeado.tipo === 'Consejera')" class="icono editar" @click="openModalTarea(getUsuario(event.trl), event.servicio, event)">
                        <svg class="icono" width="15" height="15" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0668 0.495563C9.40606 -0.165188 8.33985 -0.165188 7.6791 0.495563L0.992751 7.18191C0.9477 7.22696 0.913912 7.28328 0.89514 7.3471L0.0166417 10.5232C-0.0209009 10.6546 0.0166417 10.7935 0.114253 10.8874C0.208109 10.9812 0.350772 11.0188 0.478417 10.985L3.65453 10.1065C3.71835 10.0877 3.77466 10.0577 3.81971 10.0089L10.5061 3.31877C11.1631 2.65802 11.1631 1.59181 10.5061 0.931058L10.0668 0.495563ZM1.80743 7.42594L7.28115 1.95222L9.04565 3.71672L3.57193 9.1942L1.80743 7.42594ZM1.45453 8.1355L2.86613 9.5471L0.913912 10.0877L1.45453 8.1355ZM9.97671 2.78942L9.57876 3.18737L7.81425 1.42287L8.21221 1.02491C8.57637 0.660751 9.1733 0.660751 9.53746 1.02491L9.97671 1.46416C10.3409 1.83208 10.3409 2.42526 9.97671 2.78942Z" fill="#4F4F4F"/>
                        </svg>
                    </div>
                    <div class="imagenes" v-if="usuarios.length && event.open">
                        <img class="avatar trl" :src="getUsuario(event.trl).personales.foto || 'images/avatar_placeholder.jpg'" />
                        <img v-if="getConsejeraUsuario(event.trl)" class="avatar consejera" :src="getConsejeraUsuario(event.trl).personales.foto || 'images/avatar_placeholder.jpg'" />
                    </div>
                    <div class="contenido" v-if="usuarios.length">
                        <p class="small semibold trl" @click="event.open ? routerFicha(getUsuario(event.trl)): ''">{{getUsuario(event.trl).personales.nombre + " " + getUsuario(event.trl).personales.apellidos}}</p>
                        <p class="small light" v-if="event.open">{{$moment(event.start).format('HH:mm')}} h.</p>
                        <p class="small light servicio" v-if="event.open">{{getServicio(event.servicio).nombre}}</p>
                        <p class="small bold name">{{event.name}}</p>
                        <p class="small light" v-if="usuario_loggeado.tipo != 'RRHH' && event.open && event.notas">{{event.notas}}</p>
                    </div>
                </div>
                <div v-if="usuario_loggeado.tipo === 'TRL'"  class="evento" :class="{'open': event.open}" >
                    <div class="contenido">
                        <p class="small light">{{$moment(event.start).format('HH:mm')}} h.</p>
                        <p class="small light servicio" v-if="event.open">{{getServicio(event.servicio).nombre}}</p>
                        <p class="small bold name">{{event.name}}</p>
                    </div>
                </div>
            </template>
        </v-calendar>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'EventosCalendario',
    props: ['usuarios_filtrados', 'current_year', 'current_month', 'current_day'],
    computed: {
        ...mapState([
            'usuarios', 'eventos', 'servicios', 'usuario_loggeado'
        ]),
    },
    data() {
        return {
            value: '',
            type: 'month',
            events_filtered: [],
            open: 0,
            modal_tarea: false
        }
    },

    watch: {
        usuarios_filtrados() {
            if (this.eventos.length) {
                this.iniciarEventos();
                let that = this;
                let filtrado_check = (evento) => {
                    // console.log(evento);
                    let checks = that.usuarios_filtrados.filtrados.includes(evento.trl) || (that.getConsejeraUsuario(evento.trl) && that.usuarios_filtrados.filtrados.includes(that.getConsejeraUsuario(evento.trl).id));
                    let busqueda_vacia = that.usuarios_filtrados.filtro === '';

                    let nombre_completo_trl = (that.getUsuario(evento.trl).personales.nombre + ' ' + that.getUsuario(evento.trl).personales.apellidos).toLowerCase();
                    let nombre_completo_conse = that.getConsejeraUsuario(evento.trl) ? (that.getConsejeraUsuario(evento.trl).personales.nombre + ' ' + that.getConsejeraUsuario(evento.trl).personales.apellidos).toLowerCase() : '';
                    
                    let nombre_trl = nombre_completo_trl.includes(that.usuarios_filtrados.filtro.toLowerCase());
                    let nombre_conse = nombre_completo_conse.includes(that.usuarios_filtrados.filtro.toLowerCase());

                    return (!busqueda_vacia && (nombre_trl || nombre_conse)) || (busqueda_vacia && checks);
                };

                this.events_filtered = this.events_filtered.filter(filtrado_check);
                // this.events_filtered.length ? '' : this.events_filtered = [this.evento_vacio];
            } else {}

            // this.events_filtered = this.events_filtered.filter(evento => this.usuarios_filtrados.filtrados.includes(evento.trl) || this.usuarios_filtrados.filtrados.includes(evento.consejera));
            // this.filter_events();
        },
        eventos() {
            this.eventos.length ? this.iniciarEventos() : '';
        },
        usuarios() {
            this.eventos.length ? this.iniciarEventos() : '';
        }
    },


    methods: {
        toggleCard({ nativeEvent, event }) {
            let initialStatus = event.open;
            document.querySelectorAll('.v-calendar .v-event').forEach(el => el.classList.add('detras'));
            document.querySelectorAll('.v-calendar .v-event').forEach(el => el.classList.remove('delante'));
            this.events_filtered.forEach(eve => {eve.open = false;});
            nativeEvent.currentTarget.classList.add('delante');
            event.open = !initialStatus;

            nativeEvent.stopPropagation();
        },
        getUsuario(id) {
            let usuario = this.usuarios.find( usu => usu.id == id );
            return usuario || null;
        },
        getConsejeraUsuario(id) {
            let consejera = null;
            let trl = this.usuarios.find(usuario => usuario.id == id) ? this.usuarios.find(usuario => usuario.id == id) : null;
            if (this.usuario_loggeado.tipo === 'RRHH') {
                if (trl.consejera.foto) {
                    consejera = {
                        personales: {
                            foto: trl.consejera.foto,
                            nombre: trl.consejera.nombre,
                            apellidos: trl.consejera.apellidos
                        }
                    }
                }
            } else {
                consejera = trl ? this.usuarios.find(usuario => usuario.id == trl.consejera.id ) : null;
            }
            return consejera;
        },
        getServicio(id) {
            let servicio = this.servicios.find( servicio => servicio.id == id );
            return servicio || null;
        },
        getServicioUsuario(trl, ser_id) {
            let servicio = this.getUsuario(trl).servicios.find(servicio => servicio.id == ser_id);
            return servicio || null;
        },
        openModalTarea(usuario, servicio, tarea) {
            this.$emit("openModalTarea", usuario, servicio, tarea);
		},
        iniciarEventos() {
            // this.events_filtered = [...this.eventos].filter(evento => this.getServicioUsuario(evento.servicio).estado != 'cancelado');
            this.events_filtered = [...this.eventos];
            if (this.events_filtered.length) {
                this.events_filtered = this.events_filtered.filter(evento => this.getUsuario(evento.trl));
                // this.events_filtered = this.events_filtered.filter(evento => this.getUsuario(evento.trl) && this.getServicioUsuario(evento.trl, evento.servicio));
                if (this.usuario_loggeado.tipo === 'Admin' || this.usuario_loggeado.tipo === 'Consejera') {
                    // this.events_filtered = this.events_filtered.filter(evento => this.getUsuario(evento.trl).id == this.usuario_loggeado.id);      
                } else if (this.usuario_loggeado.tipo === 'RRHH') {
                    // this.events_filtered = this.events_filtered.filter(evento => this.getConsejeraUsuario(evento.trl).id == this.usuario_loggeado.id);
                } else if (this.usuario_loggeado.tipo === 'TRL') {
                    this.events_filtered = this.events_filtered.filter(evento => evento.trl == this.usuario_loggeado.id);
                }
                // this.events_filtered.push(this.evento_vacio);
            } else {
                // this.events_filtered = [this.evento_vacio];
            }
        },
        routerFicha(usuario) {
            this.$router.push({ name: 'FichaTRL', query: { userId: usuario.enc_id } })
        },
        viewDay ({ date }) {
            this.$emit('changeEventsView', date);
        },
	},
    created: function() {
        this.eventos.length == 1 && this.eventos[0].id == 1 ? this.eventos[0].trl = this.usuario_loggeado.id : '';
        this.iniciarEventos();
    },
    mounted: function() {}
}
</script>
<style scoped lang="scss">
#calendario {
    padding: 0 2px;
    .calendar_header {
        display: flex;
        justify-content: space-between;
        margin: 1em 0;
        .controles {
            display: flex;
            align-items: center;
            .titulo {width: 5em; text-align: center;}
            .flecha {margin: 1em .5em; cursor: pointer;}
        }
    }
    ::v-deep .v-calendar {
        height: 80vh;
        // &.trl {height: 55vh;}
        .v-calendar-weekly__head {
            background-color: #f7f7f7;
            &>:last-child, &>:nth-last-child(2) {flex: .74;}
        }
        .v-calendar-weekly__week >:last-child, .v-calendar-weekly__week >:nth-last-child(2) { flex: .7; }
        .v-calendar-weekly__day {
            &.v-outside {opacity: .5;}
            .v-calendar-weekly__day-label .v-btn {height: 1em;}
            &.v-present .v-btn {
                // color: var(--purple03); 
                // color: white; 
                background-color: transparent;
                font-weight: bold;
            }
            &.v-past {
                opacity: .5;
                .v-event .evento {box-shadow: none;}
            }
            &.v-present {background-color: var(--purple01);}
        }
        .v-event {
            height: auto!important;
            color: var(--color_textos)!important;
            width: 100%!important;
            overflow: unset;
            &.detras {z-index: 1;}
            &.delante {z-index: 10;}
            .evento {
                position: relative;
                background-color: white!important;
                display: flex;
                padding: .7em;
                height: 4em!important;
                border: 2px solid var(--purple03);
                border-radius: 5px;
                box-shadow: var(--sombra_ext);
                &:hover:not(.open) {background-color: var(--gray01)!important;}
                cursor: pointer;
                .contenido {
                    width: 100%;
                    .small {font-size: .8em; white-space: normal;}
                    .name {
                        &:before {
                            content: "•";
                            margin-right: .2em;
                        }
                        line-height: 1.2em;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: unset;
                    }
                    .trl {
                        margin-bottom: .5em;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .servicio {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &.open { 
                    height: auto!important;
                    width: 120%;
                    min-width: 17em;
                    padding: 1.8em 1.2em;
                    z-index: 100;
                    cursor: default;
                    .contenido {
                        width: 90%;
                        .small {font-size: 1em; white-space: normal;}
                        .small:not(:first-child) {margin: .8em 0;}
                        .nombre {display: flex;}
                    }
                    .editar {
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: .7em;
                        cursor: pointer;
                        &:hover .icono path {fill: var(--purple03);}
                        .icono path {
                            fill: var(--gray03);
                        }
                    }
                    .trl:hover {color: var(--purple03);cursor: pointer;}
                }
                .imagenes {
                    display: flex;
                    flex-direction: column;
                    margin-right: .8em;
                    .avatar {
                        width: 2.3em;
                        height: 2.3em;
                        margin-top: -.3em;
                        object-fit: cover;
                        &.trl {z-index: 1;}
                        // &.consejera {margin-top: -.3em;}
                    }
                }
            }
        }
        .v-event-more {
            text-align: center;
            margin-left: 4px;
        }
    }
}
</style>






