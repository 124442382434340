const url = 'https://ws.stepsrelocation-panel.com';
// const url = 'https://wsdev.stepsrelocation-panel.com';

let webServices = {
    getToken() {
        //console.log('api login');
        const ws_url = "/docs/crypt.txt";
        var requestOptions = {
            method: 'GET',
            headers: { "Content-Type": "application/json"},
            redirect: 'follow'
        };
        return {url: ws_url, requestOptions: requestOptions}
    },
    resetPassword(token, data) {
        //console.log('user password');
        const user_id = data.user_id;

        const ws_url = url + "/api/v1/user/" + user_id + "/password";

        var urlencoded = new URLSearchParams();
        urlencoded.append("current_password", data.old_password);
        urlencoded.append("new_password", data.password);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    remindPassword(token, data) {
        //console.log('remind user password');

        const ws_url = url + "/api/v1/user/reset-password-request";

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", data);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    remindPasswordFromReset(token, data) {
        //console.log('remind user password from reset');

        const ws_url = url + "/api/v1/user/" + data.id + "/password-from-reset";

        var urlencoded = new URLSearchParams();
        urlencoded.append("id", data.id);
        urlencoded.append("new_password", data.new_password);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    userLogin(token, data) {
        //console.log('user login');

        const ws_url = url + "/api/v1/user/login";

        var urlencoded = new URLSearchParams();
        urlencoded.append("_username", data.username);
        urlencoded.append("_password", data.password);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    adminRegister(token, data) {
        //console.log("admin register");

        const ws_url = url + "/api/v1/user/admin"

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", data.email);
        urlencoded.append("name", data.name);
        urlencoded.append("surnames", data.surname);

        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    getUser(token, data) {
        //console.log("get one user");
        const ws_url = url + "/api/v1/user/" + data;
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    getUserEnc(token, data) {
        //console.log("get one user encrypted");
        const ws_url = url + "/api/v1/user_encrypted?encrypted_id=" + data;

        var requestOptions = {
            method: 'GET',
            headers: { "Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    userList(token, data) {
        //console.log("get all users");
        const ws_url = url + "/api/v1/user" + data.queries;
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    deleteUser(token, data) {
        //console.log('delete User');
        const ws_url = url + "/api/v1/user-delete/" + data.trl + '/' + data.updater;
        // /api/v1/user/{user_to_delete}/{author_user_id}
        var formdata = new FormData();

        var requestOptions = {
            method: 'PUT',
            headers: { "Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    activateUser(token, data) {
        const ws_url = url + "/api/v1/user/" + data.user_id + "/activate";
        var urlencoded = new URLSearchParams();
        urlencoded.append("language", data.language);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    preferredLanguage(token, data) {
        const ws_url = url + "/api/v1/user/" + data.user_id + "/preferred_language";
        var urlencoded = new URLSearchParams();
        urlencoded.append("language", data.language);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    adminUpdate(token, data) {
        //console.log("admin update");
        const ws_url = url + "/api/v1/user/admin/" + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", data.contacto.email_profesional);
        urlencoded.append("name", data.personales.nombre);
        urlencoded.append("surnames", data.personales.apellidos);
        urlencoded.append("phone_number", data.contacto.telefono);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    consRegister(token, data) {
        //console.log('cons register');
        const ws_url = url + "/api/v1/user/cons";

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", data.contacto.email_profesional);
        urlencoded.append("name", data.personales.nombre);
        urlencoded.append("surnames", data.personales.apellidos);
        urlencoded.append("phone_number", data.contacto.telefono);
        urlencoded.append("languages", data.personales.idiomas);

        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + token,
            },
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    consUpdate(token, data) {
        //console.log('cons update');
        const ws_url = url + "/api/v1/user/cons/" + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", data.contacto.email_profesional);
        urlencoded.append("name", data.personales.nombre);
        urlencoded.append("surnames", data.personales.apellidos);
        urlencoded.append("phone_number", data.contacto.telefono);
        urlencoded.append("languages", data.personales.idiomas);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    rrhhRegister(token, data) {
        //console.log('rrhh register');
        const ws_url = url + "/api/v1/user/rrhh";

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", data.contacto.email_profesional);
        urlencoded.append("name", data.personales.nombre);
        urlencoded.append("surnames", data.personales.apellidos);
        urlencoded.append("company", data.personales.empresa);

        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + token,
            },
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    rrhhUpdate(token, data) {
        //console.log('rrhh update');
        const ws_url = url + "/api/v1/user/rrhh/" + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", data.contacto.email_profesional);
        urlencoded.append("name", data.personales.nombre);
        urlencoded.append("surnames", data.personales.apellidos);
        urlencoded.append("company", data.personales.empresa);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    legalRegister(token, data) {
        //console.log('rrhh register');
        const ws_url = url + "/api/v1/user/legal";

        var urlencoded = new URLSearchParams();
        urlencoded.append("name", data.personales.nombre);
        urlencoded.append("surnames", data.personales.apellidos);
        urlencoded.append("primary_email", data.contacto.email_profesional);
        urlencoded.append("email", data.contacto.email_profesional);

        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + token,
            },
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    legalUpdate(token, data) {
        //console.log('rrhh update');
        const ws_url = url + "/api/v1/user/legal/" + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("name", data.personales.nombre);
        urlencoded.append("surnames", data.personales.apellidos);
        urlencoded.append("primary_email", data.contacto.email_profesional);
        urlencoded.append("email", data.contacto.email_profesional);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    trlRegister(token, data) {
        // //console.log('trl register');
        const ws_url = url + "/api/v1/user/trl";
        let services = [];
        data.servicios.forEach(serv => {
            services.push(serv.id);
        });
        

        var urlencoded = new URLSearchParams();
        urlencoded.append("name", data.personales.nombre);
        urlencoded.append("surnames", data.personales.apellidos);
        urlencoded.append("email", data.contacto.email_profesional);
        urlencoded.append("secondary_email", data.contacto.email_personal);
        urlencoded.append("relocation_city", data.personales.ciudad);
        urlencoded.append("company", data.personales.empresa ? data.personales.empresa : 0);
        urlencoded.append("rrhh", data.personales.rrhh);
        urlencoded.append("services", services);
        urlencoded.append("packs", data.paquetes);
        urlencoded.append("available_legal_user_access", data.personales.visible_abogados ? 1 : 0);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        
        return {requestOptions: requestOptions, url: ws_url}
    },
    trlConsRegister(token, data) {
        //console.log("trl cons register");
        const ws_url = url + '/api/v1/user/trl/' + data.trl_id +'/cons';

        var urlencoded = new URLSearchParams();
        urlencoded.append("cons", data.cons_id);
        data.accepted ? urlencoded.append("accepted", data.accepted) : '';
        urlencoded.append("cons_order", data.cons_order);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlConsUpdate(token, data) {
        //console.log("trl cons register");
        const ws_url = url + '/api/v1/user/trl/' + data.trl_id + '/cons/' + data.cons_id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("accepted", data.accepted);
        urlencoded.append("cons_order", data.cons_order);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlConsAccept(token, data) {
        //console.log('trl cons acccept');
        const ws_url = url + "/api/v1/user/cons/" + data.cons + "/trl/" + data.trl + "/accept";

        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        
        return {requestOptions: requestOptions, url: ws_url}
    },
    trlConsReject(token, data) {
        //console.log('trl cons reject');
        const ws_url = url + "/api/v1/user/cons/" + data.cons + "/trl/" + data.trl + "/reject";

        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        
        return {requestOptions: requestOptions, url: ws_url}
    },
    trlPersonalDataReg(token, data) {
        const ws_url = url + "/api/v1/user/trl/" + data.id + "/personal_data";
        let body = {
            "updater": data.updater,
            "personal_data": {
                "name": data.personales.nombre,
                "surnames": data.personales.apellidos,
                "nationality": data.personales.nacionalidad,
                "preferred_language": data.personales.idioma_preferido,
                "current_address": data.personales.direccion,
                "identity_id": data.personales.pasaporte,
                "bank": data.datos_bancarios.banco,
                "iban": data.datos_bancarios.cuenta,
                "gender": data.personales.sexo,
                "company": data.personales.empresa,
                "rrhh": data.personales.rrhh ? data.personales.rrhh.join(", ") : null,
                "available_legal_user_access": data.personales.visible_abogados
            },
            "contact_data": {
                "professional_email": data.contacto.email_profesional,
                "personal_email": data.contacto.email_personal,
                "mobile_phone": data.contacto.telefono
            },
            "spouse_data": {
                "has_spouse": data.conyuge.nombre ? true : false,
                "name": data.conyuge.nombre || null,
                "surnames": data.conyuge.apellidos || null,
                "nationality": data.conyuge.nacionalidad || null,
                "languages": null,
                "email": data.conyuge.email || null,
                "phone_number": data.conyuge.telefono || null,
                "identity_id": data.conyuge.pasaporte || null
            }
        }

        var requestOptions = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "text/plain"
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlPrivacyPolicy(token, data) {
        //console.log('trl personal data register');
        const ws_url = url + "/api/v1/user/" + data.id + "/privacy_policy";
        
        var urlencoded = new URLSearchParams();
        urlencoded.append("privacy_policy", data.privacidad.titular);
        urlencoded.append("spouse_privacy_policy", data.privacidad.conyuge);
        urlencoded.append("updater", data.updater);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlRelocation_data(token, data) {
        //console.log('trl relocation data');
        const ws_url = url + "/api/v1/user/trl/" + data.id + "/relocation_data";
        let visit_date = [];
        data.preferencias.traslado.fecha_disponible.length ? data.preferencias.traslado.fecha_disponible.forEach(fecha => visit_date.push(new Date(fecha).getTime())) : '';
        let body = {
            "updater": data.updater,
            "relocation_preferences": {
                "relocation_city": data.personales.ciudad,
                "desired_relocation_date": data.preferencias.traslado.fecha_entrada ? new Date(data.preferencias.traslado.fecha_entrada).getTime() : 0,
                "entry_date": data.preferencias.traslado.fecha_traslado ? new Date(data.preferencias.traslado.fecha_traslado).getTime() : 0,
                "visit_date": visit_date.length ? visit_date : 0,
                "budget": data.preferencias.traslado.presupuesto ? data.preferencias.traslado.presupuesto : 0,
                "rental_duration": data.preferencias.traslado.tiempo_alquiler ? data.preferencias.traslado.tiempo_alquiler : 0
            },
            "home_preferences": {
                "type": {
                    "piso": data.preferencias.vivienda.tipo.piso ? 1 : 0,
                    "casa": data.preferencias.vivienda.tipo.casa ? 1 : 0
                },
                "meters": data.preferencias.vivienda.metros,
                "rooms": data.preferencias.vivienda.habitaciones,
                "state": {
                    "amueblada": data.preferencias.vivienda.estado.amueblada ? 1 : 0,
                    "noamueblada": data.preferencias.vivienda.estado.sin_amueblar ? 1 : 0
                },
                "style": {
                    "clasico": data.preferencias.vivienda.estilo.clasico ? 1 : 0,
                    "moderno": data.preferencias.vivienda.estilo.moderno ? 1 : 0
                },
                "features": {
                    "elevator": 0,
                    "pool": data.preferencias.vivienda.caracteristicas.piscina ? 1 : 0,
                    "garden": data.preferencias.vivienda.caracteristicas.jardin ? 1 : 0,
                    "terrace": data.preferencias.vivienda.caracteristicas.terraza ? 1 : 0,
                    "children_area": data.preferencias.vivienda.caracteristicas.zonas_infantiles ? 1 : 0,
                    "room_service": data.preferencias.vivienda.caracteristicas.habitacion_servicio ? 1 : 0
                },
                "others": {
                    "city_center": data.preferencias.vivienda.otras_preferencias.centro ? 1 : 0,
                    "city_outskirst": data.preferencias.vivienda.otras_preferencias.afueras ? 1 : 0,
                    "near_office": data.preferencias.vivienda.otras_preferencias.cerca_oficina ? 1 : 0,
                    "near_school": data.preferencias.vivienda.otras_preferencias.cerca_colegio ? 1 : 0,
                    "near_subway": data.preferencias.vivienda.otras_preferencias.cerca_metro ? 1 : 0,
                    "sunny": data.preferencias.vivienda.otras_preferencias.soleado ? 1 : 0,
                    "quiet_zone": data.preferencias.vivienda.otras_preferencias.zona_tranquila ? 1 : 0,
                    "residential_areas": data.preferencias.vivienda.areas_residenciales || null,
                    "garage": data.preferencias.vivienda.garage ? 1 : 0,
                    "parking_spaces": parseInt(data.preferencias.vivienda.coches),
                    "parking_spaces_budget": data.preferencias.vivienda.coches_en_presupuesto ? 1 : 0,
                    "pet_allowed": data.preferencias.vivienda.mascota ? 1 : 0,
                    "pet_type": data.preferencias.vivienda.mascota_tipo || null,
                    "others": data.preferencias.vivienda.otros
                }
            }
        }

        var requestOptions = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "text/plain"
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlSchoolPreferences(token, data) {
        //console.log('trl school preferences');
        const ws_url = url + "/api/v1/user/trl/" + data.id + "/school_data";
        let hijos = [];
        data.preferencias.colegios.hijos.forEach(hi => {
            let hijo = {
                "name": hi.nombre,
                "surnames": hi.apellidos,
                "birthdate": hi.nacimiento,
                "gendre": hi.sexo,
                "school_year": hi.curso,
                "languages": hi.idiomas
            };
            hijos.push(hijo);
        });
        let body = {
            "updater": data.updater,
            "school_preferences": {
                "children": hijos,
                "preferences": {
                    "school_type": {
                        "concertado": data.preferencias.colegios.preferencias.tipo_colegio.concertado,
                        "privado": data.preferencias.colegios.preferencias.tipo_colegio.privado,
                        "publico": data.preferencias.colegios.preferencias.tipo_colegio.publico
                    },
                    "education_type": {
                        "german": data.preferencias.colegios.preferencias.tipo_educacion.alemana,
                        "american": data.preferencias.colegios.preferencias.tipo_educacion.americana,
                        "britsh": data.preferencias.colegios.preferencias.tipo_educacion.britanica,
                        "spanish": data.preferencias.colegios.preferencias.tipo_educacion.española,
                        "french": data.preferencias.colegios.preferencias.tipo_educacion.francesa,
                        "other": data.preferencias.colegios.preferencias.tipo_educacion.otras
                    }
                }
            }
        }

        var requestOptions = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "text/plain"
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlHomeContract(token, data) {
        //console.log('trl home contract preferences');
        const ws_url = url + "/api/v1/user/trl/" + data.id + "/home_contract_data";
        const data_viv = data.servicio_vivienda;
        let body = {
            "updater": data.updater,
            "company_data": {
                "date_advise": new Date(data_viv.gestion.fecha_aviso).getTime(),
                "date_first_contact": new Date(data_viv.gestion.fecha_primer_contacto).getTime(),
                "date_arrival": new Date(data_viv.gestion.fecha_llegada).getTime(),
                "date_home_visit": new Date(data_viv.gestion.fecha_visita).getTime(),
                "date_search_school": new Date(data_viv.gestion.fecha_busqueda_colegios).getTime(),
                "date_signature_contract": new Date(data_viv.gestion.fecha_firma_contrato).getTime(),
                "date_entry": new Date(data_viv.gestion.fecha_entrada).getTime(),
                "company_notes": data_viv.gestion.otros
            },
            "home_data": {
                "home_address": data_viv.datos_vivienda.direccion,
                "home_cadastral_reference": data_viv.datos_vivienda.referencia_catastral,
                "home_owner_name": data_viv.datos_vivienda.propietario_nombre,
                "home_owner_identity_id": data_viv.datos_vivienda.propietario_dni,
                "home_owner_address": data_viv.datos_vivienda.propietario_direccion,
                "home_owner_phone_number": data_viv.datos_vivienda.propietario_telefono,
                "home_owner_email": data_viv.datos_vivienda.propietario_email,
                "home_owner_iban": data_viv.datos_vivienda.iban,
                "home_keeper_name": data_viv.datos_vivienda.encargado_nombre,
                "home_keeper_phone_number": data_viv.datos_vivienda.encargado_telefono,
                "home_keeper_email": data_viv.datos_vivienda.encargado_email,
                "home_real_estate_agency_name": data_viv.datos_vivienda.inmobiliaria_nombre,
                "home_real_estate_agency_phone": data_viv.datos_vivienda.inmobiliaria_telefono,
                "home_real_estate_agency_email": data_viv.datos_vivienda.inmobiliaria_email,
                "home_preselected_homes": data_viv.viviendas_preseleccionadas,
                "home_notes": data_viv.datos_vivienda.otros
            },
            "home_rental": {
                "home_rental_date_init": new Date(data_viv.contrato_alquiler.fecha_inicio).getTime(),
                "home_rental_mandatory_duration": data_viv.contrato_alquiler.duracion_obligatoria,
                "home_rental_notice": data_viv.contrato_alquiler.preaviso,
                "home_rental_rent": data_viv.contrato_alquiler.renta,
                "home_rental_holder": data_viv.contrato_alquiler.contrato_nombre,
                "home_rental_signature": data_viv.contrato_alquiler.contrato_firmado,
                "home_rental_deposit": data_viv.contrato_alquiler.fianza,
                "home_rental_deposit_holder": data_viv.contrato_alquiler.fianza_pagador,
                "home_rental_guarantee": data_viv.contrato_alquiler.deposito,
                "home_rental_guarantee_holder": data_viv.contrato_alquiler.deposito_pagador,
                "home_rental_agency_commission": data_viv.contrato_alquiler.comision,
                "home_rental_agency_commission_holder": data_viv.contrato_alquiler.comision_pagador,
                "home_rental_photographic_inventory": data_viv.contrato_alquiler.inventario,
                "home_rental_extendable": data_viv.contrato_alquiler.contrato_prorrogable,
                "home_checkin_presence_tenant": data_viv.contrato_alquiler.presencia_checkin.inquilino,
                "home_checkin_presence_cons": data_viv.contrato_alquiler.presencia_checkin.consejera,
                "home_checkin_presence_agent": data_viv.contrato_alquiler.presencia_checkin.agente,
                "home_checkin_presence_owner": data_viv.contrato_alquiler.presencia_checkin.propietario,
                "home_rental_contract_notes": data_viv.contrato_alquiler.otros
            },
            "home_insurance": {
                "home_insurance_owner_name": data_viv.seguro_hogar.propietario_nombre,
                "home_insurance_owner_phone": data_viv.seguro_hogar.propietario_telefono,
                "home_insurance_owner_policy": data_viv.seguro_hogar.propietario_poliza,
                "home_insurance_owner_email": data_viv.seguro_hogar.propietario_email,
                "home_insurance_owner_notes": data_viv.seguro_hogar.propietario_otros,
                "home_insurance_tenant_name": data_viv.seguro_hogar.inquilino_nombre,
                "home_insurance_tenant_phone": data_viv.seguro_hogar.inquilino_telefono,
                "home_insurance_tenant_policy": data_viv.seguro_hogar.inquilino_poliza,
                "home_insurance_tenant_email": data_viv.seguro_hogar.inquilino_email,
                "home_insurance_tenant_notes": data_viv.seguro_hogar.inquilino_otros
            }
        }

        var requestOptions = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "text/plain"
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlAssignHomes(token, data) {
        //console.log('trl home contract preferences');
        const ws_url = url + "/api/v1/user/trl/" + data.id + "/assigned-homes/" + data.sender;
        let platform_urls = [];
        let external_urls = [];
        let number = parseInt(data.number);

        data.links_int.forEach(link => {
            platform_urls.push({number: number, url: 'https://www.stepsrelocation-panel.com/#/fichaVivienda?vivId=' + link.id, notes: link.nota});
            number++;
        });
        data.links_ext.forEach(link => {
            link.link ? external_urls.push({number: number, url: link.link, notes: link.nota}) : '';
            number++;
        });
        // data.links_ext.length == 1 && data.links_ext[0].link ? external_urls = null : '';
        !data.links_int ? platform_urls = null : '';


        let body = {
            platform_urls: platform_urls,
            external_urls: external_urls
        };

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlServiceUpdate(token, data) {
        //console.log('trl service update');
        const ws_url = url + "/api/v1/user/trl/" + data.id + "/service/" + data.service_id;
        let body = {data: data.servicio};

        var requestOptions = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "text/plain"
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlPackUpdate(token, data) {
        //console.log('trl service update');
        const ws_url = url + "/api/v1/user/trl/" + data.user_id + "/pack/" + data.new_pack_id + "/" + data.old_pack_id;
        var requestOptions = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "text/plain"
            },
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlStatusUpdate(token, data) {
        //console.log('trl service update');
        const ws_url = url + "/api/v1/user/trl/" + data.id + "/status/" + data.updater;
        // /api/v1/user/trl/{trl_id}/status/{author_id}

        var urlencoded = new URLSearchParams();
        urlencoded.append("status", data.status);
        urlencoded.append("send_survey", data.send_survey);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    trlIdentityPhoto(token, data) {
        const ws_url = url + '/api/v1/user/identity_id_photo/' + data.id;

        var formdata = new FormData();
        formdata.append("photo", data.img, data.img.name);
        formdata.append("updater", data.updater);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    deleteTrlIdentityPhoto(token, data) {
        const ws_url = url + '/api/v1/user/identity_id_photo/' + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("updater", data.updater);

        var requestOptions = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    trlSpouseIdentityPhoto(token, data) {
        const ws_url = url + '/api/v1/user/trl/spouse_identity_id_photo/' + data.id;

        var formdata = new FormData();
        formdata.append("photo", data.img, data.img.name);
        // formdata.append("updater", data.updater);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    deleteTrlSpouseIdentityPhoto(token, data) {
        const ws_url = url + '/api/v1/user/trl/spouse_identity_id_photo/' + data.id;

        var formdata = new FormData();

        var requestOptions = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    userPhoto(token, data) {
        //console.log("update user photo");
        const ws_url = url + '/api/v1/user/photo/' + data.id;

        var formdata = new FormData();
        formdata.append("photo", data.img, data.nombre);
        formdata.append("updater", data.id);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    companyRegister(token, data) {
        //console.log("company register");
        const ws_url = url + "/api/v1/company";
        var urlencoded = new URLSearchParams();
            urlencoded.append("name", data.nombre);
            urlencoded.append("vat_number", data.cif);
            urlencoded.append("sector", data.sector_id);
            urlencoded.append("rrhh", '');
            urlencoded.append("office_address[]", data.direccion);
            data.direccion_facturacion.forEach(dire => {
                if (dire) urlencoded.append("billing_address[]", dire);
            })

        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    companyUpdate(token, data) {
        //console.log("company update");
        const ws_url = url + "/api/v1/company/" + data.id;
        var urlencoded = new URLSearchParams();
            urlencoded.append("name", data.nombre);
            urlencoded.append("vat_number", data.cif);
            urlencoded.append("sector", data.sector_id);
            urlencoded.append("rrhh", '');
            urlencoded.append("office_address[]", data.direccion);
            data.direccion_facturacion.forEach(dire => {
                if (dire) urlencoded.append("billing_address[]", dire);
            })

        var requestOptions = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    companyLogo(token, data) {
        //console.log("company register");
        const ws_url = url + "/api/v1/company/" + data.empresa_id + "/logo";

        var formdata = new FormData();
        formdata.append("logo", data.img, data.nombre);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    companyList(token) {
        //console.log('company lsit');
        const ws_url = url + "/api/v1/company";
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    deleteCompany(token, data) {
        //console.log('delete Company');
        const ws_url = url + "/api/v1/company/" + data;
        const company_id = data;
        var formdata = new FormData();

        var requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": "Bearer " + token },
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    houseRegister(token, data) {
        const ws_url = url + '/api/v1/house';

        for (const field in data) {
            !data[field] ? data[field] = 0 : '';
        }

        var urlencoded = new URLSearchParams();
        urlencoded.append("address", data.direccion);
        urlencoded.append("district", data.barrio);
        urlencoded.append("city", data.ciudad);
        urlencoded.append("map", "");
        urlencoded.append("type", data.tipo);
        urlencoded.append("meters", data.metros);
        urlencoded.append("floor", data.piso);
        urlencoded.append("rooms", data.habitaciones);
        urlencoded.append("bathrooms", data.baños);
        urlencoded.append("price", data.precio);
        urlencoded.append("equipment", data.equipamiento);
        urlencoded.append("state", data.estado);
        urlencoded.append("style", data.estilo);
        urlencoded.append("community_expenses", data.gastos_comunidad);
        urlencoded.append("contract_type", data.tipo_contrato);
        urlencoded.append("availability", data.disponibilidad ? 1 : 0);
        urlencoded.append("rental_conditions", data.condiciones_alquiler);
        urlencoded.append("orientation", data.orientacion ? 1 : 0);
        urlencoded.append("air_conditioning", data.aire_acondicionado ? 1 : 0);
        urlencoded.append("heating", data.calefaccion ? 1 : 0);
        urlencoded.append("garden", data.jardin ? 1 : 0);
        urlencoded.append("terrace", data.terraza ? 1 : 0);
        urlencoded.append("pool", data.piscina ? 1 : 0);
        urlencoded.append("storage_room", data.trastero ? 1 : 0);
        urlencoded.append("children_areas", data.zonas_infantiles ? 1 : 0);
        urlencoded.append("service_room", data.habitacion_servicio ? 1 : 0);
        urlencoded.append("sunny", data.soleado ? 1 : 0);
        urlencoded.append("pet_allowed", data.mascotas ? 1 : 0);
        urlencoded.append("downtown", data.centro ? 1 : 0);
        urlencoded.append("outskirts", data.afueras ? 1 : 0);
        urlencoded.append("near_office", data.cerca_oficina ? 1 : 0);
        urlencoded.append("near_school", data.cerca_colegio ? 1 : 0);
        urlencoded.append("near_subway", data.cerca_metro ? 1 : 0);
        urlencoded.append("quiet_zone", data.zona_tranquila ? 1 : 0);
        urlencoded.append("has_garage", data.garaje ? 1 : 0);
        urlencoded.append("parking_spaces", data.garaje_plazas || 0);
        urlencoded.append("parking_spaces_in_budget", data.garaje_en_presu ? 1 : 0);
        urlencoded.append("description", data.descripcion);
        urlencoded.append("description_en", data.descripcion_en);
        urlencoded.append("owner_name", data.info_propietario.nombre);
        urlencoded.append("owner_phone", data.info_propietario.telefono);
        urlencoded.append("owner_email", data.info_propietario.email);
        urlencoded.append("owner_notes", data.info_propietario.notas);
        urlencoded.append("creator", data.creador);

        var requestOptions = {
            method: 'POST',
            headers: { "Authorization": "Bearer " + token },
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url} 
    },
    housePhoto(token, data) {
        //console.log("house foto");
         const ws_url = url + '/api/v1/house/' + data.id + '/photos';

        var formdata = new FormData();
        formdata.append("main_photo", data.foto_principal.img, data.foto_principal.nombre);
        data.fotos.forEach(foto => {
            formdata.append("photo[]", foto.img, foto.nombre);
        });

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url} 
    },
    houseUpdate(token, data) {
        //console.log("house update");
        const ws_url = url + "/api/v1/house/" + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("address", data.direccion);
        urlencoded.append("district", data.barrio);
        urlencoded.append("city", data.ciudad);
        urlencoded.append("map", "");
        urlencoded.append("type", data.tipo);
        urlencoded.append("meters", data.metros);
        urlencoded.append("floor", data.piso);
        urlencoded.append("rooms", data.habitaciones);
        urlencoded.append("bathrooms", data.baños);
        urlencoded.append("price", data.precio);
        urlencoded.append("equipment", data.equipamiento);
        urlencoded.append("state", data.estado);
        urlencoded.append("style", data.estilo);
        urlencoded.append("community_expenses", data.gastos_comunidad);
        urlencoded.append("contract_type", data.tipo_contrato);
        urlencoded.append("availability", data.disponibilidad ? 1 : 0);
        urlencoded.append("rental_conditions", data.condiciones_alquiler);
        urlencoded.append("orientation", data.orientacion ? 1 : 0);
        urlencoded.append("air_conditioning", data.aire_acondicionado ? 1 : 0);
        urlencoded.append("heating", data.calefaccion ? 1 : 0);
        urlencoded.append("garden", data.jardin ? 1 : 0);
        urlencoded.append("terrace", data.terraza ? 1 : 0);
        urlencoded.append("pool", data.piscina ? 1 : 0);
        urlencoded.append("storage_room", data.trastero ? 1 : 0);
        urlencoded.append("children_areas", data.zonas_infantiles ? 1 : 0);
        urlencoded.append("service_room", data.habitacion_servicio ? 1 : 0);
        urlencoded.append("sunny", data.soleado ? 1 : 0);
        urlencoded.append("pet_allowed", data.mascotas ? 1 : 0);
        urlencoded.append("downtown", data.centro ? 1 : 0);
        urlencoded.append("outskirts", data.afueras ? 1 : 0);
        urlencoded.append("near_office", data.cerca_oficina ? 1 : 0);
        urlencoded.append("near_school", data.cerca_colegio ? 1 : 0);
        urlencoded.append("near_subway", data.cerca_metro ? 1 : 0);
        urlencoded.append("quiet_zone", data.zona_tranquila ? 1 : 0);
        urlencoded.append("has_garage", data.garaje ? 1 : 0);
        urlencoded.append("parking_spaces", data.garaje_plazas || 0);
        urlencoded.append("parking_spaces_in_budget", data.garaje_en_presu ? 1 : 0);
        urlencoded.append("description", data.descripcion);
        urlencoded.append("description_en", data.descripcion_en);
        urlencoded.append("owner_name", data.info_propietario.nombre);
        urlencoded.append("owner_phone", data.info_propietario.telefono);
        urlencoded.append("owner_email", data.info_propietario.email);
        urlencoded.append("owner_notes", data.info_propietario.notas);

        var requestOptions = {
            method: 'PUT',
            headers: { "Authorization": "Bearer " + token },
            body: urlencoded,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url} 
    },
    houseRemove(token, data) {
        //console.log("house remove");
        const ws_url = url + "/api/v1/house/" + data;
        var formdata = new FormData();
        var requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": "Bearer " + token },
            body: formdata,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url};
    },
    houseList(token) {
        //console.log('house lsit');
        const ws_url = url + "/api/v1/house?order_by=recents";
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    houseGetOne(token, data) {
        const ws_url = url + "/api/v1/house/" + data;
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    taskRegister(token, data) {
        //console.log("task register");
        const ws_url = url + "/api/v1/task";

        var urlencoded = new URLSearchParams();
        urlencoded.append("service", data.servicio);
        urlencoded.append("name", data.name);
        urlencoded.append("creator", data.creada_por);
        urlencoded.append("trl", data.trl);
        urlencoded.append("start", data.timestamp);
        urlencoded.append("visible_rrhh", data.mostrar_rrhh ? 'yes' : 'no');
        urlencoded.append("email_notificacion_trl", data.notificar_email ? 'yes' : 'no');
        urlencoded.append("notes", data.notas);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    taskUpdate(token, data) {
        //console.log("task update");
        const ws_url = url + "/api/v1/task/" + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("service", data.servicio);
        urlencoded.append("name", data.name);
        urlencoded.append("creator", data.creada_por);
        urlencoded.append("trl", data.trl);
        urlencoded.append("start", data.timestamp);
        urlencoded.append("visible_rrhh", data.mostrar_rrhh ? 'yes' : 'no');
        urlencoded.append("email_notificacion_trl", data.notificar_email ? 'yes' : 'no');
        urlencoded.append("notes", data.notas);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    taskRemove(token, data) {
        //console.log("task remove");
        const ws_url = url + "/api/v1/task/" + data;
        var formdata = new FormData();

        var requestOptions = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    taskList(token, data) {
        //console.log('tasks lsit');
        let ws_url;
        if (!data) {
            ws_url = url + "/api/v1/task";
        } else {
            ws_url = url + "/api/v1/task?" + data;
        }
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    fileUpload(token, data) {
        //console.log("file upload");
        const ws_url = url + "/api/v1/user/" + data.trl_id + "/file";

        var formdata = new FormData();
        formdata.append("file", data.file, data.file.nombre);
        formdata.append("type", data.tipo);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };

        return {requestOptions: requestOptions, url: ws_url}
    },
    fileList(token, data) {
        //console.log('file lsit');
        let ws_url = url + "/api/v1/user_file?user=" + data;
        
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    fileRemove(token, data) {
        //console.log("file remove");
        const ws_url = url + "/api/v1/user/file/" + data;
        var formdata = new FormData();

        var requestOptions = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    logList(token, data) {
        let ws_url = url + "/api/v1/change_log?order_by=recents&trl=" + data;
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    trlUsersList(token) {
        const ws_url = url + "/api/v1/user-trl";
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    serviceRegister(token, data) {
        const ws_url = url + "/api/v1/service-add";

        let body = {
            "name_es": data.nombre_es,
            "name_en": data.nombre_en,
            "description_es": data.descripcion_es,
            "description_en": data.descripcion_en,
            "weight": data.orden + 1,
            "subservice_es": data.variantes_es,
            "subservice_en": data.variantes_en,
            "tasks": [[...data.tareas_es], [...data.tareas_en]],
            "custom_service": {
                "fecha_envio_notificacion": null,
                "fecha_check_out": null,
                "devolucion_fianza": null
            },
            "other_services": data.fecha_inicio,
            "service_type_id": data.categoria.value
        }

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: JSON.stringify(body),
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    serviceUpdate(token, data) {
        const ws_url = url + "/api/v1/service-update/" + data.id;

        let body = {
            "name_es": data.nombre_es,
            "name_en": data.nombre_en,
            "description_es": data.descripcion_es,
            "description_en": data.descripcion_en,
            "weight": data.orden + 1,
            "subservice_es": data.variantes_es,
            "subservice_en": data.variantes_en,
            "tasks": [[...data.tareas_es], [...data.tareas_en]],
            "custom_service": {
                "fecha_envio_notificacion": null,
                "fecha_check_out": null,
                "devolucion_fianza": null
            },
            "other_services": data.fecha_inicio,
            "service_type_id": data.categoria.value
        }

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: JSON.stringify(body),
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    serviceList(token) {
        const ws_url = url + "/api/v1/service";
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    serviceRemove(token, data) {
        const ws_url = url + "/api/v1/service/" + data;
        var formdata = new FormData();

        var requestOptions = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    serviceTypeRegister(token, data) {
        const ws_url = url + "/api/v1/service_type";

        var urlencoded = new URLSearchParams();
        urlencoded.append("name", data);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    serviceTypeList(token) {
        const ws_url = url + "/api/v1/service_type";
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    packRegister(token, data) {
        const ws_url = url + "/api/v1/pack";

        var urlencoded = new URLSearchParams();
        urlencoded.append("name", data.nombre);
        urlencoded.append("description", '.');
        urlencoded.append("weight", data.orden + 1);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    packUpdate(token, data) {
        const ws_url = url + "/api/v1/pack/" + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("name", data.nombre);
        urlencoded.append("description", '.');
        urlencoded.append("weight", data.orden + 1);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    packList(token) {
        const ws_url = url + "/api/v1/pack";
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    packRemove(token, data) {
        const ws_url = url + "/api/v1/pack/" + data;
        var formdata = new FormData();

        var requestOptions = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    servicePackRegister(token, data) {
        const ws_url = url + "/api/v1/service-pack";

        var urlencoded = new URLSearchParams();
        urlencoded.append("service_id", data.service_id);
        urlencoded.append("pack_id", data.pack_id);
        if (data.subservice > -1) urlencoded.append("subservice_id", data.subservice);

        var requestOptions = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    servicePackUpdate(token, data) {
        const ws_url = url + "/api/v1/service-pack/" + data.id;

        var urlencoded = new URLSearchParams();
        urlencoded.append("service_id", data.service_id);
        urlencoded.append("pack_id", data.pack_id);
        urlencoded.append("subservice_id", data.subservice);

        var requestOptions = {
            method: 'PUT',
            headers: {"Authorization": "Bearer " + token},
            body: urlencoded,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    },
    servicePackList(token) {
        const ws_url = url + "/api/v1/service-pack";
        var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Bearer " + token},
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url};
    },
    servicePackRemove(token, data) {
        const ws_url = url + "/api/v1/service-pack/" + data;
        var formdata = new FormData();

        var requestOptions = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + token},
            body: formdata,
            redirect: 'follow'
        };
        return {requestOptions: requestOptions, url: ws_url}
    }
}

export {webServices};