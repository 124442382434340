import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'


Vue.use(Vuex);
Vue.use(moment);

export default new Vuex.Store({
    state: {
        token: null,
        loading: false,
        usuario_loggeado: {},
        usuarios: [],
        usuarios_finalizados: [],
        paquetes: [],
        servicios: [],
        // paquetes: [
        //     {
        //         id: 1,
        //         nombre: 'Paquete Single Banco Santander',
        //         info: '',
        //         servicios_asociados: [5, 10, [11, 1], 16, 22, 28, 32, 36, 39, 43, 44, 45, 46]
        //     },
        //     {
        //         id: 2,
        //         nombre: 'Paquete Pareja Banco Santander',
        //         info: '',
        //         servicios_asociados: [4, 5, 10, [11, 1], 16, 22, 27, 28, 32, 36, 39, 43, 44, 45, 46]
        //     },
        //     {
        //         id: 3,
        //         nombre: 'Paquete Familia Banco Santander ',
        //         info: '',
        //         servicios_asociados: [4, 5, 9, 10, [11, 1], 16, 22, 27, 28, 32, 36, 39, 43, 44, 45, 46]
        //     },
        //     {
        //         id: 4,
        //         nombre: 'Paquete de cierre',
        //         info: '',
        //         servicios_asociados: [43, 44]
        //     },
        //     {
        //         id: 5,
        //         nombre: 'Paquete relocation online',
        //         info: '',
        //         servicios_asociados: [[11, 1], 32, 36, 43, 44]
        //     },
        //     {
        //         id: 6,
        //         nombre: 'Paquete relocation online sin acompañamiento',
        //         info: '',
        //         servicios_asociados: [[11, 0], 32, 36, 43, 44]
        //     },
        //     {
        //         id: 7,
        //         nombre: 'Paquete Premium Mercedes-Benz',
        //         info: '',
        //         servicios_asociados: [[2, 0], [11, 2], 16, 17, 22, 28, [29, 1], 32, 36, 43, 44]
        //     },
        //     {
        //         id: 8,
        //         nombre: 'Paquete Ejecutivo Mercedes-Benz',
        //         info: '',
        //         servicios_asociados: [[2, 1], 5, [11, 1], 16, 17, 22, 28, 32, 36, 43, 44]
        //     },
        //     {
        //         id: 9,
        //         nombre: 'Paquete de cierre UPS',
        //         info: '',
        //         servicios_asociados: [19, 21, 42]
        //     },
        //     {
        //         id: 10,
        //         nombre: 'Paquete Single BNP',
        //         info: '',
        //         servicios_asociados: [[11, 1], 13, [17, 0], 22, 28, 32, 34, 43, 45]
        //     },
        //     {
        //         id: 11,
        //         nombre: 'Paquete Pareja BNP',
        //         info: '',
        //         servicios_asociados: [[11, 1], 50, [17, 0], 22, 27, 28, 32, 34, 43, 45]
        //     },
        //     {
        //         id: 12,
        //         nombre: 'Paquete Familia BNP ',
        //         info: '',
        //         servicios_asociados: [[11, 1], 50, [17, 0], 22, 27, 28, 32, 34, 43, 45]
        //     },
        // ],
        // servicios: [
        //     {   
        //         id: 1,
        //         order: 3,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Alojamiento temporal',
        //         info: 'Elaboraremos una breve lista de opciones de alojamiento temporal que estén disponibles y te acompañamos a visitar 3 opciones, negociamos condiciones favorables y coordinamos la reserva de la vivienda seleccionada. Acompañamiento a la firma del contrato.',
        //         sub: ['Con acompañamiento', 'Sin acompañamiento'],
        //         show_sub: false,
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Selección de viviendas',
        //             'Envío de agenda',
        //             'Visita a las viviendas',
        //             'Entrega reserva/fianza',
        //             'Firma del contrato',
        //             'Check-in / inventario'
        //         ],
        //         tareas_en: [
        //             'Selection of housing',
        //             'Sending agenda',
        //             'Housing visit ',
        //             'Delivery of reservation/security deposit',
        //             'Contract signature',
        //             'Check-in / Inventory'
        //         ]
        //     },
        //     {   
        //         id: 2,
        //         order: 15,
        //         estado: '',
        //         tipo: 'Otros',
        //         nombre: 'Alquiler de muebles',
        //         sub: ['Con acompañamiento', 'Sin acompañamiento'],
        //         show_sub: false,
        //         info: 'Organizamos el alquiler temporal de muebles para tu nueva casa. Ofrecemos apoyo durante la selección, reserva, pagos y soporte telefónico el día de la entrega.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto proveedor',
        //             'Follow-up '
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Follow-up'
        //         ]
        //     },
        //     {   
        //         id: 3,
        //         order: 38,
        //         estado: '',
        //         tipo: 'Otros',
        //         nombre: 'Alta empleador',
        //         info: 'Necesario para poder contratar ayuda doméstica. Todas las personas que  contratan trabajadores por primera vez, deben solicitar el registro como empleadores en la Seguridad Social Española.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'preparación documentación/expediente',
        //             'Obtención resolucion'
        //         ],
        //         tareas_en: [
        //             'Documentation / file preparation',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {   
        //         id: 4,
        //         order: 20,
        //         estado: '',
        //         tipo: 'Establecimiento',
        //         nombre: 'Apoyo a la pareja',
        //         info: 'Le informamos sobre diferentes actividades, asociaciones, clubs, ONG’s, educación, cursos, etc, para que tu experiencia en el nuevo destino sea productiva.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Hecho'
        //         ],
        //         tareas_en: [
        //             'Done'
        //         ]
        //     },
        //     {   
        //         id: 5,
        //         order: 47,
        //         estado: '',
        //         tipo: 'Establecimiento',
        //         info: 'Le prestamos asistencia telefónica de lunes a viernes en horario laboral (de 9:00 a 19:00).',
        //         nombre: 'Asistencia telefónica',
        //         otros_servicios: false,
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         }
        //     },
        //     {   
        //         id: 6,
        //         order: 30,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Aut. residencia - altamente cualificados - principal',
        //         sub: [
        //             'Con visado', 
        //             'Sin visado', 
        //         ],
        //         show_sub: false,
        //         info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida tras la llegada del extranjero a España.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor', 'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {   
        //         id: 7,
        //         order: 31,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Aut. residencia - emprendedores - principal',
        //         sub: [
        //             'Con visado', 
        //             'Sin visado', 
        //         ],
        //         show_sub: false,
        //         info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida tras la llegada del extranjero a España.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor', 'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 8,
        //         order: 39,
        //         estado: '',
        //         tipo: 'Otros',
        //         nombre: 'Ayuda doméstica',
        //         info: 'Te pondremos en contacto con agencias de servicio domestico y te presentaremos al gerente. Haremos seguimiento de las entrevistas. Alta en la seguridad social del empleado y empleador.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Puesta en contacto proveedor', 'Organización de entrevistas', 'Soporte adicional al servicio'],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Organization of interviews',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 9,
        //         order: 4,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Búsqueda de colegio',
        //         info: 'Asesoramiento sobre diferentes colegios basándonos en tu cuestionario. Coordinación de visitas y acompañamiento a 3 colegios.  Asistencia con los requisitos de matriculación. Seguimiento sobre el proceso de admisión.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Envío de agenda', 'Visita a colegios'],
        //         tareas_en: [
        //             'Sending agenda',
        //             'School visits'
        //         ]
        //     },
        //     {
        //         id: 10,
        //         order: 14,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Búsqueda de parking',
        //         info: 'Buscamos una plaza de parking cerca de tu nuevo apartamento, de acuerdo a tus requerimientos y presupuesto. Negociación del contrato y coordinación de pagos',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Firma del contrato'],
        //         tareas_en: ['Contract signature']
        //     },
        //     {
        //         id: 11,
        //         order: 5,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Búsqueda de vivienda',
        //         sub: ['Sin acompañamiento', '1 día', '2 días'],
        //         show_sub: false,
        //         info: 'Identificación de las necesidades. Te enviaremos por email opciones que cuadren con los requerimientos descritos en el cuestionario (siempre que sean realistas). Un día de visitas acompañadas a unas 6-8 opciones preseleccionadas por ti. Las visitas serán de lunes a viernes en horario laboral. Negociación de contrato, acompañamiento a la firma e inventario fotográfico.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Selección de viviendas',
        //             'Envío de agenda',
        //             'Visita a las viviendas',
        //             'Entrega reserva/fianza',
        //             'Firma del contrato',
        //             'Check-in / inventario'
        //         ],
        //         tareas_en: [
        //             'House selection',
        //             'Sending agenda',
        //             'House visit',
        //             'Delivery of reservation/security deposit',
        //             'Contract signature',
        //             'Check-in / Inventory'
        //         ]
        //     },
        //     {
        //         id: 12,
        //         order: 34,
        //         estado: '',
        //         tipo: 'Coches',
        //         nombre: 'Canje carnet de conducir (EU)',
        //         sub: ['Gestorías', 'Particulares'],
        //         show_sub: false,
        //         info: 'Información completa sobre el trámite, documentación y requisitos. Preparación del expediente y reserva de cita previa. Acompañamiento a la presentación y seguimiento con el departamento de tráfico.  Gestión de problemas. Tu carnet de conducir debe estar en vigor.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención documento presentación',
        //             'Resolución',
        //             'Acomp. trámites'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining presentation document',
        //             'Resolution',
        //             'Accompanying procedures'
        //         ]
        //     },
        //     {
        //         id: 13,
        //         order: 28,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Certificado de Registro UE - Principal',
        //         info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades. Acompañamiento a la presentación. Este certificado incluye el número NIE.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 14,
        //         order: 7,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Compra vivienda',
        //         sub: ['Normal', 'Premium'],
        //         show_sub: false,
        //         info: 'Asesoramiento sobre las diferentes zonas. Selección de viviendas que se ajusten a las preferencias previamente descritas en el cuestionario (siempre que sean realistas). Organización de visitas con agentes inmobiliarios y propietarios. Un día de visitas acompañadas. Negociación del contrato de compra-venta, comprobación en el Registro de la Propiedad y estado del pago de impuestos. Negociación del contrato de arras (contrato privado previo a la compra del contrato en el que se establecen las condiciones de compra y en las que se abona el 10% del valor de la propiedad. Acompañamiento al notario.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Selección de viviendas',
        //             'Envío de agenda',
        //             'Visita a las viviendas',
        //             'Entrega reserva/fianza',
        //             'Firma del contrato'
        //         ],
        //         tareas_en: [
        //             'House selection',
        //             'Sending agenda',
        //             'House visit',
        //             'Delivery of reservation/security deposit',
        //             'Contract signature'
        //         ]
        //     },
        //     {
        //         id: 15,
        //         order: 29,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Comunicación de desplazamiento',
        //         info: 'Información y asesoramiento sobre el procedimiento de comunicación del desplazamiento a la oficina de empleo. Preparación de expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 16,
        //         order: 19,
        //         estado: '',
        //         tipo: 'Otros',
        //         nombre: 'Trámites administrativos',
        //         info: 'Te informaremos sobre los trámites administrativos necesarios para estar completamente instalado en tu nuevo país.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //     },
        //     {
        //         id: 17,
        //         order: 13,
        //         estado: '',
        //         tipo: 'Establecimiento',
        //         nombre: 'Apertura de cuenta bancaria',
        //         sub: ['Con acompañamiento', 'Sin acompañamiento'],
        //         show_sub: false,
        //         info: 'Tu consejera encontrará la sucursal más conveniente del banco elegido y te ayudará con la presentación de la documentación para obtener tarjetas de crédito, etc y le acompañaremos a la sucursal bancaria.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Hecho'],
        //         tareas_en: ['Done']
        //     },
        //     // {
        //     //     id: 18,
        //     //     estado: '',
        //     //     tipo: 'Inmigración',
        //     //     nombre: 'Des-registro Autoridades Españolas',
        //     //
        //     //     info: '',
        //     //     otros_servicios: 'inicio',
        //     //     gestion: {
        //     //         inicio: null,
        //     //         fin: null,
        //     //         notas: null,
        //     //         finalizada_por: null
        //     //     },
        //     //     tareas: ['Hecho']
        //     // },
        //     {
        //         id: 19,
        //         order: 42,
        //         estado: '',
        //         tipo: 'Coches',
        //         nombre: 'Des-registro coche',
        //         info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente y presentación ante las autoridades',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención doc. presentación',
        //             'Resolución',
        //             'Acompañamiento trámites'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining presentation document',
        //             'Resolution',
        //             'Accompanying procedures'
        //         ]
        //     },
        //     {
        //         id: 20,
        //         order: 43,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Des-registro empadronamiento',
        //         info: 'Información y asesoramiento sobre el trámite, requisitos y documentos. ',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Obtención resolucion'
        //         ],
        //         tareas_en: ['Obtaining resolution']
        //     },
        //     {
        //         id: 21,
        //         order: 44,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Des-registro certificado de registro UE',
        //         info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Presentación ante las autoridades.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 22,
        //         order: 21,
        //         estado: '',
        //         tipo: 'Establecimiento',
        //         nombre: 'Empadronamiento',
        //         info: 'Información y asesoramiento sobre el trámite, requisitos y documentos. Acompañamiento al Ayuntamiento',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 23,
        //         order: 46,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Emprendedores - Renovacion - Principal',
        //         info: '',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     // {
        //     //     id: 24,
        //     //     estado: '',
        //     //     tipo: 'Vivienda',
        //     //     nombre: 'Firma de contrato',
        //     //
        //     //     info: '',
        //     //     otros_servicios: 'fin',
        //     //     gestion: {
        //     //         inicio: null,
        //     //         fin: null,
        //     //         notas: null,
        //     //         finalizada_por: null
        //     //     },
        //     //     tareas: []
        //     // },
        //     {
        //         id: 25,
        //         order: 16,
        //         estado: '',
        //         tipo: 'Otros',
        //         nombre: 'Gestión de mudanzas',
        //         info: 'Contactamos con la empresa de mudanzas, confirmamos el embalaje, la recogida y fechas de entrega. Coordinación de todo el proceso.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Puesta en contacto proveedor', 'Organización de entrevistas'],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Organization of interviews'
        //         ]
        //     },
        //     {
        //         id: 26,
        //         order: 35,
        //         estado: '',
        //         tipo: 'Coches',
        //         nombre: 'Legalización vehículo',
        //         info: 'Información complete sobre el proceso, preparación del expediente y presentación de la solicitud. Coordinación de la cita con el centro de ITV así como en el taller si es necesario. Obtención y envío de las nuevas matrículas.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención documento presentación',
        //             'Resolución',
        //             'Acomp. trámites'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining presentation document',
        //             'Resolution',
        //             'Accompanying procedures'
        //         ]
        //     },
        //     {
        //         id: 27,
        //         order: 23,
        //         estado: '',
        //         tipo: 'Establecimiento',
        //         nombre: 'Inclusión de beneficiarios Seguridad Social',
        //         info: 'Este procedimiento es obligatorio previo a la solicitud de las tarjetas sanitarias. Información sobre el trámite, preparación del expediente, presentación de la solicitud y recogida de la resolución.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 28,
        //         order: 10,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Inventario',
        //         info: 'Inspección de la vivienda, verificación de las condiciones de la misma y realización de inventario fotográfico.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Hecho'
        //         ],
        //         tareas_en: [
        //             'Done'
        //         ]
        //     },
        //     {
        //         id: 29,
        //         order: 2,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Look&see trip',
        //         sub: ['1 día', '1/2 día'],
        //         show_sub: false,
        //         info: 'Tu consejero te explicará las condiciones de vida, zonas residenciales y características de alquiler en un viaje de familiarización antes de tu traslado. Información sobre centros de salud, administraciones, etc.  Te enseñaremos un máximo de 4 ejemplos de vivienda.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Envío de agenda'
        //         ],
        //         tareas_en: [
        //             'Sending agenda'
        //         ]
        //     },
        //     {
        //         id: 30,
        //         order: 18,
        //         estado: '',
        //         tipo: 'Otros',
        //         nombre: 'Apoyo adicional acompañado - 1dia',
        //         info: 'Acompañamiento durante un día o  dos medios días (de 10:00 a 13:30) a un tour por el centro de la ciudad. El tiempo de su consejero puede utilizarse para cubrir cualquier necesidad específica que tengas. Comida en uno de los restaurantes de moda de Madrid.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Envío de agenda'
        //         ],
        //         tareas_en: [
        //             'Sending agenda'
        //         ]
        //     },
        //     {
        //         id: 31,
        //         order: 17,
        //         estado: '',
        //         tipo: 'Otros',
        //         nombre: 'Mascotas',
        //         info: 'Organizamos una cita con un veterinario local, gestionamos servicios de paseadores de perros y buscamos residencia para cuando estés fuera.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Organización de entrevistas', 'Soporte adicional al servicio'],
        //         tareas_en: [
        //             'Organization of interviews',
        //             'Additional service support'
        //         ]
        //     },
        //     {
        //         id: 32,
        //         order: 8,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Negociación de contrato',
        //         info: 'Asistencia durante la negociación. Revisión y adaptación del contrato de arrendamiento. Acompañamiento durante la firma y la entrega de llaves.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['hecho'],
        //         tareas_en: [
        //             'Done'
        //         ]
        //     },
        //     {
        //         id: 33,
        //         order: 27,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Certificado NIE',
        //         info: 'Información sobre el trámite, preparación del expediente, presentación de la solicitud y recogida de la resolución.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Puesta en contacto con proveedor', 'Obtención de resolución'],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 34,
        //         order: 26,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Nº Seg. Social (NAF)',
        //         info: 'Información sobre el procedimiento, preparación del expediente, presentación de la solicitud y recogida de la resolución.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Puesta en contacto con proveedor', 'Obtención de resolución'],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 35,
        //         order: 36,
        //         estado: '',
        //         tipo: 'Otros',
        //         nombre: 'Onboarding support',
        //         info: 'Apoyo a los nuevos empleados que se incorporan a la compañía y actuar como punto de contacto para cualquier consulta que pueda tener antes de la fecha de inicio y durante el periodo de adaptación. Coordinación y configuración del escritorio, ordenador y otros recursos.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Follow up', 'Contacto departamento IT', 'Entrevista personal - empleado'],
        //         tareas_en: [
        //             'Follow up',
        //             'IT department contact',
        //             'Personal interview - employee'
        //         ]
        //     },
        //     {
        //         id: 36,
        //         order: 1,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Paquete de bienvenida',
        //         info: 'carta/email de presentación, información sobre la ciudad de destino. Estudio de las necesidades personales y/o de la familia y llamada de contacto si fuera necesario. Acceso a la Intranet de Steps Relocation.',
        //         otros_servicios: false,
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //     },
        //     {
        //         id: 37,
        //         order: 32,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Programa Visar',
        //         info: '',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Puesta en contacto con proveedor', 'Obtención de resolución'],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 38,
        //         order: 37,
        //         estado: '',
        //         tipo: 'Fiscal',
        //         nombre: 'Régimen tributario especial expatriados',
        //         info: 'Revisión del caso. Explicación al empleado de las características especificaciones de este régimen y las obligaciones formales. Registro fiscal. Notificación de la opción por el régimen especial de acuerdo al modelo 149. Preparación del expediente.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Puesta en contacto con proveedor', 'Obtención de resolución'],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 39,
        //         order: 25,
        //         estado: '',
        //         tipo: 'Establecimiento',
        //         nombre: 'Registro Consulado/Embajada',
        //         info: '',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['hecho'],
        //         tareas_en: [
        //             'Done'
        //         ]
        //     },
        //     {
        //         id: 40,
        //         order: 45,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Renovación certificado de Registro UE – Principal',
        //         info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades. Acompañamiento a la presentación. Este certificado incluye el número NIE.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Puesta en contacto con proveedor', 'Obtención de resolución'],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 41,
        //         order: 6,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Segunda búsqueda de vivienda',
        //         info: 'Preparación de la búsqueda. Te enviaremos por email todas las opciones disponibles en el mercado que cuadren con los requerimientos descritos en el cuestionario (siempre que sean realistas). Un día de visitas acompañadas a unas 8 opciones pre-seleccionadas por ti. Las visitas serán de lunes a viernes en horario laboral.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Selección de viviendas',
        //             'Envío de agenda',
        //             'Visita a las viviendas',
        //             'Entrega reserva/fianza',
        //             'Firma del contrato',
        //             'Check-in / inventario'
        //         ],
        //         tareas_en: [
        //             'House selection',
        //             'Sending agenda',
        //             'House visit',
        //             'Delivery of reservation/security deposit',
        //             'Contract signing',
        //             'Check-in / Inventory'
        //         ]
        //     },
        //     {
        //         id: 42,
        //         order: 40,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Servicio cierre',
        //         info: '',
        //         otros_servicios: 'inicio',
        //         servicio: {
        //             fecha_envio_notificacion: null,
        //             fecha_check_out: null,
        //             devolucion_fianza: null,
        //             baja_luz: null,
        //             baja_agua: null,
        //             baja_gas: null,
        //             baja_internet: null
        //         },
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Suministro luz', 
        //             'Suministro gas', 
        //             'Suministro agua', 
        //             'Suministro internet', 
        //             'Comprobación de suministros', 
        //             'Envío comunicación formal', 
        //             'Check out', 
        //             'Fecha devolución fianza', 
        //             'Obtención resolución'
        //         ],
        //         tareas_en: [
        //             'Electricity supply',
        //             'Gas supply',
        //             'Water supply',
        //             'Internet supply',
        //             'Checking supplies',
        //             'Sending formal communication',
        //             'Check out',
        //             'Return of security deposit date',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 43,
        //         order: 11,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Suministros',
        //         info: 'Conexión de suministros de agua, electricidad, gas, internet y teléfono. Organización de domiciliaciones bancarias.',
        //         otros_servicios: false,
        //         tareas: [
        //             'Suministro luz',
        //             'Suministro gas',
        //             'Suministro agua',
        //             'Suministro internet',
        //         ],
        //         tareas_en: [
        //             'Subscribe electricity supply',
        //             'Subscribe gas supply',
        //             'Subscribe water supply',
        //             'Subscribe internet'
        //         ],
        //         gestion: {
        //             internet: {
        //                 servicio: {
        //                     compañia: null,
        //                     telefono_asociado: null,
        //                     num_ref: null,
        //                     fecha_alta: null,
        //                     comprobacion: null,
        //                     otros: null
        //                 },
        //                 gestion: {
        //                     inicio: null,
        //                     fin: null,
        //                     notas: null,
        //                     finalizada_por: null,
        //                     estado: ''
        //                 },
        //             },
        //             agua: {
        //                 servicio: {
        //                     compañia: null,
        //                     telefono: null,
        //                     num_contrato: null,
        //                     fecha_alta: null,
        //                     titular: null,
        //                     dni: null,
        //                     lectura: null,
        //                     comprobacion: null,
        //                     otros: null
        //                 },
        //                 gestion: {
        //                     inicio: null,
        //                     fin: null,
        //                     notas: null,
        //                     finalizada_por: null,
        //                     estado: ''
        //                 },
        //             },
        //             luz: {
        //                 servicio: {
        //                     compañia: null,
        //                     telefono: null,
        //                     num_contrato: null,
        //                     fecha_alta: null,
        //                     titular: null,
        //                     dni: null,
        //                     lectura: null,
        //                     comprobacion: null,
        //                     otros: null
        //                 },
        //                 gestion: {
        //                     inicio: null,
        //                     fin: null,
        //                     notas: null,
        //                     finalizada_por: null,
        //                     estado: ''
        //                 },
        //             },
        //             gas: {
        //                 servicio: {
        //                     compañia: null,
        //                     telefono: null,
        //                     num_contrato: null,
        //                     fecha_alta: null,
        //                     titular: null,
        //                     dni: null,
        //                     lectura: null,
        //                     comprobacion: null,
        //                     otros: null
        //                 },
        //                 gestion: {
        //                     inicio: null,
        //                     fin: null,
        //                     notas: null,
        //                     finalizada_por: null,
        //                     estado: ''
        //                 },
        //             },
        //             otros: ''
        //         },
        //     },
        //     {
        //         id: 44,
        //         order: 12,
        //         estado: '',
        //         tipo: 'Vivienda',
        //         nombre: 'Suministros comprobación',
        //         info: '',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Hecho'],
        //         tareas_en: [
        //             'Done'
        //         ]
        //     },
        //     {
        //         id: 45,
        //         order: 24,
        //         estado: '',
        //         tipo: 'Establecimiento',
        //         nombre: 'Tarjetas sanitarias',
        //         info: 'Información sobre el trámite y acompañamiento personal al Centro de Salud.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Hecho'],
        //         tareas_en: [
        //             'Done'
        //         ]
        //     },
        //     {
        //         id: 46,
        //         order: 22,
        //         estado: '',
        //         tipo: 'Establecimiento',
        //         nombre: 'Zona SER',
        //         info: 'Este trámite permite aparcar en la calle, dentro de los límites de tu barrio. Información sobre el procedimiento y acompañamiento a la solicitud.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Hecho'],
        //         tareas_en: [
        //             'Done'
        //         ]
        //     },
        //     {
        //         id: 47,
        //         order: 33,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Variación de datos en la Seguridad Social',
        //         info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Hecho'],
        //         tareas_en: [
        //             'Done'
        //         ]
        //     },
        //     {   
        //         id: 48,
        //         order: 30,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Aut. residencia - altamente cualificados - dependiente',
        //         sub: [
        //             'Con visado', 
        //             'Sin visado', 
        //         ],
        //         show_sub: false,
        //         info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida tras la llegada del extranjero a España.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor', 'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {   
        //         id: 49,
        //         order: 31,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Aut. residencia - emprendedores - dependiente',
        //         sub: [
        //             'Con visado', 
        //             'Sin visado', 
        //         ],
        //         show_sub: false,
        //         info: 'Información y asesoramiento sobre el procedimiento. Preparación del expediente, presentación de la solicitud ante las autoridades, acompañamiento a la solicitud de la tarjeta de residencia así como a la recogida tras la llegada del extranjero a España.',
        //         otros_servicios: 'fin',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor', 'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 50,
        //         order: 28,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Certificado de Registro UE - Dependiente',
        //         info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades. Acompañamiento a la presentación. Este certificado incluye el número NIE.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 51,
        //         order: 46,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Emprendedores - Renovacion - Dependiente',
        //         info: '',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: [
        //             'Puesta en contacto con proveedor',
        //             'Obtención de resolución'
        //         ],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        //     {
        //         id: 52,
        //         order: 45,
        //         estado: '',
        //         tipo: 'Inmigración',
        //         nombre: 'Renovación certificado de Registro UE – Dependiente',
        //         info: 'Información y asesoramiento sobre el procedimiento, requisitos y documentos. Preparación del expediente. Comunicación con la compañía, el ciudadano extranjero y las autoridades. Acompañamiento a la presentación. Este certificado incluye el número NIE.',
        //         otros_servicios: 'inicio',
        //         gestion: {
        //             inicio: null,
        //             fin: null,
        //             notas: null,
        //             finalizada_por: null
        //         },
        //         tareas: ['Puesta en contacto con proveedor', 'Obtención de resolución'],
        //         tareas_en: [
        //             'Get in touch with the supplier',
        //             'Obtaining resolution'
        //         ]
        //     },
        // ],
        viviendas: [],
        empresas: [],
        eventos: [
            {
                id: 1,
                name: '',
                servicio: 1,
                creada_por: 1,
                trl: 1,
                start: '1970-05-22 14:00',
                notas: '',
                mostrar_rrhh: true,
                notificar_email: false,
                open: false
            }
        ],
        sectores: [
            {id: 1, nombre: 'Aeronáutico'},
            {id: 2, nombre: 'Alimentación y bebidas'},
            {id: 3, nombre: 'Automoción'},
            {id: 4, nombre: 'Comercio'},
            {id: 5, nombre: 'Comunicación'},
            {id: 6, nombre: 'Consultoría'},
            {id: 7, nombre: 'Energía'},
            {id: 8, nombre: 'Financiero'},
            {id: 9, nombre: 'Gubernamental'},
            {id: 10, nombre: 'I+D'},
            {id: 11, nombre: 'Industria'},
            {id: 12, nombre: 'Infraestructuras'},
            {id: 13, nombre: 'Logística'},
            {id: 14, nombre: 'Ocio'},
            {id: 15, nombre: 'Sanitario'},
            {id: 16, nombre: 'Telecomunicaciones'}
        ],
        archivos: [],
        started_point: null,
        idiomaGlobal: localStorage.getItem('lang') || undefined
    },
    mutations: {
        SET_USUARIO_LOGGEADO(state, usuario) {
			state.usuario_loggeado = usuario;
		},
        SET_USUARIOS(state, usuarios) {
			state.usuarios = usuarios;
		},
        SET_USUARIOSFINALIZADOS(state, usuarios_finalizados) {
			state.usuarios_finalizados = usuarios_finalizados;
		},
        SET_VIVIENDAS(state, viviendas) {
			state.viviendas = viviendas;
		},
        SET_EMPRESAS(state, empresas) {
			state.empresas = empresas;
		},
        SET_EVENTOS(state, eventos) {
			state.eventos = eventos;
		},
        SET_TOKEN(state, token) {
			state.token = token;
		},
        SET_LOADING(state, loading) {
			state.loading = loading;
		},
        SET_ARCHIVOS(state, archivos) {
			state.archivos = archivos;
		},
        SET_STARTED_POINT(state, started_point) {
            state.started_point = started_point;
        },
        SET_IDIOMA(state, lang) {
            localStorage.setItem('lang', lang);
			state.idiomaGlobal = lang;
        },
        SET_SERVICIO(state, servicio) {
            state.servicios = [servicio];
        },
        SET_SERVICIOS(state, servicios) {
            state.servicios = servicios;
        },
        SET_PAQUETES(state, paquetes) {
            state.paquetes = paquetes;
        },
    },
    actions: {
        setLoading({commit}, data) {
            commit('SET_LOADING', data);
        },
        setUsuarioLoggeado({commit, state}, data) {
            const iterate = (obj) => {
                if (obj && typeof obj !== "undefined") {
                    Object.keys(obj).forEach(key => {
                        if (typeof obj[key] === 'object') {
                            iterate(obj[key])
                        } else {
                            obj[key] === 'null' ? obj[key] = null : ''
                        }
                    })
                }
            }
            iterate(data);

            let usuario;
            if (data) {
                let type;
                data.type === 'admin' ? type = 'Admin' : data.type === 'cons' ? type = 'Consejera' : data.type === 'rrhh' ? type = 'RRHH' :  data.type === 'legal' ? type = 'Legal' : type = 'TRL';
                if (type != 'TRL') {
                    usuario = {
                        id: parseInt(data.id),
                        enc_id: data.encrypted_id,
                        tipo: type,
                        creado_por: null,
                        finalizado_por: null,
                        idioma_seleccionado: type === 'Admin' || type === 'Consejera' ? 'es' : data.preferred_language === 'es' || data.preferred_language === 'en' ? data.preferred_language : null,
                        personales: {
                            nombre: data.name,
                            apellidos: data.surnames,
                            empresa: data.company ? data.company.id : null,
                            foto: data.photo ? data.photo : null,
                            ciudad: null,
                            rrhh: null,
                            idiomas: data.languages && data.languages != 'null' ? data.languages.split(",").map(String) : '',
                            nacionalidad: null,
                            direccion: null,
                            pasaporte: null,
                            pasaporte_imagen: null
                        },
                        contacto: {
                            email_personal: data.email,
                            telefono: data.phone_number,
                            email_profesional: data.email,
                            email: null
                        },
                        consejera: {
                            id: null,
                            asignadas: null
                        }
                    };
                } else {
                    let servicios = [];
                    data.t_rluser_services.forEach(ser => {
                        let servicio;
                        if (ser.data.id) {
                            servicio = ser.data;
                        } else {
                            servicio = state.servicios.find(se => se.id == ser.service_id);
                            if (ser.data.nombre_sub) servicio.nombre_sub = ser.data.nombre_sub;
                            if (ser.data.sub_index) servicio.sub_index = ser.data.sub_index;
                        }
                        if (!servicio.oculto) servicios.push(servicio);
                    });
                    if (servicios.length) servicios.sort((a, b) => a.order - b.order);

                    let paquetes = [];
                    data.t_rluser_packs.forEach(paq => paquetes.push(paq.pack_id));

                    let hijos = [];
                    let colegios = {};
                    if (data.t_rluser_school_preferences) {
                        data.t_rluser_school_preferences.children.forEach(hi => {
                            let hijo = {
                                nombre: hi.name,
                                apellidos: hi.surnames,
                                nacimiento: hi.birthdate,
                                sexo: hi.gendre,
                                curso: hi.school_year,
                                idiomas: hi.languages
                            }
                            hijos.push(hijo);
                        });
                        colegios = {
                            numero_hijos: data.t_rluser_school_preferences.children.length,
                            hijos: hijos,
                            preferencias: {
                                tipo_colegio: {
                                    concertado: data.t_rluser_school_preferences.type_concertado,
                                    privado: data.t_rluser_school_preferences.type_privado,
                                    publico: data.t_rluser_school_preferences.type_publico
                                },
                                tipo_educacion: {
                                    alemana: data.t_rluser_school_preferences.education_german,
                                    americana: data.t_rluser_school_preferences.education_american,
                                    britanica: data.t_rluser_school_preferences.education_british,
                                    española: data.t_rluser_school_preferences.education_spanish,
                                    francesa: data.t_rluser_school_preferences.education_french,
                                    otras: data.t_rluser_school_preferences.education_other
                                }
                            }
                        }
                    } else {
                        colegios = {
                            numero_hijos: 1,
                            hijos: [],
                            preferencias: {
                                tipo_colegio: {
                                    concertado: false,
                                    privado: false,
                                    publico: false
                                },
                                tipo_educacion: {
                                    alemana: false,
                                    americana: false,
                                    britanica: false,
                                    española: false,
                                    francesa: false,
                                    otras: false
                                }
                            }
                        }
                    }

                    let conyuge;
                    if (data.t_rluser_spouse) {
                        conyuge = {
                            id: data.t_rluser_spouse.id,
                            nombre: data.t_rluser_spouse.name,
                            apellidos: data.t_rluser_spouse.surnames,
                            nacionalidad: data.t_rluser_spouse.nationality,
                            telefono: data.t_rluser_spouse.phone_number,
                            email: data.t_rluser_spouse.email,
                            pasaporte_imagen: !data.t_rluser_spouse.identity_id_photo || data.t_rluser_spouse.identity_id_photo === 'https://ws.stepsrelocation-panel.com/file/view/siip/' ? null : data.t_rluser_spouse.identity_id_photo
                        }
                    } else {conyuge = false;}

                    let consejeras = [];
                    if (data.t_rluser_cons[0]) {
                        data.t_rluser_cons.forEach(cn => {
                            consejeras.push(cn);
                        });
                    }

                    function toDate(timestamp) {
                        return timestamp ? moment(parseInt(timestamp)).format('YYYY-MM-DD') : null;
                    }
                    let fechas_disponible = [];
                    let rrhhs = [];
                    if (data.t_rluser_rrhhs.length) {
                        data.t_rluser_rrhhs.forEach(rh => {
                            rrhhs.push(rh.id);
                        });
                    }
                    data.t_rluser_relocation_preferences && data.t_rluser_relocation_preferences.relocation_visit_date ? data.t_rluser_relocation_preferences.relocation_visit_date.forEach(fecha => fechas_disponible.push(toDate(fecha))) : '';
                    usuario = {
                        id: parseInt(data.id),
                        enc_id: data.encrypted_id,
                        tipo: type,
                        estado: data.trl_status,
                        creado_por: null,
                        finalizado_por: null,
                        politica_privacidad: data.privacy_policy && data.spouse_privacy_policy,
                        idioma_seleccionado: data.preferred_language === 'es' || data.preferred_language === 'en' ? data.preferred_language : null,
                        personales: {
                            nombre: data.name,
                            apellidos: data.surnames,
                            empresa: data.company ? data.company.id : null,
                            foto: data.photo || null,
                            ciudad: data.relocation_city,
                            rrhh: data.t_rluser_rrhhs.length ? rrhhs : null,
                            idioma: null,
                            nacionalidad: data.nationality,
                            direccion: data.current_address,
                            pasaporte: data.identity_id,
                            pasaporte_imagen: data.identity_id_photo || null
                        },
                        datos_bancarios: {
                            cuenta: data.iban || '',
                            banco: data.bank || ''
                        },
                        contacto: {
                            email_profesional: data.email,
                            email: data.secondary_email,
                            email_personal: data.secondary_email,
                            telefono: data.phone_number
                        },
                        conyuge: conyuge,
                        consejera: {
                            id: consejeras.find(cons => cons.status == 3) ? consejeras.find(cons => cons.status == 3).id : 1,
                            asignadas: consejeras
                        },
                        preferencias: {
                            traslado: {
                                fecha_traslado: data.t_rluser_relocation_preferences ? toDate(data.t_rluser_relocation_preferences.relocation_date) : null,
                                fecha_entrada: data.t_rluser_relocation_preferences ? toDate(data.t_rluser_relocation_preferences.desired_relocation_date) : null,
                                fecha_disponible: fechas_disponible,
                                tiempo_alquiler: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.relocation_rental_duration : null,
                                presupuesto: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.relocation_budget : null
                            },
                            vivienda: {
                                tipo: {
                                    piso: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_type_piso : '',
                                    casa: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_type_casa : ''
                                },
                                metros: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_meters : '',
                                habitaciones: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_rooms_number : '',
                                estado: {
                                    amueblada: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_state_amueblada : '',
                                    sin_amueblar: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_state_noamueblada : ''
                                },
                                estilo: {
                                    clasico: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_style_clasico : '',
                                    moderno: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_type_moderno : ''
                                },
                                caracteristicas: {
                                    piscina: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_pool : '',
                                    jardin: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_garden : '',
                                    terraza: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_terrace : '',
                                    zonas_infantiles: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_children_area : '',
                                    habitacion_servicio: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_room_service : ''
                                },
                                otras_preferencias: {
                                    centro: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_city_center : '',
                                    afueras: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_city_outskirts : '',
                                    cerca_oficina: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_near_office : '',
                                    cerca_colegio: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_near_school : '',
                                    cerca_metro: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_near_subway : '',
                                    soleado: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_sunny : '',
                                    zona_tranquila: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_quiet_zone : ''
                                },
                                areas_residenciales: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_residential_areas : '',
                                garaje: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_parking_spaces ? true : false : null,
                                coches: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_parking_spaces : '',
                                coches_en_presupuesto: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_parking_spaces_in_budget : '',
                                mascota: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_pet_allowed : '',
                                mascota_tipo: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_pet_type : '',
                                otros: data.t_rluser_relocation_preferences ? data.t_rluser_relocation_preferences.house_others : ''
                            },
                            colegios: colegios
                        },
                        servicio_vivienda: {
                            viviendas_preseleccionadas: data.t_rluser_home_contract ? data.t_rluser_home_contract.home_preselected_homes : [],
                            viviendas_preseleccionadas_links: [],
                            gestion: {
                                fecha_aviso: null,
                                fecha_primer_contacto: null,
                                fecha_llegada: null,
                                fecha_visita: null,
                                fecha_busqueda_colegios: null,
                                fecha_firma_contrato: null,
                                fecha_entrada: null,
                                notas: '',
                                finalizada_por: null
                            },
                            datos_vivienda: {
                                direccion: null,
                                referencia_catastral: null,
                                propietario_nombre: null,
                                propietario_dni: null,
                                propietario_direccion: null,
                                propietario_telefono: null,
                                propietario_email: null,
                                iban: null,
                                encargado_nombre: null,
                                encargado_telefono: null,
                                encargado_email: null,
                                inmobiliaria: null,
                                inmobiliaria_nombre: null,
                                inmobiliaria_telefono: null,
                                inmobiliaria_email: null,
                            },
                            contrato_alquiler: {
                                fecha_inicio: null,
                                duracion_obligatoria: null,
                                preaviso: null,
                                renta: null,
                                contrato_nombre: null,
                                contrato_firmado: null,
                                fianza: null,
                                fianza_pagador: null,
                                deposito: null,
                                deposito_pagador: null,
                                comision: null,
                                comision_pagador: null,
                                inventario: null,
                                contrato_prorrogable: null,
                                presencia_checkin: {
                                    inquilino: false,
                                    consejera: false,
                                    agente: false,
                                    propietario: false
                                }
                            },
                            seguro_hogar: {
                                propietario_nombre: null,
                                propietario_telefono: null,
                                propietario_poliza: null,
                                propietario_email: null,
                                inquilino_nombre: null,
                                inquilino_telefono: null,
                                inquilino_poliza: null,
                                inquilino_email: null
                            }
                        },
                        paquetes: paquetes,
                        servicios: servicios,
                    }
                }
            } else {usuario = {}}
			commit('SET_USUARIO_LOGGEADO', usuario);
		},
        setUsuarios({commit, state}, data) {
            let usuarios = [];
            let rrhhs = [];
            data.forEach(usu => {
                if (usu.name) {
                    const iterate = (obj) => {
                        if (obj && typeof obj !== "undefined") {
                            Object.keys(obj).forEach(key => {
                                if (typeof obj[key] === 'object') {
                                    iterate(obj[key])
                                } else {
                                    obj[key] === 'null' ? obj[key] = null : ''
                                }
                            })
                        }
                    }
                    iterate(usu);
                    let tipo; 

                    usu.type === 'admin' ? tipo = 'Admin' : usu.type === 'cons' ? tipo = 'Consejera' : usu.type === 'rrhh' ? tipo = 'RRHH' : usu.type === 'legal' ? tipo = 'Legal' : tipo = 'TRL';

                    let trl_rrhhs = [];
                    if (tipo === 'TRL' && usu.rrhh_users && usu.rrhh_users.length) {
                        usu.rrhh_users.forEach(rh => {
                            trl_rrhhs.push(rh.id);
                        });
                    }
                    let usuario = {
                        id: parseInt(usu.id),
                        enc_id: usu.encrypted_id,
                        estado: usu.trl_status,
                        tipo: tipo,
                        creado_por: null,
                        finalizado_por: null,
                        politica_privacidad: true,
                        politica_privacidad_conyuge: false,
                        personales: {
                            nombre: usu.name,
                            apellidos: usu.surnames,
                            empresa: parseInt(usu.company_id),
                            foto: usu.photo || null,
                            ciudad: null,
                            rrhh: tipo === 'TRL' && usu.rrhh_users && usu.rrhh_users.length ? trl_rrhhs : null,
                            idiomas: usu.languages && usu.languages != 'null' ? usu.languages.split(",").map(String) : '',
                            nacionalidad: null,
                            idioma_preferido: data.preferred_language === 'es' ? 'Español' : data.preferred_language === 'en' ? 'Inglés' : '',
                            direccion: null,
                            pasaporte: null,
                            pasaporte_imagen: null
                        },
                        datos_bancarios: {
                            cuenta: null,
                            banco: null
                        },
                        contacto: {
                            email_personal: null,
                            email_profesional: usu.email,
                            email: null,
                            telefono: usu.phone_number
                        },
                        consejera: {
                            id: tipo === 'TRL' ? usu.cons_id || 1 : null,
                            foto: usu.cons_photo || null,
                            nombre: usu.cons_name || null,
                            apellidos: usu.cons_surnames|| null,
                            asignadas: []
                        },
                        preferencias: {
                            traslado: {
                                fecha_traslado: null,
                                fecha_entrada: null,
                                fecha_disponible: null,
                                tiempo_alquiler: null,
                                presupuesto: null
                            },
                            vivienda: {
                                tipo: {
                                    piso: true,
                                    casa: false
                                },
                                metros: null,
                                habitaciones: null,
                                estado: {
                                    amueblada: true,
                                    sin_amueblar: true
                                },
                                estilo: {
                                    clasico: false,
                                    moderno: true
                                },
                                caracteristicas: {
                                    piscina: true,
                                    jardin: true,
                                    terraza: true,
                                    zonas_infantiles: true,
                                    habitacion_servicio: true
                                },
                                otras_preferencias: {
                                    centro: true,
                                    afueras: true,
                                    cerca_oficina: true,
                                    cerca_colegio: true,
                                    cerca_metro: true,
                                    soleado: true,
                                    zona_tranquila: true
                                },
                                areas_residenciales: null,
                                garaje: true,
                                coches: 2,
                                coches_en_presupuesto: true,
                                mascota: true,
                                mascota_tipo: 'gato',
                                otros: null
                            },
                            colegios: {
                                numero_hijos: 1,
                                hijos: [
                                    {
                                        nombre: 'Manolito',
                                        apellidos: null,
                                        nacimiento: null,
                                        sexo: null,
                                        curso: null,
                                        idiomas: null
                                    },
                                ],
                                preferencias: {
                                    tipo_colegio: {
                                        concertado: true,
                                        privado: true,
                                        publico: true
                                    },
                                    tipo_educacion: {
                                        alemana: true,
                                        americana: true,
                                        britanica: true,
                                        española: true,
                                        francesa: true,
                                        otras: true
                                    }
                                }
                            }
                        },
                        servicio_vivienda: {
                            viviendas_preseleccionadas: [],
                            viviendas_preseleccionadas_links: [],
                            gestion: {
                                fecha_aviso: null,
                                fecha_primer_contacto: null,
                                fecha_llegada: null,
                                fecha_visita: null,
                                fecha_busqueda_colegios: null,
                                fecha_firma_contrato: null,
                                fecha_entrada: null,
                                notas: '',
                                finalizada_por: null
                            },
                            datos_vivienda: {
                                direccion: null,
                                referencia_catastral: null,
                                propietario_nombre: null,
                                propietario_dni: null,
                                propietario_direccion: null,
                                propietario_telefono: null,
                                propietario_email: null,
                                iban: null,
                                encargado_nombre: null,
                                encargado_telefono: null,
                                encargado_email: null,
                                inmobiliaria: null,
                                inmobiliaria_nombre: null,
                                inmobiliaria_telefono: null,
                                inmobiliaria_email: null,
                            },
                            contrato_alquiler: {
                                fecha_inicio: null,
                                duracion_obligatoria: null,
                                preaviso: null,
                                renta: null,
                                contrato_nombre: null,
                                contrato_firmado: null,
                                fianza: null,
                                fianza_pagador: null,
                                deposito: null,
                                deposito_pagador: null,
                                comision: null,
                                comision_pagador: null,
                                inventario: null,
                                contrato_prorrogable: null,
                                presencia_checkin: {
                                    inquilino: false,
                                    consejera: false,
                                    agente: false,
                                    propietario: false
                                }
                            },
                            seguro_hogar: {
                                propietario_nombre: null,
                                propietario_telefono: null,
                                propietario_poliza: null,
                                propietario_email: null,
                                inquilino_nombre: null,
                                inquilino_telefono: null,
                                inquilino_poliza: null,
                                inquilino_email: null
                            }
                        },
                        paquetes: [],
                        servicios: usu.services,
                    }
                    if (state.usuario_loggeado.tipo === 'Admin' || usu.type != 'legal') usuarios.push(usuario);
                    if (state.usuario_loggeado.tipo === 'Consejera') {
                        if (usu.rrhh_users) {
                            let rrhh;
                            usu.rrhh_users.forEach(rh => {
                                rrhh = {
                                    id: parseInt(rh.id),
                                    enc_id: rh.encrypted_id,
                                    tipo: 'RRHH',
                                    personales: {
                                        nombre: rh.name,
                                        apellidos: rh.surnames,
                                        foto: rh.photo || null,
                                        empresa: parseInt(rh.company_id),
                                        ciudad: null,
                                        rrhh: null,
                                        idiomas: null,
                                        nacionalidad: null,
                                        direccion: null,
                                        pasaporte: null,
                                        pasaporte_imagen: null
                                    },
                                    contacto: {
                                        email_profesional: rh.email,
                                        email_personal: rh.email,
                                        email: null,
                                        telefono: null
                                    },
                                    creado_por: null,
                                    finalizado_por: null,
                                    consejera: {
                                        id: null,
                                        asignadas: null
                                    }
                                }
                                if (!rrhhs.find(rh => rh.id == rrhh.id)) rrhhs.push(rrhh);
                            });
                        }
                    }
                } else if (usu.type != 'legal') {
                    usuarios.push(usu);
                }
            });
            if (!usuarios.find(us => us.id == state.usuario_loggeado.id) && state.usuario_loggeado.tipo != 'RRHH' && state.usuario_loggeado.tipo != 'Legal') {
                usuarios.push(state.usuario_loggeado);
            }
			!rrhhs.length ? commit('SET_USUARIOS', usuarios) : commit('SET_USUARIOS', [...usuarios, ...rrhhs]);
		},
        setUsuariosFinalizados({commit}, data) {
			commit('SET_USUARIOSFINALIZADOS', data);
		},
        setViviendas({commit}, data) {
            let viviendas = [];
            if (data[0].city) {
                data.forEach(viv => {
                    const iterate = (obj) => {
                        if (obj && typeof obj !== "undefined") {
                            Object.keys(obj).forEach(key => {
                                if (typeof obj[key] === 'object') {
                                    iterate(obj[key])
                                } else {
                                    obj[key] === 'null' ? obj[key] = null : ''
                                }
                            })
                        }
                    }
                    iterate(viv);

                    if (viv.active == 1) {
                        let fotos = [];
						if (viv.images) {viv.images.forEach(im => fotos.push(im.filename));}
                        let vivienda = {
                            id: viv.id,
                            creador: viv.creator,
                            nota: null,
                            preseleccionada: false,
                            seleccionada: false,
                            modificado: moment.unix(parseInt(viv.last_update)).format('YYYY-MM-DD HH:mm'),
                            disponibilidad: viv.availability == 1 ? true : false,
                            barrio: viv.district,
                            direccion: viv.address,
                            ciudad: viv.city,
                            descripcion: viv.description == 0 || viv.description == false ? null : viv.description,
                            descripcion_en: viv.description_en == 0 || viv.description_en == false ? null : viv.description_en,
                            foto_principal: viv.images ? viv.images.find(casa => casa.main) ? viv.images.find(casa => casa.main).filename : '' : '',
                            fotos: fotos,
                            mapa: viv.map,
                            tipo: viv.type,
                            precio: parseInt(viv.price),
                            tipo_contrato: viv.contract_type,
                            habitaciones: parseInt(viv.rooms),
                            metros: parseInt(viv.meters),
                            piso: viv.floor,
                            orientacion: viv.orientation,
                            piscina: parseInt(viv.pool),
                            jardin: parseInt(viv.garden),
                            terraza: parseInt(viv.terrace),
                            baños: viv.bathrooms,
                            zonas_infantiles: parseInt(viv.children_areas),
                            habitacion_servicio: parseInt(viv.service_rooom),
                            estado: viv.state,
                            equipamiento: viv.equipment,
                            aire_acondicionado: parseInt(viv.air_conditioning),
                            calefaccion: parseInt(viv.heating),
                            estilo: viv.style,
                            centro: parseInt(viv.downtown),
                            afueras: parseInt(viv.outskirts),
                            cerca_oficina: parseInt(viv.near_office),
                            cerca_colegio: parseInt(viv.near_school),
                            cerca_metro: parseInt(viv.near_subway),
                            soleado: parseInt(viv.sunny),
                            zona_tranquila: parseInt(viv.quiet_zone),
                            garaje: parseInt(viv.has_garage) || false,
                            garaje_plazas: parseInt(viv.parking_spaces) || parseInt(viv.has_garage) ? 1 : 0,
                            garaje_en_presu: viv.parking_spaces_in_budget,
                            mascotas: parseInt(viv.pet_allowed),
                            trastero: parseInt(viv.storage_room),
                            gastos_comunidad: viv.community_expenses,
                            condiciones_alquiler: viv.rental_conditions,
                            info_propietario: {
                                nombre: viv.owner_name,
                                telefono: viv.owner_phone,
                                notas: viv.owner_notes && viv.owner_notes != 'undefined' ? viv.owner_notes : '',
                                email: viv.owner_email
                            }
                        }
                        viviendas.push(vivienda);
                    }
                });
            } else {viviendas = data}
            commit('SET_VIVIENDAS', viviendas)
        },
        setEmpresas({commit}, data) {
            let empresas = [];
            if (data[0].name) {
                data.forEach(emp => {
                    let empresa = {
                        id: emp.id,
                        nombre: emp.name,
                        sector: emp.sector,
                        sector_id: emp.sector_id,
                        cif: emp.vat_number,
                        direccion: emp.office_address[0],
                        logo: emp.logo || 'images/empresa_placeholder.png',
                        direccion_facturacion: emp.billing_address || []
                    };
                    empresas.push(empresa);
                });
            } else {empresas = data}
            commit('SET_EMPRESAS', empresas)
        },
        setEventos({commit, state}, data) {
            let eventos = [];
            if (data.length) {
                data.forEach(ev => {
                    const iterate = (obj) => {
                        if (obj && typeof obj !== "undefined") {
                            Object.keys(obj).forEach(key => {
                                if (typeof obj[key] === 'object') {
                                    iterate(obj[key])
                                } else {
                                    obj[key] === 'null' ? obj[key] = null : ''
                                }
                            })
                        }
                    }
                    iterate(ev);

                    const start = moment(parseInt(ev.start)).format('YYYY-MM-DD HH:mm');
                    let evento = {
                        id: parseInt(ev.id),
                        name: ev.name,
                        servicio: parseInt(ev.service_id),
                        creada_por: parseInt(ev.creator_id),
                        trl: parseInt(ev.trl_id),
                        start: start,
                        notas: ev.notes,
                        mostrar_rrhh: parseInt(ev.visible_rrhh),
                        notificar_email: parseInt(ev.email_notificacion_trl),
                        open: false
                    }
                    if (state.usuario_loggeado.tipo === 'Admin' || state.usuario_loggeado.tipo === 'Consejera') {
                        eventos.push(evento);
                    } else if (parseInt(ev.visible_rrhh)) {
                        eventos.push(evento);
                    } 
                });
            }
            eventos.length ? commit('SET_EVENTOS', eventos) : '';
        },
        addEventos({commit, state}, data) {
            commit('SET_EVENTOS', [...state.eventos, data])
        },
        setToken({commit}, data) {
            commit('SET_TOKEN', data)
        },
        setArchivos({commit}, data) {
            commit('SET_ARCHIVOS', data)
        },
        setStartedPoint({commit}, data) {
            commit('SET_STARTED_POINT', data)
        },
        setIdioma({commit}, data) {
            commit('SET_IDIOMA', data)
        },
        setServicio({commit}, data) {
            commit('SET_SERVICIO', data)
        },
        setServicios({commit, state}, data) {
            //Parseo del listado de servicios recibidos
            let servicios = [];
            data.forEach(service => {
                let servicio = {
                        id: service.id,
                        order: service.weight,
                        estado: '',
                        sub: service[`subservice_${state.idiomaGlobal}`],
                        show_sub: false,
                        tipo: service.service_type.name,
                        nombre: service[`name_${state.idiomaGlobal}`],
                        info: service[`description_${state.idiomaGlobal}`],
                        otros_servicios: service.other_services,
                        gestion: {
                            inicio: null,
                            fin: null,
                            notas: null,
                            finalizada_por: null
                        },
                        tareas: service.tasks[0] || [],
                        tareas_en: service.tasks[1] || [],
                        deleted: service.deleted,
                        original: {
                            id: service.id,
                            nombre_es: service.name_es,
                            nombre_en: service.name_en,
                            descripcion_es: service.description_es,
                            descripcion_en: service.description_en,
                            orden: service.weight,
                            variantes_es: service.subservice_es,
                            variantes_en: service.subservice_en,
                            tareas_es: service.tasks[0] || [],
                            tareas_en: service.tasks[1] || [],
                            custom_service: {},
                            fecha_inicio: service.other_services,
                            categoria: {
                                value: service.service_type.id,
                                text: service.service_type.name
                            }
                        }
                };
                if (service.id === 43) {
                    servicio.gestion = {
                        internet: {
                            servicio: {
                                compañia: null,
                                telefono_asociado: null,
                                num_ref: null,
                                fecha_alta: null,
                                comprobacion: null,
                                otros: null
                            },
                            gestion: {
                                inicio: null,
                                fin: null,
                                notas: null,
                                finalizada_por: null,
                                estado: ''
                            },
                        },
                        agua: {
                            servicio: {
                                compañia: null,
                                telefono: null,
                                num_contrato: null,
                                fecha_alta: null,
                                titular: null,
                                dni: null,
                                lectura: null,
                                comprobacion: null,
                                otros: null
                            },
                            gestion: {
                                inicio: null,
                                fin: null,
                                notas: null,
                                finalizada_por: null,
                                estado: ''
                            },
                        },
                        luz: {
                            servicio: {
                                compañia: null,
                                telefono: null,
                                num_contrato: null,
                                fecha_alta: null,
                                titular: null,
                                dni: null,
                                lectura: null,
                                comprobacion: null,
                                otros: null
                            },
                            gestion: {
                                inicio: null,
                                fin: null,
                                notas: null,
                                finalizada_por: null,
                                estado: ''
                            },
                        },
                        gas: {
                            servicio: {
                                compañia: null,
                                telefono: null,
                                num_contrato: null,
                                fecha_alta: null,
                                titular: null,
                                dni: null,
                                lectura: null,
                                comprobacion: null,
                                otros: null
                            },
                            gestion: {
                                inicio: null,
                                fin: null,
                                notas: null,
                                finalizada_por: null,
                                estado: ''
                            },
                        },
                        otros: ''
                    }
                    servicio.tareas = ["Alta", "Comprobación"],
                    servicio.tareas_en = ["Alta", "Comprobación"]
                } else if (service.id === 42) {
                    servicio.servicio = {
                        fecha_envio_notificacion: null,
                        fecha_check_out: null,
                        devolucion_fianza: null,
                        baja_luz: null,
                        baja_agua: null,
                        baja_gas: null,
                        baja_internet: null
                    }
                }
                servicios.push(servicio);
            });
            servicios.sort((a, b) => a.order - b.order);
            commit('SET_SERVICIOS', servicios)
        },
        setPaquetes({commit}, data) {
            //Parseo del listado de servicios recibidos
            let paquetes = [];
            data.forEach(pack => {
                let servicios = [];
                pack.services.forEach(ser => {
                    if (ser.subservice_id !== undefined) {
                        servicios.push([ser.service_id, ser.subservice_id]);
                    } else {
                        servicios.push(ser.service_id);
                    }
                });
                let servicios_pre = pack.services.map(ser => {
                    let servi = {
                        id: ser.service_id,
                        id_tabla: ser.id
                    }
                    if (ser.subservice_id !== undefined) servi.sub = ser.subservice_id;
                    return servi;
                });
                
                let paquete = {
                    id: pack.id,
                    nombre: pack.name,
                    orden: pack.weight,
                    info: pack.description,
                    deleted: pack.deleted,
                    servicios: servicios,
                    servicios_pre: servicios_pre
                };
                paquetes.push(paquete);
            });
            paquetes.sort((a, b) => a.orden - b.orden);
            commit('SET_PAQUETES', paquetes);
        }
    }
})
