<template>
    <div id="basica">
        <panel-consejera v-if="usuario_loggeado.tipo === 'TRL' && !$vuetify.breakpoint.xs" @openPreferencias="openPreferencias"
            @openServicios="openServicios" />
        <modal-nuevotrl v-if="modal_nuevotrl && usuario_loggeado.tipo === 'TRL'"
            @closeModalNuevotrl="closeModalNuevotrl" :preferencias="trl_preferencias" :trl_servicios="trl_servicios" />
        <div :class="['view-container', usuario_loggeado.tipo]">
            <div v-if="this.pagina && this.pagina != 'privacidad'" class="row back_route" @click="$router.go(-1);">
                <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.646445 3.64645C0.451183 3.84171 0.451183 4.15829 0.646445 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.73079 7.34027 4.73079 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.73079 0.976311 4.73079 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646445 3.64645ZM16 3.5L0.999999 3.5V4.5L16 4.5V3.5Z"
                        fill="#4F4F4F" />
                </svg>
                <span class="parrafo">{{$t(localeRoute + 'volver')}}</span>
            </div>
            <div v-if="titulo" class="subheader">
                <h3 class="titulo">{{titulo}}</h3>
            </div>
            <div class="contenido card" v-if="!pagina || pagina === 'privacidad'" :class="pagina"
                v-html="$t(localeRoute + 'lerecomendamosquelea')"></div>
            <div class="contenido card" v-else-if="pagina === 'condiciones_alquiler'" :class="pagina"
                v-html="$t(localeRoute + 'condicionesalquiler')"></div>
            <div class="contenido card" v-else-if="pagina === 'madrid'" :class="pagina"
                v-html="$t(localeRoute + 'madrid')"></div>
            <div class="contenido card" v-else-if="pagina === 'espana'" :class="pagina" v-html="$t(localeRoute + 'espana')"></div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import ModalNuevotrl from '@/components/ModalNuevotrl.vue'
    import PanelConsejera from '@/components/PanelConsejera.vue'

    export default {
        name: 'PaginaBasica',
        components: {
            ModalNuevotrl,
            PanelConsejera
        },
        props: ['pagina'],
        data() {
            return {
                localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
                // pagina: null,
                modal_nuevotrl: false,
                trl_preferencias: false,
                trl_servicios: false,
            }
        },
        computed: {
            ...mapState([
                'usuario_loggeado'
            ]),
            titulo() {
                let titulo = '';
                if (!this.pagina || this.pagina === 'privacidad') {
                    titulo = this.$t(this.localeRoute + 'politicadeprivacidad');
                }
                return titulo;
            }
        },

        methods: {
            openPreferencias() {
                this.trl_preferencias = true;
                this.modal_nuevotrl = true;
            },
            openServicios() {
                this.trl_servicios = true;
                this.modal_nuevotrl = true;
            },
            closeModalNuevotrl() {
                this.modal_nuevotrl = false;
                this.trl_preferencias = false;
            }
        },
        created: function () {},
        mounted: function () {
            this.$emit('scrollTop');
            this.$store.dispatch('setLoading', false);
        }
    }
</script>
<style scoped lang="scss">
#app #basica {
    .contenido.card {
        margin-top: 2em;
        // max-width: 70em;
        padding: 3em;
    }
}
@media (max-width: 900px) {
    .v-application #app .view .view-container.TRL {
        padding-top: 2em;
    }
}
</style>