<template>
    <div id="modal" class="modal">
        <service 
            :ws='ws'
            :data="data" 
            @success='onSuccess'  
            @error='console.log($event)'
        />
        <div class="submodal succes" v-if="success">
            <p class="titulo bold">Enviar viviendas</p>
            <div class="boton fab check">
                <v-icon class="icono">mdi-check</v-icon>
            </div>
            <p class="parrafo">Las viviendas se han enviado correctamente.</p>
        </div>
        <div class="submodal" v-else>
            <svg class="cerrar_modal" @click="cerrarModal" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646446 18.6464C0.451185 18.8417 0.451184 19.1583 0.646446 19.3536C0.841709 19.5488 1.15829 19.5488 1.35355 19.3536L10 10.7071L18.6464 19.3536C18.8417 19.5488 19.1583 19.5488 19.3536 19.3536C19.5488 19.1583 19.5488 18.8417 19.3536 18.6464L10.7071 10L19.3536 1.35355C19.5488 1.15829 19.5488 0.841709 19.3536 0.646447C19.1583 0.451185 18.8417 0.451185 18.6464 0.646447L10 9.29289L1.35356 0.646446C1.15829 0.451185 0.841711 0.451185 0.646448 0.646446C0.451186 0.841709 0.451186 1.15829 0.646448 1.35355L9.29289 10L0.646446 18.6464Z" fill="#B4B4B7"/>
            </svg>
            <p class="titulo">Enviar viviendas</p>
            <div class="listas">
                <p class="area_titulo">Seleccionadas</p>
                <div class="lista_viviendas" :class="{'acortar': acortar}">
                    <div v-for="(vivienda, index) in nuevas_viviendas_seleccionadas" :key="index"  :class="{con_nota: vivienda.nota}">
                        <div class="card vivienda">
                            <div class="foto">
                                <img class="foto_principal" :src="vivienda.foto_principal"/>
                            </div>
                            <!-- <div class="dcha" @click="routerFicha(vivienda)"> -->
                            <div class="dcha">
                                <div class="fila arriba">
                                    <p class="small_tit">{{vivienda.barrio}}</p>
                                    <span class="tipo" :class="vivienda.tipo">{{vivienda.tipo}}</span>
                                    <div class="boton fab" @click="removeVivienda(index)">
                                        <v-icon class="icono">mdi-close</v-icon>
                                    </div>
                                </div>
                                <div class="fila">
                                    <p class="parrafo direccion">{{vivienda.direccion}}</p>
                                    <p class="parrafo precio"><span class="bold">{{vivienda.precio}}</span> €/mes</p>
                                </div>
                            </div>
                        </div>
                        <p v-if="!vivienda.nota" class="anadir_nota parrafo" @click="anadirNotaViv(index)">Añadir Nota</p>
                        <v-text-field
                            class="input nota"
                            placeholder="Añadir nota"
                            v-model="vivienda.nota"
                            prefix="Nota: "
                            :ref="'nota_' + index"
                            flat dense solo
                        ></v-text-field>
                    </div>
                </div>
                <p v-if="acortar" @click="acortar = !acortar" class="parrafo bold mostrar_mas">Mostrar todas</p>
                <p class="area_titulo">Links externos</p>
                <div class="lista_links">
                    <div v-for="(enlace, index) in links" :key="index" :class="{con_nota: enlace.nota}">
                        <div class="enlace">
                            <v-text-field
                                class="input"
                                :label="`Enlace ${index + 1}`"
                                v-model="links[index].link"
                                @keyup.enter="addLink"
                            ></v-text-field>
                            <svg v-if="index + 1 == links.length && links[index].link.length" class="anadir" @click="addLink" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.9706 9.48528H9.48528M9.48528 9.48528V1M9.48528 9.48528L1 9.48528M9.48528 9.48528L9.48528 17.9706" stroke="#B4B4B7" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg v-else-if="links[index].link.length" class="anadir" @click="removeLink(index)" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 13L7 7M7 7L13 1M7 7L1 1M7 7L1 13" stroke="#B4B4B7" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <p v-if="!enlace.nota && links[index].link" class="parrafo anadir_nota" @click="anadirNotaLink(index)">Añadir Nota</p>
                        <v-text-field
                            class="input nota"
                            placeholder="Añadir nota"
                            v-model="enlace.nota"
                            prefix="Nota: "
                            flat dense solo
                            :ref="'nota_link_' + index"
                        ></v-text-field>
                    </div>
                </div>
            </div>
            <v-btn class="boton cta" @click="enviar" :loading="loading">Enviar</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Service from '@/components/Service.vue'

export default {
	name: 'ModalEnviarViviendas',
    components: {Service},

    props: [
        "usuario", "viviendas_seleccionadas"
    ],
    data() {
        return {
            links: [{link: '', nota: null}],
            nuevas_viviendas_seleccionadas: [],
            success: false,
            acortar: false,
            loading: false,
            loader: null,
            ws: null,
            data: null
        }
    },

    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 5000)
            this.loader = null
        },
    },

	computed:  {
        ...mapState([
            'viviendas', 'usuarios', 'usuario_loggeado'
        ])
	},

    methods: {
        cerrarModal() {
            this.$emit("closeModalEnviarViviendas", false);
            this.viviendas_seleccionadas = this.nuevas_viviendas_seleccionadas;
        },
        addLink() {
            var container = this.$el.querySelector(".listas");
            const prev_height = container.scrollHeight;
            this.links.push({link: '', nota: null});
            setTimeout(()=> {
                container.scrollTo({
                    top: container.scrollTop + (container.scrollHeight - prev_height),
                    behavior: 'smooth'
                }); 
            }, 200);
        },
        removeLink(index) {
            this.links.splice(index, 1);
        },
        removeVivienda(index) {
            this.viviendas_seleccionadas.splice(index, 1);
            this.nuevas_viviendas_seleccionadas.splice(index, 1);
        },
        anadirNotaViv(index) {
            const ref = 'nota_' + index;
            this.nuevas_viviendas_seleccionadas[index].nota = ' ';
            this.$refs[ref][0].focus();
        },
        anadirNotaLink(index) {
            // var container = this.$el.querySelector(".listas");
            const ref = 'nota_link_' + index;
            // const prev_height = container.scrollHeight;
            this.links[index].nota = ' ';
            this.$refs[ref][0].focus();
            // setTimeout(()=> {
            //     container.scrollTo({
            //         top: container.scrollTop + (container.scrollHeight + 50 - prev_height),
            //         behavior: 'smooth'
            //     });
            // }, 200);
        },
        enviar() {
            this.loader = 'loading';
            const number = this.$moment().format('YYMMDDHHmm') + '01';
            this.data = {id: this.usuario.id, sender: this.usuario_loggeado.id, number: number, links_int: this.nuevas_viviendas_seleccionadas, links_ext: this.links};
            this.ws = 'trlAssignHomes';
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            if (response.ws === 'trlAssignHomes') {
                let trl = this.usuario;
                trl.updater = this.usuario_loggeado.id;
                let nuevas_viviendas_seleccionadas = [];
                !trl.servicio_vivienda.viviendas_preseleccionadas ? trl.servicio_vivienda.viviendas_preseleccionadas = [] : '';
                this.nuevas_viviendas_seleccionadas.forEach(viv => trl.servicio_vivienda.viviendas_preseleccionadas.push(viv.id));
                trl.servicio_vivienda.viviendas_preseleccionadas_links = this.links;
                this.data = trl;
                this.ws = 'trlHomeContract';
            } else if (response.ws === 'trlHomeContract') {
                this.success = 'vivienda_enviada';
                setTimeout(() => this.$emit("closeModalEnviarViviendas", 'ok'), 1500);
                this.nuevas_viviendas_seleccionadas = [];
                // const tipo = this.usuario_loggeado.tipo;
                // this.data = {queries: '?order_by=recents&include_finished=no'}
                // if (tipo === 'Admin') {this.data.queries += '&type=trl,admin,cons,rrhh';}
                // else if (tipo === 'Consejera') {this.data.queries += '&type=trl,cons,rrhh&cons=' + this.usuario_loggeado.id;}
                // this.ws = 'userList'
            } else if (response.ws === 'userList') {
                // let usuarios = response.data;
                // this.$store.dispatch('setUsuarios', usuarios);
            }
        }
	},
    created: function() {
        this.console = window.console;
        this.viviendas_seleccionadas.length > 7 ? this.acortar = true : this.acortar = false;
        this.nuevas_viviendas_seleccionadas = [...this.viviendas_seleccionadas];
    },
    mounted: function() {
    }
}
</script>
<style scoped lang="scss">
#app #modal .submodal {
    min-height: 40vh;
    max-width: 60rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    &.succes {
        text-align: center;
        display: flex;
        flex-flow: column;
        padding-top: 1em;
        .boton.check {
            width: 6em;
            height: 6em;
            margin: 3em 0 5em;
            margin-left: calc(50% - 3em);
            text-align: center;
            background-color: #27AE60;
            .icono{color: white;}
        }
        .titulo {
            width: auto;
            margin: 2em auto 1em;
        }
        .parrafo {margin: 0 0 2em;}
    }
    .cta {margin-left: auto;}
    .area_titulo {
        font-weight: bold;
        font-size: 1.2em;
        margin-bottom: 1em;
    }
    .parrafo.anadir_nota {
        color: var(--purple03);
        cursor: pointer!important;
        display: inline-block;
        position: relative;
        z-index: 10;
    }
    .input.nota {
        &::v-deep .v-input__slot {padding: 0!important;}
        &::v-deep .v-text-field__details {display: none;}
    }
    .listas {
        overflow-y: auto;
        height: 57vh;
        padding: 0 1em;
        width: calc(100% + 2em);
        margin-left: -1em;
        .mostrar_mas {
            display: block;
            margin: 1em auto;
            text-align: center;
            color: var(--purple03);
            cursor: pointer;
        }
        .nota {opacity: 0; margin-top: -.5em;}
        .con_nota {
            .anadir_nota {display: none;}
            .nota {opacity: 1;}
        } 
        .lista_viviendas {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 3%;
            &:not(.acortar){margin-bottom: 5em;}
            &.acortar {
                height: 75%;
                overflow: hidden;
            }
            .card.vivienda {
                position: relative;
                height: 7em;
                display: flex;
                padding: 0;
                overflow: hidden;
                cursor: pointer;
                .foto {
                    text-align: center;
                    width: 25%;
                    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);
                    .foto_principal {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .dcha {
                    width: 75%;
                    padding: 1em;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    .fila {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        &.arriba {
                            align-items: flex-start;
                            .small_tit {
                                text-transform: uppercase;
                                font-weight: bold;
                                font-size: 1em;
                                margin-top: .1em;
                            }
                            .tipo {
                                margin-left: auto;
                                width: 7em;
                                padding: .5em;
                                font-size: .8em;
                                font-weight: bold;
                                text-transform: uppercase;
                                color: white;
                                text-align: center;
                                border-radius: 50px;
                                &.Piso {
                                    background-color: #61C6FF;
                                    border: 0.5 solid #2D9CDB;
                                }
                                &.Casa {
                                    background-color: #A3E07E;
                                    border: 0.5 solid #27AE60;
                                }
                                &.Atico {
                                    background-color: #F9D067;
                                    border: 0.5 solid #F2994A;
                                }
                            }
                            .boton {
                                margin-left: 1em;
                                // position: absolute;
                                width: 2em;
                                height: 2em;
                                // right: .5em;
                                // top: .5em;
                                background-color: white;
                                box-shadow: var(--sombra_ext);
                                .icono {color: #EB5757}
                            }
                        }
                        .precio {
                            min-width: 35%;
                            text-align: right;
                            .bold {font-size: 1.5em;}
                        }
                        .direccion {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .lista_links {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 3%;
            .enlace {
                height: 4em;
                // .input {
                //     margin: .5em 0;
                // }
                .anadir {
                    position: relative;
                    left: 96%;
                    bottom: 3.5em;
                    cursor: pointer;
                }
            }
            & ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {border-color: #eee;}
            & ::v-deep .v-messages {display: none;}
        }
    }
}
@media (max-width: 900px) {
    #app #modal .submodal {
        .listas {
            .lista_links {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
@media (max-width: 760px) {
    #app #modal .submodal {
        .listas {
            .lista_viviendas {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        
    }
}
</style>
