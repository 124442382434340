<template>
<v-app>
<!-- <div id="app" v-if="ready"> -->
<div id="app" ref="app">
    <servicios-back 
        @logged="logged = true"
        @errorImportante="modal_simple_error = true"
    />
    <service 
        :ws='ws'
        :data="data" 
        :spinner="ws_spinner"
        @success='onSuccess'  
        @error='console.log($event)'
    />
    <modal-simple v-if="modal_simple" :tipo="'cambiar_contraseña'" :usuario="usuario_loggeado" @closeModalSimple="closeModalSimple"/>
    <modal-simple v-if="modal_simple_error" :tipo="'error_servidor'" @closeModalSimple="closeModalSimple"/>
    <div class="header" v-if="(logged && $router.currentRoute.name != 'Login') || $router.currentRoute.name === 'FichaVivienda'">
        <router-link :to="usuario_loggeado.tipo != 'Legal' ? '/Home' : '/usuarios'" class="logo" @click="perfil_open = false">
            <img src="images/logo.png" />
        </router-link>
        <div class="nav-perfil-wrapper">
            <div class="nav" v-if="logged && $router.currentRoute.name != 'Login'">
                <router-link to="/Home" class="nav_link" v-if="usuario_loggeado.tipo != 'Legal' ">{{ $t(localeRoute + 'home') }}</router-link>
                <router-link to="/usuarios" class="nav_link" v-if="usuario_loggeado.tipo != 'TRL' ">{{ $t(localeRoute + 'usuarios') }}</router-link>
                <router-link to="/viviendas" class="nav_link" v-if="usuario_loggeado.tipo != 'RRHH' && usuario_loggeado.tipo != 'Legal' ">{{ $t(localeRoute + 'viviendas') }}</router-link>
                <router-link to="/empresas" class="nav_link" v-if="usuario_loggeado.tipo === 'Admin'">{{ $t(localeRoute + 'empresas') }}</router-link>
                <router-link to="/documentacion" class="nav_link" v-if="usuario_loggeado.tipo === 'TRL'">{{ $t(localeRoute + 'documentacion') }}</router-link>
                <router-link to="/servicios" class="nav_link" v-if="usuario_loggeado.tipo === 'Admin'">Servicios</router-link>
            </div>
            <div class="perfil" v-if="logged && $router.currentRoute.name != 'Login'" :class="{'perfil_open': perfil_open}">
                <!-- <div class="notificaciones">
                    <img src="images/icono_notificaciones.svg" />
                </div> -->
                <div class="personal" @click="perfil_open = true">
                    <div class="avatar_container">
                        <img class="avatar" :src="usuario_loggeado.personales.foto || 'images/avatar_placeholder.jpg'" />
                        <span class="editar" v-if="perfil_open">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.2424 1.31905C9.58166 0.658299 8.51545 0.658299 7.8547 1.31905L1.16835 8.0054C1.1233 8.05045 1.08951 8.10676 1.07074 8.17059L0.19224 11.3467C0.154697 11.4781 0.19224 11.617 0.289851 11.7109C0.383707 11.8047 0.52637 11.8423 0.654015 11.8085L3.83012 10.93C3.89395 10.9112 3.95026 10.8812 3.99531 10.8324L10.6817 4.14226C11.3387 3.48151 11.3387 2.4153 10.6817 1.75454L10.2424 1.31905ZM1.98302 8.24943L7.45675 2.7757L9.22125 4.54021L3.74753 10.0177L1.98302 8.24943ZM1.63012 8.95898L3.04173 10.3706L1.08951 10.9112L1.63012 8.95898ZM10.1523 3.61291L9.75436 4.01086L7.98985 2.24635L8.3878 1.8484C8.75197 1.48424 9.3489 1.48424 9.71306 1.8484L10.1523 2.28765C10.5165 2.65557 10.5165 3.24874 10.1523 3.61291Z" fill="#4F4F4F"/>
                            </svg>
                        </span>
                    </div>
                    <p class="parrafo nombre">{{`${usuario_loggeado.personales.nombre} ${usuario_loggeado.personales.apellidos}`}}</p>
                </div>
                <div class="personal-open" v-if="perfil_open">
                    <div class="avatar_container" @click="avatarClick">
                        <img class="avatar" :src="usuario_loggeado.personales.foto || 'images/avatar_placeholder.jpg'" />
                        <span class="editar" v-if="perfil_open">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.2424 1.31905C9.58166 0.658299 8.51545 0.658299 7.8547 1.31905L1.16835 8.0054C1.1233 8.05045 1.08951 8.10676 1.07074 8.17059L0.19224 11.3467C0.154697 11.4781 0.19224 11.617 0.289851 11.7109C0.383707 11.8047 0.52637 11.8423 0.654015 11.8085L3.83012 10.93C3.89395 10.9112 3.95026 10.8812 3.99531 10.8324L10.6817 4.14226C11.3387 3.48151 11.3387 2.4153 10.6817 1.75454L10.2424 1.31905ZM1.98302 8.24943L7.45675 2.7757L9.22125 4.54021L3.74753 10.0177L1.98302 8.24943ZM1.63012 8.95898L3.04173 10.3706L1.08951 10.9112L1.63012 8.95898ZM10.1523 3.61291L9.75436 4.01086L7.98985 2.24635L8.3878 1.8484C8.75197 1.48424 9.3489 1.48424 9.71306 1.8484L10.1523 2.28765C10.5165 2.65557 10.5165 3.24874 10.1523 3.61291Z" fill="#4F4F4F"/>
                            </svg>
                        </span>
                        <v-file-input 
                            ref="fileInput"
                            id="fileInput"
                            v-model="user_avatar"
                            accept="image/*"
                            style="display: none"
                        />
                    </div>
                    <p class="small">{{!usuario_loggeado.personales.foto ? $t(localeRoute + 'puedesubir') : $t(localeRoute + 'puedeeditar') }}</p>
                    <p class="parrafo nombre">{{`${usuario_loggeado.personales.nombre} ${usuario_loggeado.personales.apellidos}`}}</p>
                    <v-select
                        v-if="usuario_loggeado.tipo != 'Admin' && usuario_loggeado.tipo != 'Consejera'"
                        :items="['Español', 'English']"
                        v-model="idioma"
                        @change="changeLang"
                        class="select_idioma"
                    ></v-select>
                    <p class="parrafo contrasena" @click="clickContrasena">{{$t(localeRoute + 'cambiarcontrasena')}}</p>
                    <p class="parrafo bold salir" @click="logout">{{ $t(localeRoute + 'salir') }}</p>
                </div>
            </div>
            <v-menu top :close-on-click="closeOnClick" v-if="logged && $router.currentRoute.name != 'Login'">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="nav-menu-dropdown"
                        color="primary"
                        text
                        fab
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-if="usuario_loggeado.tipo != 'Legal' ">
                        <v-list-item-title >
                            <router-link to="/Home" class="nav_link">{{ $t(localeRoute + 'home') }}</router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="usuario_loggeado.tipo != 'TRL'">
                        <v-list-item-title>
                            <router-link to="/usuarios" class="nav_link" >{{ $t(localeRoute + 'usuarios') }}</router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="usuario_loggeado.tipo != 'RRHH' && usuario_loggeado.tipo != 'Legal' ">
                        <v-list-item-title >
                            <router-link to="/viviendas" class="nav_link">{{ $t(localeRoute + 'viviendas') }}</router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="usuario_loggeado.tipo === 'Admin'">
                        <v-list-item-title>
                            <router-link to="/empresas" class="nav_link" >{{ $t(localeRoute + 'empresas') }}</router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="usuario_loggeado.tipo === 'TRL'">
                        <v-list-item-title>
                            <router-link to="/documentacion" class="nav_link">{{ $t(localeRoute + 'documentacion') }}</router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="usuario_loggeado.tipo === 'Admin'">
                        <v-list-item-title>
                            <router-link to="/servicios" class="nav_link">Servicios</router-link>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
	<main class="view" :class="{login: (!logged && $router.currentRoute.name != 'FichaVivienda') || $router.currentRoute.name === 'Login'}" @click="perfil_open = false">
        <router-view @logged="logged = true" @scrollTop="scrollTop" />
        <div class="spinner" v-if="loading">
            <svg class="spinner_svg" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </div>
	</main>
    <div class="footer">
        <a href="#" @click="newTabPrivacidad">{{ $t(localeRoute + 'politicadeprivacidad') }}</a>
        <a class="estudio" href="https://www.estudio143.com/" target="blank">Design by Estudio143</a>
    </div>
</div>
</v-app>
</template>

<script>
import { mapState } from 'vuex'
import {webServices} from '@/api/api.js'
import ServiciosBack from '@/components/ServiciosBack.vue'
import Service from '@/components/Service.vue'
import ModalSimple from '@/components/ModalSimple.vue'

export default {
    name: "App",

    components: {
        ServiciosBack,
        Service,
        ModalSimple
    },

    data() {
        return { 
            localeRoute: `message.${[this.$options.name.toLowerCase()]}.`,
            modal_simple: false,
            modal_simple_error: false,
            logged: false,
            ready: false,
            idioma: 'Español',
            perfil_open: false,
            user_avatar: null,
            ws_spinner: false,
            ws: null,
            data: null
        };
    },
    computed: {
        ...mapState([
            'usuario_loggeado', 'usuarios', 'token', 'sectores', 'loading', 'idiomaGlobal'
        ]),
    },

    watch: {
        user_avatar() {
            this.subirFoto();
        },
        idiomaGlobal() {
            this.checkLang();
            // if (this.usuario_loggeado && (this.usuario_loggeado.tipo === 'TRL' || this.usuario_loggeado.tipo === 'RRHH')) {
            //     this.data = {language: this.idiomaGlobal, user_id: this.usuario_loggeado.id};
            //     this.ws = 'preferredLanguage';
            // }
        },
        usuario_loggeado() {
            this.checkLang();
        }
    },

    created: function() {
        this.console = window.console;
        
        if (this.$route.query['satisfaction_form']) {
            let link = this.$route.query['satisfaction_form'] === 'email_es' ? 'https://web508514.typeform.com/to/CWXTD5Uh' : 'https://web508514.typeform.com/to/JaWiM2Nh';
            window.location.replace(link);
        };
        this.checkLang();
        this.setAppLang();
    },

    mounted: function() {
    },

    methods: {
        scrollTop() {
            this.$refs.app.scrollTop = 0;
        },
        avatarClick() {
            // console.log(this.$refs.fileInput.$el);
            // this.$refs.fileInput.click();
            document.getElementById("fileInput").click()
        },
        checkLang() {
            const userLang = navigator.language || navigator.userLanguage; 
            if (!this.idiomaGlobal) {
                if (this.usuario_loggeado.tipo === 'Admin' || this.usuario_loggeado.tipo === 'Consejera') {
                    this.idioma = 'Español';
                } else if (this.usuario_loggeado.id && this.usuario_loggeado.idioma_seleccionado) {
                    if (this.usuario_loggeado.idioma_seleccionado === 'es') {
                        this.idioma = 'Español';
                        this.$store.dispatch('setIdioma', 'es');
                    } else {
                        this.idioma = 'English';
                        this.$store.dispatch('setIdioma', 'en');
                    }
                } else {
                    if (userLang === 'es-ES') {
                        this.idioma = 'Español';
                        this.$store.dispatch('setIdioma', 'es');
                    } else {
                        this.idioma = 'English';
                        this.$store.dispatch('setIdioma', 'en');
                    }
                }
            } else {
                if (this.usuario_loggeado.id && (this.usuario_loggeado.tipo === 'TRL' || this.usuario_loggeado.tipo === 'RRHH') && (this.idiomaGlobal === 'es' || this.idiomaGlobal === 'en' && this.usuario_loggeado.idioma_seleccionado != this.idiomaGlobal)) {
                    this.data = {language: this.idiomaGlobal, user_id: this.usuario_loggeado.id};
                    this.ws_spinner = false;
                    this.ws = 'preferredLanguage';
                } else if (this.usuario_loggeado.tipo === 'TRL' || this.usuario_loggeado.tipo === 'RRHH') {
                    if (this.idiomaGlobal === 'es') {
                        this.idioma = 'Español';
                    } else {
                        this.idioma = 'English';
                    }
                } else {
                    this.idioma = this.idiomaGlobal === 'en' ? 'English' : 'Español';
                    this.$store.dispatch('setIdioma', this.idiomaGlobal || 'es');
                }
            }
        },

        setAppLang() {
            if (this.usuario_loggeado.tipo === 'Admin' || this.usuario_loggeado.tipo === 'Consejera') {
                this.$moment.locale('es');
                this.$i18n.locale = 'es';
                this.$vuetify.lang.current = 'es';
            } else {
                this.$moment.locale(this.idiomaGlobal);
                this.$i18n.locale = this.idiomaGlobal;
                this.$vuetify.lang.current = this.idiomaGlobal;
            }
        },

        changeLang(lang) {
            this.$store.dispatch('setIdioma', lang === 'English' ? 'en' : 'es');
            this.setAppLang();
        },
        
        logout() {
            // console.log('logout');
            localStorage.removeItem('isLogged');
            localStorage.removeItem('viviendas_preseleccionadas');
            localStorage.removeItem('usuario_logged');
            localStorage.removeItem('toquen');
            localStorage.removeItem('ult_con');
            this.logged = false;
            this.$router.push('/');
            this.$store.dispatch('setUsuarioLoggeado', {});
        },
        subirFoto() {
            this.data = {
                id: this.usuario_loggeado.id,
                img: this.user_avatar,
                nombre: this.user_avatar.name
            };
            this.ws_spinner = true;
            this.ws = 'userPhoto';
        },
        clickContrasena() {
            this.modal_simple = true;
            this.perfil_open = false;
        },
        closeModalSimple(action) {
            this.modal_simple = false;
            this.modal_simple_error = false;
        },
        newTabPrivacidad() {
            let routeData = this.$router.resolve({name: 'PoliticaPrivacidad', params: {'pagina': 'privacidad'}});
            window.open(routeData.href, '_blank');
        },
        onSuccess(response) {
            this.ws = null;
            this.data = null;
            this.ws_spinner = false;
            if (response.ws === 'userPhoto') {
                this.data = this.usuario_loggeado.enc_id;
                this.ws = 'getUserEnc';
            } else if (response.ws === 'getUser' || response.ws === 'getUserEnc') {
                this.$store.dispatch('setUsuarioLoggeado', response.data);
            } else if (response.ws === 'preferredLanguage') {
                this.checkLang();
            }
        }
    }
  };
</script>

<style scoped lang="scss">
.nav-menu-dropdown {
    display: none;
}
.v-application #app {
    position: absolute;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    overflow-y: scroll;

    .header {
        position: fixed;
        width: 100%;
        height: 5em;
        top: 0;
        left: 0;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
        padding: 0 2em;
        z-index: 100;
        .logo {
            img {max-width: 8em;}
        }
        .nav-perfil-wrapper {
           display: flex;
           align-items: center; 
        }
        .nav {
            .nav_link {
                padding-bottom: .2em;
                &.router-link-active {
                    font-weight: 600;
                    border-bottom: 2px solid #A291D6;
                    font-weight: bold;
                }
                &:hover {
                    font-weight: bold;
                }
            }
        }
        
        .perfil {
            margin-left: 2em;
            &:not(.open) {cursor: pointer;}
            .notificaciones {margin: 0 1em;}
            .personal {
                align-items: center;
                display: flex;
                .nombre {padding-left: .5em;}
                .avatar {
                    height: 3em;
                    width: 3em;
                    object-fit: cover;
                    margin: 0 .5em;
                }
                &:hover:not(.open) .nombre {color: var(--purple03);}
            }
            &.perfil_open {
                .personal-open {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    flex-flow: column;
                    padding: 1em;
                    background-color: white;
                    border-radius: 5px;
                    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.15);
                    z-index: 5;
                    .avatar_container {
                        position: relative;
                        width: 6em;
                        cursor: pointer;
                        &:hover {
                            opacity: .7;
                        }
                        .avatar {
                            height: 6em;
                            width: 6em;
                            margin: 0;
                            margin-bottom: 1em;
                            object-fit: cover;
                        }
                        .editar {
                            position: absolute;
                            width: 2em;
                            height: 2em;
                            bottom: 20px;
                            right: 0;
                            background-color: white;
                            padding: 8px;
                            border-radius: 2em;
                            display: flex;
                            box-shadow: var(--sombra_ext);
                        }
                    }
                    .small {
                        width: 100%;
                        text-align: center; 
                        color: var(--gray03);
                        padding-bottom: 1em; 
                        border-bottom: 1px solid var(--gray02);
                    }
                    .nombre {
                        width: 100%; 
                        text-align: center;
                        padding: 1em 0; 
                        border-bottom: 1px solid var(--gray02);
                        font-weight: bold;
                        cursor: default;
                    }
                    .contrasena {
                        width: 100%;
                        // text-align: left;
                        padding: 1em 0;
                        border-bottom: 1px solid var(--gray02);
                        &:hover {color: var(--purple03);}
                    }
                    .select_idioma {
                        margin: 0;
                        border-bottom: 1px solid var(--gray02);
                        z-index: 101;
                        ::v-deep .v-text-field__details {display: none;}
                    }
                    ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {display: none}
                    // ::v-deep .v-text-field > .v-input__control > .v-input__slot:after {display: none;}
                    .salir {
                        padding-top: 1em;
                        color: var(--purple03);
                        cursor: pointer;
                    }
                }
            }
        }
        @media (max-width: 1150px) {
            .perfil {
                .personal .parrafo {
                    display: none;
                }
            } 
        }
    }
    .view {
        position: relative;
        padding: 5em 0 0;
        margin-bottom: 3.5em;
        min-height: calc(100vh - 3.5em);
        &.login {
            background-color: white;
            padding: 0;
        }
        .spinner {
            position: absolute;
            top: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(255,255,255, .7);
            z-index: 1000;

            .spinner_svg {
                animation: rotate 2s linear infinite;
                z-index: 2;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -25px 0 0 -25px;
                width: 50px;
                height: 50px;
                
                & .path {
                    stroke: var(--purple03);
                    stroke-linecap: round;
                    animation: dash 1.5s ease-in-out infinite;
                }
                
            }

            @keyframes rotate {
                100% {transform: rotate(360deg);}
            }

            @keyframes dash {
                0% {
                    stroke-dasharray: 1, 150;
                    stroke-dashoffset: 0;
                }
                50% {
                    stroke-dasharray: 90, 150;
                    stroke-dashoffset: -35;
                }
                100% {
                    stroke-dasharray: 90, 150;
                    stroke-dashoffset: -124;
                }
            }
        }
    }
    .footer {
        position: fixed;
        display: flex;
        width: 100%;
        padding: 1em;
        bottom: 0;
        left: 0;
        background-color: #F7F8F9;
        box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);
        z-index: 100;
        a {
            color: var(--color_texto);
            &.estudio {margin-left: auto; color: var(--purple03)}
        }
    }

    //MOBILE
    @media (max-width: 900px) {
        .nav-menu-dropdown {
            display: block;
            .nav_link {
                padding-bottom: .2em;
                &.router-link-active {
                    font-weight: 600;
                    border-bottom: 2px solid #A291D6;
                    font-weight: bold;
                }
                &:hover {
                    font-weight: bold;
                }
            }
        }
        .header {
            padding-right: .3em;
            .nav {
                display: none;
            }
        }
    }
    @media (max-width:760px) {
        .view {
            margin-bottom: 2.8em;
        }
        .footer {
            padding: .8em 1.5em;
            a {
                font-size: 12px;
            }
        }
    }
}
</style>
