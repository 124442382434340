<template>
    <v-menu
        v-if="!multiple"
        class="date_picker"
        v-model="open"
        transition="scale-transition"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-on="on"
                v-model="fecha_formateada"
                :label="label"
                :disabled="disabled"
                readonly
                clearable
                @click:clear="fecha = ''"
                @click:append="open = true"
                append-icon="mdi-calendar"
            ></v-text-field>
        </template>
        <v-date-picker v-model="fecha" @input="okClick()" :min="futuro ? fecha_inicio : ''" :first-day-of-week="1"></v-date-picker>
    </v-menu>
    <v-menu
        v-else
        class="date_picker"
        ref="menu"
        v-model="open"
        transition="scale-transition"
        :close-on-content-click="false"
        :return-value.sync="fechas"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-combobox
                v-model="fechas_formateadas"
                v-on="on"
                :label="label"
                :disabled="disabled"
                multiple
                readonly
                chips
                small-chips
                @click:append="open = true"
                append-icon="mdi-calendar"
            ></v-combobox>
        </template>
        <v-date-picker v-model="fechas" multiple no-title scrollable :min="futuro ? fecha_inicio : ''" :first-day-of-week="1">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="open = false">Cancel</v-btn>
            <v-btn text color="primary" @click="okClick(fechas)">OK</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>

export default {
	name: 'StDatePicker',
	props: ['label', 'disabled', 'multiple', 'futuro', 'old_fecha'],

    data() {
        return {
			open: false,
            fecha: null,
            rules: {
                dateValid: value => this.$moment(value).isValid()
            },
            fechas: []
		}
    },

    watch: {
        fecha() {
            this.$emit("nueva_fecha", this.fecha);
            this.fecha != this.old_fecha ? this.$emit("change", this.fechas) : '';
        },
        fechas() {
            this.$emit("nueva_fecha", this.fechas);
        },
        old_fecha() {
            if (this.multiple) {
                this.old_fecha ? this.fechas = this.old_fecha : ''
            } else {
                this.old_fecha ? this.fecha = this.old_fecha : ''
            }
        }
    },
    computed: {
        fecha_formateada() {
            return this.fecha ? this.$moment(this.fecha).format('DD/MM/YYYY') : null;
        },
        fechas_formateadas() {
            let fechas_formateadas = [];
            this.fechas.forEach(fecha => {
                fechas_formateadas.push(this.$moment(fecha).format('DD/MM/YYYY'));
            });
            return fechas_formateadas;
        },
        fecha_inicio() {
            let inicio = this.$moment().format('YYYY-MM-DD');
            return inicio;
        }
    },

    created: function() {
        if (this.old_fecha) {
            this.multiple ? this.fechas = this.old_fecha : this.fecha = this.old_fecha;
        }
    },
    mounted: function() {},

    methods: {
        okClick(fechas) {
            this.multiple ? this.$refs.menu.save(fechas) : '';
            this.$emit("change", this.fechas);
            this.open = false;
        }
	}
}
</script>

<style scoped lang="scss">
    ::v-deep .v-icon {
        font-size: 1.4em !important;
        color: #747574 !important;
    }
</style>

